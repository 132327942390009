import { IAxiosErrorResponse } from "../contracts/models/serverResponseContracts";
import { HttpStatusCode } from "../contracts/httpStatusCodes";

export const ParseErrorResponse = (error: IAxiosErrorResponse, defaultError: string = "") => {
    if (!error) {
        return defaultError;
    }

    if (!error.response) {
        return error.toString();
    }

    if (!error.response.data) {
        return error.toString();
    }

    if (error.response.status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
        // This is a validation Error
        return "Validation Error encountered.";
    }

    return error.response.data.ErrorMessage;
};
