import { action } from "typesafe-actions";
import { TargetingActionTypes } from ".";
import { SiufDeviceType, SiufTargetType, SiufTargetRingSelection, SiufTargetRingChannelSelection, SiufRing, SiufRingChannel, IUpdateJustificationModel, IUpdateDeviceFilterModel } from "../../../contracts";

export const updateDeviceType = (deviceType: SiufDeviceType) => action(
    TargetingActionTypes.UPDATE_DEVICE_TYPE,
    {
        deviceType
    }
);

export const updateMinBuild = (minBuild: number) => action(
    TargetingActionTypes.UPDATE_MINBUILD,
    {
        minBuild
    }
);

export const updateMaxBuild = (maxBuild: number) => action(
    TargetingActionTypes.UPDATE_MAXBUILD,
    {
        maxBuild
    }
);

export const updateTargetingType = (targetingType: SiufTargetType) => action(
    TargetingActionTypes.UPDATE_TARGETING_TYPE,
    {
        targetingType
    }
);

 export const updateTargetingRing = (ringSelection: SiufTargetRingSelection, siufRings?: SiufRing[]) => action(
     TargetingActionTypes.UPDATE_TARGETING_RING,
     {
         ringSelection,
         siufRings
     }
 );

export const updateTargetingRingChannel = (ringChannelSelection: SiufTargetRingChannelSelection, siufRingChannels?: SiufRingChannel[]) => action(
    TargetingActionTypes.UPDATE_TARGETING_RINGCHANNEL,
    {
        ringChannelSelection,
        siufRingChannels
    }
);

export const updateAudienceName = (audienceName: string) => action(
    TargetingActionTypes.UPDATE_AUDIENCE_NAME,
    {
        audienceName
    }
);

export const updateDeviceIdUri = (deviceIdUri: string) => action(
    TargetingActionTypes.UPDATE_DEVICEID_URI,
    {
        deviceIdUri
    }
);

export const updateCustomGroupIds = (customGroupIds: string[]) => action(
    TargetingActionTypes.UPDATE_CUSTOM_GROUP_IDS,
    {
        customGroupIds
    }
);

export const updateTestSystemFilter = (includeTestSystems: boolean) => action(
    TargetingActionTypes.UPDATE_INCLUDE_TEST_SYSTEM,
    {
        includeTestSystems
    }
);

export const updateJustifications = (justification: IUpdateJustificationModel) => action(
    TargetingActionTypes.UPDATE_JUSTIFICATION,
    {
        justification
    }
);

export const addDeviceFilter = () => action(
    TargetingActionTypes.ADD_DEVICE_FILTER
);

export const removeDeviceFilter = (index: number) => action(
    TargetingActionTypes.REMOVE_DEVICE_FILTER,
    {
        index
    }
);

export const updateDeviceFilter = (index: number, deviceFilter: IUpdateDeviceFilterModel) => action(
    TargetingActionTypes.UPDATE_DEVICE_FILTER,
    {
        index,
        deviceFilter
    }
);

export const updateToastText = (toastText: string) => action(
    TargetingActionTypes.UPDATE_TOAST_TEXT,
    {
        toastText
    }
);
