import React from 'react';
import { Segment, Header, Icon } from 'semantic-ui-react';

type Props = {
    header?: string;
    message: string;
};

const LoadingPage: React.FC<Props> = props => {
    const heeaderMessage = props.header || "Please wait a moment...";
    return (
        <Segment placeholder textAlign="center" size="big">
            <Header icon>
                <Icon name='circle notched' loading color="blue" />
                { heeaderMessage }
            </Header>
            { props.message }
            { props.children }
        </Segment>
    );
};

export default LoadingPage;