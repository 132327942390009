import React from 'react';
import { Divider, Header, Icon, List, Menu, Popup } from 'semantic-ui-react';
import UserProfileService from '../../userProfile';

type Props = {
    userName: string;
    onNavigateToNewSiufPage: () => void;
    onNavigateToExistingSiufPage: (siufId: number) => void;
    onNavigateToReviewsPage: () => void;
};

const AppHeader: React.FC<Props> = props => {

    const isUserReviewer = UserProfileService.IsCurrentUserReviewer();

    let searchSiufInput: HTMLInputElement;

    return (
        <Menu stackable fluid color="blue">
            <Menu.Menu onClick={ () => {
                if (searchSiufInput && searchSiufInput.value && Number(searchSiufInput.value) > 0) {
                    const val = Number(searchSiufInput.value);
                    searchSiufInput.value = "";
                    props.onNavigateToExistingSiufPage(val);
                }
            } }>
                <div className='ui right aligned category search item'>
                    <div className='ui transparent icon input'>
                        <input ref={ node => searchSiufInput = node }
                            onKeyPress={ e => {
                                if (e.key === 'Enter') {
                                    const val = Number(searchSiufInput.value);
                                    searchSiufInput.value = "";
                                    props.onNavigateToExistingSiufPage(val);
                                }
                            } }
                            className='prompt'
                            type='number'
                            placeholder='Enter SIUF Id'
                            tabIndex="1"
                        />
                        <i className='search link icon' />
                    </div>
                    <div className='results' />
                </div>
            </Menu.Menu>
            <Menu.Item header onClick={ () => props.onNavigateToNewSiufPage() }
                onKeyPress={
                    e => {
                        if (e.charCode == "32") {
                            props.onNavigateToNewSiufPage();
                        }
                    }}
                tabIndex="2">
                <Icon name="edit" size="large" color="black" />
                Create a new SIUF
            </Menu.Item>
            {
                isUserReviewer
                &&
                <Menu.Item header onClick={ () => props.onNavigateToReviewsPage() }
                    onKeyPress={
                        e => {
                            if (e.charCode == "32") {
                                props.onNavigateToReviewsPage();
                            }
                        }
                    }
                    tabIndex="3">
                    <Icon name="unordered list" size="large" color="black" />
                    Review SIUFs (Admins)
                </Menu.Item>
            }
            <Menu.Menu position="right">
                <Menu.Item>
                    { props.userName }
                </Menu.Item>
            </Menu.Menu>
            {
                isUserReviewer
                    ?
                    <Menu.Item header>
                        <Popup trigger={ <Icon name="registered outline" size="large" color="purple" /> } >
                            <Header as='h3'>
                                SIUF Reviewer permissions:
                            </Header>
                            <List bulleted>
                                <List.Item>Creating SIUF</List.Item>
                                <List.Item>Editing SIUF</List.Item>
                                <List.Item>Submit SIUF for Review</List.Item>
                                <List.Item>Withdraw SIUF from Review</List.Item>
                                <List.Item>Terminate active SIUF</List.Item>
                                <List.Item>Reactivate terminated SIUF</List.Item>
                                <Divider />
                                <List.Item>Approve SIUF in Review</List.Item>
                                <List.Item>Terminate SIUF in Review</List.Item>
                            </List>
                            <Divider hidden />
                        </Popup>
                        Reviewer
                    </Menu.Item>
                    :
                    <Menu.Item header>
                        <Popup trigger={ <Icon name="user circle outline" size="large" color="blue" /> } >
                            <Header as='h3'>
                                SIUF Author permissions:
                            </Header>
                            <List bulleted>
                                <List.Item>Creating SIUF</List.Item>
                                <List.Item>Editing SIUF</List.Item>
                                <List.Item>Submit SIUF for Review</List.Item>
                                <List.Item>Withdraw SIUF from Review</List.Item>
                                <List.Item>Terminate active SIUF</List.Item>
                                <List.Item>Reactivate terminated SIUF</List.Item>
                            </List>
                            <Divider hidden />
                        </Popup>
                        Author
                    </Menu.Item>
            }
        </Menu >
    );
};

export default AppHeader;