import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import StateMachineEditor from '../../../components/authoring/stateMachine/StateMachineEditor';
import { IApplicationRootReducerState } from "../../../configureStore";
import { TriggerEvent } from '../../../contracts/states/stateMachineStates';
import * as selectors from '../../../selectors';
import { GetTriggerEventDisplayName } from '../../../utils';

const mapStateToProps = (state: IApplicationRootReducerState) => ({
    stateMachine: selectors.getStateMachineRootState(state),
    uiState: selectors.getSiufTriggerUiState(state)
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
    getFriendlyName: (triggerEvent: TriggerEvent) => GetTriggerEventDisplayName(triggerEvent)
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(StateMachineEditor);