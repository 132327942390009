//
// UI models used specifically by UI controls and presentational components
//

export enum SiufAuthoringSectionId {
    type = 1,
    question = 2,
    preview = 3,
    trigger = 4,
    targeting = 5,
    settings = 6,
    submission = 7
}

export enum SiufReviewingSectionId {
    search = 1,
    approval = 2
}

export enum OperationStatus {
    notStarted = 1,
    failed = 2,
    success = 3
}

export enum ReviewOperationStatus {
    notStarted = 1,
    approvalFailed = 2,
    approvalSuccess = 3,
    rejectionFailed = 4,
    rejectionSuccess = 5
}