import { action } from "typesafe-actions";
import { SiufCreationActionTypes } from ".";
import { ThunkResult } from "../..";
import SiufAuthoringApi from "../../../api/siufAuthoringApi";
import { IAxiosErrorResponse, INewSiufModel, ISiufEntity } from "../../../contracts/models";
import Navigation from "../../../navigation/navigationActions";
import { ParseErrorResponse } from '../../../utils/actionUtils';

export const createSiufFromServerSent = (newSiufModel: INewSiufModel) => action(
    SiufCreationActionTypes.CREATE_NEWSIUF_SENT,
    {
        newSiufModel
    }
);

export const createSiufFromServerSuccess = (newSiufModel: INewSiufModel, response: ISiufEntity) => action(
    SiufCreationActionTypes.CREATE_NEWSIUF_SUCCESS,
    {
        newSiufModel,
        response
    }
);

export const createSiufFromServerFail = (newSiufModel: INewSiufModel, error: string) => action(
    SiufCreationActionTypes.CREATE_NEWSIUF_FAIL,
    {
        newSiufModel,
        error
    }
);

export const createSiufFromServer = (newSiufModel: INewSiufModel): ThunkResult<void, void> => {
    return async (dispatch) => {
        dispatch(createSiufFromServerSent(newSiufModel));
        try {
            const response = await SiufAuthoringApi.createSiuf(newSiufModel);
            dispatch(createSiufFromServerSuccess(newSiufModel, response));
            // Load SIUF
            dispatch(Navigation.NavigateTo_ExistingSiufPage(response.SiufId));
            // dispatch(getSiufFromServerFromServer(response.SiufId));
        } catch (error) {
            const e = error as IAxiosErrorResponse;
            dispatch(createSiufFromServerFail(
                newSiufModel,
                ParseErrorResponse(e))
            );
        }
    };
};