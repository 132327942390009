import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import ThreePointRatingPreview from '../../../components/authoring/questionPreview/ThreePointRatingPreview';
import { IApplicationRootReducerState } from "../../../configureStore";
import { IQuestionEntity } from '../../../contracts/models';
import { GetThreePointSiufFocusFromValue } from '../../../utils/dataUtils';

type Props = {
    questionModel: IQuestionEntity;
};

const mapStateToProps = (state: IApplicationRootReducerState, ownProps: Props) => ({
    questionStructure: ownProps.questionModel.QuestionStructure,
    questionText: ownProps.questionModel.QuestionText,
    questionFocus: GetThreePointSiufFocusFromValue(ownProps.questionModel.Answers.find(a => a.AnswerIndex === 2).AnswerText, ownProps.questionModel.Answers.find(a => a.AnswerIndex === 1).AnswerText, ownProps.questionModel.Answers.find(a => a.AnswerIndex === 0).AnswerText),
    positiveAnchor: ownProps.questionModel.Answers.find(a => a.AnswerIndex === 2).AnswerText,
    neutralAnchor: ownProps.questionModel.Answers.find(a => a.AnswerIndex === 1).AnswerText,
    negativeAnchor: ownProps.questionModel.Answers.find(a => a.AnswerIndex === 0).AnswerText,
    positiveFollowupQuestion: ownProps.questionModel.Answers.find(a => a.AnswerIndex === 2).FollowUpQuestion ? ownProps.questionModel.Answers.find(a => a.AnswerIndex === 2).FollowUpQuestion.QuestionText : "",
    neutralFollowupQuestion: ownProps.questionModel.Answers.find(a => a.AnswerIndex === 1).FollowUpQuestion ? ownProps.questionModel.Answers.find(a => a.AnswerIndex === 1).FollowUpQuestion.QuestionText : "",
    negativeFollowupQuestion: ownProps.questionModel.Answers.find(a => a.AnswerIndex === 0).FollowUpQuestion ? ownProps.questionModel.Answers.find(a => a.AnswerIndex === 0).FollowUpQuestion.QuestionText : ""
});

const mapDispatchToProps = (dispatch: Dispatch, ownProps: Props) => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ThreePointRatingPreview);
