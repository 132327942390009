import * as React from 'react';
import { Confirm, Form } from 'semantic-ui-react';
import { SiufLifeTimeOption, SiufTriggeringType } from '../../../contracts/models';
import { ISectionUiState } from '../../../contracts/states';
import { GetSiufLifeSpanSelection, GetSiufLifeTimeSelectionFromLifeSpan } from '../../../utils';
import StateMachineEditorView from '../../../containers/authoring/stateMachine/StateMachineEditorView';

type Props = {
    lifeSpanInDays: number;
    triggerType: SiufTriggeringType;
    uiState: ISectionUiState;
    onUpdateSiufTriggerType: (triggerType: SiufTriggeringType) => void;
    onUpdateSiufLifeTimeInDays: (durationInDays: number) => void;
};

type State = {
    isConfirmationOpen: boolean;
    selection: SiufTriggeringType;
};

export default class Trigger extends React.Component<Props, State> {

    constructor (props: Props) {
        super(props);

        this.props.onUpdateSiufTriggerType.bind(this);
        this.props.onUpdateSiufLifeTimeInDays.bind(this);

        this.open.bind(this);
        this.close.bind(this);

        this.state = {
            isConfirmationOpen: false,
            selection: SiufTriggeringType.URI
        };
    }

    private open = (selection: SiufTriggeringType) => this.setState({ isConfirmationOpen: true, selection: selection });
    private close = () => this.setState({ ...this.state, isConfirmationOpen: false });

    public render () {
        let lifeTimeOptions = GetSiufLifeSpanSelection().map(o => {
            return {
                key: o.type,
                value: o.type,
                text: o.displayName,
                disabled: o.disabled
            };
        });

        let currentLifeSpanInDays = GetSiufLifeTimeSelectionFromLifeSpan(this.props.lifeSpanInDays);

        let lifeTime =
            this.props.uiState.isReadOnly
                ?
                <Form.Input fluid value={ lifeTimeOptions.find(o => o.value === currentLifeSpanInDays).text }
                    label='Select SIUF life time'
                    readOnly={ true }
                    title='Select SIUF life time'
                    tabIndex='7'
                    placeholder='Select SIUF life time' required
                />
                :
                <Form.Select fluid value={ currentLifeSpanInDays }
                    label='Select SIUF life time'
                    options={ lifeTimeOptions }
                    placeholder='Select SIUF life time'
                    disabled={ this.props.uiState.isReadOnly }
                    required
                    title='Select SIUF life time'
                    tabIndex='7'
                    onChange={ (event, data) => {
                        const lifeSpanInDays = data.value as SiufLifeTimeOption;
                        if (lifeSpanInDays !== currentLifeSpanInDays) {
                            this.props.onUpdateSiufLifeTimeInDays(lifeSpanInDays);
                        }
                    } }
                />;

        const triggerType = (
            <Form.Group inline>
                <label>Select the Trigger Type:</label>
                <Form.Radio
                    label='Uri Link'
                    name='SiufTrigger_UriLink'
                    readOnly={ this.props.uiState.isReadOnly }
                    title='Uri Link'
                    tabIndex='7'
                    className = 'ui radio_button_new'
                    checked={ this.props.triggerType === SiufTriggeringType.URI }
                    value={ SiufTriggeringType.URI }
                    onChange={ () => this.open(SiufTriggeringType.URI) }
                />
                <Form.Radio
                    label='State Machine'
                    name='SiufTrigger_StateMachine'
                    readOnly={ this.props.uiState.isReadOnly }
                    title='State Machine'
                    tabIndex='7'
                    className = 'ui radio_button_new'
                    checked={ this.props.triggerType === SiufTriggeringType.StateMachine }
                    value={ SiufTriggeringType.StateMachine }
                    onChange={ () => this.props.onUpdateSiufTriggerType(SiufTriggeringType.StateMachine) }
                />
                <Confirm
                    content={ `Changing trigger type to Uri will remove any existing state machine and targeting properties, are you sure?` }
                    cancelButton='No, keep using state machine'
                    confirmButton="Yes, change to Uri"
                    open={ this.state.isConfirmationOpen }
                    onCancel={ () => this.close() }
                    onConfirm={ () => {
                        this.props.onUpdateSiufTriggerType(this.state.selection);
                        this.close();
                    } }
                />
            </Form.Group>
        );

        return (
            <React.Fragment>
                <Form>
                    { lifeTime }
                    { triggerType }
                    {
                        this.props.triggerType === SiufTriggeringType.StateMachine
                        &&
                        <StateMachineEditorView />
                    }
                </Form>
            </React.Fragment>
        );
    }
}