import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import YesNoPreview from '../../../components/authoring/questionPreview/YesNoPreview';
import { IApplicationRootReducerState } from "../../../configureStore";
import { IQuestionEntity } from '../../../contracts/models';

type Props = {
    questionModel: IQuestionEntity;
};

const mapStateToProps = (state: IApplicationRootReducerState, ownProps: Props) => ({
    questionStructure: ownProps.questionModel.QuestionStructure,
    questionText: ownProps.questionModel.QuestionText,
    yesFollowupQuestion: ownProps.questionModel.Answers[0].FollowUpQuestion ? ownProps.questionModel.Answers[0].FollowUpQuestion.QuestionText : "",
    noFollowupQuestion: ownProps.questionModel.Answers[1].FollowUpQuestion ? ownProps.questionModel.Answers[1].FollowUpQuestion.QuestionText : ""
});

const mapDispatchToProps = (dispatch: Dispatch, ownProps: Props) => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(YesNoPreview);
