import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import * as ActionCreators from '../../../../actions/authoring/stateMachine/actions';
import DiagramContainer from '../../../../components/authoring/stateMachine/diagram/DiagramContainer';
import { IApplicationRootReducerState } from "../../../../configureStore";
import * as selectors from '../../../../selectors';
import * as siufAuthoringActions from '../../../../actions';

const mapStateToProps = (state: IApplicationRootReducerState) => ({
    maxDurationMs: selectors.getStateMachineMaxDurationMs(state)
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
    updateName: (name: string) => dispatch(ActionCreators.updateStateMachineModelName(name)),
    updateMaxDurationMs: (durationInMs: number) => dispatch(siufAuthoringActions.updateMaxDurationMs(durationInMs))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(DiagramContainer);