import * as React from 'react';
import { Divider, Form, Grid, Label } from 'semantic-ui-react';
import { SiufQuestionStructure } from '../../../contracts/models';
import '../../../siufAuthUx.css';

type Props = {
    questionStructure: SiufQuestionStructure
    questionText: string;
    isNpsQuestion: boolean;
    minAnchor: string;
    maxAnchor: string;
    ratings1FollowupQuestion?: string;
    ratings2FollowupQuestion?: string;
    ratings3FollowupQuestion?: string;
    ratings4FollowupQuestion?: string;
    ratings5FollowupQuestion?: string;
};

type State = {
    currentSelectedOption: number;
};

export default class FivePointRatingPreview extends React.Component<Props, State> {

    constructor (props: Props) {
        super(props);

        this.state = {
            currentSelectedOption: -1
        };
    }

    public render () {
        let followupQuestionText = "";

        if (this.state.currentSelectedOption === 1) {
            followupQuestionText = this.props.ratings1FollowupQuestion;
        }
        else if (this.state.currentSelectedOption === 2) {
            followupQuestionText = this.props.ratings2FollowupQuestion;
        }
        else if (this.state.currentSelectedOption === 3) {
            followupQuestionText = this.props.ratings3FollowupQuestion;
        }
        else if (this.state.currentSelectedOption === 4) {
            followupQuestionText = this.props.ratings4FollowupQuestion;
        }
        else if (this.state.currentSelectedOption === 5) {
            followupQuestionText = this.props.ratings5FollowupQuestion;
        }

        let followupQuestion = followupQuestionText && (
            <React.Fragment>
                <h3>{ followupQuestionText }</h3>
                <Form.TextArea placeholder='Enter your feedback here...' tabIndex='6' />
            </React.Fragment>
        );

        return (
            <React.Fragment>
                <Form widths='equal'>
                    <div className='flex-div'>
                        <h3 className='no-margin'>{ this.props.questionText || '<Question Text>' }</h3>
                        {this.props.isNpsQuestion && <Label className='float-right' as='a' size='small' color='blue' tag>NPS</Label>}
                    </div>
                    <Divider hidden />
                    <Grid>
                        <Grid.Row columns='equal'>
                            <Grid.Column>
                                <Form.Radio
                                    checked={ this.state.currentSelectedOption === 1 }
                                    name='5pointrating_1'
                                    label='1'
                                    value={ 1 }
                                    title='1'
                                    tabIndex='6'
                                    className = 'ui radio_button_new'
                                    onChange={ () => this.setState({ currentSelectedOption: 1 }) }
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <Form.Radio
                                    checked={ this.state.currentSelectedOption === 2 }
                                    name='5pointrating_2'
                                    label='2'
                                    value={ 2 }
                                    title='2'
                                    tabIndex='6'
                                    className = 'ui radio_button_new'
                                    onChange={ () => this.setState({ currentSelectedOption: 2 }) }
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <Form.Radio
                                    checked={ this.state.currentSelectedOption === 3 }
                                    name='5pointrating_3'
                                    label='3'
                                    value={ 3 }
                                    title='3'
                                    tabIndex='6'
                                    className = 'ui radio_button_new'
                                    onChange={ () => this.setState({ currentSelectedOption: 3 }) }
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <Form.Radio
                                    checked={ this.state.currentSelectedOption === 4 }
                                    name='5pointrating_4'
                                    label='4'
                                    value={ 4 }
                                    title='4'
                                    tabIndex='6'
                                    className = 'ui radio_button_new'
                                    onChange={ () => this.setState({ currentSelectedOption: 4 }) }
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <Form.Radio
                                    checked={ this.state.currentSelectedOption === 5 }
                                    name='5pointrating_5'
                                    label='5'
                                    value={ 5 }
                                    title='5'
                                    tabIndex='6'
                                    className = 'ui radio_button_new'
                                    onChange={ () => this.setState({ currentSelectedOption: 5 }) }
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns='equal'>
                            <Grid.Column>
                                <label>{ this.props.minAnchor || "<Negative Anchor>" }</label>
                            </Grid.Column>
                            <Grid.Column />
                            <Grid.Column />
                            <Grid.Column />
                            <Grid.Column>
                                <label>{ this.props.maxAnchor || "<Positive Anchor>" }</label>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    {
                        (this.props.questionStructure === SiufQuestionStructure.SingleQuestion)
                        &&
                        followupQuestion
                    }
                </Form>
            </React.Fragment>
        );
    }
}