export enum StateMachineActionTypes {

    // Ajax related actions types

    // normal action types

    UPDATE_MAX_DURATION_IN_MS = '@@SIUF/STATEMACHINE/UPDATE_MAX_DURATION_IN_MS',
    SET_STATEMACHINENAME = '@@SIUF/STATEMACHINE/SET_STATEMACHINENAME',
    SET_STATEMACHINE = '@@SIUF/STATEMACHINE/SET_STATEMACHINE',
    UPDATE_TRIGGERFILTER = '@@SIUF/STATEMACHINE/UPDATE_TRIGGERFILTER',
    UPDATE_TRIGGERFILTER_EVENT = '@@SIUF/STATEMACHINE/UPDATE_TRIGGERFILTER_EVENT',
    ADD_TRANSITIONFILTER = '@@SIUF/STATEMACHINE/ADD_TRANSITIONFILTER',
    REMOVE_TRANSITIONFILTER = '@@SIUF/STATEMACHINE/REMOVE_TRANSITIONFILTER',
    UPDATE_TRANSITIONFILTER_REFERENCE = '@@SIUF/STATEMACHINE/UPDATE_TRANSITIONFILTER_REFERENCE',
    UPDATE_TRANSITIONFILTER_STRICT = '@@SIUF/STATEMACHINE/UPDATE_TRANSITIONFILTER_STRICT',
    REMOVE_TRANSITION = '@@SIUF/STATEMACHINE/REMOVE_TRANSITION',
    ADD_TRANSITION = '@@SIUF/STATEMACHINE/ADD_TRANSITION',
    UPDATE_TRANSITION = '@@SIUF/STATEMACHINE/UPDATE_TRANSITION',
    UPDATE_TRIGGEREVENT = '@@SIUF/STATEMACHINE/UPDATE_TRIGGEREVENT',
    REMOVE_TRIGGEREVENT = '@@SIUF/STATEMACHINE/REMOVE_TRIGGEREVENT',
    ADD_TRIGGEREVENT = '@@SIUF/STATEMACHINE/ADD_TRIGGEREVENT',
    REMOVE_STATE = '@@SIUF/STATEMACHINE/REMOVE_STATE',
    ADD_STATE = '@@SIUF/STATEMACHINE/ADD_STATE'
}