/* tslint:disable:no-duplicate-imports class-name semicolon interface-name switch-default switch-final-break radix*/

import * as React from 'react';
import { ISectionUiState, TransitionFilter, TriggerFilter } from '../../../../contracts';
import ConditionSingleOrDualTriggerFilterPanelView from '../../../../containers/authoring/stateMachine/transitions/ConditionSingleOrDualTriggerFilterPanelView';
import { Grid, MenuItemProps, Menu, Icon } from 'semantic-ui-react';

interface ConditionsPanelProps {
    transitionId: string;
    transitionFilters: TransitionFilter[];
    triggerFilters: TriggerFilter[];
    uiState: ISectionUiState;
    allTransitionFilters: TransitionFilter[];
    addTransitionFilter (transitionId: string): string;
    removeTransitionFilter (transitionFilterId: string): void;
}
interface ConditionsPanelState {
    selectedTransitionFilterId: string;
}

export default class ConditionsPanel extends React.Component<ConditionsPanelProps, ConditionsPanelState> {
    constructor (props: ConditionsPanelProps) {
        super(props);
        this.state = {
            selectedTransitionFilterId: props.transitionFilters.length ? props.transitionFilters[0].TransitionFilterId : null
        };
    }

    public render () {
        return (
            <div>
                <Grid>
                    <Grid.Column width={ 5 }>
                        <h3>Filters</h3>
                        { this.renderFiltersMenu() }
                    </Grid.Column>
                    <Grid.Column width={ 11 }>
                        <h3>Filter Details { this.renderSharedFilterTooltip() }</h3>
                        { this.renderFilterDetails() }
                    </Grid.Column>
                </Grid>
            </div>
        );
    }

    private renderSharedFilterTooltip = () => {
        if (this.state.selectedTransitionFilterId !== null) {
            let selectedTriggerFilterId = this.props.allTransitionFilters.find(x => x.TransitionFilterId === this.state.selectedTransitionFilterId)?.TriggerFilterId;
            if (selectedTriggerFilterId && this.props.allTransitionFilters.filter(x => x.TriggerFilterId === selectedTriggerFilterId).length !== 1) {
                return (
                    <div className="ui icon button" data-tooltip="This filter is referenced by multiple transitions. Changes made here will be reflected everywhere else it is referenced." data-inverted="">
                        <i>Shared</i>
                    </div>
                );
            }
        }
    };

    private renderFiltersMenu = () => {
        let existingFilters = this.props.transitionFilters.map(transitionFilter => {
            let triggerFilter = this.props.triggerFilters.find(tf => tf.TriggerFilterId === transitionFilter.TriggerFilterId);
            return (
                <Menu.Item
                    key={ `existingFilter+${transitionFilter.TransitionFilterId}` }
                    active={ this.state.selectedTransitionFilterId === transitionFilter.TransitionFilterId }
                    name={ transitionFilter.TransitionFilterId }
                    tabIndex='7'
                    onClick={ this.handleFiltersMenuClick }
                    onKeyPress={e => {
                        var eventCode = e.charCode || e.which;
                        if (eventCode === 13 || eventCode === 32) {
                            var name={ 'name': transitionFilter.TransitionFilterId }
                            this.handleFiltersMenuKeyPressed(e, name);
                        }
                    }}>
                    { triggerFilter.IsDirty ? <Icon name='bug' /> : '' } { triggerFilter.FilterName || '{Unnamed filter}' }
                </Menu.Item>
            );
        });
        let menuAction = (
            <Menu icon attached='top'>
                <Menu.Item name='add' disabled={this.props.uiState.isReadOnly} tabIndex='7' onClick={ this.handleAddFilterClick }
                 onKeyPress={e => {
                    var eventCode = e.charCode || e.which;
                    if (eventCode === 13 || eventCode === 32) {
                        this.handleAddFilterClick();
                    }
                }}>
                    <Icon name='plus' />
                </Menu.Item>
                <Menu.Item name='remove' disabled={this.props.uiState.isReadOnly} tabIndex='7' onClick={ this.handleRemoveFilterClick }
                 onKeyPress={e => {
                    var eventCode = e.charCode || e.which;
                    if (eventCode === 13 || eventCode === 32) {
                        this.handleRemoveFilterClick();
                    }
                }}>
                    <Icon name='minus' />
                </Menu.Item>
            </Menu>

        );
        return (
            <>
                { menuAction }
                <Menu attached vertical style={ { width: 'initial' } }>
                    { existingFilters }
                </Menu>
            </>
        );
    }

    private handleFiltersMenuClick = (e: any, { name }: MenuItemProps) => this.setState({ selectedTransitionFilterId: name })
    private handleFiltersMenuKeyPressed = (e: any, { name }: MenuItemProps) => {
        this.setState({ selectedTransitionFilterId: name });
    };                        

    private handleAddFilterClick = () => {
        let newTransitionFilterId = this.props.addTransitionFilter(this.props.transitionId);
        this.setState({ selectedTransitionFilterId: newTransitionFilterId });
    };

    private handleRemoveFilterClick = () => {
        let transitionFilterIdMap = this.props.transitionFilters.map(x => x.TransitionFilterId);
        let selectedIndex = transitionFilterIdMap.indexOf(this.state.selectedTransitionFilterId);
        let nextIndex = selectedIndex === transitionFilterIdMap.length - 1 ? selectedIndex - 1 : selectedIndex + 1;
        let nextSelectedTransitionFilterId = nextIndex < 0 ? null : transitionFilterIdMap[nextIndex];
        this.props.removeTransitionFilter(this.state.selectedTransitionFilterId);
        this.setState({ selectedTransitionFilterId: nextSelectedTransitionFilterId });
    }

    private renderFilterDetails = () => {
        if (!this.state.selectedTransitionFilterId) {
            return;
        }
        let transitionFilter = this.props.transitionFilters.find(x => x.TransitionFilterId === this.state.selectedTransitionFilterId);
        if (!transitionFilter) {
            return;
        }
        let triggerFilter = this.props.triggerFilters.find(x => x.TriggerFilterId === transitionFilter.TriggerFilterId);
        if (!triggerFilter) {
            return;
        }
        return (<ConditionSingleOrDualTriggerFilterPanelView transitionFilter={ transitionFilter } triggerFilter={ triggerFilter } />);
    }
};
