import { ILoadingState, IReadOnlyState, IVisibleState } from "..";

export interface ISectionUiState extends ILoadingState, IReadOnlyState, IVisibleState {
}

export const ISectionUiState_Default: ISectionUiState = {
    "isVisible": false,
    "isReadOnly": true,
    "isLoading": false
};

export interface ISiufAuthoringUiState {
    overall: ISectionUiState;
    siufBasics: ISectionUiState;
    questionDetails: ISectionUiState;
    preview: ISectionUiState;
    trigger: ISectionUiState;
    targeting: ISectionUiState;
    settings: ISectionUiState;
    validation: ISectionUiState;
}

export const ISiufAuthoringUiState_Default: ISiufAuthoringUiState = {
    "overall": {
        "isVisible": false,
        "isReadOnly": false,
        "isLoading": false
    } as ISectionUiState,
    "siufBasics": {
        "isVisible": true,
        "isReadOnly": false,
        "isLoading": false
    } as ISectionUiState,
    "questionDetails": {
        "isVisible": false,
        "isReadOnly": false,
        "isLoading": false
    } as ISectionUiState,
    "preview": {
        "isVisible": false,
        "isReadOnly": true,
        "isLoading": false
    } as ISectionUiState,
    "trigger": {
        "isVisible": false,
        "isReadOnly": false,
        "isLoading": false
    } as ISectionUiState,
    "targeting": {
        "isVisible": false,
        "isReadOnly": false,
        "isLoading": false
    } as ISectionUiState,
    "settings": {
        "isVisible": false,
        "isReadOnly": false,
        "isLoading": false
    } as ISectionUiState,
    "validation": {
        "isVisible": true,
        "isReadOnly": false,
        "isLoading": false
    } as ISectionUiState
};

export interface ISiufReviewingUiState {
    search: ISectionUiState;
    approval: ISectionUiState;
}

export const ISiufReviewingUiState_Default: ISiufReviewingUiState = {
    "search": {
        "isVisible": true,
        "isReadOnly": false,
        "isLoading": false
    } as ISectionUiState,
    "approval": {
        "isVisible": true,
        "isReadOnly": false,
        "isLoading": false
    } as ISectionUiState
};

export interface ISiufDomainUiState {
    authoring: ISiufAuthoringUiState;
    reviewing: ISiufReviewingUiState;
}

export const ISiufDomainUiState_Default: ISiufDomainUiState = {
    authoring: ISiufAuthoringUiState_Default,
    reviewing: ISiufReviewingUiState_Default
};