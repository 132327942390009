import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import * as ActionCreators from '../../../../actions/authoring/stateMachine/actions';
import ConditionSingleOrDualTriggerFilterPanel from '../../../../components/authoring/stateMachine/transitions/ConditionSingleOrDualTriggerFilterPanel';
import { IApplicationRootReducerState } from "../../../../configureStore";
import { IUpdateTransitionFilterReferenceModel, IUpdateTransitionFilterStrictModel, IUpdateTriggerFilterEventModel, IUpdateTriggerFilterModel } from '../../../../contracts/states/stateMachineStates';
import * as selectors from '../../../../selectors';

const mapStateToProps = (state: IApplicationRootReducerState) => ({
    triggerEvents: selectors.getStateMachineRootState(state).TriggerEvents,
    triggerFilters: selectors.getStateMachineRootState(state).TriggerFilters,
    uiState: selectors.getSiufTriggerUiState(state)
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
    updateTriggerFilter: (triggerFilterChanges: IUpdateTriggerFilterModel) => dispatch(ActionCreators.updateStateMachineTriggerFilter(triggerFilterChanges)),
    updateTriggerFilterEvent: (triggerFilterEventChanges: IUpdateTriggerFilterEventModel) => dispatch(ActionCreators.updateStateMachineTriggerFilterEvent(triggerFilterEventChanges)),
    updateTransitionFilterReference: (updateTransitionFilterChanges: IUpdateTransitionFilterReferenceModel) => dispatch(ActionCreators.updateStateMachineTransitionFilterReference(updateTransitionFilterChanges)),
    updateTransitionFilterStrict: (updateTransitionFilterChanges: IUpdateTransitionFilterStrictModel) => dispatch(ActionCreators.updateStateMachineTransitionFilterStrict(updateTransitionFilterChanges))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ConditionSingleOrDualTriggerFilterPanel);