import { connect } from 'react-redux';
import { IApplicationRootReducerState } from "../../../configureStore";
import { Dispatch } from 'redux';
import { SiufTriggeringType } from '../../../contracts/models';
import * as selectors from '../../../selectors';
import * as siufAuthoringActions from '../../../actions';
import Trigger from '../../../components/authoring/trigger/Trigger';

type Props = {
};

const mapStateToProps = (state: IApplicationRootReducerState, ownProps: Props) => ({
    lifeSpanInDays: selectors.getSiufLifeTimeInDays(state),
    triggerType: selectors.getSiufTriggerTypeSelection(state),
    uiState: selectors.getSiufTriggerUiState(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    onUpdateSiufTriggerType: (triggerType: SiufTriggeringType) => dispatch(siufAuthoringActions.updateSiufTriggerType(triggerType)),
    onUpdateSiufLifeTimeInDays: (durationInDays: number) => dispatch(siufAuthoringActions.updateSiufLifeTimeInDays(durationInDays))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Trigger);