import React from 'react';
import { Segment, Header, Icon, SemanticICONS } from "semantic-ui-react";

type Props = {
    header: string;
    message: string | JSX.Element;
    iconName?: SemanticICONS;
};

const ErrorPage: React.FC<Props> = props => {
    const icon = props.iconName || "frown";
    return (
        <Segment placeholder textAlign="center" size="big">
            <Header icon>
                <Icon name={ icon } color="blue" />
                { props.header }
            </Header>
            { props.message }
            { props.children }
        </Segment>
    );
};

export default ErrorPage;