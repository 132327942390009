import { connect } from 'react-redux';
import { IApplicationRootReducerState } from "../../../configureStore";
import { Dispatch } from 'redux';
import { IQuestionEntity, LikertResponseCount, LikertResponseType, SiufFocus } from '../../../contracts/models';
import * as siufAuthoringActions from '../../../actions';
import LikertQuestion from '../../../components/authoring/questionDetails/LikertQuestion';
import { GetLikertResponseCount, GetLikertResponseInputCssClass, GetLikertResponseType, GetLikertSiufFocusFromValue } from '../../../utils';

type Props = {
    questionModel: IQuestionEntity;
    isReadOnly: boolean;
    pageIndex: number;
};

const mapStateToProps = (state: IApplicationRootReducerState, ownProps: Props) => ({
    isReadOnly: ownProps.isReadOnly,
    questionStructure: ownProps.questionModel.QuestionStructure,
    questionText: ownProps.questionModel.QuestionText,
    responseOptions: ownProps.questionModel.Answers.sort((a, b) => (a.AnswerIndex > b.AnswerIndex) ? 1 : -1).map(a => a.AnswerText),
    scaleItems: ownProps.questionModel.ScaleItems?.sort((a, b) => (a.ScaleItemIndex > b.ScaleItemIndex) ? 1 : -1)?.map(a => a.ScaleItemText) || [],
    likertResponseCount: GetLikertResponseCount(ownProps.questionModel.Answers, ownProps.questionModel.LikertResponseCount),
    LikertResponseType: GetLikertResponseType(ownProps.questionModel.Answers, ownProps.questionModel.LikertResponseType),
    questionFocus: GetLikertSiufFocusFromValue(ownProps.questionModel.Answers),
    likertOptionInputClass: GetLikertResponseInputCssClass(ownProps.questionModel.LikertResponseCount, ownProps.questionModel.LikertResponseType),
    followupQuestion: ownProps.questionModel.Answers.find(a => a.AnswerIndex === 0)?.FollowUpQuestion ? ownProps.questionModel.Answers.find(a => a.AnswerIndex === 0)?.FollowUpQuestion.QuestionText : "",
});

const mapDispatchToProps = (dispatch: Dispatch, ownProps: Props) => ({
    onUpdateLikertResponseType: (responseType: LikertResponseType, questionFocus: SiufFocus, followUpQuestion: string) => dispatch(siufAuthoringActions.updateLikertResponseType(ownProps.pageIndex, ownProps.questionModel.QuestionIndex, responseType, questionFocus, followUpQuestion)),
    onUpdateLikertResponseCount: (responseCount: LikertResponseCount, questionFocus: SiufFocus, followUpQuestion: string) => dispatch(siufAuthoringActions.updateLikertResponseCount(ownProps.pageIndex, ownProps.questionModel.QuestionIndex, responseCount, questionFocus, followUpQuestion)),
    onUpdateQuestionText: (questionText: string) => dispatch(siufAuthoringActions.updateQuestionText(ownProps.pageIndex, ownProps.questionModel.QuestionIndex, questionText)),
    onUpdateLikertOptionText: (text: string, index: number) => dispatch(siufAuthoringActions.updateLikertOptionText(ownProps.pageIndex, ownProps.questionModel.QuestionIndex, text, index)),
    onAddLikertOption: (likertOptionText?: string) => dispatch(siufAuthoringActions.addLikertOption(ownProps.pageIndex, ownProps.questionModel.QuestionIndex, likertOptionText)),
    onRemoveLikertOption: (checkboxIndex: number) => dispatch(siufAuthoringActions.removeLikertOption(ownProps.pageIndex, ownProps.questionModel.QuestionIndex, checkboxIndex)),
    onAddLikertScaleItem: () => dispatch(siufAuthoringActions.addLikertScaleItem(ownProps.pageIndex, ownProps.questionModel.QuestionIndex)),
    onUpdateLikertScaleItemText: (text: string, index: number) => dispatch(siufAuthoringActions.updateLikertScaleItemText(ownProps.pageIndex, ownProps.questionModel.QuestionIndex, text, index)),
    onRemoveLikertScaleItem: (checkboxIndex: number) => dispatch(siufAuthoringActions.removeLikertScaleItem(ownProps.pageIndex, ownProps.questionModel.QuestionIndex, checkboxIndex)),
    onUpdateFollowQuestionText: (questionText: string) => dispatch(siufAuthoringActions.updateFollowUpQuestionText(ownProps.pageIndex, ownProps.questionModel.QuestionIndex, questionText, 0)),
    onUpdateSiufFocus: (questionFocus: SiufFocus) => dispatch(siufAuthoringActions.updateSiufFocus(ownProps.pageIndex, ownProps.questionModel.QuestionIndex, questionFocus))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LikertQuestion);
