import { HostPlatform } from "./models";

export interface IAzureAdConfigOptions {
    clientId: string;
    authority: string;
    redirectUri: string;
    postLogoutRedirectUri: string;
}

export const IAzureAdConfigOptions_Default: IAzureAdConfigOptions = {
    "clientId": "",
    "authority": "",
    "redirectUri": "",
    "postLogoutRedirectUri": ""
};

export interface IEndpointsOptions {
    siufApiBaseUrl: string;
    siufApiScope: string;
}

export const IEndpointsOptions_Default: IEndpointsOptions = {
    "siufApiBaseUrl": "",
    "siufApiScope": ""
};

export interface IResourceProviderOptions {
    azureAD: IAzureAdConfigOptions;
    endpoints: IEndpointsOptions;
    hostPlatform: HostPlatform;
    cosmicUrl: string;
    cosmicUrlLabel: string;
    cosmicMigrationPrefix: string;
    cosmicMigrationSuffix: string;
    forceCosmicMigration: boolean;
}

export const IResourceProviderOptions_Default: IResourceProviderOptions = {
    "azureAD": IAzureAdConfigOptions_Default,
    "endpoints": IEndpointsOptions_Default,
    "hostPlatform": HostPlatform.Azure,
    "cosmicUrl": "https://siufauthoring.core.microsoft",
    "cosmicMigrationPrefix": "This portal has been migrated to COSMIC platform. It will be unavailable from August 15, 2024. ",
    "cosmicUrlLabel": "Go to new portal now",
    "cosmicMigrationSuffix": " and update your bookmarks.",
    "forceCosmicMigration": false
};