import React from 'react';
import ErrorPage from './ErrorPage';

const UnauthenticatedPage: React.FC<any> = () => (
    <React.Fragment>
        <ErrorPage
            header="Uh oh, it looks like we were not able to authenticate you to log in"
            message="The SiufAuthoring site requires the user to be in the Microsoft Tenant. If you believe this is an error, please contact support" />;
    </React.Fragment>
);

export default UnauthenticatedPage;