// import FeedbackAuthoringApi from './contexts/api/feedbackAuthoringApi';
import { MsalService } from './auth';
import { IUserProfile } from './contracts';
import SiufAuthoringApi from './api/siufAuthoringApi';

let userProfile: IUserProfile = null;

export default class UserProfileService {

    public static async InitializeCurrentUserProfile () {
        //Getting logged user details from the Graph API
        const currentUser = await MsalService.GetCurrentUser();

        if (!currentUser && !currentUser.userPrincipalName) {
            Promise.reject("Cannot retrieve current logged in user profile using MsalService");
            return null;
        }

        try {
            //Getting logged in user details from the SIUF API
            userProfile = !userProfile && currentUser.userPrincipalName
                ? await SiufAuthoringApi.getUserProfile(currentUser.userPrincipalName)
                : userProfile;
        } catch (e) {
            console.error("Failed to get current user profile in UserProfileService.");
            console.error(e);
            Promise.reject("Failed to get current user profile in UserProfileService.");
            return null;
        }

        return userProfile.UserPrincipalName ? userProfile.UserPrincipalName : null;
    }

    public static GetCurrentUserProfile () {
        return userProfile;
    }

    public static GetCurrentUserUpn () {
        return userProfile && userProfile.UserPrincipalName;
    }

    public static IsCurrentUserAdmin () {
        return userProfile && userProfile.IsAdmin;
    }

    public static IsCurrentUserReviewer () {
        return userProfile && userProfile.IsReviewer;
    }

    public static IsCurrentUserAuthor () {
        return userProfile && userProfile.IsAuthor;
    }
}