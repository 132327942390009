import * as React from 'react';
import { Button, Divider, Form, Grid, Icon } from 'semantic-ui-react';
import { INPUT_DELAY, LikertResponseCount, LikertResponseType, MAXIMUM_LIKERT_RESPONSE_OPTION_COUNT, MAXIMUM_LIKERT_SCALE_ITEM_COUNT, SiufFocus, SiufQuestionStructure } from '../../../contracts/models';
import { GetLikertResponseCounts, GetLikertResponseTypes, GetLikertSiufFocusValues } from '../../../utils';
import '../../../siufAuthUx.css';

type Props = {
    isReadOnly: boolean,
    questionStructure: SiufQuestionStructure;
    responseOptions: string[];
    scaleItems: string[];
    questionText: string;
    followupQuestion?: string;
    LikertResponseType: number;
    likertResponseCount: number;
    questionFocus: SiufFocus;
    likertOptionInputClass: string;
    onUpdateLikertResponseType: (responseType: LikertResponseType, questionFocus: SiufFocus, followUpQuestion: string) => void;
    onUpdateLikertResponseCount: (responseCount: LikertResponseCount, questionFocus: SiufFocus, followUpQuestion: string) => void;
    onUpdateQuestionText: (questionText: string) => void;
    onUpdateLikertOptionText: (text: string, index: number) => void;
    onAddLikertOption: (likertOptionText?: string) => void;
    onRemoveLikertOption: (index: number) => void;
    onUpdateLikertScaleItemText: (text: string, index: number) => void;
    onAddLikertScaleItem: () => void;
    onRemoveLikertScaleItem: (index: number) => void;
    onUpdateFollowQuestionText: (questionText: string) => void;
    onUpdateSiufFocus: (questionFocus: SiufFocus) => void;
};

const LikertQuestion: React.FC<Props> = props => {

    // Delay the update
    let timer: NodeJS.Timeout;
    const onUpdateWithDelay = (func: any, ...params: any[]) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Function/call
            return func.call(props, ...params);
        }, INPUT_DELAY);
    };

    const likertResponseTypes = GetLikertResponseTypes().map(qt => {
        return { key: qt.likertResponseType, text: qt.displayName, value: qt.likertResponseType };
    });

    const likertResponseCounts = GetLikertResponseCounts().map(qt => {
        return { key: qt.likertResponseCount, text: qt.displayName, value: qt.likertResponseCount };
    });

    const options = [
        { key: 'Accuracy', value: SiufFocus.Accuracy, text: 'Accuracy' },
        { key: 'Aesthetics', value: SiufFocus.Aesthetics, text: 'Aesthetics' },
        { key: 'Difficulty', value: SiufFocus.Difficulty, text: 'Difficulty' },
        { key: 'Duration', value: SiufFocus.Duration, text: 'Duration' },
        { key: 'Efficiency', value: SiufFocus.Efficiency, text: 'Efficiency' },
        { key: 'Frequency', value: SiufFocus.Frequency, text: 'Frequency' },
        { key: 'Satisfaction', value: SiufFocus.Satisfaction, text: 'Satisfaction' },
        { key: 'Usefulness', value: SiufFocus.Usefulness, text: 'Usefulness' },
        { key: 'Custom', value: SiufFocus.Custom, text: 'Custom' },
    ];

    const responseOptions = props.responseOptions.map((cb, index) => (
            <Grid.Column key={ index } className={props.likertOptionInputClass}>
                <Form.Input fluid required
                    label={ `Option ${index + 1}:` }
                    readOnly={ props.isReadOnly }
                    tabIndex='5'
                    value={ cb }
                    placeholder='Enter your option here'
                    onChange={ e => props.onUpdateLikertOptionText(e.currentTarget.value, index) }
                    action={ !props.isReadOnly && props.likertResponseCount === LikertResponseCount.Custom && index != 0 && {
                        icon: "delete",
                        onClick: () => props.onRemoveLikertOption(index)
                      }}
                />
            </Grid.Column>
    ));

    const scaleItems = props.scaleItems.map((cb, index) => (
        <Grid.Column width={ 4 } key={ index } className="listItem">
            <Form.Input fluid required
                label={ `Scale Item ${index + 1}:` }
                readOnly={ props.isReadOnly }
                tabIndex='5'
                value={ cb }
                placeholder='Enter item to measure'
                onChange={ e => props.onUpdateLikertScaleItemText(e.currentTarget.value, index) }
                action={ !props.isReadOnly && {
                    icon: "delete",
                    onClick: () => props.onRemoveLikertScaleItem(index)
                  }}
            />
        </Grid.Column>
));

    return (
        <React.Fragment>
            <Form.Input fluid defaultValue={ props.questionText }
                readOnly={ props.isReadOnly }
                label='Question Text' placeholder='Enter your question here' required
                tabIndex='5'
                onChange={ e => onUpdateWithDelay(props.onUpdateQuestionText, e.currentTarget.value) }
            />
            <Form.Group widths='equal'>
                {
                    props.isReadOnly
                    ?
                    <Form.Input fluid value={ likertResponseCounts.find(o => o.value === props.likertResponseCount).text } label='Response Options Count'
                        readOnly={ true }
                        placeholder='Select response count' required
                        tabIndex='5'
                    />
                    : 
                    <Form.Select fluid value={ props.likertResponseCount }
                    readOnly={ props.isReadOnly }
                    label='Response Options Count' options={ likertResponseCounts }
                    placeholder='Select Response Count'
                    title='Response Count'
                    tabIndex='5'
                    onChange={ (event, data) => props.onUpdateLikertResponseCount(data.value as number, props.questionFocus, props.followupQuestion) }
                />
                }
                {   
                    props.isReadOnly
                    ?
                    <Form.Input fluid value={ likertResponseTypes.find(o => o.value === props.LikertResponseType).text } label='Response Options Type'
                        readOnly={ true }
                        placeholder='Select response type' required
                        tabIndex='5'
                    />
                    :         
                    <Form.Select fluid value={ props.LikertResponseType }
                        readOnly={ props.isReadOnly }
                        label='Response Options Type' options={ likertResponseTypes }
                        placeholder='Select response type'
                        title='Response Type'
                        tabIndex='5'
                        onChange={ (event, data) => props.onUpdateLikertResponseType(data.value as number, props.questionFocus, props.followupQuestion) }
                    />
                }
                {
                props.isReadOnly
                    ?
                    props.LikertResponseType === LikertResponseType.Alphanumeric && 
                    <Form.Input fluid value={ options.find(o => o.value === props.questionFocus).text } label='Question Focus'
                        readOnly={ true }
                        placeholder='Select question focus' required
                        tabIndex='5'
                    />
                    :
                    props.LikertResponseType === LikertResponseType.Alphanumeric && 
                    (props.likertResponseCount === LikertResponseCount.Three || props.likertResponseCount === LikertResponseCount.Five) &&
                    <Form.Select fluid value={ props.questionFocus }
                        label='Question Focus' options={ options }
                        placeholder='Select question focus'
                        disabled={ props.isReadOnly }
                        title='Question Focus'
                        tabIndex='5'
                        onChange={ (event, data) => {
                            const focus = data.value as SiufFocus;
                            if (focus !== props.questionFocus) {
                                const focusInfo = GetLikertSiufFocusValues(focus, props.likertResponseCount);
                                focusInfo.forEach((info, index) => props.onUpdateLikertOptionText(info.AnswerText, info.AnswerIndex));
                                props.onUpdateSiufFocus(focus);
                            }
                        } }
                    />
                }
            </Form.Group>
            <Grid>
                <Grid.Row verticalAlign='bottom' widths="equal">
                    { responseOptions }
                </Grid.Row>
                {
                    !props.isReadOnly && props.likertResponseCount === LikertResponseCount.Custom
                    && props.responseOptions?.length < MAXIMUM_LIKERT_RESPONSE_OPTION_COUNT &&
                    <Grid.Row verticalAlign='bottom'>
                        <Grid.Column width={ 1 }>
                        </Grid.Column>
                        <Grid.Column width={ 10 }>
                            <Button icon labelPosition='right' positive basic
                                onClick={ e => props.onAddLikertOption() }
                                tabIndex='5'>
                                Add Response Option
                            <Icon name='plus' />
                            </Button>
                        </Grid.Column>
                        <Grid.Column width={ 3 }>
                        </Grid.Column>
                    </Grid.Row>
                }
                <Grid.Row verticalAlign='bottom' widths="equal">
                    { scaleItems }
                </Grid.Row>
                {
                    !props.isReadOnly && props.scaleItems?.length < MAXIMUM_LIKERT_SCALE_ITEM_COUNT &&
                    <Grid.Row verticalAlign='bottom'>
                        <Grid.Column width={ 1 }>
                        </Grid.Column>
                        <Grid.Column width={ 10 }>
                            <Button icon labelPosition='right' positive basic
                                onClick={ e => props.onAddLikertScaleItem() }
                                tabIndex='5'>
                                Add Scale Item
                            <Icon name='plus' />
                            </Button>
                        </Grid.Column>
                        <Grid.Column width={ 3 }>
                        </Grid.Column>
                    </Grid.Row>
                }
            </Grid>
            <Divider hidden />
            {
                (props.questionStructure === SiufQuestionStructure.SingleQuestion)
                &&
                <Form.Input fluid label='Follow up Question'
                    readOnly={ props.isReadOnly }
                    tabIndex='5'
                    placeholder='Enter follow up question here' value={ props.followupQuestion }
                    onChange={ e => props.onUpdateFollowQuestionText(e.currentTarget.value) }
                />
            }
        </React.Fragment>
    );
};

export default LikertQuestion;