import { IEditingState } from "..";
import { INewSiufModel, INewSiufModel_Default, ISiufEntity, ISiufEntity_Default, ISiufReviewInfo, ISiufReviewSearchProperty, ISiufReviewSearchProperty_Default, ISiufReviewSortProperty, ISiufReviewSortProperty_Default, ISiufValidationResult, ISiufValidationResult_Default, ISubmitState, ISubmitState_Default, SiufTypeSelection } from "../models";

export interface IPageState {
    pageNumber: number;
    pageAmount: number;
}

export interface ISiufCreationAppState {
    newSiufModel: INewSiufModel;
    submitState: ISubmitState<ISiufEntity>;
}

export const ISiufCreationAppState_Default: ISiufCreationAppState = {
    newSiufModel: INewSiufModel_Default,
    submitState: ISubmitState_Default
};

export interface ISiufEditingAppState extends IEditingState {
    isDirty: boolean;
    siufEntity: ISiufEntity;
    validationResult: ISiufValidationResult;
    siufTypeSelection: SiufTypeSelection;
    currentSelectedPreviewOption: number;
    submitState: ISubmitState<ISiufEntity>;
    sideloadSubmitState: ISubmitState<string>;
    downloadXmlSubmitState: ISubmitState<string>;
    validationSubmitState: ISubmitState<ISiufValidationResult>;
    exportExtendedPayload: boolean;
}

export const ISiufEditingAppState_Default: ISiufEditingAppState = {
    isDirty: false,
    siufEntity: ISiufEntity_Default,
    validationResult: ISiufValidationResult_Default,
    siufTypeSelection: SiufTypeSelection.singleQuestion,
    currentSelectedPreviewOption: -1,
    submitState: ISubmitState_Default,
    sideloadSubmitState: ISubmitState_Default,
    downloadXmlSubmitState: ISubmitState_Default,
    validationSubmitState: ISubmitState_Default,
    exportExtendedPayload: false
};

export interface ISiufAuthoringDomainAppState {
    newSiuf: ISiufCreationAppState;
    currentSiuf: ISiufEditingAppState;
}

export const ISiufAuthoringDomainAppState_Default: ISiufAuthoringDomainAppState = {
    newSiuf: ISiufCreationAppState_Default,
    currentSiuf: ISiufEditingAppState_Default
};

export interface ISiufReviewingDomainAppState {
    siufCollection: ISiufReviewInfo[];
    searchProperty: ISiufReviewSearchProperty;
    sortProperty: ISiufReviewSortProperty;
}

export const ISiufReviewingDomainAppState_Default: ISiufReviewingDomainAppState = {
    siufCollection: [],
    searchProperty: ISiufReviewSearchProperty_Default,
    sortProperty: ISiufReviewSortProperty_Default
};

export interface ISiufDomainAppState {
    authoring: ISiufAuthoringDomainAppState;
    reviewing: ISiufReviewingDomainAppState;
}

export const ISiufDomainAppState_Default: ISiufDomainAppState = {
    authoring: ISiufAuthoringDomainAppState_Default,
    reviewing: ISiufReviewingDomainAppState_Default
};
