import React from 'react';
import { Container, Segment } from 'semantic-ui-react';
import NavAppHeader from '../../containers/NavAppHeader';

interface ILayoutProps {
}
const RootLayout: React.FC<ILayoutProps> = props => (
    <Container fluid>
        <NavAppHeader />
        <Segment basic>
            { props.children }
        </Segment>
    </Container>
);

export default RootLayout;