import axios from "axios";
import { GetAppEnvironmentConfigs } from '../appConfigs';
import { INewSiufModel, ISiufAuthoringApiResponse, ISiufEntity, ISiufValidationResult, IUpdateSiufModel, SiufStateAction, SiufReviewAction, HostPlatform } from '../contracts/models';
import { IUserProfile } from '../contracts/userProfile';

export default class SiufAuthoringApi {

    private static get BaseUrl () {
        const environmentConfigs = GetAppEnvironmentConfigs();
        if (environmentConfigs) {
            return environmentConfigs.endpoints.siufApiBaseUrl;
        }
        console.error("Cannot retrieve SiufApiBaseUrl because the environment configs doesn't exist");
        return "";
    }

    private static get<TResponse> (url: string): Promise<TResponse> {
        return new Promise<TResponse>((resolve, reject) => {
            axios.get<ISiufAuthoringApiResponse<TResponse>>(url)
                .then(response => {
                    resolve(response.data.Data);
                })
                .catch(error => reject(error));
        });
    }

    private static post<TRequestBody, TResponse> (url: string, requestBody: TRequestBody): Promise<TResponse> {
        return new Promise<TResponse>((resolve, reject) => {
            axios.post<ISiufAuthoringApiResponse<TResponse>>(url, requestBody)
                .then(response => {
                    resolve(response.data.Data);
                })
                .catch(error => reject(error));
        });
    }

    private static put<TRequestBody, TResponse> (url: string, requestBody: TRequestBody): Promise<TResponse> {
        return new Promise<TResponse>((resolve, reject) => {
            axios.put<ISiufAuthoringApiResponse<TResponse>>(url, requestBody)
                .then(response => {
                    resolve(response.data.Data);
                })
                .catch(error => reject(error));
        });
    }

    private static delete (url: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios.delete(url)
                .then(response => {
                    resolve(response.data.Data);
                })
                .catch(error => reject(error));
        });
    }

    public static getUserProfile (userUpn: string): Promise<IUserProfile> {
        return this.get(`${SiufAuthoringApi.BaseUrl}/userprofiles?upn=${userUpn}`);
    }

    //
    // Siufs
    //

    public static getAllSiufs (): Promise<ISiufEntity[]> {
        const url = `${SiufAuthoringApi.BaseUrl}/siufs`;
        return this.get(url);
    }

    public static getSiuf (siufId: number): Promise<ISiufEntity> {
        const url = `${SiufAuthoringApi.BaseUrl}/siufs/${siufId}`;
        return this.get(url);
    }

    public static createSiuf (newSiufModel: INewSiufModel): Promise<ISiufEntity> {
        const url = `${SiufAuthoringApi.BaseUrl}/siufs`;
        return this.post(url, newSiufModel);
    }

    public static saveSiuf (siufId: number, updateSiufModel: IUpdateSiufModel): Promise<ISiufEntity> {
        const url = `${SiufAuthoringApi.BaseUrl}/siufs/${siufId}`;
        return this.put(url, updateSiufModel);
    }

    public static submitSiuf (siufId: number, reason: string, hostPlatform: HostPlatform): Promise<ISiufEntity> {
        const url = `${SiufAuthoringApi.BaseUrl}/siufs/${siufId}`;
        return this.post(url, {
            Action: SiufStateAction.Submit,
            Comment: reason,
            HostPlatform: hostPlatform
        });
    }

    public static withdrawSiuf (siufId: number, reason: string, hostPlatform: HostPlatform): Promise<ISiufEntity> {
        const url = `${SiufAuthoringApi.BaseUrl}/siufs/${siufId}`;
        return this.post(url, {
            Action: SiufStateAction.Cancel,
            Comment: reason,
            HostPlatform: hostPlatform
        });
    }

    public static approveSiuf (siufId: number, reason: string, hostPlatform: HostPlatform): Promise<ISiufEntity> {
        const url = `${SiufAuthoringApi.BaseUrl}/reviews/${siufId}`;
        return this.post(url, {
            Action: SiufReviewAction.Approve,
            Message: reason,
            HostPlatform: hostPlatform
        });
    }

    public static rejectSiuf (siufId: number, reason: string, hostPlatform: HostPlatform): Promise<ISiufEntity> {
        const url = `${SiufAuthoringApi.BaseUrl}/reviews/${siufId}`;
        return this.post(url, {
            Action: SiufReviewAction.Reject,
            Message: reason,
            HostPlatform: hostPlatform
        });
    }

    public static terminateSiuf (siufId: number, reason: string, hostPlatform: HostPlatform): Promise<ISiufEntity> {
        const url = `${SiufAuthoringApi.BaseUrl}/siufs/${siufId}`;
        return this.post(url, {
            Action: SiufStateAction.Terminate,
            Comment: reason,
            HostPlatform: hostPlatform
        });
    }

    public static reactivateSiuf (siufId: number, reason: string, hostPlatform: HostPlatform): Promise<ISiufEntity> {
        const url = `${SiufAuthoringApi.BaseUrl}/siufs/${siufId}`;
        return this.post(url, {
            Action: SiufStateAction.Reactivate,
            Comment: reason,
            HostPlatform: hostPlatform
        });
    }

    public static testSideLoadSiuf (siufId: number, exportExtendedPayload: boolean): Promise<string> {
        const url = `${SiufAuthoringApi.BaseUrl}/siufs/${siufId}/localtestscript?ExtendedPayload=${exportExtendedPayload}`;
        return this.get(url);
    }

    public static downloadSiufXml (siufId: number, exportExtendedPayload: boolean): Promise<string> {
        const url = `${SiufAuthoringApi.BaseUrl}/siufs/${siufId}/sexml?ExtendedPayload=${exportExtendedPayload}`;
        return this.get(url);
    }

    public static validateSiuf (siufId: number): Promise<ISiufValidationResult> {
        const url = `${SiufAuthoringApi.BaseUrl}/siufs/${siufId}/validationresult`;
        return this.get(url);
    }
}
