/* tslint:disable:class-name semicolon interface-name no-duplicate-imports*/

import { action } from "typesafe-actions";
import { StateMachineActionTypes } from "./types";
import * as StateMachineModels from '../../../contracts/states/stateMachineStates';
import { uuidv4_id } from "../../../utils";

export const updateMaxDurationMs = (durationInMs: number) => action(
    StateMachineActionTypes.UPDATE_MAX_DURATION_IN_MS,
    {
        durationInMs
    }
);

export const updateStateMachineModelName = (name: string) => action(
    StateMachineActionTypes.SET_STATEMACHINENAME,
    {
        name
    }
);

// Trigger filters
export const updateStateMachineTriggerFilter = (triggerFilterChanges: StateMachineModels.IUpdateTriggerFilterModel) => action(
    StateMachineActionTypes.UPDATE_TRIGGERFILTER,
    {
        triggerFilterChanges
    }
);

export const updateStateMachineTriggerFilterEvent = (triggerFilterEventChanges: StateMachineModels.IUpdateTriggerFilterEventModel) => action(
    StateMachineActionTypes.UPDATE_TRIGGERFILTER_EVENT,
    {
        triggerFilterEventChanges
    }
);

// Transition filters
export const addStateMachineTransitionFilter = (newTransitionFilterId: string, newTriggerFilterId: string, transitionId: string) => action(
    StateMachineActionTypes.ADD_TRANSITIONFILTER,
    {
        newTransitionFilterId,
        newTriggerFilterId,
        transitionId
    }
);

export const removeStateMachineTransitionFilter = (transitionFilterId: string) => action(
    StateMachineActionTypes.REMOVE_TRANSITIONFILTER,
    {
        transitionFilterId
    }
);

export const updateStateMachineTransitionFilterReference = (transitionFilterReferenceModel: StateMachineModels.IUpdateTransitionFilterReferenceModel) => action(
    StateMachineActionTypes.UPDATE_TRANSITIONFILTER_REFERENCE,
    {
        transitionFilterReferenceModel
    }
);

export const updateStateMachineTransitionFilterStrict = (transitionFilterStrictModel: StateMachineModels.IUpdateTransitionFilterStrictModel) => action(
    StateMachineActionTypes.UPDATE_TRANSITIONFILTER_STRICT,
    {
        transitionFilterStrictModel
    }
);

// Transitions
export const removeStateMachineTransition = (transitionId: string) => action(
    StateMachineActionTypes.REMOVE_TRANSITION,
    {
        transitionId
    }
);

export const addStateMachineTransition = (transition: StateMachineModels.IAddTransitionModel) => action(
    StateMachineActionTypes.ADD_TRANSITION,
    {
        transition
    }
);

export const updateStateMachineTransition = (transitionChanges: StateMachineModels.IUpdateTransitionModel) => action(
    StateMachineActionTypes.UPDATE_TRANSITION,
    {
        transitionChanges
    }
);

// Trigger events
export const updateStateMachineTriggerEvent = (triggerEventChanges: StateMachineModels.IUpdateTriggerEventModel) => action(
    StateMachineActionTypes.UPDATE_TRIGGEREVENT,
    {
        triggerEventChanges
    }
);

export const removeStateMachineTriggerEvent = (triggerEventId: string) => action(
    StateMachineActionTypes.REMOVE_TRIGGEREVENT,
    {
        triggerEventId
    }
);

export const addStateMachineTriggerEvent = (triggerEventId: string) => action(
    StateMachineActionTypes.ADD_TRIGGEREVENT,
    {
        triggerEventId
    }
);

// States
export const removeStateMachineState = (stateId: string) => action(
    StateMachineActionTypes.REMOVE_STATE,
    {
        stateId
    }
);

export const addStateMachineState = () => action(
    StateMachineActionTypes.ADD_STATE,
    {
        stateId: uuidv4_id()
    }
);