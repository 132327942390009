import { action } from "typesafe-actions";
import { SiufRetrievalActionTypes } from "./types";
import { ISiufEntity, IAxiosErrorResponse } from "../../../contracts/models";
import { ThunkResult } from "../..";
import SiufAuthoringApi from "../../../api/siufAuthoringApi";
import { ParseErrorResponse } from "../../../utils/actionUtils";

export const getSiufFromServerSent = () => action(
    SiufRetrievalActionTypes.GET_SIUF_FROM_SERVER_SENT,
    {
    }
);

export const getSiufFromServerSuccess = (response: ISiufEntity) => action(
    SiufRetrievalActionTypes.GET_SIUF_FROM_SERVER_SUCCESS,
    {
        response
    }
);

export const getSiufFromServerFail = (error: string) => action(
    SiufRetrievalActionTypes.GET_SIUF_FROM_SERVER_FAIL,
    {
        error
    }
);

export const getSiufFromServerFromServer = (siufId: number): ThunkResult<void, void> => {
    return async (dispatch) => {
        dispatch(getSiufFromServerSent());
        try {
            const response = await SiufAuthoringApi.getSiuf(siufId);
            dispatch(getSiufFromServerSuccess(response));
        } catch (error) {
            const e = error as IAxiosErrorResponse;
            dispatch(getSiufFromServerFail(
                ParseErrorResponse(e))
            );
        }
    };
};