/* tslint:disable:no-duplicate-imports class-name semicolon interface-name switch-default switch-final-break radix*/

import * as React from 'react';
import { Form } from 'semantic-ui-react';
import { ISectionUiState, IUpdateTransitionModel, State, TriggerEvent, TransitionEventSource, Transition } from '../../../../contracts';
import { GetTriggerEventDisplayName } from '../../../../utils';

interface DetailsPanelProps {
    transition: Transition;
    states: State[];
    triggerEvents: TriggerEvent[];
    uiState: ISectionUiState;
    updateTransition (transitionChanges: IUpdateTransitionModel): void;
};

export default class DetailsPanel extends React.Component<DetailsPanelProps, {}> {
    public render () {
        let stateOptions = this.renderStateOptions();
        let triggerEventOptions = this.renderTriggerEventOptions();

        return <div>
            <h3>Details</h3>
            <React.Fragment>
                <Form.Group>
                    <Form.Field
                        label='From State'
                        control='select'
                        disabled={ this.props.uiState.isReadOnly }
                        title='From State'
                        tabIndex='7'
                        width={ 4 }
                        value={ this.props.transition.FromStateId || undefined }
                        onChange={ this.handleFromStateIdChange }>{ stateOptions }</Form.Field>
                    <Form.Field
                        label='To State'
                        control='select'
                        disabled={ this.props.uiState.isReadOnly }
                        title='To State'
                        tabIndex='7'
                        width={ 4 }
                        value={ this.props.transition.ToStateId || undefined }
                        onChange={ this.handleToStateIdChange }>{ stateOptions }</Form.Field>
                    <Form.Field
                        label='Upload Trigger'
                        control='input'
                        disabled={ this.props.uiState.isReadOnly }
                        title='Upload Trigger'
                        tabIndex='7'
                        type='checkbox'
                        value={ this.props.transition.UploadTrigger || undefined }
                        onChange={ this.handleUploadTriggerChange } />
                </Form.Group>
                <Form.Group>
                    <Form.Field
                        label='On Event'
                        control='select'
                        disabled={ this.props.uiState.isReadOnly }
                        title='On Event'
                        tabIndex='7'
                        width={ 10 }
                        value={ this.props.transition.TriggerEventId || undefined }
                        onChange={ this.handleTriggerEventIdChange }>{ triggerEventOptions }
                    </Form.Field>
                    <Form.Field
                        value={ this.props.transition.EventSource }
                        label='Source'
                        control='select'
                        disabled={ this.props.uiState.isReadOnly }
                        title='Source'
                        tabIndex='7'
                        onChange={ this.handleEventSourceChange }>
                        {
                            [
                                <option key={ TransitionEventSource.Agent } value={ TransitionEventSource.Agent }>{ 'Agent' }</option>,
                                <option key={ TransitionEventSource.Any } value={ TransitionEventSource.Any }>{ 'Any'}</option>,
                                <option key={ TransitionEventSource.Host } value={ TransitionEventSource.Host }>{ 'Host' }</option>
                            ]
                        }
                    </Form.Field>
                </Form.Group>
            </React.Fragment>
        </div>;
    }
    private handleFromStateIdChange = (e: any) => this.props.updateTransition({ TransitionId: this.props.transition.TransitionId, FromStateId: e.target.value });
    private handleToStateIdChange = (e: any) => this.props.updateTransition({ TransitionId: this.props.transition.TransitionId, ToStateId: e.target.value });
    private handleUploadTriggerChange = (e: any) => this.props.updateTransition({ TransitionId: this.props.transition.TransitionId, UploadTrigger: e.target.checked });
    private handleTriggerEventIdChange = (e: any) => this.props.updateTransition({ TransitionId: this.props.transition.TransitionId, TriggerEventId: e.target.value });
    private handleEventSourceChange = (e, d) => this.props.updateTransition({ TransitionId: this.props.transition.TransitionId, EventSource: e.target.value });

    private renderStateOptions = () => this.props.states.map(state => <option key={ `stateOption+${state.StateId}` } value={ state.StateId }>{ state.StateName }</option>);
    private renderTriggerEventOptions = () =>
        this.props.triggerEvents.map(te => <option key={ `triggerEventOptions+${te.TriggerEventId}` } value={ te.TriggerEventId }>{ GetTriggerEventDisplayName(te) }</option>);
}