/* tslint:disable:no-duplicate-imports class-name semicolon interface-name switch-default switch-final-break radix*/

import * as React from 'react';
import * as Types from "../../../../contracts";
import { ISectionUiState } from '../../../../contracts';
import { GetTriggerEventDisplayName } from '../../../../utils';
import TriggerEventPanelView from '../../../../containers/authoring/stateMachine/events/TriggerEventPanelView';
import { Grid, Menu, Icon } from 'semantic-ui-react';

interface EventsContainerProps {
    triggerEvents: Types.TriggerEvent[];
    uiState: ISectionUiState;
    addTriggerEvent (): string;
    removeTriggerEvent (triggerEventId: string): void;
    getFriendlyName (triggerEvent: Types.TriggerEvent): string;
};
interface EventsContainerState {
    selectedTriggerEventId?: string;
}

export default class EventsContainer extends React.Component<EventsContainerProps, EventsContainerState> {

    public constructor (props: EventsContainerProps) {
        super(props);
        this.state = {
            selectedTriggerEventId: null
        };
    }

    public render () {
        return <div>
            <Grid>
                <Grid.Column width={ 5 }>
                    <h3>Events</h3>
                    { this.renderEventsMenu() }
                </Grid.Column>
                <Grid.Column width={ 11 }>
                    <h3>Details</h3>
                    { this.renderEventsDetails() }
                </Grid.Column>
            </Grid>
        </div>;
    }

    private renderEventsMenu = () => {
        let menuActions =
            <Menu icon attached='top'>
                <Menu.Item name='add' disabled={this.props.uiState.isReadOnly } onClick={ this.handleAddTriggerEventClick } title='add' tabIndex='7'
                onKeyPress={e => {
                    var eventCode = e.charCode || e.which;
                    if (eventCode === 13 || eventCode === 32) {
                        this.handleAddTriggerEventClick();
                    }
                }}>
                    <Icon name='plus' />
                </Menu.Item>
                <Menu.Item name='remove' disabled={this.props.uiState.isReadOnly } onClick={ this.handleRemoveTriggerEventClick } title='remove' tabIndex='7'
                onKeyPress={e => {
                    var eventCode = e.charCode || e.which;
                    if (eventCode === 13 || eventCode === 32) {
                        this.handleRemoveTriggerEventClick();
                    }
                }}>
                    <Icon name='minus' />
                </Menu.Item>
            </Menu>;
        let eventMenuItems = this.props.triggerEvents.map(triggerEvent =>
            <Menu.Item
                key={ `triggerEventMenuItem+${triggerEvent.TriggerEventId}` }
                active={ this.state.selectedTriggerEventId === triggerEvent.TriggerEventId }
                name={ triggerEvent.TriggerEventId }
                disabled={ this.props.uiState.isReadOnly }
                className='triggerEventName'
                title={ triggerEvent.TriggerEventId }
                tabIndex='7'
                onClick={ this.handleTriggerEventMenuItemClick(triggerEvent.TriggerEventId) }
                onKeyPress={e => {
                    var eventCode = e.charCode || e.which;
                    if (eventCode === 13 || eventCode === 32) {
                        this.handleTriggerEventMenuItemKeyPressed(triggerEvent.TriggerEventId);
                    }
                }}>
                { GetTriggerEventDisplayName(triggerEvent) }
            </Menu.Item>);
        return <React.Fragment>
            { menuActions }
            <Menu attached vertical style={ { width: 'initial' } }>
                { eventMenuItems }
            </Menu>
        </React.Fragment>;
    }

    private handleAddTriggerEventClick = () => {
        let newTriggerEventId = this.props.addTriggerEvent();
        this.setState({ selectedTriggerEventId: newTriggerEventId });
    };

    private handleRemoveTriggerEventClick = () => {
        if (typeof this.state.selectedTriggerEventId === 'string') {
            let triggerEventIdMap = this.props.triggerEvents.map(x => x.TriggerEventId);
            let selectedIndex = triggerEventIdMap.indexOf(this.state.selectedTriggerEventId);
            let nextIndex = selectedIndex === triggerEventIdMap.length - 1 ? selectedIndex - 1 : selectedIndex + 1;
            let nextSelectedTriggerEventId = nextIndex < 0 ? null : triggerEventIdMap[nextIndex];
            this.props.removeTriggerEvent(this.state.selectedTriggerEventId);
            this.setState({ selectedTriggerEventId: nextSelectedTriggerEventId });
        }
    }

    private handleTriggerEventMenuItemClick = (triggerEventId: string) => () => this.setState({ selectedTriggerEventId: triggerEventId });

    private handleTriggerEventMenuItemKeyPressed = (triggerEventId: string) => {
        this.setState({ selectedTriggerEventId: triggerEventId });
    };

    private renderEventsDetails = () => {
        if (typeof this.state.selectedTriggerEventId !== 'string') {
            return <React.Fragment />;
        }
        let selectedTriggerEvent = this.props.triggerEvents.find(x => x.TriggerEventId === this.state.selectedTriggerEventId);
        if (!selectedTriggerEvent) {
            return <React.Fragment />;
        }
        return <TriggerEventPanelView uiState={ this.props.uiState } triggerEvent={ selectedTriggerEvent } />
    }
}