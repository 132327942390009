import { connect } from 'react-redux';
import { IApplicationRootReducerState } from "../../configureStore";
import NewSiuf from '../../components/authoring/NewSiuf';
import * as selectors from '../../selectors';
import * as siufAuthoringActions from '../../actions';
import { INewSiufModel } from '../../contracts/models';
import { RouteComponentProps } from 'react-router';
import { IConnectedReduxProps } from '../../reducers';
import { ThunkDispatch } from 'redux-thunk';

type IPropsFromState = {
};

type IRouteParams = {
};

type AllProps = IPropsFromState & RouteComponentProps<IRouteParams> & IConnectedReduxProps;

const mapStateToProps = (state: IApplicationRootReducerState) => ({
    newSiufModel: selectors.getNewSiufModel(state)
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
    onUpdateNewSiufProperties: (newSiufModel: INewSiufModel) => dispatch(siufAuthoringActions.updateNewSiufProperties(newSiufModel)),
    onCreateSiuf: (newSiufModel: INewSiufModel) => dispatch(siufAuthoringActions.createSiufFromServer(newSiufModel))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewSiuf);
