import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import * as siufAuthoringActions from '../../../actions';
import Approval from '../../../components/reviewing/approval/Approval';
import { IApplicationRootReducerState } from "../../../configureStore";
import * as selectors from '../../../selectors';
import { HostPlatform, SiufSortProperty } from '../../../contracts/models';

type Props = {

};

const mapStateToProps = (state: IApplicationRootReducerState, ownProps: Props) => ({
    siufCollection: selectors.getSortedReviewSiufCollectionAppState(state),
    activePreviewPage: selectors.getSiufActivePreviewPage(state),
    hostPlatform: state.resourceProviders.hostPlatform,
    forceCosmicMigration: state.resourceProviders.forceCosmicMigration
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
    onFetchSiufs: () => dispatch(siufAuthoringActions.getSiufReviewsFromServer()),
    onApproveSiuf: (siufId: number, reason: string, hostPlatform: HostPlatform) => dispatch(siufAuthoringActions.approveSiufFromServer(siufId, reason, hostPlatform)),
    onRejectSiuf: (siufId: number, reason: string, hostPlatform: HostPlatform) => dispatch(siufAuthoringActions.rejectSiufFromServer(siufId, reason, hostPlatform)),
    onTerminateSiuf: (siufId: number, reason: string, hostPlatform: HostPlatform) => dispatch(siufAuthoringActions.terminateSiufFromServer(siufId, reason, hostPlatform)),
    onUpdateSortProperty: (property: SiufSortProperty) => dispatch(siufAuthoringActions.updateSiufSortProperty(property)),
    onChangePreviewPage: (pageIndex: string | number) => dispatch(siufAuthoringActions.changePreviewPage(pageIndex))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Approval);