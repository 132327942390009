import React from 'react';
import ErrorPage from './ErrorPage';

const NotFoundPage: React.FC<any> = () => (
    <React.Fragment>
        <ErrorPage
            header="Uh oh, it looks like the page you are looking for does not exist"
            message="If you believe this is an error, please contact support" />;
    </React.Fragment>
);

export default NotFoundPage;