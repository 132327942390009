import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import * as ActionCreators from '../../../../actions/authoring/stateMachine/actions';
import { IApplicationRootReducerState } from "../../../../configureStore";
import { TriggerEvent } from '../../../../contracts/states/stateMachineStates';
import * as selectors from '../../../../selectors';
import { GetTriggerEventDisplayName, uuidv4_id } from '../../../../utils';
import EventsContainer from '../../../../components/authoring/stateMachine/events/EventContainer';

const mapStateToProps = (state: IApplicationRootReducerState) => ({
    triggerEvents: (selectors.getStateMachineRootState(state) && selectors.getStateMachineRootState(state).TriggerEvents) || []
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
    addTriggerEvent: (): string => {
        let newTriggerEventId = uuidv4_id();
        dispatch(ActionCreators.addStateMachineTriggerEvent(newTriggerEventId));
        return newTriggerEventId;
    },
    removeTriggerEvent: (triggerEventId: string) => dispatch(ActionCreators.removeStateMachineTriggerEvent(triggerEventId)),
    getFriendlyName: (triggerEvent: TriggerEvent) => GetTriggerEventDisplayName(triggerEvent)
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(EventsContainer);