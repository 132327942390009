import { createSelector } from "reselect";
import { IApplicationRootReducerState } from "../../configureStore";
import { ISiufReviewInfo, SiufSortProperty } from "../../contracts";
import { GetDisplayNameForSiufTargeting } from "../../utils";

const getSiufAppState = (state: IApplicationRootReducerState) => {
    return state.siufDomain.appState;
};

const getSiufUiState = (state: IApplicationRootReducerState) => {
    return state.siufDomain.uiState;
};

export const getReviewSiufCollectionAppState = createSelector(
    [getSiufAppState],
    (appState) => {
        return appState.reviewing.siufCollection;
    }
);

export const getReviewSiufCollectionCount = createSelector(
    [getReviewSiufCollectionAppState],
    (siufCollection) => {
        return siufCollection ? siufCollection.length : 0;
    }
);

export const getReviewSearchAppState = createSelector(
    [getSiufAppState],
    (appState) => {
        return appState.reviewing.searchProperty;
    }
);

export const getReviewSortAppState = createSelector(
    [getSiufAppState],
    (appState) => {
        return appState.reviewing.sortProperty;
    }
);

export const getReviewUiState = createSelector(
    [getSiufUiState],
    (uiState) => {
        return uiState.reviewing;
    }
);

export const getReviewSearchUiState = createSelector(
    [getSiufUiState],
    (uiState) => {
        return uiState.reviewing.search;
    }
);

export const getReviewApprovalUiState = createSelector(
    [getSiufUiState],
    (uiState) => {
        return uiState.reviewing.approval;
    }
);

export const getFilteredReviewSiufCollectionAppState = createSelector(
    [getReviewSiufCollectionAppState, getReviewSearchAppState],
    (siufCollection, searchState) => {
        if (!siufCollection) {
            return [];
        }
        let result: ISiufReviewInfo[] = siufCollection;

        if (searchState.state && searchState.state.length > 0) {
            result = result.filter(s => searchState.state.findIndex(st => st === s.siufReviewEntity.State) !== -1);
        }

        if (searchState.siufId && searchState.siufId > 0) {
            result = result.filter(s => s.siufReviewEntity.SiufId.toString().indexOf(searchState.siufId.toString()) !== -1);
        }

        if (searchState.siufName) {
            result = result.filter(s => s.siufReviewEntity.SiufName.toLowerCase().indexOf(searchState.siufName.trim().toLowerCase()) !== -1);
        }

        if (searchState.scenarioId) {
            result = result.filter(s => s.siufReviewEntity.ScenarioId.toLowerCase().indexOf(searchState.scenarioId.trim().toLowerCase()) !== -1);
        }

        if (searchState.ownerAlias) {
            result = result.filter(s => s.siufReviewEntity.Owner.toLowerCase().indexOf(searchState.ownerAlias.trim().toLowerCase()) !== -1);
        }

        return result;
    }
);

export const getSortedReviewSiufCollectionAppState = createSelector(
    [getFilteredReviewSiufCollectionAppState, getReviewSortAppState],
    (siufCollection, sortState) => {
        if (!siufCollection) {
            return [];
        }

        let result = siufCollection.sort((a, b) => {
            let s = 0;
            switch (sortState.property) {
                case SiufSortProperty.id:
                    s = a.siufReviewEntity.SiufId > b.siufReviewEntity.SiufId ? 1 : -1;
                    break;
                case SiufSortProperty.name:
                    s = a.siufReviewEntity.SiufName > b.siufReviewEntity.SiufName ? 1 : -1;
                    break;
                case SiufSortProperty.owner:
                    s = a.siufReviewEntity.Owner > b.siufReviewEntity.Owner ? 1 : -1;
                    break;
                case SiufSortProperty.triggerType:
                    s = a.siufReviewEntity.Payload.TriggeringType.toString() > b.siufReviewEntity.Payload.TriggeringType.toString() ? 1 : -1;
                    break;
                case SiufSortProperty.targeting:
                    if (!a.siufReviewEntity.Targeting) {
                        s = -1;
                    } else if (!b.siufReviewEntity.Targeting) {
                        s = 1;
                    } else {
                        s = GetDisplayNameForSiufTargeting(a.siufReviewEntity.Targeting.TargetType) > GetDisplayNameForSiufTargeting(b.siufReviewEntity.Targeting.TargetType) ? 1 : -1;
                    }
                    break;
                case SiufSortProperty.version:
                    if (a.siufReviewEntity.Version > b.siufReviewEntity.Version) {
                        s = 1;
                    } else if (a.siufReviewEntity.Version < b.siufReviewEntity.Version) {
                        s = -1;
                    } else {
                        if (a.siufReviewEntity.Iteration > b.siufReviewEntity.Iteration) {
                            s = 1;
                        } else if (a.siufReviewEntity.Iteration < b.siufReviewEntity.Iteration) {
                            s = -1;
                        }
                    }
                    break;
                case SiufSortProperty.state:
                    s = a.siufReviewEntity.State.toString() > b.siufReviewEntity.State.toString() ? 1 : -1;
                    break;
                default:
                    return 0;
            }

            return sortState.isAscending ? s : (s * -1);
        });

        return [...result];
    }
);