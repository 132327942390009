/* tslint:disable:no-duplicate-imports class-name semicolon interface-name switch-default switch-final-break radix*/

import * as React from 'react';
import * as Types from "../../../../contracts";
import { ISectionUiState, IUpdateTriggerEventModel } from '../../../../contracts';
import { Form } from 'semantic-ui-react';

interface TriggerEventPanelProps {
    uiState: ISectionUiState;
    triggerEvent: Types.TriggerEvent;
    updateTriggerEvent (triggerEventChanges: IUpdateTriggerEventModel): void;
};

export default class TriggerEventPanel extends React.Component<TriggerEventPanelProps, {}> {
    public render () {
        switch (this.props.triggerEvent.Type) {
            case 'manifestEtw':
                return this.renderManifestEtwForm(this.props.triggerEvent);
            case 'time':
                return this.renderTimeForm(this.props.triggerEvent);
            case 'traceLogging':
                return this.renderTraceLoggingForm(this.props.triggerEvent);
        }
    }

    private renderTimeForm = (triggerEvent: Types.TimeTriggerEvent) => {
        return <Form>
            { this.renderTypeField(triggerEvent) }
            <Form.Group>
                <Form.Field
                    disabled={ this.props.uiState.isReadOnly }
                    label='Duration (ms)'
                    control='input'
                    type='number'
                    value={ triggerEvent.DurationMs }
                    width={ 8 }
                    title='Duration (ms)'
                    tabIndex='7'
                    onChange={ this.handleDurationMsChange } />
            </Form.Group>
            <Form.Group>
                <Form.Field
                    disabled={ this.props.uiState.isReadOnly }
                    label='Allow Coalescing'
                    control='input'
                    type='checkbox'
                    value={ triggerEvent.AllowCoalescing }
                    title='Allow Coalescing'
                    tabIndex='7'
                    onChange={ this.handleAllowCoalescingChange } />
            </Form.Group>
        </Form>;
    }

    private handleDurationMsChange = (e: any) => this.props.updateTriggerEvent({ TriggerEventId: this.props.triggerEvent.TriggerEventId, DurationMs: e.target.value });
    private handleAllowCoalescingChange = (e: any) => this.props.updateTriggerEvent({ TriggerEventId: this.props.triggerEvent.TriggerEventId, AllowCoalescing: e.target.checked });

    private renderManifestEtwForm = (triggerEvent: Types.ManifestEtwTriggerEvent) => {
        return <Form>
            { this.renderTypeField(triggerEvent) }
            <Form.Group>
                { this.renderEventNameField(triggerEvent) }
            </Form.Group>
            <Form.Group>
                { this.renderProviderGuidField(triggerEvent) }
                { this.renderKeywordField(triggerEvent) }
                { this.renderLevelField(triggerEvent) }
            </Form.Group>
            <Form.Group>
                <Form.Field
                    disabled={ this.props.uiState.isReadOnly }
                    label='Event Id'
                    control='input'
                    type='number'
                    value={ triggerEvent.EventId }
                    width={ 8 }
                    title='Event Id'
                    tabIndex='7'
                    onChange={ this.handleEventIdChange } />
                <Form.Field
                    disabled={ this.props.uiState.isReadOnly }
                    label='Event Version'
                    control='input'
                    type='number'
                    value={ triggerEvent.EventVersion }
                    width={ 8 }
                    title='Event Version'
                    tabIndex='7'
                    onChange={ this.handleEventVersionChange } />
            </Form.Group>
        </Form>;
    }

    private handleEventIdChange = (e: any) => this.props.updateTriggerEvent({ TriggerEventId: this.props.triggerEvent.TriggerEventId, EventId: e.target.value });
    private handleEventVersionChange = (e: any) => this.props.updateTriggerEvent({ TriggerEventId: this.props.triggerEvent.TriggerEventId, EventVersion: e.target.value });

    private renderTraceLoggingForm = (triggerEvent: Types.TraceLoggingTriggerEvent) => {
        return <React.Fragment>
            { this.renderTypeField(triggerEvent) }
            <Form.Group>
                { this.renderTraceLoggingEventNameField(triggerEvent) }
            </Form.Group>
            <Form.Group>
                { this.renderProviderGuidField(triggerEvent) }
                { this.renderKeywordField(triggerEvent) }
                { this.renderLevelField(triggerEvent) }
            </Form.Group>
        </React.Fragment>;
    }

    private renderTypeField = (triggerEvent: Types.TriggerEvent) => {
        return <Form.Field
            disabled={ this.props.uiState.isReadOnly }
            label='Trigger Type'
            control='select'
            value={ triggerEvent.Type }
            width={ 8 }
            title='Trigger Type'
            tabIndex='7'
            onChange={ this.handleTypeChange }>
            <option value='manifestEtw'>Manifest ETW</option>
            <option value='traceLogging'>Trace Logging</option>
            <option value='time'>Time</option>
        </Form.Field>;
    }
    private handleTypeChange = (e: any) => this.props.updateTriggerEvent({ TriggerEventId: this.props.triggerEvent.TriggerEventId, Type: e.target.value });

    private renderEventNameField = (triggerEvent: Types.ManifestEtwTriggerEvent) => {
        return <Form.Field
            disabled={ this.props.uiState.isReadOnly }
            label='Event Name'
            control='input'
            value={ triggerEvent.EventName }
            width={ 16 }
            title='Event Name'
            tabIndex='7'
            onChange={ this.handleEventNameChange } />
    };

    private renderTraceLoggingEventNameField = (triggerEvent: Types.TraceLoggingTriggerEvent) => {
        return <Form.Field
            label='Event Name (fully qualified)'
            disabled={ this.props.uiState.isReadOnly }
            control='input'
            value={ triggerEvent.EventName }
            width={ 16 }
            title='Event Name (fully qualified)'
            tabIndex='7'
            onChange={ this.handleEventNameChange } />
    }

    private handleEventNameChange = (e: any) => this.props.updateTriggerEvent({ TriggerEventId: this.props.triggerEvent.TriggerEventId, EventName: e.target.value });

    private renderProviderGuidField = (triggerEvent: Types.ManifestEtwTriggerEvent | Types.TraceLoggingTriggerEvent) => {
        return <Form.Field
            label='Provider GUID'
            disabled={ this.props.uiState.isReadOnly }
            control='input'
            value={ triggerEvent.ProviderGuid }
            width={ 8 }
            title='Provider GUID'
            tabIndex='7'
            onChange={ this.handleProviderGuidChange } />
    };

    private handleProviderGuidChange = (e: any) => this.props.updateTriggerEvent({ TriggerEventId: this.props.triggerEvent.TriggerEventId, ProviderGuid: e.target.value });

    private renderKeywordField = (triggerEvent: Types.ManifestEtwTriggerEvent | Types.TraceLoggingTriggerEvent) => {
        return <Form.Field
            disabled={ this.props.uiState.isReadOnly }
            label='Keyword'
            control='input'
            value={ triggerEvent.Keyword }
            width={ 4 }
            title='Keyword'
            tabIndex='7'
            onChange={ this.handleKeywordChange } />
    };

    private handleKeywordChange = (e: any) => this.props.updateTriggerEvent({ TriggerEventId: this.props.triggerEvent.TriggerEventId, Keyword: e.target.value });

    private renderLevelField = (triggerEvent: Types.ManifestEtwTriggerEvent | Types.TraceLoggingTriggerEvent) => {
        return <Form.Field
            disabled={ this.props.uiState.isReadOnly }
            label='Level'
            control='input'
            type='number'
            value={ triggerEvent.Level }
            width={ 4 }
            title='Level'
            tabIndex='7'
            onChange={ this.handleLevelChange } />
    };
    private handleLevelChange = (e: any) => this.props.updateTriggerEvent({ TriggerEventId: this.props.triggerEvent.TriggerEventId, Level: e.target.value });
};