import React from 'react';
import ErrorPage from './ErrorPage';

const UnauthorizedPage: React.FC<any> = () => (
    <React.Fragment>
        <ErrorPage
            header="Uh oh, it looks like you are unauthorized to view this page"
            message={<span>
                The SiufAuthoring site requires the user to have membership for <a href="https://osgwiki.com/wiki/Asimov/Access#Cosmos_VC_Access" target="_blank" rel="noopener noreferrer">OSG Data Extended</a> or <a href="https://osgwiki.com/wiki/Asimov/Access#Windows_Tools_Access" target="_blank" rel="noopener noreferrer">Windows Tool Access SG</a>.<br/>Please complete trainings listed <a href="https://osgwiki.com/wiki/Windows_Asimov_Access_Management_Compliance#Training_Requirements" target="_blank" rel="noopener noreferrer">here</a> to raise SG membership request.
                If you have any questions, please contact <a href="mailto:sifcrew@microsoft.com">sifcrew@microsoft.com</a> for support.
            </span>} >
        </ErrorPage>;
    </React.Fragment>
);

export default UnauthorizedPage;