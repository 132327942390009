import { connect } from 'react-redux';
import { IApplicationRootReducerState } from "../../../configureStore";
import { Dispatch } from 'redux';
import ThreePointRatingQuestion from '../../../components/authoring/questionDetails/ThreePointRatingQuestion';
import { IQuestionEntity, SiufFocus } from '../../../contracts/models';
import * as siufAuthoringActions from '../../../actions';
import { GetThreePointSiufFocusFromValue } from '../../../utils/dataUtils';

type Props = {
    questionModel: IQuestionEntity;
    isReadOnly: boolean;
    pageIndex: number;
};

const mapStateToProps = (state: IApplicationRootReducerState, ownProps: Props) => ({
    isReadOnly: ownProps.isReadOnly,
    questionStructure: ownProps.questionModel.QuestionStructure,
    questionText: ownProps.questionModel.QuestionText,
    questionFocus: GetThreePointSiufFocusFromValue(ownProps.questionModel.Answers.find(a => a.AnswerIndex === 2).AnswerText, ownProps.questionModel.Answers.find(a => a.AnswerIndex === 1).AnswerText, ownProps.questionModel.Answers.find(a => a.AnswerIndex === 0).AnswerText),
    positiveAnchor: ownProps.questionModel.Answers.find(a => a.AnswerIndex === 2).AnswerText,
    neutralAnchor: ownProps.questionModel.Answers.find(a => a.AnswerIndex === 1).AnswerText,
    negativeAnchor: ownProps.questionModel.Answers.find(a => a.AnswerIndex === 0).AnswerText,
    positiveFollowupQuestion: ownProps.questionModel.Answers.find(a => a.AnswerIndex === 2).FollowUpQuestion ? ownProps.questionModel.Answers.find(a => a.AnswerIndex === 2).FollowUpQuestion.QuestionText : "",
    neutralFollowupQuestion: ownProps.questionModel.Answers.find(a => a.AnswerIndex === 1).FollowUpQuestion ? ownProps.questionModel.Answers.find(a => a.AnswerIndex === 1).FollowUpQuestion.QuestionText : "",
    negativeFollowupQuestion: ownProps.questionModel.Answers.find(a => a.AnswerIndex === 0).FollowUpQuestion ? ownProps.questionModel.Answers.find(a => a.AnswerIndex === 0).FollowUpQuestion.QuestionText : ""
});

const mapDispatchToProps = (dispatch: Dispatch, ownProps: Props) => ({
    onUpdateSiufFocus: (questionFocus: SiufFocus) => dispatch(siufAuthoringActions.updateSiufFocus(ownProps.pageIndex, ownProps.questionModel.QuestionIndex, questionFocus)),
    onUpdateQuestionText: (questionText: string) => dispatch(siufAuthoringActions.updateQuestionText(ownProps.pageIndex, ownProps.questionModel.QuestionIndex, questionText)),
    onUpdateAnchorText: (positiveAnchor: string, neutralAnchor: string, negativeAnchor: string) => {
        dispatch(siufAuthoringActions.updateAnchorText(ownProps.pageIndex, ownProps.questionModel.QuestionIndex, positiveAnchor, 2));
        dispatch(siufAuthoringActions.updateAnchorText(ownProps.pageIndex, ownProps.questionModel.QuestionIndex, neutralAnchor, 1));
        dispatch(siufAuthoringActions.updateAnchorText(ownProps.pageIndex, ownProps.questionModel.QuestionIndex, negativeAnchor, 0));
    },
    onUpdateFollowQuestionText: (questionText: string, index: number) => dispatch(siufAuthoringActions.updateFollowUpQuestionText(ownProps.pageIndex, ownProps.questionModel.QuestionIndex, questionText, index))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ThreePointRatingQuestion);
