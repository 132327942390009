export enum SiufCreationActionTypes {

    // Ajax related actions types

    CREATE_NEWSIUF_SENT = '@@SIUF/SIUFCREATION/CREATE_NEWSIUF_SENT',
    CREATE_NEWSIUF_SUCCESS = '@@SIUF/SIUFCREATION/CREATE_NEWSIUF_SUCCESS',
    CREATE_NEWSIUF_FAIL = '@@SIUF/SIUFCREATION/CREATE_NEWSIUF_FAIL',

    // normal action types

    UPDATE_NEWSIUF_PROPERTIES = '@@SIUF/SIUFCREATION/UPDATE_NEWSIUF_PROPERTIES',

}