import React from 'react';
import { History } from 'history';
import { ConnectedRouter } from 'connected-react-router';
import Routes from './navigation/Routes';

interface IAppProps {
    history: History;
}

const App = ({ history }: IAppProps) => {
    return (
        <ConnectedRouter history={ history }>
            { Routes }
        </ConnectedRouter>
    );
};

export default App;
