import * as React from 'react';
import { Button, Divider, Form, Grid, Icon } from 'semantic-ui-react';
import { INPUT_DELAY, SiufQuestionStructure } from '../../../contracts/models';

type Props = {
    isReadOnly: boolean,
    questionStructure: SiufQuestionStructure;
    checkboxTexts: string[];
    questionText: string;
    followupQuestion?: string;
    onUpdateQuestionText: (questionText: string) => void;
    onUpdateCheckboxText: (text: string, index: number) => void;
    onAddCheckboxText: () => void;
    onRemoveCheckboxText: (index: number) => void;
    onUpdateFollowQuestionText: (questionText: string) => void;
};

const CheckboxQuestion: React.FC<Props> = props => {

    // Delay the update
    let timer: NodeJS.Timeout;
    const onUpdateWithDelay = (func: any, ...params: any[]) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Function/call
            return func.call(props, ...params);
        }, INPUT_DELAY);
    };

    const checkboxes = props.checkboxTexts.filter((c, i) => i !== 0).map((cb, index) => (
        <Grid.Row verticalAlign='bottom' key={ index }>
            <Grid.Column width={ 1 }>
            </Grid.Column>
            <Grid.Column width={ 10 }>
                <Form.Input fluid
                    label={ `Response ${index + 1}:` }
                    readOnly={ props.isReadOnly }
                    tabIndex='5'
                    value={ cb }
                    placeholder='Enter your checkbox text here'
                    onChange={ e => props.onUpdateCheckboxText(e.currentTarget.value, index + 1) }
                />
            </Grid.Column>
            <Grid.Column width={ 3 }>
                {
                    !props.isReadOnly
                    &&
                    <Button icon labelPosition='right' negative basic
                        onClick={ e => props.onRemoveCheckboxText(index + 1) }
                        tabIndex='5'>
                        Remove
                        <Icon name='delete' />
                    </Button>
                }
            </Grid.Column>
        </Grid.Row>
    ));

    const defaultCheckbox = (
        <Grid.Row verticalAlign='bottom'>
            <Grid.Column width={ 1 }>
            </Grid.Column>
            <Grid.Column width={ 10 }>
                <Form.Input fluid
                    readOnly={ true }
                    label='Response (Default and cannot be changed)'
                    title='Response (Default and cannot be changed)'
                    tabIndex='5'
                    value={ props.checkboxTexts[0] }
                />
            </Grid.Column>
            <Grid.Column width={ 3 }>
            </Grid.Column>
        </Grid.Row>
    );

    return (
        <React.Fragment>
            <Form.Input fluid defaultValue={ props.questionText }
                readOnly={ props.isReadOnly }
                label='Question Text' placeholder='Enter your question here' required
                tabIndex='5'
                onChange={ e => onUpdateWithDelay(props.onUpdateQuestionText, e.currentTarget.value) }
            />
            <Grid columns={ 3 } >
                { checkboxes }
                { defaultCheckbox }
                {
                    !props.isReadOnly
                    &&
                    <Grid.Row verticalAlign='bottom'>
                        <Grid.Column width={ 1 }>
                        </Grid.Column>
                        <Grid.Column width={ 10 }>
                            <Button icon labelPosition='right' positive basic
                                onClick={ e => props.onAddCheckboxText() }
                                tabIndex='5'>
                                Add Checkbox
                            <Icon name='plus' />
                            </Button>
                        </Grid.Column>
                        <Grid.Column width={ 3 }>
                        </Grid.Column>
                    </Grid.Row>
                }
            </Grid>
            <Divider hidden />
            {
                (props.questionStructure === SiufQuestionStructure.SingleQuestion)
                &&
                <Form.Input fluid label='Follow up Question'
                    readOnly={ props.isReadOnly }
                    tabIndex='5'
                    placeholder='Enter follow up question here' value={ props.followupQuestion }
                    onChange={ e => props.onUpdateFollowQuestionText(e.currentTarget.value) }
                />
            }
        </React.Fragment>
    );
};

export default CheckboxQuestion;