import { action } from "typesafe-actions";
import { SiufTriggerActionTypes } from ".";
import { SiufTriggeringType } from "../../../contracts";

export const updateSiufTriggerType = (triggerType: SiufTriggeringType) => action(
    SiufTriggerActionTypes.UPDATE_TRIGGER_TYPE,
    {
        triggerType
    }
);

export const updateSiufLifeTimeInDays = (durationInDays: number) => action(
    SiufTriggerActionTypes.UPDATE_LIFETIME_INDAYS,
    {
        durationInDays
    }
);

