/* tslint:disable:no-duplicate-imports class-name semicolon interface-name switch-default switch-final-break radix comment-format*/

import * as dagre from "dagre";

const defaultSize = {
    width: 30,
    height: 30
};
const pillSize = {
    width: 35,
    height: 25
};
const circleSize = {
    width: 65,
    height: 65
};

export function distributeElements (model: any) {
    let clonedModel = { ...model };
    let nodes = distributeGraph(clonedModel);
    nodes.forEach(node => {
        let modelNode = clonedModel.nodes.find((item: any) => item.id === node.id);
        modelNode.x = node.x;
        modelNode.y = node.y;
    });
    return clonedModel;
}

function distributeGraph (model: any) {
    let nodes = mapNodes(model);
    let edges = mapEdges(model);
    let graph = new dagre.graphlib.Graph();
    graph.setGraph({
        rankdir: 'LR',
        align: 'UL',
        marginx: 5,
        marginy: 5,
        nodesep: 20,
        ranksep: 25
    });
    graph.setDefaultEdgeLabel(function () {
        return {};
    });
    //add elements to dagre graph
    nodes.forEach((node: any) => {
        graph.setNode(node.id, node.metadata);
    });
    edges.forEach((edge: any) => {
        if (edge.from && edge.to) {
            graph.setEdge(edge.from, edge.to);
        }
    });
    //auto-distribute
    dagre.layout(graph);
    return graph.nodes().map(node => graph.node(node));
}

function mapNodes (model: any) {
    return model.nodes.map((node: any) => {
        let size = getSize(node);
        return {
            id: node.id,
            metadata: {
                ...size,
                id: node.id
            }
        };
    });
}

function getSize (node: any) {
    if (!node || !node.shape) return defaultSize;
    switch (node.shape) {
        case 'pill':
            return pillSize;
        case 'circle':
            return circleSize;
        default:
            return defaultSize;
    }
}

function mapEdges (model: any) {
    // returns links which connects nodes
    // we check are there both from and to nodes in the model. Sometimes links can be detached
    return model.links
        .map((link: any) => ({
            from: link.source,
            to: link.target
        }))
        .filter(
            (item: any) => model.nodes.find((node: any) => node.id === item.from) && model.nodes.find((node: any) => node.id === item.to)
        );
}
