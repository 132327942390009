import { connect } from 'react-redux';
import { IApplicationRootReducerState } from "../../../configureStore";
import { Dispatch } from 'redux';
import Question from '../../../components/authoring/questionDetails/Question';
import { SiufQuestionType } from '../../../contracts/models';
import * as siufAuthoringActions from '../../../actions';
import * as selectors from '../../../selectors';

type Props = {
};

const mapStateToProps = (state: IApplicationRootReducerState, ownProps: Props) => ({
    uiState: selectors.getSiufQuestionDetailsUiState(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    onUpdateQuestionType: (pageIndex: number, questionIndex: number, questionType: SiufQuestionType) => dispatch(siufAuthoringActions.updateQuestionType(pageIndex, questionIndex, questionType))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Question);
