import { connect } from 'react-redux';
import { IApplicationRootReducerState } from "../../../configureStore";
import { Dispatch } from 'redux';
import FivePointRatingQuestion from '../../../components/authoring/questionDetails/FivePointRatingQuestion';
import { IQuestionEntity, SiufFocus } from '../../../contracts/models';
import * as siufAuthoringActions from '../../../actions';
import { GetFivePointSiufFocusFromValue } from '../../../utils/dataUtils';

type Props = {
    questionModel: IQuestionEntity;
    isReadOnly: boolean;
    pageIndex: number;
};

const mapStateToProps = (state: IApplicationRootReducerState, ownProps: Props) => ({
    isReadOnly: ownProps.isReadOnly,
    questionStructure: ownProps.questionModel.QuestionStructure,
    questionText: ownProps.questionModel.QuestionText,
    questionFocus: GetFivePointSiufFocusFromValue(ownProps.questionModel.Answers.find(a => a.AnswerIndex === 0).AnswerText, ownProps.questionModel.Answers.find(a => a.AnswerIndex === 4).AnswerText, ownProps.questionModel.QuestionStructure),
    minAnchor: ownProps.questionModel.Answers.find(a => a.AnswerIndex === 0).AnswerText,
    maxAnchor: ownProps.questionModel.Answers.find(a => a.AnswerIndex === 4).AnswerText,
    ratings1FollowupQuestion: ownProps.questionModel.Answers.find(a => a.AnswerIndex === 0).FollowUpQuestion ? ownProps.questionModel.Answers.find(a => a.AnswerIndex === 0).FollowUpQuestion.QuestionText : "",
    ratings2FollowupQuestion: ownProps.questionModel.Answers.find(a => a.AnswerIndex === 1).FollowUpQuestion ? ownProps.questionModel.Answers.find(a => a.AnswerIndex === 1).FollowUpQuestion.QuestionText : "",
    ratings3FollowupQuestion: ownProps.questionModel.Answers.find(a => a.AnswerIndex === 2).FollowUpQuestion ? ownProps.questionModel.Answers.find(a => a.AnswerIndex === 2).FollowUpQuestion.QuestionText : "",
    ratings4FollowupQuestion: ownProps.questionModel.Answers.find(a => a.AnswerIndex === 3).FollowUpQuestion ? ownProps.questionModel.Answers.find(a => a.AnswerIndex === 3).FollowUpQuestion.QuestionText : "",
    ratings5FollowupQuestion: ownProps.questionModel.Answers.find(a => a.AnswerIndex === 4).FollowUpQuestion ? ownProps.questionModel.Answers.find(a => a.AnswerIndex === 4).FollowUpQuestion.QuestionText : ""
});

const mapDispatchToProps = (dispatch: Dispatch, ownProps: Props) => ({
    onUpdateSiufFocus: (questionFocus: SiufFocus) => dispatch(siufAuthoringActions.updateSiufFocus(ownProps.pageIndex, ownProps.questionModel.QuestionIndex, questionFocus)),
    onUpdateQuestionText: (questionText: string) => dispatch(siufAuthoringActions.updateQuestionText(ownProps.pageIndex, ownProps.questionModel.QuestionIndex, questionText)),
    onUpdateAnchorText: (minAnchor: string, maxAnchor: string) => {
        dispatch(siufAuthoringActions.updateAnchorText(ownProps.pageIndex, ownProps.questionModel.QuestionIndex, minAnchor, 0));
        dispatch(siufAuthoringActions.updateAnchorText(ownProps.pageIndex, ownProps.questionModel.QuestionIndex, maxAnchor, 4));
    },
    onUpdateFollowQuestionText: (questionText: string, index: number) => dispatch(siufAuthoringActions.updateFollowUpQuestionText(ownProps.pageIndex, ownProps.questionModel.QuestionIndex, questionText, index))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FivePointRatingQuestion);
