import * as React from 'react';
import { Grid, Divider, Header, Label, List, Segment, Button } from 'semantic-ui-react';
import CheckboxPreviewView from '../../../containers/authoring/questionPreview/CheckboxPreviewView';
import FivePointRatingPreviewView from '../../../containers/authoring/questionPreview/FivePointRatingPreviewView';
import FreeformTextPreviewView from '../../../containers/authoring/questionPreview/FreeformTextPreviewView';
import LikertPreviewView from '../../../containers/authoring/questionPreview/LikertPreviewView';
import ThreePointRatingPreviewView from '../../../containers/authoring/questionPreview/ThreePointRatingPreviewView';
import YesNoPreviewView from '../../../containers/authoring/questionPreview/YesNoPreviewView';
import { IPageEntity, IQuestionEntity, SiufQuestionType, SiufTypeSelection } from '../../../contracts/models';
import { ISectionUiState } from '../../../contracts/states';

type Props = {
    siufType: SiufTypeSelection;
    pageCollectionModel: IPageEntity[];
    title: string;
    ignoreTitleOnToast: boolean;
    uiState: ISectionUiState;
    activePreviewPage: string | number;
    onChangePreviewPage: (pageIndex: string | number) => void;
};

const Preview: React.FC<Props> = props => {
    let questions = props.pageCollectionModel[props.activePreviewPage]?.Questions.map((q, index) => {
        let questionPreview: JSX.Element;
        switch (q.QuestionType) {
            case SiufQuestionType.FivePoint:
                questionPreview = <FivePointRatingPreviewView questionModel={ q } />;
                break;
            case SiufQuestionType.ThreePoint:
                questionPreview = <ThreePointRatingPreviewView questionModel={ q } />;
                break;
            case SiufQuestionType.YesNo:
                questionPreview = <YesNoPreviewView questionModel={ q } />;
                break;
            case SiufQuestionType.Text:
                questionPreview = <FreeformTextPreviewView questionModel={ q } />;
                break;
            case SiufQuestionType.CheckBox:
                questionPreview = <CheckboxPreviewView questionModel={ q } />;
                break;
            case SiufQuestionType.Likert:
                questionPreview = <LikertPreviewView questionModel={ q } />;
                break;
            default:
                questionPreview = <div>Question type not supported</div>;
        }

        return (
            <List.Item key={ index } >
                <List.Content>
                    { questionPreview }
                </List.Content>
            </List.Item>
        );
    });

    return (
        <React.Fragment>
            <Segment basic>
                {
                    props.siufType === SiufTypeSelection.multiQuestion
                    &&
                    <React.Fragment>
                        <div className='flex-div'>
                            <Header as='h3' className='preview-title'>{ props.title || "<Title>" }</Header>
                            {props.ignoreTitleOnToast && <Label className='float-right' as='a' size='small' color='yellow' tag>Ingored on Toast</Label>}
                        </div>
                        <Divider hidden />
                        { props.pageCollectionModel.length > 1 && <Label>Page { +props.activePreviewPage+1 } of { props.pageCollectionModel.length }</Label> }
                    </React.Fragment>
                }
                <List relaxed>
                    { questions }
                </List>
                {
                    props.pageCollectionModel.length > 1 && <Grid textAlign='center' padded>
                        <Button content='Back' icon='left arrow' labelPosition='left' disabled={+props.activePreviewPage <= 0} onClick={ () => {
                            if(+props.activePreviewPage > 0){
                                props.onChangePreviewPage(+props.activePreviewPage-1);
                            }
                        }} />
                        <Button content='Next' icon='right arrow' labelPosition='right' disabled={+props.activePreviewPage+1 >= props.pageCollectionModel.length} 
                            onClick={ () => {
                            if(+props.activePreviewPage+1 < props.pageCollectionModel.length ){
                                props.onChangePreviewPage(+props.activePreviewPage+1);
                            }
                        }} />
                  </Grid>
                }
            </Segment>
        </React.Fragment>
    );
};

export default Preview;