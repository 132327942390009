import * as React from 'react';
import { Divider, Form, Label, Table } from 'semantic-ui-react';
import { SiufQuestionStructure } from '../../../contracts/models';
import '../../../siufAuthUx.css'

type Props = {
    questionStructure: SiufQuestionStructure
    questionText: string;
    isNpsQuestion: boolean;
    scaleItems: string[];
    responseOptions: string[];
    followupQuestion: string;
};

type State = {
    currentSelectedOptions: Set<string>;
};

export default class LikertPreview extends React.Component<Props, State> {

    constructor (props: Props) {
        super(props);

        this.state = {
            currentSelectedOptions: new Set()
        };
    }

    updateSelection(itemIndex: number, optionIndex: number){
        var currentSelection = this.state.currentSelectedOptions;
        const key = this.generateKeyFromIndexes(itemIndex,optionIndex);
        currentSelection.forEach(selection => {
            if(key === selection){
                return;
            }
            const [indexItem, indexOption] = selection.split("-");
            if(indexItem === itemIndex.toString()){
                currentSelection.delete(selection);
            }
        })
        currentSelection.add(key);
        this.setState({currentSelectedOptions: currentSelection});
    }

    generateKeyFromIndexes(itemIndex: number, optionIndex: number){
       return itemIndex+'-'+optionIndex
    }

    public render () {
        return (
            <React.Fragment>
                <Form>
                <div className='flex-div'>
                        <h3 className='no-margin' tabIndex={6}>{ this.props.questionText || '<Question Text>' }</h3>
                        {this.props.isNpsQuestion && <Label className='float-right' as='a' size='small' color='blue' tag>NPS</Label>}
                    </div>
                    <Divider hidden />
                    <React.Fragment>
                        <Table striped>
                                <Table.Header>
                                    <Table.Row textAlign='center'>
                                        <Table.HeaderCell key={'h'+0} className='likertPreviewOptionHeader'></Table.HeaderCell>
                                        {this.props.responseOptions.map((option, index) => 
                                            <Table.HeaderCell key={('o'+index)} className='likertPreviewOptionHeader' tabIndex='6'>{option || '<Opt '+(index+1)+'>'}</Table.HeaderCell>
                                        )}
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {this.props.scaleItems.map((item, itemIndex) => 
                                    <Table.Row key={'r'+itemIndex}>
                                        <Table.Cell  key={'d'+itemIndex} tabIndex='6'>{item || '<Scale Item ' +(itemIndex+1) +'>'}</Table.Cell>
                                        {this.props.responseOptions.map((option, optionIndex) => 
                                        <Table.Cell key={itemIndex+'-'+optionIndex} textAlign='center'>
                                            <Form.Radio checked={ this.state.currentSelectedOptions.has(this.generateKeyFromIndexes(itemIndex,optionIndex)) }
                                            name={this.generateKeyFromIndexes(itemIndex,optionIndex)} value={ this.generateKeyFromIndexes(itemIndex,optionIndex) }
                                            tabIndex='6' className='ui radio_button_new'
                                            onChange={ () => this.updateSelection(itemIndex, optionIndex) }
                                        />
                                        </Table.Cell>
                                        )}
                                    </Table.Row>
                                    )}
                                </Table.Body>
                            </Table>
                        </React.Fragment>
                        {
                            (this.props.questionStructure === SiufQuestionStructure.SingleQuestion) && (this.props.followupQuestion)
                            &&
                            <React.Fragment>
                                <h3 tabIndex={6}>{ this.props.followupQuestion }</h3>
                                <Form.TextArea placeholder='Enter your feedback here...' tabIndex='6' />
                            </React.Fragment>
                        }
                </Form>
            </React.Fragment>
        );
    }
}