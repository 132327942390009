import * as React from 'react';
import { Label } from 'semantic-ui-react';
import { SiufState } from '../../contracts/models';
import { GetDisplayNameForSiufState } from '../../utils';

type Props = {
    state: SiufState;
};

const StateLabel: React.FC<Props> = props => {
    switch (props.state) {
        case SiufState.Draft:
            return <Label basic color="orange">{ GetDisplayNameForSiufState(SiufState.Draft) }</Label>;
        case SiufState.Submitted:
            return <Label basic color="purple">{ GetDisplayNameForSiufState(SiufState.Submitted) }</Label>;
        case SiufState.Approved:
            return <Label basic color="green">{ GetDisplayNameForSiufState(SiufState.Approved) }</Label>;
        case SiufState.Published:
            return <Label basic color="blue">{ GetDisplayNameForSiufState(SiufState.Published) }</Label>;
        case SiufState.Terminated:
            return <Label basic color="red">{ GetDisplayNameForSiufState(SiufState.Terminated) }</Label>;
        default:
            return <Label color="red">{ "Unknown State" }</Label>;
    }
};

export default StateLabel;