import { action } from "typesafe-actions";
import { SiufSubmissionActionTypes } from "./types";
import { ISiufValidationResult, IAxiosErrorResponse, ISiufEntity, IUpdateSiufModel, HostPlatform } from "../../../contracts";
import { ThunkResult, getSiufFromServerFromServer } from "../..";
import SiufAuthoringApi from "../../../api/siufAuthoringApi";
import { ParseErrorResponse } from "../../../utils/actionUtils";
import * as FileSaver from 'file-saver';

export const saveSiufSent = (siufId: number, updateSiufModel: IUpdateSiufModel) => action(
    SiufSubmissionActionTypes.SAVE_SIUF_SENT,
    {
        siufId,
        updateSiufModel
    }
);

export const saveSiufSuccess = (siufId: number, response: ISiufEntity) => action(
    SiufSubmissionActionTypes.SAVE_SIUF_SUCCESS,
    {
        siufId,
        response
    }
);

export const saveSiufFail = (siufId: number, error: string) => action(
    SiufSubmissionActionTypes.SAVE_SIUF_FAIL,
    {
        siufId,
        error
    }
);

export const saveSiufFromServer = (siufId: number, updateSiufModel: IUpdateSiufModel): ThunkResult<void, void> => {
    return async (dispatch) => {
        dispatch(saveSiufSent(siufId, updateSiufModel));
        try {
            const response = await SiufAuthoringApi.saveSiuf(siufId, updateSiufModel);
            dispatch(saveSiufSuccess(siufId, response));
        } catch (error) {
            const e = error as IAxiosErrorResponse;
            dispatch(saveSiufFail(
                siufId,
                ParseErrorResponse(e))
            );
        }
    };
};

export const validateSiufSent = (siufId: number) => action(
    SiufSubmissionActionTypes.VALIDATE_SIUF_SENT,
    {
        siufId
    }
);

export const validateSiufSuccess = (siufId: number, response: ISiufValidationResult) => action(
    SiufSubmissionActionTypes.VALIDATE_SIUF_SUCCESS,
    {
        siufId,
        response
    }
);

export const validateSiufFail = (siufId: number, error: string) => action(
    SiufSubmissionActionTypes.VALIDATE_SIUF_FAIL,
    {
        siufId,
        error
    }
);

export const validateSiufFromServer = (siufId: number): ThunkResult<void, void> => {
    return async (dispatch) => {
        dispatch(validateSiufSent(siufId));
        try {
            const response = await SiufAuthoringApi.validateSiuf(siufId);
            dispatch(validateSiufSuccess(siufId, response));
        } catch (error) {
            const e = error as IAxiosErrorResponse;
            dispatch(validateSiufFail(
                siufId,
                ParseErrorResponse(e))
            );
        }
    };
};

export const submitSiufSent = (siufId: number, reason: string) => action(
    SiufSubmissionActionTypes.SUBMIT_SIUF_SENT,
    {
        siufId,
        reason
    }
);

export const submitSiufSuccess = (siufId: number, response: ISiufEntity) => action(
    SiufSubmissionActionTypes.SUBMIT_SIUF_SUCCESS,
    {
        siufId,
        response
    }
);

export const submitSiufFail = (siufId: number, error: string) => action(
    SiufSubmissionActionTypes.SUBMIT_SIUF_FAIL,
    {
        siufId,
        error
    }
);

export const submitSiufFromServer = (siufId: number, reason: string = '', hostPlatform: HostPlatform): ThunkResult<void, void> => {
    return async (dispatch) => {
        dispatch(submitSiufSent(siufId, reason));
        try {
            const response = await SiufAuthoringApi.submitSiuf(siufId, reason, hostPlatform);
            dispatch(submitSiufSuccess(siufId, response));
            // Load SIUF
            dispatch(getSiufFromServerFromServer(siufId));
        } catch (error) {
            const e = error as IAxiosErrorResponse;
            dispatch(submitSiufFail(
                siufId,
                ParseErrorResponse(e))
            );
        }
    };
};

export const withdrawSiufSent = (siufId: number, reason: string) => action(
    SiufSubmissionActionTypes.WITHDRAW_SIUF_SENT,
    {
        siufId,
        reason
    }
);

export const withdrawSiufSuccess = (siufId: number, response: ISiufEntity) => action(
    SiufSubmissionActionTypes.WITHDRAW_SIUF_SUCCESS,
    {
        siufId,
        response
    }
);

export const withdrawSiufFail = (siufId: number, error: string) => action(
    SiufSubmissionActionTypes.WITHDRAW_SIUF_FAIL,
    {
        siufId,
        error
    }
);

export const withdrawSiufFromServer = (siufId: number, reason: string, hostPlatform: HostPlatform): ThunkResult<void, void> => {
    return async (dispatch) => {
        dispatch(withdrawSiufSent(siufId, reason));
        try {
            const response = await SiufAuthoringApi.withdrawSiuf(siufId, reason, hostPlatform);
            dispatch(withdrawSiufSuccess(siufId, response));
            // Load SIUF
            dispatch(getSiufFromServerFromServer(siufId));
        } catch (error) {
            const e = error as IAxiosErrorResponse;
            dispatch(withdrawSiufFail(
                siufId,
                ParseErrorResponse(e))
            );
        }
    };
};

export const terminateSiufSent = (siufId: number, reason: string) => action(
    SiufSubmissionActionTypes.TERMINATE_SIUF_SENT,
    {
        siufId,
        reason
    }
);

export const terminateSiufSuccess = (siufId: number, reason: string, response: ISiufEntity) => action(
    SiufSubmissionActionTypes.TERMINATE_SIUF_SUCCESS,
    {
        siufId,
        reason,
        response
    }
);

export const terminateSiufFail = (siufId: number, reason: string, error: string) => action(
    SiufSubmissionActionTypes.TERMINATE_SIUF_FAIL,
    {
        siufId,
        reason,
        error
    }
);

export const terminateSiufFromServer = (siufId: number, reason: string, hostPlatform: HostPlatform): ThunkResult<void, void> => {
    return async (dispatch) => {
        dispatch(terminateSiufSent(siufId, reason));
        try {
            const response = await SiufAuthoringApi.terminateSiuf(siufId, reason, hostPlatform);
            dispatch(terminateSiufSuccess(siufId, reason, response));
            // Load SIUF
            dispatch(getSiufFromServerFromServer(siufId));
        } catch (error) {
            const e = error as IAxiosErrorResponse;
            dispatch(terminateSiufFail(
                siufId,
                reason,
                ParseErrorResponse(e))
            );
        }
    };
};

export const terminateSiufByAuthorSent = (siufId: number, reason: string) => action(
    SiufSubmissionActionTypes.TERMINATE_SIUF_FROM_AUTHOR_SENT,
    {
        siufId,
        reason
    }
);

export const terminateSiufByAuthorSuccess = (siufId: number, reason: string, response: ISiufEntity) => action(
    SiufSubmissionActionTypes.TERMINATE_SIUF_FROM_AUTHOR_SUCCESS,
    {
        siufId,
        reason,
        response
    }
);

export const terminateSiufByAuthorFail = (siufId: number, reason: string, error: string) => action(
    SiufSubmissionActionTypes.TERMINATE_SIUF_FROM_AUTHOR_FAIL,
    {
        siufId,
        reason,
        error
    }
);

export const terminateSiufByAuthorFromServer = (siufId: number, reason: string, hostPlatform: HostPlatform): ThunkResult<void, void> => {
    return async (dispatch) => {
        dispatch(terminateSiufByAuthorSent(siufId, reason));
        try {
            const response = await SiufAuthoringApi.terminateSiuf(siufId, reason, hostPlatform);
            dispatch(terminateSiufByAuthorSuccess(siufId, reason, response));
            // Load SIUF
            dispatch(getSiufFromServerFromServer(siufId));
        } catch (error) {
            const e = error as IAxiosErrorResponse;
            dispatch(terminateSiufByAuthorFail(
                siufId,
                reason,
                ParseErrorResponse(e))
            );
        }
    };
};

export const reactivateSiufSent = (siufId: number, reason: string) => action(
    SiufSubmissionActionTypes.REACTIVATE_SIUF_SENT,
    {
        siufId,
        reason
    }
);

export const reactivateSiufSuccess = (siufId: number, response: ISiufEntity) => action(
    SiufSubmissionActionTypes.REACTIVATE_SIUF_SUCCESS,
    {
        siufId,
        response
    }
);

export const reactivateSiufFail = (siufId: number, error: string) => action(
    SiufSubmissionActionTypes.REACTIVATE_SIUF_FAIL,
    {
        siufId,
        error
    }
);

export const reactivateSiufFromServer = (siufId: number, reason: string = '', hostPlatform: HostPlatform): ThunkResult<void, void> => {
    return async (dispatch) => {
        dispatch(reactivateSiufSent(siufId, reason));
        try {
            const response = await SiufAuthoringApi.reactivateSiuf(siufId, reason, hostPlatform);
            dispatch(reactivateSiufSuccess(siufId, response));
            // Load SIUF
            dispatch(getSiufFromServerFromServer(siufId));
        } catch (error) {
            const e = error as IAxiosErrorResponse;
            dispatch(reactivateSiufFail(
                siufId,
                ParseErrorResponse(e))
            );
        }
    };
};

export const sideloadSiufSent = (siufId: number) => action(
    SiufSubmissionActionTypes.SIDELOAD_SIUF_SENT,
    {
        siufId
    }
);

export const sideloadSiufSuccess = (siufId: number, response: string) => action(
    SiufSubmissionActionTypes.SIDELOAD_SIUF_SUCCESS,
    {
        siufId,
        response
    }
);

export const sideloadSiufFail = (siufId: number, error: string) => action(
    SiufSubmissionActionTypes.SIDELOAD_SIUF_FAIL,
    {
        siufId,
        error
    }
);

export const sideloadSiufFromServer = (siufId: number, exportExtendedPayload: boolean): ThunkResult<void, void> => {
    return async (dispatch) => {
        dispatch(sideloadSiufSent(siufId));
        try {
            const response = await SiufAuthoringApi.testSideLoadSiuf(siufId, exportExtendedPayload);
            const blob = new Blob([response], { type: "text/plain;charset=utf-8" });
            FileSaver.saveAs(blob, `sideload_SIUF_${siufId}.cmd`);
            dispatch(sideloadSiufSuccess(siufId, response));
        } catch (error) {
            const e = error as IAxiosErrorResponse;
            dispatch(sideloadSiufFail(
                siufId,
                ParseErrorResponse(e))
            );
        }
    };
};

export const downloadSiufXmlSent = (siufId: number) => action(
    SiufSubmissionActionTypes.GET_SIUF_XML_SENT,
    {
        siufId
    }
);

export const downloadSiufXmlSuccess = (siufId: number, response: string) => action(
    SiufSubmissionActionTypes.GET_SIUF_XML_SUCCESS,
    {
        siufId,
        response
    }
);

export const downloadSiufXmlFail = (siufId: number, error: string) => action(
    SiufSubmissionActionTypes.GET_SIUF_XML_FAIL,
    {
        siufId,
        error
    }
);

export const downloadSiufXmlFromServer = (siufId: number, exportExtendedPayload: boolean): ThunkResult<void, void> => {
    return async (dispatch) => {
        dispatch(downloadSiufXmlSent(siufId));
        try {
            const response = await SiufAuthoringApi.downloadSiufXml(siufId, exportExtendedPayload);
            const blob = new Blob([response], { type: "application/xml" });
            FileSaver.saveAs(blob, `scxml_SIUF_${siufId}.xml`);
            dispatch(downloadSiufXmlSuccess(siufId, response));
        } catch (error) {
            const e = error as IAxiosErrorResponse;
            dispatch(downloadSiufXmlFail(
                siufId,
                ParseErrorResponse(e))
            );
        }
    };
};