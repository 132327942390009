import { connect } from 'react-redux';
import { IApplicationRootReducerState } from "../../../configureStore";
import { Dispatch } from 'redux';
import FreeformTextQuestion from '../../../components/authoring/questionDetails/FreeformTextQuestion';
import { IQuestionEntity } from '../../../contracts/models';
import * as siufAuthoringActions from '../../../actions';

type Props = {
    questionModel: IQuestionEntity;
    isReadOnly: boolean;
    pageIndex: number;
};

const mapStateToProps = (state: IApplicationRootReducerState, ownProps: Props) => ({
    isReadOnly: ownProps.isReadOnly,
    questionStructure: ownProps.questionModel.QuestionStructure,
    questionText: ownProps.questionModel.QuestionText
});

const mapDispatchToProps = (dispatch: Dispatch, ownProps: Props) => ({
    onUpdateQuestionText: (questionText: string) => dispatch(siufAuthoringActions.updateQuestionText(ownProps.pageIndex, ownProps.questionModel.QuestionIndex, questionText))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FreeformTextQuestion);
