import { action } from "typesafe-actions";
import { QuestionDetailsActionTypes } from ".";
import { SiufQuestionType, SiufFocus, LikertResponseType, LikertResponseCount } from "../../../contracts/models";

export const updateQuestionType = (pageIndex: number, questionIndex: number, questionType: SiufQuestionType) => action(
    QuestionDetailsActionTypes.UPDATE_QUESTIONTYPE,
    {
        pageIndex,
        questionIndex,
        questionType
    }
);

export const updateLikertResponseType = (pageIndex: number, questionIndex: number, likertResponseType: LikertResponseType, questionFocus: SiufFocus, followUpQuestion: string) => action(
    QuestionDetailsActionTypes.UPDATE_LIKERTRESPONSETYPE,
    {
        pageIndex,
        questionIndex,
        likertResponseType,
        questionFocus,
        followUpQuestion
    }
);

export const updateLikertResponseCount = (pageIndex: number, questionIndex: number, likertResponseCount: LikertResponseCount, questionFocus: SiufFocus, followUpQuestion: string) => action(
    QuestionDetailsActionTypes.UPDATE_LIKERTRESPONSECOUNT,
    {
        pageIndex,
        questionIndex,
        likertResponseCount,
        questionFocus,
        followUpQuestion
    }
);

export const updateTitle = (title: string) => action(
    QuestionDetailsActionTypes.UPDATE_TITLE,
    {
        title
    }
);

export const updateIgnoreTitleOnToast = (IgnoreTitleOnToast: boolean) => action(
    QuestionDetailsActionTypes.UPDATE_IGNORETITLEONTOAST,
    {
        IgnoreTitleOnToast
    }
);

export const addQuestion = (pageIndex: number) => action(
    QuestionDetailsActionTypes.ADD_QUESTION,
    {
        pageIndex
    }
);

export const removeQuestion = (pageIndex: number, questionIndex: number) => action(
    QuestionDetailsActionTypes.REMOVE_QUESTION,
    {
        pageIndex,
        questionIndex
    }
);

export const addPage = () => action(
    QuestionDetailsActionTypes.ADD_PAGE,
    {
    }
);

export const removePage = (pageIndex: number) => action(
    QuestionDetailsActionTypes.REMOVE_PAGE,
    {
        pageIndex
    }
);

export const changeEditPage = (activeEditPage: string | number) => action(
    QuestionDetailsActionTypes.CHANGE_EDIT_PAGE,
    {
        activeEditPage
    }
);

export const changePreviewPage = (activePreviewPage: string | number) => action(
    QuestionDetailsActionTypes.CHANGE_PREVIEW_PAGE,
    {
        activePreviewPage
    }
);

export const updateNpsQuestion = (pageIndex: number, questionIndex: number, checked: boolean) => action(
    QuestionDetailsActionTypes.UPDATE_NPSQUESTION,
    {
        pageIndex,
        questionIndex,
        checked
    }
);

export const updateQuestionText = (pageIndex: number, questionIndex: number, questionText: string) => action(
    QuestionDetailsActionTypes.UPDATE_QUESTIONTEXT,
    {
        pageIndex,
        questionIndex,
        questionText
    }
);

export const updateSiufFocus = (pageIndex: number, questionIndex: number, siufFocus: SiufFocus) => action(
    QuestionDetailsActionTypes.UPDATE_SIUFFOCUS,
    {
        pageIndex,
        questionIndex,
        siufFocus
    }
);

export const updateAnchorText = (pageIndex: number, questionIndex: number, anchorText: string, anchorIndex: number) => action(
    QuestionDetailsActionTypes.UPDATE_ANCHORTEXT,
    {
        pageIndex,
        questionIndex,
        anchorText,
        anchorIndex
    }
);

export const updateFollowUpQuestionText = (pageIndex: number, questionIndex: number, followUpQuestionText: string, anchorIndex: number) => action(
    QuestionDetailsActionTypes.UPDATE_FOLLOWUPQUESTIONTEXT,
    {
        pageIndex,
        questionIndex,
        followUpQuestionText,
        anchorIndex
    }
);

export const updateCheckBoxText = (pageIndex: number, questionIndex: number, checkBoxText: string, checkBoxIndex: number) => action(
    QuestionDetailsActionTypes.UPDATE_CHECKBOXTEXT,
    {
        pageIndex,
        questionIndex,
        checkBoxText,
        checkBoxIndex
    }
);

export const addCheckBoxText = (pageIndex: number, questionIndex: number, ) => action(
    QuestionDetailsActionTypes.ADD_CHECKBOXTEXT,
    {
        pageIndex,
        questionIndex
    }
);

export const removeCheckBoxText = (pageIndex: number, questionIndex: number, checkBoxIndex: number) => action(
    QuestionDetailsActionTypes.REMOVE_CHECKBOXTEXT,
    {
        pageIndex,
        questionIndex,
        checkBoxIndex
    }
);

export const updateLikertOptionText = (pageIndex: number, questionIndex: number, likertOptionText: string, likertOptionIndex: number) => action(
    QuestionDetailsActionTypes.UPDATE_LIKERTOPTIONTEXT,
    {
        pageIndex,
        questionIndex,
        likertOptionText,
        likertOptionIndex
    }
);

export const addLikertOption = (pageIndex: number, questionIndex: number, likertOptionText: string) => action(
    QuestionDetailsActionTypes.ADD_LIKERTOPTION,
    {
        pageIndex,
        questionIndex,
        likertOptionText
    }
);

export const removeLikertOption = (pageIndex: number, questionIndex: number, likertOptionIndex: number) => action(
    QuestionDetailsActionTypes.REMOVE_LIKERTOPTION,
    {
        pageIndex,
        questionIndex,
        likertOptionIndex
    }
);

export const updateLikertScaleItemText = (pageIndex: number, questionIndex: number, likertScaleItemText: string, likertScaleItemIndex: number) => action(
    QuestionDetailsActionTypes.UPDATE_LIKERTSCALEITEMTEXT,
    {
        pageIndex,
        questionIndex,
        likertScaleItemText,
        likertScaleItemIndex
    }
);

export const addLikertScaleItem = (pageIndex: number, questionIndex: number, ) => action(
    QuestionDetailsActionTypes.ADD_LIKERTSCALEITEM,
    {
        pageIndex,
        questionIndex
    }
);

export const removeLikertScaleItem = (pageIndex: number, questionIndex: number, likertScaleItemIndex: number) => action(
    QuestionDetailsActionTypes.REMOVE_LIKERTSCALEITEM,
    {
        pageIndex,
        questionIndex,
        likertScaleItemIndex
    }
);