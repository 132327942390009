import * as React from 'react';
import { Accordion, Button, Divider, Form, Header, Icon, Label, Message, Segment } from 'semantic-ui-react';
import AdvancedPropertiesView from '../../containers/authoring/advancedSettings/AdvancedPropertiesView';
import SiufBasicsView from '../../containers/authoring/basics/SiufBasicsView';
import QuestionCollectionView from '../../containers/authoring/questionDetails/QuestionCollectionView';
import PreviewView from '../../containers/authoring/questionPreview/PreviewView';
import TargetingPropertiesView from '../../containers/authoring/targeting/TargetingPropertiesView';
import TriggerView from '../../containers/authoring/trigger/TriggerView';
import ValidationView from '../../containers/authoring/validation/ValidationView';
import { ErrorType, RequestState } from '../../contracts';
import { HostPlatform, ISiufEntity, ISubmitState, IUpdateSiufModel, SiufAuthoringSectionId, SiufEntityToUpdateSiufModelMapper, SiufState, SiufTriggeringType } from '../../contracts/models';
import { ISiufAuthoringUiState } from '../../contracts/states';
import { GetDisplayNameForSiufSubmitOperation, GetSiufStateHeaderDisplayText, GetSiufStateSubHeaderDisplayText, ShowSuccessMessage } from '../../utils';
import LoadingMessage from '../ui/LoadingMessage';
import StateLabel from '../ui/StateLabel';

type Props = {
    siufId: number;
    siufModel: ISiufEntity;
    uiState: ISiufAuthoringUiState;
    submitState: ISubmitState<ISiufEntity>;
    isDirty: boolean;
    hostPlatform: HostPlatform;
    cosmicUrl: string;
    cosmicUrlLabel: string;
    cosmicMigrationPrefix: string;
    cosmicMigrationSuffix: string;
    forceCosmicMigration: boolean;
    onSaveSiuf: (siufId: number, updateSiufModel: IUpdateSiufModel) => void;
    onSubmitSiuf: (siufId: number, hostPlatform: HostPlatform) => void;
    onWithdrawSiuf: (siufId: number, reason: string, hostPlatform: HostPlatform) => void;
    onTerminateSiuf: (siufId: number, reason: string, hostPlatform: HostPlatform) => void;
    onReactivateSiuf: (siufId: number, hostPlatform: HostPlatform) => void;
    onDismissSubmitState: () => void;
    onUpdateSectionVisibility: (sectionId: SiufAuthoringSectionId, isVisible: boolean) => void;
    fetchSiuf: () => void;
};

type State = {
    submissionReason: string;
};

export default class ExistingSiuf extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.props.onUpdateSectionVisibility.bind(this);
        this.props.fetchSiuf.bind(this);

        this.state = {
            submissionReason: ''
        };
    }

    public componentDidMount() {
        this.props.fetchSiuf();

        window.onbeforeunload = (e: BeforeUnloadEvent) => {
            if (!this.props.isDirty) {
                return;
            }
            const text = 'Pending changes';
            e.returnValue = text;
            return text;
        };
    }

    get showButton() {
        return (this.props.hostPlatform == HostPlatform.Azure && !this.props.forceCosmicMigration) || this.props.hostPlatform == HostPlatform.Cosmic;
    }

    private getPanels() {
        let panels: Array<{ key: string, title: JSX.Element, content: JSX.Element }> = [];
        panels.push(
            {
                key: 'SiufBasics',
                title: <Accordion.Title active={this.props.uiState.siufBasics.isVisible}>
                    <Header as='h3' color='black' block onClick={() => this.props.onUpdateSectionVisibility(SiufAuthoringSectionId.type, !this.props.uiState.siufBasics.isVisible)}
                        tabIndex='4'
                        onKeyPress={e => {
                            let eventCode = e.charCode || e.which;
                            if (eventCode === 13 || eventCode === 32) {
                                this.props.onUpdateSectionVisibility(SiufAuthoringSectionId.type, !this.props.uiState.siufBasics.isVisible);
                            }
                        }}>
                        <Icon name="dropdown" />
                        Fill in basic properties
                        {
                            this.props.uiState.questionDetails.isReadOnly
                            &&
                            <Label basic color='orange'>Read Only</Label>
                        }
                    </Header>
                </Accordion.Title>,
                content: <Accordion.Content active={this.props.uiState.siufBasics.isVisible} >
                    <Segment loading={this.props.uiState.siufBasics.isLoading}>
                        <SiufBasicsView />
                    </Segment>
                </Accordion.Content>,
            },
            {
                key: 'Question',
                title: <Accordion.Title active={this.props.uiState.questionDetails.isVisible}>
                    <Header as='h3' color='black' block onClick={() => this.props.onUpdateSectionVisibility(SiufAuthoringSectionId.question, !this.props.uiState.questionDetails.isVisible)}
                        tabIndex='5'
                        onKeyPress={e => {
                            let eventCode = e.charCode || e.which;
                            if (eventCode === 13 || eventCode === 32) {
                                this.props.onUpdateSectionVisibility(SiufAuthoringSectionId.question, !this.props.uiState.questionDetails.isVisible);
                            }
                        }}>
                        <Icon name="dropdown" />
                        Fill in the question details
                        {
                            this.props.uiState.questionDetails.isReadOnly
                            &&
                            <Label basic color='orange'>Read Only</Label>
                        }
                    </Header>
                </Accordion.Title>,
                content: <Accordion.Content active={this.props.uiState.questionDetails.isVisible} >
                    <Segment loading={this.props.uiState.questionDetails.isLoading}>
                        <QuestionCollectionView />
                    </Segment>
                </Accordion.Content>,
            },
            {
                key: 'Preview',
                title: <Accordion.Title active={this.props.uiState.preview.isVisible}>
                    <Header as='h3' color='black' block onClick={() => this.props.onUpdateSectionVisibility(SiufAuthoringSectionId.preview, !this.props.uiState.preview.isVisible)}
                        tabIndex='6'
                        onKeyPress={e => {
                            let eventCode = e.charCode || e.which;
                            if (eventCode === 13 || eventCode === 32) {
                                this.props.onUpdateSectionVisibility(SiufAuthoringSectionId.preview, !this.props.uiState.preview.isVisible);
                            }
                        }}>
                        <Icon name="dropdown" />
                        Preview the SIUF content
                        {
                            this.props.uiState.questionDetails.isReadOnly
                            &&
                            <Label basic color='orange'>Read Only</Label>
                        }
                    </Header>
                </Accordion.Title>,
                content: <Accordion.Content active={this.props.uiState.preview.isVisible} >
                    <Segment loading={this.props.uiState.preview.isLoading}>
                        <PreviewView />
                    </Segment>
                </Accordion.Content>,
            },
            {
                key: 'Trigger',
                title: <Accordion.Title active={this.props.uiState.trigger.isVisible}>
                    <Header as='h3' color='black' block onClick={() => this.props.onUpdateSectionVisibility(SiufAuthoringSectionId.trigger, !this.props.uiState.trigger.isVisible)}
                        tabIndex='7'
                        onKeyPress={e => {
                            let eventCode = e.charCode || e.which;
                            if (eventCode === 13 || eventCode === 32) {
                                this.props.onUpdateSectionVisibility(SiufAuthoringSectionId.trigger, !this.props.uiState.trigger.isVisible);
                            }
                        }}>
                        <Icon name="dropdown" />
                        Setup how this SIUF triggers
                        {
                            this.props.uiState.questionDetails.isReadOnly
                            &&
                            <Label basic color='orange'>Read Only</Label>
                        }
                    </Header>
                </Accordion.Title>,
                content: <Accordion.Content active={this.props.uiState.trigger.isVisible} >
                    <Segment loading={this.props.uiState.trigger.isLoading}>
                        <TriggerView />
                    </Segment>
                </Accordion.Content>,
            });

        if (this.props.siufModel.Payload.TriggeringType === SiufTriggeringType.StateMachine) {
            panels.push(
                {
                    key: 'Targeting',
                    title: <Accordion.Title active={this.props.uiState.targeting.isVisible}>
                        <Header as='h3' color='black' block onClick={() => this.props.onUpdateSectionVisibility(SiufAuthoringSectionId.targeting, !this.props.uiState.targeting.isVisible)}
                            tabIndex='8'
                            onKeyPress={e => {
                                let eventCode = e.charCode || e.which;
                                if (eventCode === 13 || eventCode === 32) {
                                    this.props.onUpdateSectionVisibility(SiufAuthoringSectionId.targeting, !this.props.uiState.targeting.isVisible)
                                }
                            }}>
                            <Icon name="dropdown" />
                            Setup how this SIUF targets devices
                            {
                                this.props.uiState.questionDetails.isReadOnly
                                &&
                                <Label basic color='orange'>Read Only</Label>
                            }
                        </Header>
                    </Accordion.Title>,
                    content: <Accordion.Content active={this.props.uiState.targeting.isVisible} >
                        <Segment loading={this.props.uiState.targeting.isLoading}>
                            <TargetingPropertiesView />
                        </Segment>
                    </Accordion.Content>,
                },
                {
                    key: 'Settings',
                    title: <Accordion.Title active={this.props.uiState.settings.isVisible}>
                        <Header as='h3' color='black' block onClick={() => this.props.onUpdateSectionVisibility(SiufAuthoringSectionId.settings, !this.props.uiState.settings.isVisible)}
                            tabIndex='9'
                            onKeyPress={e => {
                                let eventCode = e.charCode || e.which;
                                if (eventCode === 13 || eventCode === 32) {
                                    this.props.onUpdateSectionVisibility(SiufAuthoringSectionId.settings, !this.props.uiState.settings.isVisible);
                                }
                            }}>
                            <Icon name="dropdown" />
                            Configure additional settings
                            {
                                this.props.uiState.questionDetails.isReadOnly
                                &&
                                <Label basic color='orange'>Read Only</Label>
                            }
                        </Header>
                    </Accordion.Title>,
                    content: <Accordion.Content active={this.props.uiState.settings.isVisible} >
                        <Segment loading={this.props.uiState.settings.isLoading}>
                            <AdvancedPropertiesView />
                        </Segment>
                    </Accordion.Content>,
                });
        }

        panels.push(
            {
                key: 'Validation',
                title: <Accordion.Title active={this.props.uiState.validation.isVisible}>
                    <Header as='h3' color='black' block onClick={() => this.props.onUpdateSectionVisibility(SiufAuthoringSectionId.submission, !this.props.uiState.validation.isVisible)}
                        tabIndex='10'
                        onKeyPress={e => {
                            let eventCode = e.charCode || e.which;
                            if (eventCode === 13 || eventCode === 32) {
                                this.props.onUpdateSectionVisibility(SiufAuthoringSectionId.submission, !this.props.uiState.validation.isVisible);
                            }
                        }}>
                        <Icon name="dropdown" />
                        Validate the SIUF
                    </Header>
                </Accordion.Title>,
                content: <Accordion.Content active={this.props.uiState.validation.isVisible} >
                    <Segment loading={this.props.uiState.validation.isLoading}>
                        <ValidationView />
                    </Segment>
                </Accordion.Content>,
            }
        );
        return panels;
    }

    private renderControls() {
        const submit = (
            <React.Fragment>
                <Header as='h4'>Submit this SIUF when you are ready and it does not contain validation errors</Header>
                {
                    this.props.isDirty
                    &&
                    <Message info content='This SIUF contains pending changes, click the save button to save these changes' />
                }
                {
                    this.showButton &&
                    <Button primary loading={this.props.submitState.requestState === RequestState.inProgress}
                        disabled={!this.props.isDirty || (this.props.submitState.requestState === RequestState.inProgress)}
                        onClick={e => this.props.onSaveSiuf(this.props.siufModel.SiufId, SiufEntityToUpdateSiufModelMapper(this.props.siufModel, this.props.hostPlatform))}>
                        Save this draft
                    </Button>
                }
                {
                    this.showButton &&
                    <Button primary loading={this.props.submitState.requestState === RequestState.inProgress}
                        disabled={(this.props.submitState.requestState === RequestState.inProgress) || this.props.isDirty}
                        onClick={e => this.props.onSubmitSiuf(this.props.siufModel.SiufId, this.props.hostPlatform)}>
                        Submit for review
                    </Button>
                }
            </React.Fragment>
        );

        const withdraw = (
            <React.Fragment>
                <Header as='h4'>If you wish to continue edit this SIUF, you may withdraw it from the review</Header>
                <Form.TextArea required
                    placeholder='Enter a reason for withdraw'
                    onChange={e => this.setState({ submissionReason: e.currentTarget.value })} />
                <Divider hidden />
                {
                    this.showButton &&
                    <Button negative loading={this.props.submitState.requestState === RequestState.inProgress}
                        disabled={!this.state.submissionReason || (this.props.submitState.requestState === RequestState.inProgress)}
                        onClick={e => {
                            this.props.onWithdrawSiuf(this.props.siufModel.SiufId, this.state.submissionReason, this.props.hostPlatform);
                            this.setState({ submissionReason: "" });
                        }}>
                        Withdraw from review
                    </Button>
                }
            </React.Fragment>
        );

        const approved = (
            <React.Fragment>
                <Header as='h4'>This SIUF has been approved, it should be automatically published shortly</Header>
            </React.Fragment>
        );

        const terminate = (
            <React.Fragment>
                <Header as='h4'>If you no longer need this SIUF, you may terminate this SIUF</Header>
                <Form.TextArea required
                    placeholder='Enter a reason for termination'
                    onChange={e => this.setState({ submissionReason: e.currentTarget.value })} />
                <Divider hidden />
                {
                    this.showButton &&
                    <Button negative loading={this.props.submitState.requestState === RequestState.inProgress}
                        disabled={(this.props.submitState.requestState === RequestState.inProgress)}
                        onClick={e => this.props.onTerminateSiuf(this.props.siufModel.SiufId, this.state.submissionReason, this.props.hostPlatform)}>
                        Terminate this SIUF
                    </Button>
                }
            </React.Fragment>
        );

        const reactivate = (
            <React.Fragment>
                <Header as='h4'>If you wish to create the next version of this SIUF, you may reactivate this SIUF</Header>
                {
                    this.showButton &&
                    <Button positive loading={this.props.submitState.requestState === RequestState.inProgress}
                        disabled={this.props.submitState.requestState === RequestState.inProgress}
                        onClick={e => this.props.onReactivateSiuf(this.props.siufModel.SiufId, this.props.hostPlatform)}>
                        Reactivate this SIUF
                    </Button>
                }
            </React.Fragment>
        );

        let controlUi: JSX.Element;

        switch (this.props.siufModel.State) {
            case SiufState.Draft:
                controlUi = submit;
                break;
            case SiufState.Submitted:
                controlUi = withdraw;
                break;
            case SiufState.Approved:
                controlUi = approved;
                break;
            case SiufState.Published:
                controlUi = terminate;
                break;
            case SiufState.Terminated:
                controlUi = reactivate;
                break;
            default:
                controlUi = <label>Siuf State not supported</label>;
        }

        return (
            <Form
                loading={this.props.submitState.requestState === RequestState.inProgress}
                error={(this.props.submitState.requestState === RequestState.complete) && (this.props.submitState.errorType === ErrorType.error)}
                success={(this.props.submitState.requestState === RequestState.complete) && (this.props.submitState.errorType === ErrorType.none)}>
                {
                    (this.props.submitState.requestState === RequestState.complete && this.props.submitState.response && ShowSuccessMessage(this.props.submitState.operation))
                    &&
                    <Message icon success onDismiss={() => this.props.onDismissSubmitState()}>
                        <Icon name='checkmark' color='green' />
                        <Message.Header>
                            {`${GetDisplayNameForSiufSubmitOperation(this.props.submitState.operation)} ${this.props.submitState.response.SiufId} was successful`}
                        </Message.Header>
                    </Message>

                }
                {
                    (this.props.submitState.requestState === RequestState.complete)
                    &&
                    <Message error onDismiss={() => this.props.onDismissSubmitState()}>
                        <Message.Header>
                            {`An error occured while ${GetDisplayNameForSiufSubmitOperation(this.props.submitState.operation)}`}
                        </Message.Header>
                        <Message.Content>
                            {this.props.submitState.errorMessage || "<No error message shown>"}
                        </Message.Content>
                    </Message>
                }
                {controlUi}
            </Form>
        );
    }

    private renderStateAlert() {
        return (
            <Message info icon>
                <Icon name='info' />
                <Message.Content>
                    <Message.Header>
                        {GetSiufStateHeaderDisplayText(this.props.siufModel.State)}
                    </Message.Header>
                    {GetSiufStateSubHeaderDisplayText(this.props.siufModel.State)}
                </Message.Content>
            </Message>
        );
    }

    private renderSiufHeader() {
        return (
            <Header as="h1">
                <Icon name="file alternate" />
                <Header.Content>
                    {this.props.siufModel.SiufName || "<SIUF Name>"}
                    <Label basic color="blue">SIUF Id: {this.props.siufId}</Label>
                    <Label basic color="purple">{`V ${this.props.siufModel.Version}.${this.props.siufModel.Iteration}`}</Label>
                    <StateLabel state={this.props.siufModel.State} />
                    <Label basic color="teal">Owner: {this.props.siufModel.Owner}</Label>
                    <Label basic color="brown">Scenario Id:{this.props.siufModel.ScenarioId || '<Unknown>'}</Label>
                    {
                        this.props.isDirty
                        &&
                        <Label color="purple" >changes unsaved</Label>
                    }
                </Header.Content>
            </Header>
        );
    }

    private renderSiuf() {
        return (
            <Accordion fluid defaultActiveIndex={0} panels={this.getPanels()} />
        );
    }

    private renderCosmicMigrationAlert() {
        return (
            <Message error icon>
                <Message.Content>
                    <Message.Content>
                        {this.props.cosmicMigrationPrefix} <a className='unsetBackground' href={this.props.cosmicUrl}>{this.props.cosmicUrlLabel}</a>{this.props.cosmicMigrationSuffix}
                    </Message.Content>
                </Message.Content>
            </Message>
        );
    }

    public render() {
        return (
            <React.Fragment>
                <LoadingMessage isVisible={this.props.uiState.overall.isLoading} header={`Loading SIUF ${this.props.siufId}`} />
                {
                    (this.props.submitState.requestState === RequestState.complete && this.props.submitState.errorType === ErrorType.error)
                    &&
                    <Form error>
                        <Message error onDismiss={() => this.props.onDismissSubmitState()}
                            header='An error was encountered'
                            content={this.props.submitState.errorMessage} />
                    </Form>
                }
                {
                    <Segment basic loading={this.props.submitState.requestState === RequestState.inProgress} >
                        {this.props.hostPlatform == HostPlatform.Azure && this.renderCosmicMigrationAlert()}
                        {this.renderSiufHeader()}
                        <Divider />
                        {
                            (this.props.siufModel.State !== SiufState.Draft)
                            &&
                            this.renderStateAlert()
                        }
                        {this.renderSiuf()}
                        <Divider />
                        {this.renderControls()}
                    </Segment>
                }
            </React.Fragment>
        );
    }
}