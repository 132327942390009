import * as React from 'react';
import { Form, FormField, Label } from 'semantic-ui-react';
import { ISiufAdvancedPropertySection, SiufAdvancedProperty } from '../../../contracts/models';
import { ISectionUiState } from '../../../contracts/states';

type Props = {
    uiState: ISectionUiState;
    previousQuestionId: string;
    advancedProperties: ISiufAdvancedPropertySection;
    isInsightfulSurvey: boolean;
    onUpdateAdancedProperty: (property: SiufAdvancedProperty, propertyValue: boolean) => void;
    onUpdatePreviousQuestionId: (questionId: string) => void;
};

const AdvancedProperties: React.FC<Props> = props => {

    return (
        <Form>
            <Form.Checkbox
                readOnly={ props.uiState.isReadOnly }
                label='Show this SIUF even if another SIUF has recently been asked to the user (Frequency rule)'
                checked={ props.advancedProperties.frequencyRuleEnabled }
                title='Show this SIUF even if another SIUF has recently been asked to the user (Frequency rule)'
                tabIndex='9'
                onChange={ (event, data) => props.onUpdateAdancedProperty(SiufAdvancedProperty.frequencyRule, data.checked) }
            />
            <Form.Checkbox
                readOnly={ props.uiState.isReadOnly }
                label='Show this SIUF to the user even if has already been asked (History rule)'
                checked={ props.advancedProperties.reaskRuleEnabled }
                title='Show this SIUF to the user even if has already been asked (History rule)'
                tabIndex='9'
                onChange={ (event, data) => props.onUpdateAdancedProperty(SiufAdvancedProperty.reaskRule, data.checked) }
            />
            <Form.Checkbox
                readOnly={ props.uiState.isReadOnly }
                label='Re-ask this SIUF again after a build revision (including minor build number changes)'
                checked={ props.advancedProperties.reaskAfterUpdateEnabled }
                title='Re-ask this SIUF again after a build revision (including minor build number changes)'
                tabIndex='9'
                onChange={ (event, data) => props.onUpdateAdancedProperty(SiufAdvancedProperty.reaskAfterUpdate, data.checked) }
            />
            <Form.Checkbox
                readOnly={ props.uiState.isReadOnly }
                label='Show this SIUF even during the first 15 min of first logon (Ignore Post Logon Quiet Period)'
                checked={ props.advancedProperties.postLogonQuietPeriodEnabled }
                title='Show this SIUF even during the first 15 min of first logon (Ignore Post Logon Quiet Period)'
                tabIndex='9'
                onChange={ (event, data) => props.onUpdateAdancedProperty(SiufAdvancedProperty.postLogonQuietPeriod, data.checked) }
            />
            <Form.Checkbox
                readOnly={ props.uiState.isReadOnly }
                label='Do not show this SIUF to users who have already seen toast from the previous version of this SIUF'
                checked={ props.advancedProperties.DoNotAskSiufAgainIfSeenPreviousVersionEnabled }
                title='Do not show this SIUF to users who have already seen toast from the previous version of this SIUF'
                tabIndex='9'
                onChange={ (event, data) => props.onUpdateAdancedProperty(SiufAdvancedProperty.doNotAskSiufAgainIfSeenPreviousVersion, data.checked) }
            />
            {
                props.advancedProperties.DoNotAskSiufAgainIfSeenPreviousVersionEnabled
                &&
                <Form.Input fluid label='Enter the Previous Question Id (Guid)' required value={ props.previousQuestionId }
                    readOnly={ props.uiState.isReadOnly }
                    placeholder='e.g: abcdefgh-1234-1234-1234-1234abcd1234'
                    title='Enter the Previous Question Id (Guid)'
                    tabIndex='9'
                    onChange={ e => { props.onUpdatePreviousQuestionId(e.currentTarget.value); } } />
            }
            <Form.Checkbox
                readOnly={ props.uiState.isReadOnly }
                label='Do not deactivate this scenario even after receiving 4000 responses'
                checked={ props.advancedProperties.noAutoDeactivateEnabled }
                title='Do not deactivate this scenario even after receiving 4000 responses'
                tabIndex='9'
                onChange={ (event, data) => props.onUpdateAdancedProperty(SiufAdvancedProperty.noAutoDeactivate, data.checked) }
            />
            <Form.Checkbox
                readOnly={ props.uiState.isReadOnly }
                label='Show UI to collect email address from Retail customers (Does not apply to OSG or Insider rings)'
                checked={ props.advancedProperties.askEmailEnabled }
                title='Show UI to collect email address from Retail customers (Does not apply to OSG or Insider rings)'
                tabIndex='9'
                onChange={ (event, data) => props.onUpdateAdancedProperty(SiufAdvancedProperty.askEmail, data.checked) }
            />
            <Form.Checkbox
                readOnly={ props.uiState.isReadOnly }
                label='Use machine learning model'
                checked={ props.advancedProperties.useMLModel }
                title='Use machine learning model'
                tabIndex='9'
                onChange={ (event, data) => props.onUpdateAdancedProperty(SiufAdvancedProperty.useMLModel, data.checked) }
            />
            <FormField inline className='inline-label'>
                <Form.Checkbox
                    readOnly={ props.uiState.isReadOnly }
                    label= 'Use Feedback Hub to toast (for interactive SIUFs)'
                    checked={ props.advancedProperties.useFeedbackHubToToast }
                    title='Use Feedback Hub to toast (for interactive SIUFs)'
                    tabIndex='9'
                    onChange={ (event, data) => props.onUpdateAdancedProperty(SiufAdvancedProperty.useFeedbackHubToToast, data.checked) }/>
                { props.isInsightfulSurvey && !props.advancedProperties.useFeedbackHubToToast &&
                    <Label basic color='blue' pointing='left'>To be added based on the current SIUF set up </Label> }
            </FormField>
        </Form>
    );
};

export default AdvancedProperties;