import { action } from "typesafe-actions";
import { ReviewSearchActionTypes } from ".";
import { ISiufReviewSearchProperty, SiufSortProperty } from "../../../contracts/models";

export const updateSearchProperty = (searchProperties: ISiufReviewSearchProperty) => action(
    ReviewSearchActionTypes.UPDATE_SEARCH_PROPERTIES,
    {
        searchProperties
    }
);

export const updateSiufSortProperty = (property: SiufSortProperty) => action(
    ReviewSearchActionTypes.UPDATE_SORT_PROPERTY,
    {
        property
    }
);