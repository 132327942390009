/* tslint:disable:no-duplicate-imports class-name semicolon interface-name switch-default switch-final-break radix*/

import * as React from 'react';
import { Button, Form, FormGroup } from 'semantic-ui-react';
import * as SRD from 'storm-react-diagrams';
import { ISectionUiState, State, Transition } from '../../../../contracts';
import * as CustomNode from './CustomNode';
import { distributeElements } from '../../../../utils/dagre-utils';

require("./module.scss");
require("storm-react-diagrams/dist/style.min.css");
// require("@projectstorm/react-diagrams/dist/style.min.css");

interface DiagramContainerProps {
    transitions: Transition[];
    states: State[];
    stateMachineName: string;
    maxDurationMs: number;
    uiState: ISectionUiState;
    updateName (name: string): void;
    updateMaxDurationMs (durationInMs: number): void;
};

interface DiagramContainerState {
    engine: SRD.DiagramEngine
};

export default class DiagramContainer extends React.Component<DiagramContainerProps, DiagramContainerState> {

    constructor (props: DiagramContainerProps) {
        super(props);

        let engine = new SRD.DiagramEngine();
        engine.installDefaultFactories();
        engine.registerNodeFactory(new CustomNode.CustomNodeFactory());

        this.state = {
            engine: engine
        };
    }

    public componentDidMount () {
        this.autoDistribute(true);
    }

    public render () {
        let { engine } = this.state;
        engine.setDiagramModel(this.getModelFromStateMachine());
        this.autoDistribute(false);
        return <React.Fragment>
            <FormGroup widths="equal">
                <Form.Field
                    label='State Machine name'
                    control='input'
                    value={ this.props.stateMachineName }
                    disabled={ this.props.uiState.isReadOnly }
                    title='State Machine name'
                    tabIndex='7'
                    width={ 16 }
                    onChange={ this.handleNameChange }>
                </Form.Field>
                <Form.Field value={ this.props.maxDurationMs }
                    control='input' type='number' min={ 0 }
                    label='Maximum duration (ms)'
                    disabled={ this.props.uiState.isReadOnly }
                    title='Maximum duration (ms)'
                    tabIndex='7'
                    placeholder='Enter value in ms' required
                    onChange={ (e: any) => this.props.updateMaxDurationMs(Number(e.currentTarget.value)) }
                />
                <Button onClick={ this.handleDistributeClick } primary content='Beautify' title='Beautify' tabIndex='7' />
            </FormGroup>
            <SRD.DiagramWidget diagramEngine={ engine } />
        </React.Fragment>;
    }

    private handleDistributeClick = () => this.autoDistribute(true);

    private getModelFromStateMachine = () => {
        let model = new SRD.DiagramModel();

        let stateNodes: CustomNode.CustomNodeModel[] = []
        let statePortsMap = new Map<string, { inPort: SRD.DefaultPortModel, outPort: SRD.DefaultPortModel }>();
        this.props.states.forEach(state => {
            let stateNode = new CustomNode.CustomNodeModel(state.StateName, '#D4D4B4', CustomNode.Shape.Circle);
            let inPort = stateNode.addInPort('');
            let outPort = stateNode.addOutPort('');
            stateNodes.push(stateNode);
            statePortsMap.set(state.StateId, { inPort, outPort });
        });

        let transitionNodes: CustomNode.CustomNodeModel[] = [];
        let links: SRD.LinkModel[] = [];
        this.props.transitions.forEach(transition => {
            let transitionNode = new CustomNode.CustomNodeModel(transition.TransitionName, 'rgb(79,114,204)', CustomNode.Shape.Pill);
            transitionNodes.push(transitionNode);

            let fromStatePort = statePortsMap.get(transition.FromStateId).outPort;
            let toTransitionPort = transitionNode.addInPort('');
            links.push(fromStatePort.link(toTransitionPort));

            let fromTransitionPort = transitionNode.addOutPort('');
            let toStatePort = statePortsMap.get(transition.ToStateId).inPort;
            links.push(fromTransitionPort.link(toStatePort));
        });
        model.addAll(...stateNodes, ...transitionNodes, ...links);
        return model;
    }

    private autoDistribute = (forceUpdate: boolean) => {
        const { engine } = this.state;
        const model = engine.getDiagramModel();
        let distributedModel = this.getDistributedModel(engine, model);
        engine.setDiagramModel(distributedModel);
        if (forceUpdate) {
            this.forceUpdate();
        }
    }
    private getDistributedModel = (engine: SRD.DiagramEngine, model: SRD.DiagramModel) => {
        const serialized = model.serializeDiagram();
        const distributedSerializedDiagram = distributeElements(serialized);
        let deSerializedModel = new SRD.DiagramModel();
        deSerializedModel.deSerializeDiagram(distributedSerializedDiagram, engine);
        return deSerializedModel;
    }

    private handleNameChange = (e: any) => {
        this.props.updateName(e.target.value)
    }
}