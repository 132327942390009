/* tslint:disable:no-duplicate-imports class-name semicolon interface-name switch-default switch-final-break radix*/

import * as React from 'react';
import * as SRD from "storm-react-diagrams";
import { uuidv4_id } from '../../../../utils';

// Factory
export class CustomNodeFactory extends SRD.AbstractNodeFactory<CustomNodeModel> {
    constructor () {
        super("custom");
    }

    public generateReactWidget (diagramEngine: SRD.DiagramEngine, node: CustomNodeModel): JSX.Element {
        return <CustomNodeWidget node={ node } />;
    }

    public getNewInstance (initialConfig?: any): CustomNodeModel {
        return new CustomNodeModel();
    }
}

// Model
export enum Shape {
    Circle = 'circle',
    Pill = 'pill'
}

export class CustomNodeModel extends SRD.NodeModel {
    public name: string;
    public color: string;
    public ports: { [s: string]: SRD.DefaultPortModel };
    public shape: Shape;

    constructor (name: string = "Untitled", color: string = "rgb(0,192,255)", shape: Shape = Shape.Circle) {
        super("custom");
        this.name = name;
        this.color = color;
        this.shape = shape;
        this.ports = {};
    }

    public deSerialize (object: any, engine: SRD.DiagramEngine) {
        super.deSerialize(object, engine);
        this.name = object.name;
        this.color = object.color;
        this.shape = object.shape;
    }

    public serialize () {
        return {
            ...super.serialize(),
            name: this.name,
            color: this.color,
            shape: this.shape,
        };
    }

    public addInPort (label: string): SRD.DefaultPortModel {
        const port = new SRD.DefaultPortModel(true, uuidv4_id(), label);
        return this.addPort(port);
    }

    public addOutPort (label: string): SRD.DefaultPortModel {
        return this.addPort(new SRD.DefaultPortModel(false, uuidv4_id(), label));
    }

    public getInPorts (): SRD.DefaultPortModel[] {
        return Object.keys(this.ports).map(key => this.ports[key]).filter(port => port.in);
    }

    public getOutPorts (): SRD.DefaultPortModel[] {
        return Object.keys(this.ports).map(key => this.ports[key]).filter(port => !port.in);
    }
};

// Widget
export interface CustomNodeWidgetProps {
    node: CustomNodeModel;
}

export default class CustomNodeWidget extends React.Component<CustomNodeWidgetProps, {}> {

    public generatePort = (port: SRD.DefaultPortModel) => {
        return <div className='port' key={ port.id } data-name={ port.getName() } data-nodeid={ port.getNode().getID() } />;
    }

    public render () {
        switch (this.props.node.shape) {
            case Shape.Circle: return this.renderCircle();
            case Shape.Pill: return this.renderPill();
        }
    }

    public renderCircle = () => {
        return (
            <div style={ { background: this.props.node.color } } className={ `node-circle${(this.props.node.isSelected() ? ' selected' : '')}` }>
                { this.props.node.getInPorts().map(this.generatePort) }
                { this.props.node.getOutPorts().map(this.generatePort) }
                <div className='node-label'>{ this.props.node.name }</div>
            </div>
        );
    }

    public renderPill = () => {
        return (
            <div style={ { background: this.props.node.color } } className={ `node-pill${(this.props.node.isSelected() ? ' selected' : '')}` }>
                { this.props.node.getInPorts().map(this.generatePort) }
                { this.props.node.getOutPorts().map(this.generatePort) }
                <div className='node-label'>{ this.props.node.name }</div>
            </div>
        );
    }
}