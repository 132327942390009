// General Id that is not available
export const ID_NOT_AVAILABLE = -1;

// Number of entries per page on the siuf review table
export const SIUF_REVIEW_TABLE_PAGE_SIZE = 15;

// Initial page number for the siuf review table
export const SIUF_REVIEW_TABLE_INITIAL_PAGE_NUMBER = 1;

// Delay timer for input in ms
export const INPUT_DELAY = 1000;

// Default Max Duration for State Machine
export const DEFAULT_MAX_DURATION_IN_MS = 30000;

// Default preview selection
export const DEFAULT_PREVIEW_SELECTION = -1;

export const MAXIMUM_LIKERT_SCALE_ITEM_COUNT = 8;

export const MAXIMUM_LIKERT_RESPONSE_OPTION_COUNT = 15;

export const MAXIMUM_PAGE_COUNT = 10;

export const DISABLE_RINGS_TARGETING = true;

export const DISABLE_RETAIL_ALL_TARGETING = true;

export const ENABLE_AUTO_INTERACTIVE_TARGETING = false;