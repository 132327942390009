import * as React from 'react';
import { Divider, Form } from 'semantic-ui-react';
import { SiufQuestionStructure } from '../../../contracts/models';

type Props = {
    questionStructure: SiufQuestionStructure
    questionText: string;
    positiveAnchor: string;
    neutralAnchor: string;
    negativeAnchor: string;
    positiveFollowupQuestion?: string;
    neutralFollowupQuestion?: string;
    negativeFollowupQuestion?: string;
};

type State = {
    currentSelectedOption: number;
};

export default class ThreePointRatingPreview extends React.Component<Props, State> {

    constructor (props: Props) {
        super(props);

        this.state = {
            currentSelectedOption: -1
        };
    }

    public render () {
        let followupQuestionText = "";

        if (this.state.currentSelectedOption === 2) {
            followupQuestionText = this.props.positiveFollowupQuestion;
        }
        else if (this.state.currentSelectedOption === 1) {
            followupQuestionText = this.props.neutralFollowupQuestion;
        }
        else if (this.state.currentSelectedOption === 0) {
            followupQuestionText = this.props.negativeFollowupQuestion;
        }

        let followupQuestion = followupQuestionText && (
            <React.Fragment>
                <h3>{ followupQuestionText }</h3>
                <Form.TextArea placeholder='Enter your feedback here...' tabIndex='6' />
            </React.Fragment>
        );
        return (
            <React.Fragment>
                <Form widths='equal'>
                    <h3>{ this.props.questionText || '<Question Text>' }</h3>
                    <Divider hidden />
                    <React.Fragment>
                        <Form.Radio
                            checked={ this.state.currentSelectedOption === 2 }
                            name='3pointrating_2'
                            label={ this.props.positiveAnchor || '<Positive Anchor>' }
                            value={ 2 }
                            title='Positive Anchor'
                            tabIndex='6'
                            className = 'ui radio_button_new'
                            onChange={ () => this.setState({ currentSelectedOption: 2 }) }
                        />
                        <Form.Radio
                            checked={ this.state.currentSelectedOption === 1 }
                            name='3pointrating_1'
                            label={ this.props.neutralAnchor || '<Neutral Anchor>' }
                            value={ 1 }
                            title='Neutral Anchor'
                            tabIndex='6'
                            className = 'ui radio_button_new'
                            onChange={ () => this.setState({ currentSelectedOption: 1 }) }
                        />
                        <Form.Radio
                            checked={ this.state.currentSelectedOption === 0 }
                            name='3pointrating_0'
                            label={ this.props.negativeAnchor || '<Negative Anchor>' }
                            value={ 0 }
                            title='Negative Anchor'
                            tabIndex='6'
                            className = 'ui radio_button_new'
                            onChange={ () => this.setState({ currentSelectedOption: 0 }) }
                        />
                    </React.Fragment>
                    {
                        (this.props.questionStructure === SiufQuestionStructure.SingleQuestion)
                        &&
                        followupQuestion
                    }
                </Form>
            </React.Fragment>
        );
    }
}