import { action } from "typesafe-actions";
import { AuthoringUiActionTypes } from ".";
import { SiufAuthoringSectionId } from "../../../contracts/models";

export const updateUiAuthoringSectionVisibility = (section: SiufAuthoringSectionId, isVisible: boolean) => action(
    AuthoringUiActionTypes.UI_UPDATE_AUTHORING_SECTION_VISIBILITY,
    {
        section,
        isVisible
    }
);