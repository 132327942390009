import React, { Component } from 'react';
import { render } from 'react-dom';
import { Confirm, Modal, Button } from 'semantic-ui-react';

let resolve;
const defaultProps = {
  // title: 'Confirmation',
  message: 'Are you sure you want to perform this action?',
  confirmButton: 'Ok',
  cancelButton: 'Cancel',
  size: 'small'
};

type Props = {
  createConfirmProps: any
};
type State = {
  isOpen: boolean,
  showConfirmProps: any,
}
class ConfirmDialog extends Component<Props, State> {
  _isMounted = false;
  static create() {
    const containerElement = document.createElement('div');
    document.body.appendChild(containerElement);
    return render(<ConfirmDialog createConfirmProps={defaultProps}/>, containerElement);
  }
  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: false,
      showConfirmProps: {},
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.show = this.show.bind(this);
  }

  handleCancel() {
    if(this._isMounted){
      this.setState({ isOpen: false });
      resolve(false);
    }
  }

  handleConfirm() {
    if(this._isMounted){
      this.setState({ isOpen: false });
      resolve(true);
    }
  }

  show(props = {}) {
    const showConfirmProps = { ...this.props.createConfirmProps, ...props };
    this.setState({ isOpen: true, showConfirmProps });
    return new Promise((res) => {
      resolve = res;
    });
  }

  render() {
    const { isOpen, showConfirmProps } = this.state;
    const { message, title, confirmButton, cancelButton, size, positive, negative, ...rest } = showConfirmProps;
   return (
    <Modal size={size} open={isOpen} >
      { title && <Modal.Header>{title}</Modal.Header> }
      <Modal.Content> <p>{message}</p> </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => this.handleCancel() } content={cancelButton} />
        <Button content={confirmButton} onClick={() =>this.handleConfirm() } negative={negative} positive={positive} 
        primary={ !negative && !positive}/>
      </Modal.Actions>
    </Modal>
   );
 }

 componentDidMount() {
    this._isMounted = true;
 }
 componentWillUnmount() {
  this._isMounted = false;
 }
}

export default ConfirmDialog;