/* tslint:disable:no-duplicate-imports class-name semicolon interface-name switch-default switch-final-break radix*/

import * as React from "react";
import { Accordion, Container, Icon } from "semantic-ui-react";
import EventsContainerView from '../../../containers/authoring/stateMachine/events/EventContainerView';
import TransitionsContainerView from '../../../containers/authoring/stateMachine/transitions/TransitionsContainerView';
import { ISectionUiState } from "../../../contracts";
import { StateMachineModel, TriggerEvent } from "../../../contracts/states/stateMachineStates";
import DiagramContainerView from "../../../containers/authoring/stateMachine/diagram/DiagramContainerView";

interface StateMachineProps {
    stateMachine: StateMachineModel;
    uiState: ISectionUiState;
    getFriendlyName (triggerEvent: TriggerEvent): string;
}

interface StateMachineState {
    activeIndex: number;
}

export default class StateMachineEditor extends React.Component<StateMachineProps, StateMachineState> {
    constructor (props: StateMachineProps) {
        super(props);
        this.state = {
            activeIndex: 1
        };
    }

    public render () {

        const { activeIndex } = this.state;
        return (
            <Container>
                <DiagramContainerView
                    transitions={ this.props.stateMachine ? this.props.stateMachine.Transitions || [] : [] }
                    states={ this.props.stateMachine ? this.props.stateMachine.States || [] : [] }
                    stateMachineName={ this.props.stateMachine ? this.props.stateMachine.StateMachineName || '' : '' }
                    uiState={ this.props.uiState }
                    ref={ (diagramContainer) => { (window as any).DiagramContainer = diagramContainer } } />
                <Accordion styled fluid>
                    <Accordion.Title active={ activeIndex === 0 } index={ 0 } onClick={ this.handleAccordionClick } tabIndex='7'
                    onKeyPress={e => {
                        var eventCode = e.charCode || e.which;
                        if (eventCode === 13 || eventCode === 32) {
                            var index={ 'index': 0 }
                            this.handleAccordionClick(e, index);
                        }
                    }}><Icon name='dropdown' />Transitions</Accordion.Title>
                    {
                        activeIndex === 0 ?
                            <Accordion.Content active={ activeIndex === 0 }>
                                <TransitionsContainerView
                                    uiState={ this.props.uiState }
                                    states={ this.props.stateMachine ? this.props.stateMachine.States || [] : [] }
                                    transitions={ this.props.stateMachine ? this.props.stateMachine.Transitions || [] : [] }
                                    triggerFilters={ this.props.stateMachine ? this.props.stateMachine.TriggerFilters || [] : [] }
                                    triggerEvents={ this.props.stateMachine ? this.props.stateMachine.TriggerEvents || [] : [] }
                                    transitionFilters={ this.props.stateMachine ? this.props.stateMachine.TransitionFilters || [] : [] }
                                    ref={ (transitionsContainer) => { (window as any).TransitionsContainer = transitionsContainer } }
                                />
                            </Accordion.Content> : <></>
                    }
                    <Accordion.Title active={ activeIndex === 1 } index={ 1 } onClick={ this.handleAccordionClick }  tabIndex='7'
                      onKeyPress={e => {
                        var eventCode = e.charCode || e.which;
                        if (eventCode === 13 || eventCode === 32) {
                            var index={ 'index': 1 }
                            this.handleAccordionClick(e, index);
                        }
                    }}><Icon name='dropdown' />Events</Accordion.Title>
                    <Accordion.Content active={ activeIndex === 1 }>
                        {
                            activeIndex === 1 ?
                                <Accordion.Content active={ activeIndex === 1 }>
                                    <EventsContainerView ref={ (eventComponent) => { (window as any).EventsContainer = eventComponent } } uiState={ this.props.uiState } />
                                </Accordion.Content> : <></>
                        }
                    </Accordion.Content>
                </Accordion>
            </Container>
        );
    }

    public handleAccordionClick = (e: any, titleProps: any) => {
        const { index } = titleProps;
        const { activeIndex } = this.state;
        const newIndex = activeIndex === index ? -1 : index;

        this.setState({ activeIndex: newIndex });
    }
}