import * as React from 'react';
import { Button, Divider, Form, Header, Label, List, Message, Modal, Segment, Table } from 'semantic-ui-react';
import { HostPlatform, ISiufReviewInfo, SiufQuestionStructure, SiufReviewState, SiufSortProperty, SiufState, SiufTypeSelection } from '../../../contracts/models';
import Navigation from '../../../navigation/navigationActions';
import { GetDisplayNameForSiufTargeting, GetDisplayNameForSiufTriggeringType, GetDisplayNameForSiufTypes, GetOwnerAliasFromEmail } from '../../../utils';
import Preview from '../../authoring/questionPreview/Preview';
import DeviceLabel from '../../ui/DeviceLabel';
import StateLabel from '../../ui/StateLabel';

type Props = {
    siufCollection: ISiufReviewInfo[];
    activePreviewPage: string | number;
    hostPlatform: HostPlatform;
    forceCosmicMigration: boolean;
    onFetchSiufs: () => void;
    onApproveSiuf: (siufId: number, reason: string, hostPlatform: HostPlatform) => void;
    onRejectSiuf: (siufId: number, reason: string, hostPlatform: HostPlatform) => void;
    onTerminateSiuf: (siufId: number, reason: string, hostPlatform: HostPlatform) => void;
    onUpdateSortProperty: (property: SiufSortProperty) => void;
    onChangePreviewPage: (pageIndex: string | number) => void;
};

enum ReviewAction {
    review = 0,
    terminate = 1
}

export default class Approval extends React.Component<Props> {

    constructor (props: Props) {
        super(props);

        this.props.onApproveSiuf.bind(this);
        this.props.onRejectSiuf.bind(this);
        this.props.onFetchSiufs.bind(this);
        this.props.onUpdateSortProperty.bind(this);
    }

    public componentDidMount () {

        this.props.onFetchSiufs();
    }
    get showButton() {
        return (this.props.hostPlatform == HostPlatform.Azure && !this.props.forceCosmicMigration) || this.props.hostPlatform == HostPlatform.Cosmic;
    }

    private renderModal (s: ISiufReviewInfo, action: ReviewAction, retry: boolean = false) {
        let reasonInputRef: HTMLTextAreaElement;

        let reviewButtons: JSX.Element;
        let reviewText = "";

        if (action === ReviewAction.review) {
            reviewText = retry ? "Error" : "Review";
            reviewButtons = (
                <React.Fragment>
                    <Button positive size='tiny'
                        loading={ (s.reviewState === SiufReviewState.inProgress) }
                        disabled={ (s.reviewState !== SiufReviewState.notReviewed && s.reviewState !== SiufReviewState.error) }
                        onClick={ e => {
                            this.props.onApproveSiuf(s.siufReviewEntity.SiufId, reasonInputRef.value || "", this.props.hostPlatform);
                        } }>Approve</Button>
                    <Button negative size='tiny'
                        loading={ (s.reviewState === SiufReviewState.inProgress) }
                        disabled={ (s.reviewState !== SiufReviewState.notReviewed && s.reviewState !== SiufReviewState.error) }
                        onClick={ e => {
                            if (reasonInputRef && reasonInputRef.value) {
                                this.props.onRejectSiuf(s.siufReviewEntity.SiufId, reasonInputRef.value, this.props.hostPlatform);
                            }
                        } }>Reject</Button>
                </React.Fragment>
            );
        } else if (action === ReviewAction.terminate) {
            reviewText = retry ? "Error" : "Terminate";
            reviewButtons = (
                <React.Fragment>
                    <Button negative size='tiny'
                        loading={ (s.reviewState === SiufReviewState.inProgress) }
                        disabled={ (s.reviewState !== SiufReviewState.notReviewed && s.reviewState !== SiufReviewState.error) }
                        onClick={ e => {
                            if (reasonInputRef && reasonInputRef.value) {
                                this.props.onTerminateSiuf(s.siufReviewEntity.SiufId, reasonInputRef.value, this.props.hostPlatform);
                            }
                        } }>Terminate</Button>
                </React.Fragment>
            );
        } else {
            reviewButtons = <React.Fragment></React.Fragment>;
        }

        return (
            <Modal trigger={ <Button
                primary={ action === ReviewAction.review }
                negative={ action === ReviewAction.terminate || retry }
                size='tiny'>{ reviewText }
            </Button> }>
                <Modal.Header>
                    <Header as='h3'>{ `${s.siufReviewEntity.SiufId}: ${s.siufReviewEntity.SiufName}` }
                        <Label basic color='blue'>{ `V ${s.siufReviewEntity.Version}.${s.siufReviewEntity.Iteration}` }</Label>
                        <StateLabel state={ s.siufReviewEntity.State } />
                    </Header>
                </Modal.Header>
                <Modal.Content>
                    <Form error={ s.reviewState === SiufReviewState.error }>
                        <Message error header='An error was encountered, please try again' content={ s.errorMessage } />
                        <Form.Field>
                            <label>Review Comment (Required for Rejection/Termination)</label>
                            <textarea ref={ node => reasonInputRef = node }
                                placeholder="Enter reason for approval/rejection here..."
                                maxLength={ 1000 }
                                rows={ 10 }></textarea>
                        </Form.Field>
                    </Form>
                    <Modal.Description>
                        <Segment basic>
                            { reviewButtons }
                        </Segment>
                    </Modal.Description>
                </Modal.Content>
            </Modal >
        );
    }

    public render () {

        let tableRows = this.props.siufCollection.map(s => {
            const firstSiufQuestion = s.siufReviewEntity.Payload.Pages && (s.siufReviewEntity.Payload.Pages.length > 0) && s.siufReviewEntity.Payload.Pages[0]?.Questions?.length > 0 && s.siufReviewEntity.Payload.Pages[0].Questions[0];

            let reviewControl: JSX.Element;

            if (s.reviewState === SiufReviewState.notReviewed) {
                if (s.siufReviewEntity.State === SiufState.Submitted) {
                    reviewControl = this.renderModal(s, ReviewAction.review);
                } else if (s.siufReviewEntity.State === SiufState.Published) {
                    reviewControl = this.renderModal(s, ReviewAction.terminate);
                } else {
                    reviewControl = <label>n/a</label>;
                }
            } else if (s.reviewState === SiufReviewState.inProgress) {
                reviewControl = (
                    <Button primary size='tiny'
                        loading={ true }
                        content='Waiting...' />
                );
            } else if (s.reviewState === SiufReviewState.approved) {
                reviewControl = (
                    <Label basic content='Approved' color='green' />
                );
            } else if (s.reviewState === SiufReviewState.rejected) {
                reviewControl = (
                    <Label basic content='Rejected' color='red' />
                );
            } else if (s.reviewState === SiufReviewState.terminated) {
                reviewControl = (
                    <Label basic content='Terminated' color='red' />
                );
            } else if (s.reviewState === SiufReviewState.error) {
                if (s.siufReviewEntity.State === SiufState.Submitted) {
                    reviewControl = this.renderModal(s, ReviewAction.review, true);
                } else if (s.siufReviewEntity.State === SiufState.Published) {
                    reviewControl = this.renderModal(s, ReviewAction.terminate, true);
                } else {
                    reviewControl = <label>n/a</label>;
                }
            } else {
                reviewControl = (
                    <Label content='unknown state error' color='red' />
                );
            }

            let justifications: JSX.Element;
            if (s.siufReviewEntity.Justifications && s.siufReviewEntity.Justifications.length === 3) {
                justifications = (
                    <React.Fragment>
                        <Header as='h3' textAlign='center'>SIUF Description from Author</Header>
                        <List relaxed ordered>
                            <List.Item>
                                <List.Header>{ s.siufReviewEntity.Justifications[0].Question }</List.Header>
                                { s.siufReviewEntity.Justifications[0].Answer || "<Author did not specify>" }
                            </List.Item>
                            <List.Item>
                                <List.Header>{ s.siufReviewEntity.Justifications[1].Question }</List.Header>
                                { s.siufReviewEntity.Justifications[1].Answer || "<Author did not specify>" }
                            </List.Item>
                            <List.Item>
                                <List.Header>{ s.siufReviewEntity.Justifications[2].Question }</List.Header>
                                { s.siufReviewEntity.Justifications[2].Answer || "<Author did not specify>" }
                            </List.Item>
                        </List>
                    </React.Fragment>
                );
            } else {
                justifications = <label>Unable to load justifications, please contact support</label>;
            }

            return (
                <Table.Row key={ s.siufReviewEntity.SiufId }>
                    <Table.Cell textAlign='center'><a href={ Navigation.Route_ExistingAuthoringPage(s.siufReviewEntity.SiufId) } target='_blank' rel="noopener noreferrer">{ s.siufReviewEntity.SiufId }</a></Table.Cell>
                    <Table.Cell textAlign='center'><a href={ Navigation.Route_ExistingAuthoringPage(s.siufReviewEntity.SiufId) } target='_blank' rel="noopener noreferrer">{ s.siufReviewEntity.SiufName }</a></Table.Cell>
                    <Table.Cell textAlign='center'>
                        {
                            firstSiufQuestion
                                ?
                                <Modal trigger={ <Button color={ firstSiufQuestion.QuestionStructure === SiufQuestionStructure.SingleQuestion ? 'teal' : 'orange' } size='tiny'>{ GetDisplayNameForSiufTypes(firstSiufQuestion.QuestionStructure) }</Button> }>
                                    <Modal.Header>
                                        <Header as='h3'>{ `${s.siufReviewEntity.SiufId}: ${s.siufReviewEntity.SiufName}` }
                                            <Label basic color='blue'>{ `V ${s.siufReviewEntity.Version}.${s.siufReviewEntity.Iteration}` }</Label>
                                            <StateLabel state={ s.siufReviewEntity.State } />
                                            { s.siufReviewEntity.Targeting && <DeviceLabel deviceType={ s.siufReviewEntity.Targeting.DeviceType } /> }
                                            <Label basic color='purple'>Targeting Method: { s.siufReviewEntity.Targeting ? GetDisplayNameForSiufTargeting(s.siufReviewEntity.Targeting.TargetType) : "n/a" }</Label>
                                        </Header>
                                    </Modal.Header>
                                    <Modal.Content scrolling>
                                        <Segment basic>
                                            { justifications }
                                            <Divider />
                                            <Preview
                                                siufType={
                                                    firstSiufQuestion.QuestionStructure === SiufQuestionStructure.SingleQuestion
                                                        ? SiufTypeSelection.singleQuestion
                                                        : SiufTypeSelection.multiQuestion
                                                }
                                                pageCollectionModel={ s.siufReviewEntity.Payload.Pages }
                                                title={ s.siufReviewEntity.Payload.Title }
                                                activePreviewPage={this.props.activePreviewPage}
                                                onChangePreviewPage={(pageId: string | number) => this.props.onChangePreviewPage(pageId) }
                                                uiState={ {
                                                    isReadOnly: true,
                                                    isLoading: false,
                                                    isVisible: true
                                                } }
                                            />
                                        </Segment>
                                    </Modal.Content>
                                </Modal>
                                :
                                "<No Questions>"
                        }
                    </Table.Cell>
                    <Table.Cell textAlign='center'>{ GetOwnerAliasFromEmail(s.siufReviewEntity.Owner) }</Table.Cell>
                    <Table.Cell textAlign='center'>
                        {
                            s.siufReviewEntity.Targeting ? GetDisplayNameForSiufTargeting(s.siufReviewEntity.Targeting.TargetType) : "n/a"
                        }
                    </Table.Cell>
                    <Table.Cell textAlign='center'>{ GetDisplayNameForSiufTriggeringType(s.siufReviewEntity.Payload.TriggeringType) }</Table.Cell>
                    <Table.Cell textAlign='center'>
                        <Label basic color='blue'>{ `${s.siufReviewEntity.Version}.${s.siufReviewEntity.Iteration}` }</Label>
                    </Table.Cell>
                    <Table.Cell textAlign='center'>
                        <StateLabel state={ s.siufReviewEntity.State } />
                    </Table.Cell>
                    <Table.Cell textAlign='center'>
                        { this.showButton && reviewControl }
                        { !this.showButton && <Label>Review</Label> }
                    </Table.Cell>
                </Table.Row>
            );
        });

        return (
            <Segment basic>
                {
                    this.props.siufCollection.length > 0
                        ?
                        <Table color='blue' sortable striped celled>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell textAlign='center' width="1" onClick={ () => this.props.onUpdateSortProperty(SiufSortProperty.id) }>ID</Table.HeaderCell>
                                    <Table.HeaderCell textAlign='center' width="5" onClick={ () => this.props.onUpdateSortProperty(SiufSortProperty.name) }>Name</Table.HeaderCell>
                                    <Table.HeaderCell textAlign='center' width="1" >Details (click to preview)</Table.HeaderCell>
                                    <Table.HeaderCell textAlign='center' width="1" onClick={ () => this.props.onUpdateSortProperty(SiufSortProperty.owner) }>Owner</Table.HeaderCell>
                                    <Table.HeaderCell textAlign='center' width="2" onClick={ () => this.props.onUpdateSortProperty(SiufSortProperty.targeting) }>Targeting</Table.HeaderCell>
                                    <Table.HeaderCell textAlign='center' width="2" onClick={ () => this.props.onUpdateSortProperty(SiufSortProperty.triggerType) }>Trigger Type</Table.HeaderCell>
                                    <Table.HeaderCell textAlign='center' width="1" onClick={ () => this.props.onUpdateSortProperty(SiufSortProperty.version) }>Version</Table.HeaderCell>
                                    <Table.HeaderCell textAlign='center' width="2" onClick={ () => this.props.onUpdateSortProperty(SiufSortProperty.state) }>State</Table.HeaderCell>
                                    <Table.HeaderCell textAlign='center' width="1" >Approval</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                { tableRows }
                            </Table.Body>
                        </Table>
                        :
                        <Message info>No SIUFs found based to the current filters</Message>
                }
            </Segment >
        );
    }
}