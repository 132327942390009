import { SiufDomainAction } from '../actions';
import { StateMachineActionTypes } from '../actions/authoring/stateMachine/types';
import * as Types from '../contracts';
import { TriggerFilterOperation } from '../contracts';

export const stateMachineReducer = (state: Types.StateMachineModel = Types.StateMachineModel_Default, action: SiufDomainAction) => {
    switch (action.type) {
        case StateMachineActionTypes.SET_STATEMACHINENAME:
            return updateNameReducer(state, action);
        case StateMachineActionTypes.ADD_TRANSITIONFILTER:
            return addTransitionFilter(state, action);
        case StateMachineActionTypes.REMOVE_TRANSITIONFILTER:
            return removeTransitionFilter(state, action);
        case StateMachineActionTypes.UPDATE_TRANSITIONFILTER_REFERENCE:
            return updateTransitionFilterReference(state, action);
        case StateMachineActionTypes.UPDATE_TRANSITIONFILTER_STRICT:
            return updateTransitionFilterStrict(state, action);
        case StateMachineActionTypes.REMOVE_TRANSITION:
            return removeTransition(state, action);
        case StateMachineActionTypes.UPDATE_TRIGGERFILTER:
            return updateTriggerFilterReducer(state, action);
        case StateMachineActionTypes.UPDATE_TRIGGERFILTER_EVENT:
            return updateTriggerFilterEventReducer(state, action);
        case StateMachineActionTypes.UPDATE_TRANSITION:
            return updateTransition(state, action);
        case StateMachineActionTypes.ADD_TRANSITION:
            return addTransition(state, action);
        case StateMachineActionTypes.UPDATE_TRIGGEREVENT:
            return updateTriggerEvent(state, action);
        case StateMachineActionTypes.REMOVE_TRIGGEREVENT:
            return removeTriggerEvent(state, action);
        case StateMachineActionTypes.ADD_TRIGGEREVENT:
            return addTriggerEvent(state, action);
        case StateMachineActionTypes.REMOVE_STATE:
            return removeState(state, action);
        case StateMachineActionTypes.ADD_STATE:
            return addState(state, action);
        default:
            return state;
    }
};

// Trigger Filters
const updateTriggerFilterReducer = (state: Types.StateMachineModel, action: SiufDomainAction): Types.StateMachineModel => {
    switch (action.type) {
        case StateMachineActionTypes.UPDATE_TRIGGERFILTER:
            let index = state.TriggerFilters.findIndex(x => x.TriggerFilterId === action.payload.triggerFilterChanges.TriggerFilterId);
            let oldTriggerFilter = state.TriggerFilters[index];
            let sourceObject: Types.TriggerFilter;
            if (action.payload.triggerFilterChanges.Type) {
                let newTriggerFilter: Types.TriggerFilter;
                switch (action.payload.triggerFilterChanges.Type) {
                    case 'dual':
                        newTriggerFilter = {
                            Type: 'dual',
                            Event1: { Type: 'triggerEvent', Property: { Type: 'eventProperty' } },
                            Event2: { Type: 'triggerEvent', Property: { Type: 'eventProperty' } }
                        } as Types.DualTriggerFilter;
                        break;
                    case 'single':
                        newTriggerFilter = {
                            Type: 'single',
                            Event: { Type: 'triggerEvent', Property: { Type: 'eventProperty' } }
                        } as Types.SingleTriggerFilter;
                        break;
                    default:
                }
                sourceObject = {
                    ...newTriggerFilter, ...{
                        ApplyToEscalations: oldTriggerFilter.ApplyToEscalations,
                        FilterName: oldTriggerFilter.FilterName,
                        Operation: oldTriggerFilter.Operation,
                        TriggerFilterId: oldTriggerFilter.TriggerFilterId
                    }
                };
            }
            else {
                sourceObject = oldTriggerFilter;
            }

            let updatedTriggerFilter = {
                ...sourceObject,
                ...action.payload.triggerFilterChanges,
                IsDirty: false
            } as Types.TriggerFilter;

            return {
                ...state,
                TriggerFilters: state.TriggerFilters.map((existing, i) => i === index ? updatedTriggerFilter : existing)
            };
        default:
            return state;
    }

};

const updateTriggerFilterEventReducer = (state: Types.StateMachineModel, action: SiufDomainAction): Types.StateMachineModel => {
    switch (action.type) {
        case StateMachineActionTypes.UPDATE_TRIGGERFILTER_EVENT:
            let index = state.TriggerFilters.findIndex(x => x.TriggerFilterId === action.payload.triggerFilterEventChanges.TriggerFilterId);
            let triggerFilter = state.TriggerFilters[index];
            let sourceObject: Types.TriggerFilterEvent;
            if (action.payload.triggerFilterEventChanges.Type) {
                switch (action.payload.triggerFilterEventChanges.Type) {
                    case 'scenarioMetadata':
                        sourceObject = {
                            Type: 'scenarioMetadata',
                            Property: Types.ScenarioMetadataProperty.IsACOn,
                            StrictTransition: false,
                            Bitmask: null,
                            ConstantOperation: null
                        };
                        break;
                    case 'triggerEvent':
                        sourceObject = {
                            Type: 'triggerEvent',
                            Bitmask: null,
                            ConstantOperation: null,
                            Property: {
                                Property: null,
                                Type: 'eventProperty'
                            },
                            StrictTransition: false,
                            TriggerEventId: null
                        };
                        break;
                    default:
                }
            } else {
                if (triggerFilter.Type === 'single') {
                    sourceObject = triggerFilter.Event;
                } else if (triggerFilter.Type === 'dual') {
                    sourceObject = action.payload.triggerFilterEventChanges.IsSecondEvent ? triggerFilter.Event2 : triggerFilter.Event1;
                }
            }

            let sourceProperty: Types.TriggerEventProperty | string;
            if (action.payload.triggerFilterEventChanges.PropertyType) {
                switch (action.payload.triggerFilterEventChanges.PropertyType) {
                    case 'eventProperty':
                        sourceProperty = { Type: 'eventProperty', Property: '' };
                        break;
                    case 'systemProperty':
                        sourceProperty = { Type: 'systemProperty', Property: Types.SystemProperty.AID };
                        break;
                    default:
                }
            }
            else {
                sourceProperty = sourceObject.Property;
            }
            let newProperty: Types.TriggerEventProperty | string;
            if (action.payload.triggerFilterEventChanges.Property) {
                if (typeof sourceProperty === 'string'|| sourceObject.Type === "scenarioMetadata") {
                    newProperty = action.payload.triggerFilterEventChanges.Property;
                }
                else {
                    newProperty = {
                        Type: sourceProperty.Type,
                        Property: action.payload.triggerFilterEventChanges.Property
                    } as any;
                }
            }
            else {
                newProperty = typeof sourceProperty === 'string' ? sourceProperty : { ...sourceProperty };
            }

            let newConstantOperation: Types.ConstantOperation;
            if (action.payload.triggerFilterEventChanges.ConstantOperation === null && action.payload.triggerFilterEventChanges.ConstantOperand === null && sourceObject.ConstantOperation === null) {
                newConstantOperation = null;
            } else {
                newConstantOperation = {
                    ...sourceObject.ConstantOperation,
                    ...{
                        ...(action.payload.triggerFilterEventChanges.ConstantOperation !== undefined && { Operation: action.payload.triggerFilterEventChanges.ConstantOperation }),
                        ...(action.payload.triggerFilterEventChanges.ConstantOperand !== undefined && { Operand: action.payload.triggerFilterEventChanges.ConstantOperand }),
                    }
                };
            }
            let newTriggerFilterEvent = {
                ...sourceObject,
                ...{
                    Property: newProperty,
                    ConstantOperation: newConstantOperation,
                    ...(action.payload.triggerFilterEventChanges.TriggerEventId !== undefined && { TriggerEventId: action.payload.triggerFilterEventChanges.TriggerEventId } as any),
                    ...(action.payload.triggerFilterEventChanges.Bitmask !== undefined && { Bitmask: action.payload.triggerFilterEventChanges.Bitmask }),
                    ...(action.payload.triggerFilterEventChanges.StrictTransition !== undefined && { StrictTransition: action.payload.triggerFilterEventChanges.StrictTransition }),
                }
            } as Types.TriggerFilterEvent;

            let updatedTriggerFilter: Types.TriggerFilter;
            if (triggerFilter.Type === 'single') {
                updatedTriggerFilter = { ...triggerFilter, Event: newTriggerFilterEvent };
            } else if (triggerFilter.Type === 'dual') {
                updatedTriggerFilter = {
                    ...triggerFilter,
                    ...(action.payload.triggerFilterEventChanges.IsSecondEvent ? { Event2: newTriggerFilterEvent } : { Event1: newTriggerFilterEvent })
                };
            }
            return {
                ...state,
                TriggerFilters: state.TriggerFilters.map((existing, i) => i === index ? updatedTriggerFilter : existing)
            };
        default:
            return state;
    }
};

// Transition filters
const addTransitionFilter = (state: Types.StateMachineModel, action: SiufDomainAction): Types.StateMachineModel => {
    switch (action.type) {
        case StateMachineActionTypes.ADD_TRANSITIONFILTER:
            let newTriggerFilter = {
                Type: 'single',
                TriggerFilterId: action.payload.newTriggerFilterId,
                ApplyToEscalations: true,
                FilterName: '',
                Operation: TriggerFilterOperation.Equals,
                Operand: '',
                IsDirty: false,
                Event: { Type: 'triggerEvent', Property: { Type: 'eventProperty' } }
            } as Types.SingleTriggerFilter;
            let newTransitionFilter = {
                TransitionFilterId: action.payload.newTransitionFilterId,
                TransitionId: action.payload.transitionId,
                TriggerFilterId: newTriggerFilter.TriggerFilterId,
                IsStrict: false
            } as Types.TransitionFilter;
            return {
                ...state,
                TriggerFilters: [...state.TriggerFilters, newTriggerFilter],
                TransitionFilters: [...state.TransitionFilters, newTransitionFilter]
            };
        default:
            return state;
    }
};

const removeTransitionFilter = (state: Types.StateMachineModel, action: SiufDomainAction): Types.StateMachineModel => {
    switch (action.type) {
        case StateMachineActionTypes.REMOVE_TRANSITIONFILTER:
            let newTransitionFilters = state.TransitionFilters.filter(x => x.TransitionFilterId !== action.payload.transitionFilterId);
            let triggerFilterIdsStillInUse = newTransitionFilters.map(x => x.TriggerFilterId);
            let newTriggerFilters = state.TriggerFilters.filter(x => triggerFilterIdsStillInUse.indexOf(x.TriggerFilterId) !== -1);

            return {
                ...state,
                TransitionFilters: newTransitionFilters,
                TriggerFilters: newTriggerFilters,
            };
        default:
            return state;
    }
};

const updateTransitionFilterReference = (state: Types.StateMachineModel, action: SiufDomainAction): Types.StateMachineModel => {
    switch (action.type) {
        case StateMachineActionTypes.UPDATE_TRANSITIONFILTER_REFERENCE:
            let transitionFilterIndex = state.TransitionFilters.findIndex(x => x.TransitionFilterId === action.payload.transitionFilterReferenceModel.TransitionFilterId);
            let oldTriggerFilterId = state.TransitionFilters[transitionFilterIndex].TriggerFilterId;
            if (oldTriggerFilterId === action.payload.transitionFilterReferenceModel.TriggerFilterId) return state;

            let shouldRemoveOldTriggerFilter = state.TransitionFilters.filter(x => x.TriggerFilterId === oldTriggerFilterId).length === 1;
            return {
                ...state,
                TransitionFilters: state.TransitionFilters.map((existing, i) => i !== transitionFilterIndex ? existing : {
                    ...existing,
                    TriggerFilterId: action.payload.transitionFilterReferenceModel.TriggerFilterId
                }),
                ...(shouldRemoveOldTriggerFilter && { TriggerFilters: state.TriggerFilters.filter(triggerFilter => triggerFilter.TriggerFilterId !== oldTriggerFilterId) }),
            };
        default:
            return state;
    }
};

const updateTransitionFilterStrict = (state: Types.StateMachineModel, action: SiufDomainAction): Types.StateMachineModel => {
    switch (action.type) {
        case StateMachineActionTypes.UPDATE_TRANSITIONFILTER_STRICT:
            let transitionFilterIndex = state.TransitionFilters.findIndex(x => x.TransitionFilterId === action.payload.transitionFilterStrictModel.TransitionFilterId);
            return {
                ...state,
                TransitionFilters: state.TransitionFilters.map((existing, i) => i !== transitionFilterIndex ? existing : {
                    ...existing,
                    IsStrict: action.payload.transitionFilterStrictModel.Strict
                })
            };
        default:
            return state;
    }
};

// Transitions
const removeTransition = (state: Types.StateMachineModel, action: SiufDomainAction): Types.StateMachineModel => {
    switch (action.type) {
        case StateMachineActionTypes.REMOVE_TRANSITION:
            let newTransitionFilters = state.TransitionFilters.filter(x => x.TransitionId !== action.payload.transitionId);
            let triggerFilterIdsStillInUse = newTransitionFilters.map(x => x.TriggerFilterId);
            let newTriggerFilters = state.TriggerFilters.filter(x => triggerFilterIdsStillInUse.indexOf(x.TriggerFilterId) !== -1);
            // resetSubdurations(state);
            return {
                ...state,
                Transitions: state.Transitions.filter(x => x.TransitionId !== action.payload.transitionId),
                TransitionFilters: newTransitionFilters,
                TriggerFilters: newTriggerFilters,
            };
        default:
            return state;
    }
};

const addTransition = (state: Types.StateMachineModel, action: SiufDomainAction): Types.StateMachineModel => {
    switch (action.type) {
        case StateMachineActionTypes.ADD_TRANSITION:
            let fromStateId = action.payload.transition.FromStateId || state.States.find(x => x.StateName === "_start").StateId;
            let toStateId = state.States.find(x => x.StateName === '_complete').StateId;
            let triggerEventId = state.TriggerEvents ? state.TriggerEvents[0] ? state.TriggerEvents[0].TriggerEventId : null : null;
            let maxTransitionNumber = Math.max.apply(null, state.Transitions.map(x => {
                let numberMatch = x.TransitionName.match(/T(\d+)/);
                return (numberMatch && parseInt(numberMatch[1], 10)) || 0;
            }).filter(x => typeof x === 'number'));
            if (maxTransitionNumber < 0) {
                maxTransitionNumber = 0;
            }
            let newTransition: Types.Transition = {
                TransitionId: action.payload.transition.TransitionId,
                FromStateId: fromStateId,
                EventSource: Types.TransitionEventSource.Host,
                ToStateId: toStateId,
                TriggerEventId: triggerEventId,
                UploadTrigger: false,
                TransitionName: 'T' + (maxTransitionNumber + 1)
            };
            return {
                ...state,
                Transitions: [
                    ...state.Transitions,
                    newTransition
                ]
            };
        default:
            return state;
    }
};

const updateTransition = (state: Types.StateMachineModel, action: SiufDomainAction): Types.StateMachineModel => {
    switch (action.type) {
        case StateMachineActionTypes.UPDATE_TRANSITION:
            let index = state.Transitions.findIndex(x => x.TransitionId === action.payload.transitionChanges.TransitionId);
            let sourceObject = state.Transitions[index];
            let updatedTransition = {
                ...sourceObject,
                ...action.payload.transitionChanges
            };
            return {
                ...state,
                Transitions: state.Transitions.map((existing, i) => i === index ? updatedTransition : existing)
            };
        default:
            return state;
    }
};

// Trigger events
const updateTriggerEvent = (state: Types.StateMachineModel, action: SiufDomainAction): Types.StateMachineModel => {
    switch (action.type) {
        case StateMachineActionTypes.UPDATE_TRIGGEREVENT:
            let index = state.TriggerEvents.findIndex(x => x.TriggerEventId === action.payload.triggerEventChanges.TriggerEventId);
            let sourceTriggerEvent = state.TriggerEvents[index];
            let sourceObject: Types.TriggerEvent;
            if (action.payload.triggerEventChanges.Type) {
                switch (action.payload.triggerEventChanges.Type) {
                    case 'manifestEtw':
                        sourceObject = {
                            Type: 'manifestEtw',
                            EventId: 0,
                            EventName: (sourceTriggerEvent.Type === 'traceLogging' && sourceTriggerEvent.EventName) || '',
                            EventVersion: 0,
                            Keyword: (sourceTriggerEvent.Type === 'traceLogging' && sourceTriggerEvent.Keyword) || '0',
                            Level: (sourceTriggerEvent.Type === 'traceLogging' && sourceTriggerEvent.Level) || 5,
                            ProviderGuid: (sourceTriggerEvent.Type === 'traceLogging' && sourceTriggerEvent.ProviderGuid) || '',
                            TriggerEventId: sourceTriggerEvent.TriggerEventId
                        } as Types.ManifestEtwTriggerEvent;
                        break;
                    case 'time':
                        sourceObject = {
                            Type: 'time',
                            AllowCoalescing: false,
                            DurationMs: 0,
                            TriggerEventId: sourceTriggerEvent.TriggerEventId
                        } as Types.TimeTriggerEvent;
                        break;
                    case 'traceLogging':
                        sourceObject = {
                            Type: 'traceLogging',
                            EventName: (sourceTriggerEvent.Type === 'manifestEtw' && sourceTriggerEvent.EventName) || '',
                            Keyword: (sourceTriggerEvent.Type === 'manifestEtw' && sourceTriggerEvent.Keyword) || '0',
                            Level: (sourceTriggerEvent.Type === 'manifestEtw' && sourceTriggerEvent.Level) || 5,
                            ProviderGuid: (sourceTriggerEvent.Type === 'manifestEtw' && sourceTriggerEvent.ProviderGuid) || '',
                            TriggerEventId: sourceTriggerEvent.TriggerEventId
                        } as Types.TraceLoggingTriggerEvent;
                        break;
                    default:
                }
            }
            else {
                sourceObject = sourceTriggerEvent;
            }
            let updatedTriggerEvent = {
                ...sourceObject,
                ...action.payload.triggerEventChanges
            } as Types.TriggerEvent;

            return {
                ...state,
                TriggerEvents: state.TriggerEvents.map((existing, i) => i === index ? updatedTriggerEvent : existing)
            };
        default:
            return state;
    }
};

const removeTriggerEvent = (state: Types.StateMachineModel, action: SiufDomainAction): Types.StateMachineModel => {
    switch (action.type) {
        case StateMachineActionTypes.REMOVE_TRIGGEREVENT:
            let triggerEventIdToRemove = action.payload.triggerEventId;
            let newTriggerEventId =
                state.TriggerEvents ?
                    state.TriggerEvents[0] ?
                        state.TriggerEvents[0].TriggerEventId !== triggerEventIdToRemove ?
                            state.TriggerEvents[0].TriggerEventId :
                            state.TriggerEvents[1] ?
                                state.TriggerEvents[1].TriggerEventId :
                                null :
                        null :
                    null;
            // resetMetadata(state);
            return {
                ...state,
                TriggerEvents: state.TriggerEvents.filter(x => x.TriggerEventId !== triggerEventIdToRemove),
                Transitions: state.Transitions.map(transition => ({
                    ...transition,
                    TriggerEventId: transition.TriggerEventId === triggerEventIdToRemove ? newTriggerEventId : transition.TriggerEventId
                }))
            };
        default:
            return state;
    }
};

const addTriggerEvent = (state: Types.StateMachineModel, action: SiufDomainAction): Types.StateMachineModel => {
    switch (action.type) {
        case StateMachineActionTypes.ADD_TRIGGEREVENT:
            let newTriggerEvent: Types.TriggerEvent = {
                Type: 'traceLogging',
                EventName: '',
                Keyword: '0',
                Level: 5,
                ProviderGuid: '',
                TriggerEventId: action.payload.triggerEventId
            };
            return {
                ...state,
                TriggerEvents: [
                    ...state.TriggerEvents,
                    newTriggerEvent
                ],
                Transitions: state.Transitions.map(transition => ({ ...transition, TriggerEventId: transition.TriggerEventId ? transition.TriggerEventId : newTriggerEvent.TriggerEventId }))
            };
        default:
            return state;
    }
};

const removeState = (state: Types.StateMachineModel, action: SiufDomainAction): Types.StateMachineModel => {
    switch (action.type) {
        case StateMachineActionTypes.REMOVE_STATE:
            let newTransistions = state.Transitions.filter(t => t.FromStateId !== action.payload.stateId && t.ToStateId !== action.payload.stateId);
            let newTransitionFilters = state.TransitionFilters.filter(tf => newTransistions.some(t => t.TransitionId === tf.TransitionId));
            let newTriggerFilters = state.TriggerFilters.filter(triggerFilter => newTransitionFilters.some(tf => tf.TriggerFilterId === triggerFilter.TriggerFilterId));
            let newStates = state.States.filter(s => s.StateId !== action.payload.stateId);
            return {
                ...state,
                Transitions: newTransistions,
                TransitionFilters: newTransitionFilters,
                TriggerFilters: newTriggerFilters,
                States: newStates
            };
        default:
            return state;
    }
};

const addState = (state: Types.StateMachineModel, action: SiufDomainAction): Types.StateMachineModel => {
    switch (action.type) {
        case StateMachineActionTypes.ADD_STATE:
            let maxStateNumber = Math.max.apply(null, state.States.map(x => {
                let numberMatch = x.StateName.match(/(\d+)/);
                return (numberMatch && parseInt(numberMatch[1], 10)) || 0;
            }).filter(x => typeof x === 'number'));
            return {
                ...state,
                States: [
                    ...state.States,
                    {
                        StateId: action.payload.stateId,
                        StateName: `${maxStateNumber + 1}`
                    }
                ]
            };
        default:
            return state;
    }
};

const updateNameReducer = (state: Types.StateMachineModel, action: SiufDomainAction): Types.StateMachineModel => {
    switch (action.type) {
        case StateMachineActionTypes.SET_STATEMACHINENAME:
            return {
                ...state,
                StateMachineName: action.payload.name
            };
        default:
            return state;
    }
};
