import { action } from "typesafe-actions";
import { ReviewingUiActionTypes } from ".";
import { SiufReviewingSectionId } from "../../../contracts/models";

export const updateUiReviewSectionVisibility = (section: SiufReviewingSectionId, isVisible: boolean) => action(
    ReviewingUiActionTypes.UI_UPDATE_AUTHORING_SECTION_VISIBILITY,
    {
        section,
        isVisible
    }
);