import { SiufDeviceType, SiufLifeTimeOption, SiufQuestionStructure, SiufQuestionType, SiufRing, SiufState, SiufTargetType, SiufTriggeringType, SiufTypeSelection, SubmitOperation, SiufRingChannel, LikertResponseType, LikertResponseCount } from "../contracts/models";
import * as StateMachineModels from '../contracts/states/stateMachineStates';

export const GetTriggerEventDisplayName = (triggerEvent: StateMachineModels.TriggerEvent) => {
    if (triggerEvent) {
        switch (triggerEvent.Type) {
            case 'manifestEtw':
                return triggerEvent.EventName;
            case 'time':
                return `Wait ${triggerEvent.DurationMs} ms`;
            case 'traceLogging':
                return triggerEvent.EventName;
            default:
                return '{Unnamed trigger event}';
        }
    }
    return '{Unnamed trigger event}';
};

export const GetDisplayNameForSiufTypeSelections = (structure: SiufTypeSelection) => {
    switch (structure) {
        case SiufTypeSelection.singleQuestion:
            return "Single Question";
        case SiufTypeSelection.multiQuestion:
            return "Multi Question";
        default:
            return "[selection not Recognized]";
    }
};

export const GetDisplayNameForSiufTypes = (structure: SiufQuestionStructure) => {
    switch (structure) {
        case SiufQuestionStructure.SingleQuestion:
            return "Single Question";
        case SiufQuestionStructure.MultiQuestion:
            return "Multi Question";
        case SiufQuestionStructure.FollowUpQuestion:
            return "Follow up Question";
        default:
            return "[Structure not Recognized]";
    }
};

export const GetDisplayNameForSiufQuestionTypes = (siufQuestionType: SiufQuestionType) => {
    switch (siufQuestionType) {
        case SiufQuestionType.FivePoint:
            return "Five Point Ratings Question";
        case SiufQuestionType.ThreePoint:
            return "Three Point Ratings Question";
        case SiufQuestionType.YesNo:
            return "Yes No Question";
        case SiufQuestionType.Text:
            return "Freeform Text Question";
        case SiufQuestionType.CheckBox:
            return "CheckBox Question";
        case SiufQuestionType.Likert:
            return "Likert Question";
        default:
            return "[Question not Recognized]";
    }
};

export const GetDisplayNameForSiufState = (state: SiufState) => {
    switch (state) {
        case SiufState.Draft:
            return "Draft";
        case SiufState.Submitted:
            return "In Review";
        case SiufState.Approved:
            return "Approved";
        case SiufState.Published:
            return "Published";
        case SiufState.Terminated:
            return "Terminated";
        default:
            return state;
    }
};

export const GetSiufStateHeaderDisplayText = (state: SiufState) => {
    switch (state) {
        case SiufState.Submitted:
            return "This SIUF is currently under review";
        case SiufState.Approved:
            return "This SIUF is currently approved and will soon be published automatically";
        case SiufState.Published:
            return "This SIUF is published and active";
        case SiufState.Terminated:
            return "This SIUF is currently terminated";
        case SiufState.Draft:
        default:
            return "";
    }
};

export const GetSiufStateSubHeaderDisplayText = (state: SiufState) => {
    switch (state) {
        case SiufState.Submitted:
            return "You will not be able to edit this SIUF. You may withdraw this SIUF from the review if you wish to continue edit the SIUF after specify a reason.";
        case SiufState.Approved:
            return "You will not be able to edit this SIUF. ";
        case SiufState.Published:
            return "You will not be able to edit this SIUF. You may terminate this SIUF if you no longer want it to be active.";
        case SiufState.Terminated:
            return "You will not be able to edit this SIUF. You may reactivate this SIUF if you want to create the next version of this SIUF";
        case SiufState.Draft:
        default:
            return "";
    }
};

export const GetDisplayNameForSiufLifeTimes = (siufLifeTime: SiufLifeTimeOption) => {
    switch (siufLifeTime) {
        case SiufLifeTimeOption._30Days:
            return "30 Days";
        case SiufLifeTimeOption._90Days:
            return "90 Days";
        case SiufLifeTimeOption._180Days:
            return "180 Days";
        case SiufLifeTimeOption._365Days:
            return "365 Days (NPS only)";
        case SiufLifeTimeOption._730Days:
            return "730 Days";
        default:
            return "[Value not Recognized]";
    }
};

export const GetDisplayNameForSiufDeviceTypes = (deviceType: SiufDeviceType) => {
    switch (deviceType) {
        case SiufDeviceType.Desktop:
            return "Desktop";
        case SiufDeviceType.Mobile:
            return "Mobile";
        case SiufDeviceType.Holographic:
            return "Holographic";
        case SiufDeviceType.TeamOS:
            return "Team OS (Surface Hub)";
        case SiufDeviceType.Xbox:
            return "XBox";
        case SiufDeviceType.Core:
            return "Core";
        case SiufDeviceType.Andromeda:
            return "Andromeda (DEPRECATED, use Core for Windows Core devices)";
        default:
            return "[Device Type not Recognized]";
    }
};

export const GetDisplayNameForSiufTargeting = (targetType: SiufTargetType) => {
    switch (targetType) {
        case SiufTargetType.AudienceId:
            return "Audience";
        case SiufTargetType.CustomGroups:
            return "Custom Groups";
        case SiufTargetType.DeviceIdUri:
            return "Device Id Uri";
        case SiufTargetType.Rings:
            return "Rings";
        case SiufTargetType.RingChannels:
            return "RingChannels";
        case SiufTargetType.RetailRecommendedSampling:
            return "Retail Rings (Recommended Sampling)";
        case SiufTargetType.AllRingsAndAllDevices:
            return "All Rings and All Devices";
        default:
            return "[Value not Recognized]";
    }
};

export const GetDisplayNameForSiufRings = (siufRing: SiufRing) => {
    switch (siufRing) {
        case SiufRing.Canary:
            return "Canary";
        case SiufRing.MSIT:
            return "MSIT";
        case SiufRing.NOT_SET:
            return "NOT_SET";
        case SiufRing.OSG:
            return "OSG";
        case SiufRing.RP:
            return "RP";
        case SiufRing.Retail:
            return "Retail";
        case SiufRing.WIF:
            return "WIF";
        case SiufRing.WIS:
            return "WIS";
        default:
            return "[Value not Recognized]";
    }
};

export const GetDisplayNameForSiufRingChannels = (siufRingChannel: SiufRingChannel) => {
    switch (siufRingChannel) {
        case SiufRingChannel.Canary:
            return "Canary";
        case SiufRingChannel.MSIT:
            return "MSIT";
        case SiufRingChannel.NOT_SET:
            return "NOT_SET";
        case SiufRingChannel.OSG:
            return "OSG";
        case SiufRingChannel.RP:
            return "RP";
        case SiufRingChannel.Retail:
            return "Retail";
        case SiufRingChannel.WIF:
            return "WIF";
        case SiufRingChannel.WIS:
            return "WIS";
        case SiufRingChannel.Dev:
            return "Dev";
        case SiufRingChannel.Dev_Internal:
            return "Dev_Internal";
        case SiufRingChannel.Dev_External:
            return "Dev_External";
        case SiufRingChannel.Beta:
            return "Beta";
        case SiufRingChannel.Beta_Internal:
            return "Beta_Internal";
        case SiufRingChannel.Beta_External:
            return "Beta_External";
        case SiufRingChannel.Feature_Preview:
            return "Feature_Preview";
        case SiufRingChannel.Feature_Preview_Internal:
            return "Feature_Preview_Internal";
        case SiufRingChannel.Feature_Preview_External:
            return "Feature_Preview_External";
        case SiufRingChannel.Release_Preview:
            return "Release_Preview";
        case SiufRingChannel.Release_Preview_Internal:
            return "Release_Preview_Internal";
        case SiufRingChannel.Release_Preview_External:
            return "Release_Preview_External"
        default:
            return "[Value not Recognized]";
    }
};

export const GetDisplayNameForSiufTriggeringType = (triggerType: SiufTriggeringType) => {
    switch (triggerType) {
        case SiufTriggeringType.StateMachine:
            return "State Machine";
        case SiufTriggeringType.URI:
            return "Uri Link";
        default:
            return "[Value not Recognized]";
    }
};

export const GetDisplayNameForSiufSubmitOperation = (submitOperation: SubmitOperation) => {
    switch (submitOperation) {
        case SubmitOperation.createSiuf:
            return "Creating a new SIUF";
        case SubmitOperation.loadSiuf:
            return "Loading the SIUF";
        case SubmitOperation.reactivateSiuf:
            return "Reactivating the SIUF";
        case SubmitOperation.saveSiuf:
            return "Saving the SIUF";
        case SubmitOperation.sideloadSiuf:
            return "Sideloading the SIUF";
        case SubmitOperation.submitSiufForReview:
            return "Submiting the SIUF for review";
        case SubmitOperation.terminateSiuf:
            return "Terminating the SIUF";
        case SubmitOperation.validateSiuf:
            return "Validating the SIUF";
        case SubmitOperation.withdrawSiufFromReview:
            return "Withdrawing the SIUF from review";
        case SubmitOperation.none:
            return "[No operation]";
        default:
            return "[Value not Recognized]";
    }
};

export const GetDisplayNameForLikertResponseTypes = (likertResponseType: LikertResponseType) => {
    switch (likertResponseType) {
        case LikertResponseType.Alphanumeric:
            return "Alphanumeric";
        case LikertResponseType.Numeric:
            return "Numeric";
        default:
            return "[Responses Type not Recognized]";
    }
};

export const GetDisplayNameForLikertResponseCounts = (likertResponseCount: LikertResponseCount) => {
    switch (likertResponseCount) {
        case LikertResponseCount.Three:
            return "Three";
        case LikertResponseCount.Five:
            return "Five";
        case LikertResponseCount.Ten:
            return "Ten";
        case LikertResponseCount.Custom:
            return "Custom";
        default:
            return "[Responses Count not Recognized]";
    }
};
