import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import FreeformTextPreview from '../../../components/authoring/questionPreview/FreeformTextPreview';
import { IApplicationRootReducerState } from "../../../configureStore";
import { IQuestionEntity } from '../../../contracts/models';

type Props = {
    questionModel: IQuestionEntity
};

const mapStateToProps = (state: IApplicationRootReducerState, ownProps: Props) => ({
    questionStructure: ownProps.questionModel.QuestionStructure,
    questionText: ownProps.questionModel.QuestionText
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FreeformTextPreview);
