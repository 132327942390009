import { Configuration, RedirectRequest } from "@azure/msal-browser";

// Default Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: "d59234bb-04f4-40f2-85a0-1db6eb593d8c",
        authority: "https://login.windows.net/72f988bf-86f1-41af-91ab-2d7cd011db47",
        redirectUri: "/",
        postLogoutRedirectUri: "/"
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: true
    },
    system: {
        allowRedirectInIframe: false
    }
};

// scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: RedirectRequest = {
    scopes: ["User.Read"],
};

// The endpoints for MS Graph API services
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};

