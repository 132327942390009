export enum ReviewApprovalActionTypes {

    // Ajax related actions types
    FETCH_REVIEW_SIUFS_SENT = '@@SIUF/REVIEWAPPROVAL/FETCH_REVIEW_SIUFS_SENT',
    FETCH_REVIEW_SIUFS_SUCCESS = '@@SIUF/REVIEWAPPROVAL/FETCH_REVIEW_SIUFS_SUCCESS',
    FETCH_REVIEW_SIUFS_FAIL = '@@SIUF/REVIEWAPPROVAL/FETCH_REVIEW_SIUFS_FAIL',

    REVIEW_APPROVE_SIUFS_SENT = '@@SIUF/REVIEWAPPROVAL/REVIEW_APPROVE_SIUFS_SENT',
    REVIEW_APPROVE_SIUFS_SUCCESS = '@@SIUF/REVIEWAPPROVAL/REVIEW_APPROVE_SIUFS_SUCCESS',
    REVIEW_APPROVE_SIUFS_FAIL = '@@SIUF/REVIEWAPPROVAL/REVIEW_APPROVE_SIUFS_FAIL',

    REVIEW_REJECT_SIUFS_SENT = '@@SIUF/REVIEWAPPROVAL/REVIEW_REJECT_SIUFS_SENT',
    REVIEW_REJECT_SIUFS_SUCCESS = '@@SIUF/REVIEWAPPROVAL/REVIEW_REJECT_SIUFS_SUCCESS',
    REVIEW_REJECT_SIUFS_FAIL = '@@SIUF/REVIEWAPPROVAL/REVIEW_REJECT_SIUFS_FAIL',

    // normal action types
}