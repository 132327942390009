/* tslint:disable:no-duplicate-imports class-name semicolon interface-name switch-default switch-final-break radix*/

import * as React from 'react';
import { Button, Table } from 'semantic-ui-react';
import { ISectionUiState, State, Transition, TransitionFilter, TriggerEvent, TriggerFilter } from '../../../../contracts';
import { GetTriggerEventDisplayName } from '../../../../utils';
import ConditionsPanelView from '../../../../containers/authoring/stateMachine/transitions/ConditionsPanelView';
import DetailsPanelView from '../../../../containers/authoring/stateMachine/transitions/DetailsPanelView';

interface TransistionsContainerProps {
    states: State[],
    transitions: Transition[],
    triggerFilters: TriggerFilter[],
    triggerEvents: TriggerEvent[],
    transitionFilters: TransitionFilter[],
    uiState: ISectionUiState;
    addTransition (fromStateId: string): string;
    removeTransition (transitionId: string): void;
    removeState (stateId: string): void;
    addState (): void;
}

enum SelectionMode {
    Conditions,
    Details
}

interface TransitionsContainerState {
    selectedTransitionId: string;
    selectionMode: SelectionMode;
}

export default class TransitionsContainer extends React.Component<TransistionsContainerProps, TransitionsContainerState> {
    constructor (props: TransistionsContainerProps) {
        super(props);
        this.state = {
            selectedTransitionId: null,
            selectionMode: null
        }
    }

    public render () {
        return (
            <div>
                <h2><Button primary onClick={ () => this.handleAddStateClick() } disabled={ this.props.uiState.isReadOnly } content="Add State" title='Add State' tabIndex='7' /></h2>
                <Table definition>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell></Table.HeaderCell>
                            <Table.HeaderCell>To State</Table.HeaderCell>
                            <Table.HeaderCell>Event</Table.HeaderCell>
                            <Table.HeaderCell>Provider</Table.HeaderCell>
                            <Table.HeaderCell>Conditions</Table.HeaderCell>
                            <Table.HeaderCell>Actions</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        { this.renderTableBodyRows() }
                    </Table.Body>
                </Table>
            </div>
        );
    }

    private renderTableBodyRows = () => {
        let tableBodyRows: JSX.Element[] = [];
        this.props.states.forEach(state => {
            tableBodyRows.push(this.renderTableBodyRowState(state));
            this.props.transitions
                .filter(x => x.FromStateId === state.StateId)
                .forEach(transition => {
                    tableBodyRows.push(this.renderTableBodyRowTransition(transition));
                    if (this.state.selectedTransitionId === transition.TransitionId) {
                        switch (this.state.selectionMode) {
                            case SelectionMode.Conditions:
                                tableBodyRows.push(this.renderTableBodyRowTransitionConditionsDetail(transition));
                                break;
                            case SelectionMode.Details:
                                tableBodyRows.push(this.renderTableBodyRowTransitionDetailsPanel(transition));
                                break;
                        }

                    }
                });
        });
        return tableBodyRows;
    }

    private renderTableBodyRowState = (state: State) => {
        return (
            <Table.Row key={ `state+${state.StateId}` } active>
                <Table.Cell>{ state.StateName }</Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell>
                    <React.Fragment>
                        {
                            (state.StateName === '_start' || state.StateName === '_complete' || state.StateName === '_cancel')
                                ? null
                                : <Button disabled={ this.props.uiState.isReadOnly } negative onClick={ () => this.handleDeleteStateClick(state.StateId) } content="Delete" size="mini" title='Delete' tabIndex='7' />
                        }
                    </React.Fragment>
                    <React.Fragment>
                        {
                            (state.StateName === '_complete' || state.StateName === '_cancel')
                                ? null
                                : <Button disabled={ this.props.uiState.isReadOnly } positive onClick={ () => this.handleAddTransitionClick(state.StateId) } content="Insert Transition" size="mini" title='Insert Transition' tabIndex='7' />
                        }
                    </React.Fragment>
                </Table.Cell>
            </Table.Row>
        );
    }

    private handleAddTransitionClick = (fromStateId: string) => {
        let newTransitionId = this.props.addTransition(fromStateId);
        this.setState({
            selectedTransitionId: newTransitionId,
            selectionMode: SelectionMode.Details
        });
    }

    private handleDeleteStateClick = (stateId: string) => {
        this.props.removeState(stateId);
    }

    private handleAddStateClick = () => {
        this.props.addState();
    }

    private renderTableBodyRowTransition = (transition: Transition) => {
        let triggerEvent = this.props.triggerEvents.find(x => x.TriggerEventId === transition.TriggerEventId);
        let toStateLabel = this.props.states.find(x => x.StateId === transition.ToStateId).StateName;
        let transitionFilters = this.props.transitionFilters.filter(x => x.TransitionId === transition.TransitionId);
        let transitionFilterCount = transitionFilters.length;

        let eventLabel = GetTriggerEventDisplayName(triggerEvent);
        let providerLabel;
        if (triggerEvent) {
            switch (triggerEvent.Type) {
                case 'manifestEtw':
                    providerLabel = triggerEvent.ProviderGuid;
                    break;
                case 'time':
                    providerLabel = '';
                    break;
                case 'traceLogging':
                    providerLabel = triggerEvent.ProviderGuid;
                    break;
            }
        } else {
            providerLabel = '';
        }
        let hasDirty = false;
        transitionFilters.forEach(transitionFilter => {
            let triggerFilter = this.props.triggerFilters.find(tf => tf.TriggerFilterId === transitionFilter.TriggerFilterId);
            hasDirty = hasDirty || triggerFilter.IsDirty;
        });
        return (
            <Table.Row key={ 'transition+' + transition.TransitionId }>
                <Table.Cell rowSpan={ transition.TransitionId === this.state.selectedTransitionId ? 2 : 1 } onClick={ () => this.handleSelectionClick(
                    transition.TransitionId,
                    SelectionMode.Details) }>
                    <Button primary icon="tasks" size="mini" content={ transition.TransitionName }
                        title={ transition.TransitionName }
                        tabIndex='7'
                        onClick={ () => this.handleSelectionClick(
                            transition.TransitionId,
                            SelectionMode.Details) } />
                </Table.Cell>
                <Table.Cell>{ toStateLabel }</Table.Cell>
                <Table.Cell>{ eventLabel }</Table.Cell>
                <Table.Cell>{ providerLabel }</Table.Cell>
                <Table.Cell>
                    { transitionFilterCount + ' filter' + (transitionFilterCount !== 1 ? 's' : '') }
                </Table.Cell>
                <Table.Cell>
                    <Button negative onClick={ () => this.handleRemoveTransitionClick(transition.TransitionId) } disabled={ this.props.uiState.isReadOnly } content="Delete" title='Delete' tabIndex='7' />
                </Table.Cell>
            </Table.Row>
        );
    }

    private handleSelectionClick = (selectedTransitionId: string, selectionMode: SelectionMode) => {
        if (this.state.selectedTransitionId === selectedTransitionId && this.state.selectionMode === selectionMode) {
            this.setState({
                selectedTransitionId: null,
                selectionMode: null
            });
        }
        else {
            this.setState({
                selectedTransitionId,
                selectionMode
            });
        }
    };

    private handleRemoveTransitionClick = (transitionId: string) => {
        this.props.removeTransition(transitionId);
    };

    private renderTableBodyRowTransitionConditionsDetail = (transition: Transition) => {
        let transitionFilters = this.props.transitionFilters.filter(x => x.TransitionId === transition.TransitionId);
        let triggerFilters = this.props.triggerFilters.filter(x => transitionFilters.find(y => y.TriggerFilterId === x.TriggerFilterId));
        return (
            <Table.Row key={ 'conditions+' + transition.TransitionId }>
                <Table.Cell style={ { display: 'none' } }></Table.Cell>
                <Table.Cell style={ { display: 'none' } }></Table.Cell>
                <Table.Cell colSpan={ 7 }>
                    <ConditionsPanelView uiState={ this.props.uiState } transitionId={ transition.TransitionId } transitionFilters={ transitionFilters } triggerFilters={ triggerFilters } />
                </Table.Cell>
            </Table.Row>
        );
    }

    private renderTableBodyRowTransitionDetailsPanel = (transition: Transition) => {
        let transitionFilters = this.props.transitionFilters.filter(x => x.TransitionId === transition.TransitionId);
        let triggerFilters = this.props.triggerFilters.filter(x => transitionFilters.find(y => y.TriggerFilterId === x.TriggerFilterId));
        return <Table.Row key={ 'details+' + transition.TransitionId }>
            <Table.Cell style={ { display: 'none' } }></Table.Cell>
            <Table.Cell style={ { display: 'none' } }></Table.Cell>
            <Table.Cell colSpan={ 7 }>
                <DetailsPanelView
                    uiState={ this.props.uiState }
                    transition={ transition }
                    states={ this.props.states }
                    triggerEvents={ this.props.triggerEvents } />
                <ConditionsPanelView uiState={ this.props.uiState } transitionId={ transition.TransitionId } transitionFilters={ transitionFilters } triggerFilters={ triggerFilters } />
            </Table.Cell>
        </Table.Row>;
    }
}