import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import * as siufAuthoringActions from '../../../actions';
import TargetingProperties from '../../../components/authoring/targeting/TargetingProperties';
import { IApplicationRootReducerState } from "../../../configureStore";
import { IUpdateDeviceFilterModel, SiufDeviceType, SiufTargetRingSelection, SiufTargetRingChannelSelection, SiufRing, SiufRingChannel, SiufTargetType } from '../../../contracts/models';
import * as selectors from '../../../selectors';

type Props = {
};

const mapStateToProps = (state: IApplicationRootReducerState, ownProps: Props) => ({
    targetingModel: selectors.getSiufTargetingEntity(state),
    toastText: selectors.getSiufToastText(state),
    uiState: selectors.getSiufTargetingUiState(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    onUpdateDeviceType: (deviceType: SiufDeviceType) => dispatch(siufAuthoringActions.updateDeviceType(deviceType)),
    onUpdateMinBuild: (minBuild: number) => dispatch(siufAuthoringActions.updateMinBuild(minBuild)),
    onUpdateMaxBuild: (maxBuild: number) => dispatch(siufAuthoringActions.updateMaxBuild(maxBuild)),
    onUpdateTargetingType: (targetingType: SiufTargetType) => dispatch(siufAuthoringActions.updateTargetingType(targetingType)),
    onUpdateTargetingRing: (ringSelection: SiufTargetRingSelection, siufRings?: SiufRing[]) => dispatch(siufAuthoringActions.updateTargetingRing(ringSelection, siufRings)),
    onUpdateTargetingRingChannel: (ringChannelSelection: SiufTargetRingChannelSelection, siufRingChannels?: SiufRingChannel[]) => dispatch(siufAuthoringActions.updateTargetingRingChannel(ringChannelSelection, siufRingChannels)),
    onUpdateAudience: (audienceName: string) => dispatch(siufAuthoringActions.updateAudienceName(audienceName)),
    onUpdateDeviceIdUri: (deviceIdUri: string) => dispatch(siufAuthoringActions.updateDeviceIdUri(deviceIdUri)),
    onUpdateCustomGroupIds: (customGroupIds: string[]) => dispatch(siufAuthoringActions.updateCustomGroupIds(customGroupIds)),
    onUpdateIncludeTestSystem: (includeTestSystem: boolean) => dispatch(siufAuthoringActions.updateTestSystemFilter(includeTestSystem)),
    onAddDeviceFilter: () => dispatch(siufAuthoringActions.addDeviceFilter()),
    onRemoveDeviceFilter: (index: number) => dispatch(siufAuthoringActions.removeDeviceFilter(index)),
    onUpdateDeviceFilter: (index: number, deviceFilter: IUpdateDeviceFilterModel) => dispatch(siufAuthoringActions.updateDeviceFilter(index, deviceFilter)),
    onUpdateToastText: (toastText: string) => dispatch(siufAuthoringActions.updateToastText(toastText))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TargetingProperties);