import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Preview from '../../../components/authoring/questionPreview/Preview';
import { IApplicationRootReducerState } from "../../../configureStore";
import * as selectors from '../../../selectors';
import * as siufAuthoringActions from '../../../actions';

type Props = {
};

const mapStateToProps = (state: IApplicationRootReducerState, ownProps: Props) => ({
    siufType: selectors.getSiufTypeSelection(state),
    pageCollectionModel: selectors.getSiufPagesSorted(state),
    title: selectors.getSiufQuestionsTitle(state),
    ignoreTitleOnToast: selectors.getSiufIgnoreTitleOnToast(state),
    uiState: selectors.getSiufPreviewUiState(state),
    activePreviewPage: selectors.getSiufActivePreviewPage(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    onChangePreviewPage: (pageIndex: string | number) => dispatch(siufAuthoringActions.changePreviewPage(pageIndex)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Preview);
