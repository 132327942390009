import * as React from 'react';
import { Divider, Form } from 'semantic-ui-react';
import { SiufQuestionStructure } from '../../../contracts/models';

type Props = {
    questionStructure: SiufQuestionStructure;
    checkboxTexts: string[];
    questionText: string;
    followupQuestion?: string;
};

const CheckboxPreview: React.FC<Props> = props => {

    let checkBoxes = props.checkboxTexts.filter((c, i) => i !== 0).map((cb, ind) => {
        return <Form.Checkbox
            key={ ind }
            label={ cb || `<Response ${ind + 1} Text>` }
            value={ cb }
        />;
    });

    return (
        <React.Fragment>
            <Form widths='equal'>
                <h3>{ props.questionText || '<Question Text>' }</h3>
                <Divider hidden />
                <React.Fragment>
                    { checkBoxes }
                    <Form.Checkbox
                        label={ props.checkboxTexts[0] }
                        value={ props.checkboxTexts[0] }
                        title='Question Text'
                        tabIndex='6'
                    />
                </React.Fragment>
                {
                    (props.questionStructure === SiufQuestionStructure.SingleQuestion)
                    &&
                    (props.followupQuestion)
                    &&
                    <React.Fragment>
                        <h3>{ props.followupQuestion }</h3>
                        <Form.TextArea placeholder='Enter your feedback here...' tabIndex='6' />
                    </React.Fragment>
                }
            </Form>
        </React.Fragment>
    );
};

export default CheckboxPreview;