import { push } from "connected-react-router";

// Note: In order to execute the navigation, it must be dispatched by the Redux dispatch form the store
// Containers and async action creators both have access to dispatch

export default class Navigation {

    // Routes

    public static Route_HomePage = `/`;
    public static Route_NotFoundPage = `/notfound`;
    public static Route_AuthoringPage = `/authoring/siufs`;
    public static Route_NewAuthoringPage = `/authoring/siufs/new`;
    public static Route_ExistingAuthoringPage = (siufId: number) => `/authoring/siufs/${siufId}`;
    public static Route_ReviewsPage = `/reviewing/siufs`;

    // Navigation actions

    public static NavigateTo_Page = (route: string) => push(route);
    public static NavigateTo_NotFoundPage = () => push(Navigation.Route_NotFoundPage);
    public static NavigateTo_HomePage = () => push(Navigation.Route_HomePage);
    public static NavigateTo_NewSiufPage = () => push(Navigation.Route_NewAuthoringPage);
    public static NavigateTo_ExistingSiufPage = (siufId: number) => push(Navigation.Route_ExistingAuthoringPage(siufId));
    public static NavigateTo_ReviewsPage = () => push(Navigation.Route_ReviewsPage);

}
