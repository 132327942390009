
// STATE MACHINE related  Do not modify this file unless it is related to state machines

/* tslint:disable:class-name semicolon interface-name */

export enum TriggerFilterOperation {
    Equals,
    NotEquals,
    GreaterThan,
    LessThan,
    GreaterThanOrEqualTo,
    LessThanOrEqualTo,
    Like,
    NotLike,
    Regex,
    NotRegex
}

export enum TriggerFilterOperationDescription {
    Equals = '==',
    NotEquals = '!=',
    GreaterThan = '>',
    LessThan = '<',
    GreaterThanOrEqualTo = '>=',
    LessThanOrEqualTo = '<=',
    Like = 'Like',
    NotLike = 'Not Like',
    Regex = 'Regex',
    NotRegex = 'Not Regex'
}

export enum Operation {
    Add,
    Subtract,
    Multiply,
    Divide
}

export enum SystemProperty {
    TID,
    PID,
    TimeStamp,
    AID,
    OpCode,
    AppId,
    AppVer,
    BootId,
    AppSessionId
}

export enum ScenarioMetadataProperty {
    TimeSinceInstall = '0',
    LastBootTime = '1',
    IsACOn = '2',
    IsKernelDebuggerPresent = '3',
    LastPowerSourceChangeTime = '4',
    LastSuccessfulRefreshTime = '5',
    LastApplicationInstallTime = '6',
    LastUnlockTime = '7',
    LastLogonTime = '8',
    LastDeviceArrivalOrRemovalTime = '9',
    LastResumeFromHibernationTime = '10',
    LastResumeFromStandbyTime = '11',
    IsEscalationInProgress = '12',
    IsContinuumSessionActive = '13'
}

export interface StateMachineModel {
    StateMachineId?: number;
    StateMachineName: string;
    States: State[];
    TriggerEvents: TriggerEvent[];
    TriggerFilters: TriggerFilter[];
    Transitions: Transition[];
    TransitionFilters: TransitionFilter[];
    AllowBranching: boolean;
};

export const StateMachineModel_Default: StateMachineModel = {
    StateMachineId: -1,
    StateMachineName: 'New Model',
    States: [{ StateId: "0", StateName: "_start" }, { StateId: "1", StateName: "_complete" }, { StateId: "2", StateName: "_cancel" }],
    TransitionFilters: [],
    Transitions: [],
    TriggerEvents: [],
    TriggerFilters: [],
    AllowBranching: false
};

// States
export interface State {
    StateId: string;
    StateName: string;
}

// Trigger events
interface TriggerEventBase {
    Type: string;
    TriggerEventId: string;
}
export interface ManifestEtwTriggerEvent extends TriggerEventBase {
    Type: 'manifestEtw';
    EventName: string;
    ProviderGuid: string;
    EventId: number;
    EventVersion: number;
    Keyword: string;
    Level: number;
}
export interface TraceLoggingTriggerEvent extends TriggerEventBase {
    Type: 'traceLogging';
    EventName: string;
    ProviderGuid: string;
    Keyword: string;
    Level: number;
}
export interface TimeTriggerEvent extends TriggerEventBase {
    Type: 'time';
    DurationMs: number;
    AllowCoalescing: boolean;
}

export type TriggerEvent = ManifestEtwTriggerEvent | TraceLoggingTriggerEvent | TimeTriggerEvent;

// Trigger filters
interface TriggerFilterBase {
    Type: string;
    TriggerFilterId: string;
    FilterName: string;
    ApplyToEscalations: boolean;
    Operation: TriggerFilterOperation;
    IsDirty: boolean;
}
export interface DualTriggerFilter extends TriggerFilterBase {
    Type: 'dual';
    Event1: TriggerFilterEvent;
    Event2: TriggerFilterEvent;
}

export interface SingleTriggerFilter extends TriggerFilterBase {
    Type: 'single';
    Event: TriggerFilterEvent;
    Operand: string;
}

export type TriggerFilter = DualTriggerFilter | SingleTriggerFilter;

// Trigger filters - event types
interface TriggerFilterEventBase {
    Type: string;
    Bitmask?: number;
    ConstantOperation?: ConstantOperation;
    StrictTransition: boolean;
}
interface TriggerEventTriggerFilterEvent extends TriggerFilterEventBase {
    Type: 'triggerEvent';
    TriggerEventId: string;
    Property: TriggerEventProperty;
}

export interface ScenarioMetatadataTriggerFilterEvent extends TriggerFilterEventBase {
    Type: 'scenarioMetadata';
    Property: ScenarioMetadataProperty;
}

export type TriggerFilterEvent = TriggerEventTriggerFilterEvent | ScenarioMetatadataTriggerFilterEvent;

export interface ConstantOperation {
    Operation: Operation,
    Operand: string;
}

interface TriggerEventPropertyBase {
    Type: string;
}

export interface EventPropertyTriggerEventProperty extends TriggerEventPropertyBase {
    Type: 'eventProperty';
    Property: string;
}

export interface SystemPropertyTriggerEventProperty extends TriggerEventPropertyBase {
    Type: 'systemProperty';
    Property: SystemProperty;
}

export type TriggerEventProperty = EventPropertyTriggerEventProperty | SystemPropertyTriggerEventProperty;

// Transitions
export interface Transition {
    TransitionId: string;
    TransitionName: string;
    FromStateId: string;
    ToStateId: string;
    TriggerEventId: string;
    UploadTrigger: boolean;
    EventSource: TransitionEventSource;
};

export enum TransitionEventSource {
    Host = 0,
    Agent = 1,
    Any = 2
};

// Transition filters
export interface TransitionFilter {
    TransitionFilterId: string;
    TransitionId: string;
    TriggerFilterId: string;
    IsStrict: boolean;
};

export interface IUpdateTriggerFilterModel {
    TriggerFilterId: string;
    Type?: TriggerFilter["Type"];
    FilterName?: string;
    ApplyToEscalations?: boolean;
    Operand?: string;
    Operation?: TriggerFilterOperation;
    Script?: string;
}

export interface IUpdateTriggerFilterEventModel {
    TriggerFilterId: string;
    Type?: TriggerFilterEvent["Type"];
    FilterName?: string;
    ApplyToEscalations?: boolean;
    Operation?: TriggerFilterOperation;
    IsSecondEvent?: boolean;
    PropertyType?: EventPropertyTriggerEventProperty["Type"] | SystemPropertyTriggerEventProperty["Type"];
    Property?: ScenarioMetadataProperty;
    TriggerEventId?: string;
    Bitmask?: number;
    ConstantOperation?: Operation;
    ConstantOperand?: string;
    StrictTransition?: boolean;
}

export interface IUpdateTransitionFilterReferenceModel {
    TransitionFilterId: string;
    TriggerFilterId: string;
}

export interface IUpdateTransitionFilterStrictModel {
    TransitionFilterId: string;
    Strict: boolean;
}

export interface IAddTransitionModel {
    TransitionId: string;
    FromStateId?: string;
}

export interface IUpdateTransitionModel {
    TransitionId: string;
    FromStateId?: string;
    ToStateId?: string;
    TriggerEventId?: string;
    UploadTrigger?: boolean;
    EventSource?: TransitionEventSource;
}

export interface IUpdateTriggerEventModel {
    TriggerEventId: string;
    Type?: TriggerEvent["Type"];
    DurationMs?: number;
    AllowCoalescing?: boolean;
    EventName?: string;
    ProviderGuid?: string;
    Keyword?: number;
    Level?: number;
    EventId?: number;
    EventVersion?: number;
}
