import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import * as ActionCreators from '../../../../actions/authoring/stateMachine/actions';
import TriggerEventPanel from '../../../../components/authoring/stateMachine/events/TriggerEventPanel';
import { IApplicationRootReducerState } from "../../../../configureStore";
import { IUpdateTriggerEventModel } from '../../../../contracts/states/stateMachineStates';

const mapStateToProps = (state: IApplicationRootReducerState) => ({});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
    updateTriggerEvent: (triggerEventChanges: IUpdateTriggerEventModel) => dispatch(ActionCreators.updateStateMachineTriggerEvent(triggerEventChanges))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(TriggerEventPanel);