import * as React from 'react';
import { Form } from 'semantic-ui-react';
import { ISiufReviewInfo, ISiufReviewSearchProperty, SiufState } from '../../../contracts/models';
import { ISectionUiState } from '../../../contracts/states/uiStates';
import { GetSiufStateTypes } from '../../../utils/dataUtils';

type Props = {
    siufCollection: ISiufReviewInfo[];
    searchProperties: ISiufReviewSearchProperty;
    uiState: ISectionUiState;
    onUpdateSearchProperty: (searchProperties: ISiufReviewSearchProperty) => void;
};

export default class SearchReview extends React.Component<Props> {

    constructor (props: Props) {
        super(props);

        this.props.onUpdateSearchProperty.bind(this);
    }

    public render () {

        return (
            <React.Fragment>
                <Form>
                    <Form.Group widths='equal'>
                        <Form.Field label='SIUF Id' required
                            control='input' type='number'
                            defaultValue={ this.props.searchProperties.siufId || undefined }
                            disabled={ this.props.uiState.isReadOnly }
                            placeholder='Enter the SIUF ID'
                            onChange={ (e: any) => this.props.onUpdateSearchProperty({
                                ...this.props.searchProperties,
                                siufId: Number(e.currentTarget.value)
                            }) } />
                        <Form.Input fluid label='SIUF name' required defaultValue={ this.props.searchProperties.siufName }
                            disabled={ this.props.uiState.isReadOnly }
                            placeholder='Enter the SIUF name'
                            onChange={ e => {
                                this.props.onUpdateSearchProperty({
                                    ...this.props.searchProperties,
                                    siufName: e.currentTarget.value
                                });
                            } } />
                    </Form.Group>
                    <Form.Group inline widths='equal'>
                        <Form.Input fluid label='Scenario Id' required defaultValue={ this.props.searchProperties.scenarioId }
                            disabled={ this.props.uiState.isReadOnly }
                            placeholder='Enter the scenario Id'
                            onChange={ e => {
                                this.props.onUpdateSearchProperty({
                                    ...this.props.searchProperties,
                                    scenarioId: e.currentTarget.value
                                });
                            } } />
                        <Form.Input fluid label='Owner Alias' required defaultValue={ this.props.searchProperties.ownerAlias }
                            disabled={ this.props.uiState.isReadOnly }
                            placeholder='Enter the owner alias'
                            onChange={ e => {
                                this.props.onUpdateSearchProperty({
                                    ...this.props.searchProperties,
                                    ownerAlias: e.currentTarget.value
                                });
                            } } />
                    </Form.Group>
                    <Form.Group inline widths='equal'>
                        <Form.Select fluid value={ this.props.searchProperties.state }
                            label='SIUF state'
                            title='SIUF state'
                            multiple
                            options={
                                GetSiufStateTypes().map(dt => {
                                    return {
                                        key: dt.type,
                                        value: dt.type,
                                        text: dt.displayName
                                    };
                                })
                            }
                            placeholder='Select SIUF state'
                            disabled={ this.props.uiState.isReadOnly }
                            onChange={ (event, data) => {
                                const state = data.value as SiufState[];
                                this.props.onUpdateSearchProperty({
                                    ...this.props.searchProperties,
                                    state: state
                                });
                            } }
                        />
                    </Form.Group>
                </Form>
            </React.Fragment>
        );
    }
}