import * as React from 'react';
import { Button, Header, Input, Label, Segment, Tab, MenuItem, Confirm, Checkbox } from 'semantic-ui-react';
import QuestionView from '../../../containers/authoring/questionDetails/QuestionView';
import { IPageEntity, MAXIMUM_PAGE_COUNT, SiufTypeSelection } from '../../../contracts/models';
import { ISectionUiState } from '../../../contracts/states';
import confirmService from '../../../containers/shared/ConfirmDialog/ConfirmDialogService';

type Props = {
    siufType: SiufTypeSelection;
    pageCollectionModel: IPageEntity[];
    activeEditPage: string | number;
    activePreviewPage: string | number;
    title?: string;
    ignoreTitleOnToast: boolean;
    uiState: ISectionUiState;
    onAddQuestion: (pageIndex: number) => void;
    onRemoveQuestion: (pageIndex: number, questionIndex: number, questionCount: number, activePreviewPage: number) => void;
    onUpdateNpsQuestion: (pageIndex: number, questionIndex: number, checked: boolean) => void;
    onUpdateTitle: (title: string) => void;
    onUpdateIgnoreTitleOnToast: (ignoreTitleOnToast: boolean) => void;
    onAddPage: (pageCount?: number) => void;
    onRemovePage: (pageIndex: number, activeEditPage: number, activePreviewPage: number) => void;
    onChangeEditPage: (pageIndex: string | number) => void;
};

const QuestionCollection: React.FC<Props> = props => {


    let questionView: JSX.Element | JSX.Element[];
    let questionHeader: JSX.Element = <React.Fragment />;
    let questionTitle: JSX.Element = <React.Fragment />;
    let questionControl: JSX.Element = <React.Fragment />;
    let pages: any[];
    if (props.siufType === SiufTypeSelection.singleQuestion) {
        if (props.pageCollectionModel[0]?.Questions?.length > 1) {
            questionHeader = <Header tabIndex={5} as='h3'>Even though this SIUF is suppose to be a single question SIUF, there appears to be multiple questions here. Please contact SIUF Admins for help.</Header>;
        }
        questionView = props.pageCollectionModel[0]?.Questions?.map((q, index) => {
            return <QuestionView questionModel={q} questionIndex={index} key={index} pageIndex={0}
                onRemoveQuestion={() => props.onRemoveQuestion(0, index, 1, +props.activePreviewPage)}
                onUpdateNpsQuestion={(checked: boolean) => props.onUpdateNpsQuestion(0, index, checked)}
                canDelete={!props.uiState.isReadOnly && (props.pageCollectionModel.length > 1 || props.pageCollectionModel[0].Questions.length > 1)} />;
        });
    } else if (props.siufType === SiufTypeSelection.multiQuestion) {
        questionTitle = (
            <React.Fragment>
                <div className='flex-div'>
                    <Header as='h4'>Enter a Title</Header>
                    <span className='aligned-checkbox'>
                        <Checkbox toggle label={"Ignore on Toast"} checked={props.ignoreTitleOnToast}
                            disabled={props.uiState.isReadOnly} onChange={(e, data) => props.onUpdateIgnoreTitleOnToast(data.checked)} />
                    </span>
                </div>
                <Input fluid tabIndex={5}
                    disabled={props.uiState.isReadOnly}
                    placeholder='Enter title here...'
                    value={props.title || ''}
                    onChange={e => props.onUpdateTitle(e.currentTarget.value)} />
            </React.Fragment>
        );
        questionView = props.pageCollectionModel[0]?.Questions?.map((q, index) => {
            return <QuestionView questionModel={q} questionIndex={index} key={index} pageIndex={0}
                onRemoveQuestion={() => props.onRemoveQuestion(0, index, props.pageCollectionModel[0]?.Questions.length, +props.activePreviewPage)}
                onUpdateNpsQuestion={(checked: boolean) => props.onUpdateNpsQuestion(0, index, checked)}
                canDelete={!props.uiState.isReadOnly && (props.pageCollectionModel.length > 1 || props.pageCollectionModel[0].Questions.length > 1)} />;
        });
        questionControl = (
            <React.Fragment>
                <Button tabIndex={5} positive onClick={() => props.onAddQuestion(0)}>
                    Add Question
                </Button>
                <Label tabIndex={5} basic size='large' color='purple'>Number of questions: {props.pageCollectionModel[0]?.Questions?.length}</Label>
                {props.pageCollectionModel.length < MAXIMUM_PAGE_COUNT &&
                    <Button className='new-chip-button' tabIndex={5} basic color='green' onClick={() => props.onAddPage(props.pageCollectionModel.length)}>
                        New page
                        <span className="new-chip">New</span>
                    </Button>
                }
            </React.Fragment>
        );
        pages = props.pageCollectionModel?.map((page, pageIndex) => {
            return {
                menuItem: (
                    <MenuItem tabIndex={5} key={pageIndex}>
                        <div className='tabHeaderLabel'>{"Page " + (pageIndex + 1)}</div>
                        {!props.uiState.isReadOnly && <Button className='tabHeaderButton' icon="close" basic compact negative color='red' tabIndex={5} onClick={async (e) => {
                            e.stopPropagation();
                            const result = await confirmService.show({
                                message: 'Removing this page will remove all questions on this page. Are you sure?',
                                confirmButton: 'Yes, Remove this page',
                                cancelButton: 'No, Keep this page',
                                size: 'tiny',
                                negative: true
                            });
                            if (result) {
                                props.onRemovePage(pageIndex, +props.activeEditPage, +props.activePreviewPage);
                            }
                        }} />}
                    </MenuItem>
                ),
                render: () =>
                    <Tab.Pane>
                        {page.Questions.map((q, index) => {
                            return <QuestionView questionModel={q} questionIndex={index} key={pageIndex + '-' + index} pageIndex={pageIndex}
                                onRemoveQuestion={async () => {
                                    if (page.Questions.length == 1) {
                                        const result = await confirmService.show({
                                            message: 'Deleting this question will remove this page. Are you sure?',
                                            confirmButton: 'Yes, Delete this question',
                                            cancelButton: 'No, Keep this question',
                                            size: 'tiny',
                                            negative: true
                                        });
                                        if (result) {
                                            props.onRemoveQuestion(pageIndex, index, page.Questions.length, +props.activePreviewPage)
                                        }
                                    } else {
                                        props.onRemoveQuestion(pageIndex, index, page.Questions.length, +props.activePreviewPage)
                                    }
                                }
                                }
                                canDelete={!props.uiState.isReadOnly && (props.pageCollectionModel.length > 1 || page.Questions.length > 1)}
                                onUpdateNpsQuestion={(checked: boolean) => props.onUpdateNpsQuestion(pageIndex, index, checked)} />
                        })
                        }
                        {!props.uiState.isReadOnly &&
                            <React.Fragment>
                                <Button tabIndex={5} positive onClick={() => props.onAddQuestion(pageIndex)}>
                                    Add Question
                                </Button>
                                <Label tabIndex={5} basic size='large' color='purple'>Number of questions: {page.Questions.length}</Label>
                                {props.pageCollectionModel.length < MAXIMUM_PAGE_COUNT &&
                                    <Button className='new-chip-button' tabIndex={5} basic color='green' onClick={() => props.onAddPage(props.pageCollectionModel.length)}>
                                        New page
                                    </Button>
                                }
                            </React.Fragment>
                        }
                    </Tab.Pane>
            }
        });
    } else {
        questionView = (
            <Header tabIndex={5} as='h3'>Please select the type of SIUF first</Header>
        );
    }

    return (
        <React.Fragment>
            {props.siufType === SiufTypeSelection.multiQuestion && <Segment>{questionTitle}</Segment>}
            {questionHeader}
            {pages && pages.length > 1 && <Tab panes={pages} menu={{ pointing: true, widths: pages.length }} activeIndex={props.activeEditPage}
                onTabChange={((event, data) => {
                    if (props.activeEditPage !== data.activeIndex && pages.length > +data.activeIndex) {
                        props.onChangeEditPage(data.activeIndex)
                    }
                })} />}
            {(!pages || pages.length <= 1) && questionView}
            {(!pages || pages.length <= 1) && !props.uiState.isReadOnly && questionControl}
        </React.Fragment>
    );
};

export default QuestionCollection;