import { connect } from 'react-redux';
import { IApplicationRootReducerState } from "../../../configureStore";
import { Dispatch } from 'redux';
import { SiufTypeSelection, IUpdateJustificationModel } from '../../../contracts/models';
import * as selectors from '../../../selectors';
import * as siufAuthoringActions from '../../../actions';
import SiufBasics from '../../../components/authoring/basics/SiufBasics';

type Props = {
};

const mapStateToProps = (state: IApplicationRootReducerState, ownProps: Props) => ({
    siufType: selectors.getSiufTypeSelection(state),
    siufName: selectors.getSiufName(state),
    ownerAlias: selectors.getSiufOwner(state),
    uiState: selectors.getSiufBasicsUiState(state),
    justifications: selectors.getSiufJustifications(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    onUpdateSiufTypeSelection: (siufType: SiufTypeSelection) => dispatch(siufAuthoringActions.updateSiufType(siufType)),
    onUpdateSiufName: (siufName: string) => dispatch(siufAuthoringActions.updateSiufName(siufName)),
    onUpdateOwnerAlias: (ownerAlias: string) => dispatch(siufAuthoringActions.updateSiufOwner(ownerAlias)),
    onUpdateJustifications: (justification: IUpdateJustificationModel) => dispatch(siufAuthoringActions.updateJustifications(justification))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SiufBasics);