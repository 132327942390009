import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import UserProfileService from "../userProfile";
import { IApplicationRootReducerState } from "../configureStore";
import Navigation from "../navigation/navigationActions";
import AppHeader from "../components/layout/AppHeader";


// https://react-redux.js.org/using-react-redux/connect-mapstate
const mapStateToProps = (state: IApplicationRootReducerState) => ({
    userName: UserProfileService.GetCurrentUserProfile() ? UserProfileService.GetCurrentUserProfile().DisplayName : "Not signed in"
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
    onNavigateToNewSiufPage: () => dispatch(Navigation.NavigateTo_NewSiufPage()),
    onNavigateToExistingSiufPage: (siufId: number) => dispatch(Navigation.NavigateTo_ExistingSiufPage(siufId)),
    onNavigateToReviewsPage: () => dispatch(Navigation.NavigateTo_ReviewsPage())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AppHeader);
