import * as React from 'react';
import { Form } from 'semantic-ui-react';
import { SiufFocus, SiufQuestionStructure } from '../../../contracts/models';
import { GetFivePointSiufFocusValues, GetProductNameFromSiufNpsQuestion, GetSiufNpsQuestion } from '../../../utils';

type Props = {
    isReadOnly: boolean,
    questionStructure: SiufQuestionStructure;
    questionText: string;
    questionFocus: SiufFocus;
    minAnchor: string;
    maxAnchor: string;
    ratings1FollowupQuestion?: string;
    ratings2FollowupQuestion?: string;
    ratings3FollowupQuestion?: string;
    ratings4FollowupQuestion?: string;
    ratings5FollowupQuestion?: string;
    onUpdateSiufFocus: (questionFocus: SiufFocus) => void;
    onUpdateQuestionText: (questionText: string) => void;
    onUpdateAnchorText: (minAnchor: string, maxAnchor: string) => void;
    onUpdateFollowQuestionText: (questionText: string, index: number) => void;
};

const FivePointRatingQuestion: React.FC<Props> = props => {

    const FivePointSiufFocusOptions = [
        { key: 'Accuracy', value: SiufFocus.Accuracy, text: 'Accuracy' },
        { key: 'Aesthetics', value: SiufFocus.Aesthetics, text: 'Aesthetics' },
        { key: 'Difficulty', value: SiufFocus.Difficulty, text: 'Difficulty' },
        { key: 'Duration', value: SiufFocus.Duration, text: 'Duration' },
        { key: 'Efficiency', value: SiufFocus.Efficiency, text: 'Efficiency' },
        { key: 'Frequency', value: SiufFocus.Frequency, text: 'Frequency' },
        { key: 'NetPromoter', value: SiufFocus.NetPromoter, text: 'NetPromoter' },
        { key: 'Satisfaction', value: SiufFocus.Satisfaction, text: 'Satisfaction' },
        { key: 'Usefulness', value: SiufFocus.Usefulness, text: 'Usefulness' },
        { key: 'Custom', value: SiufFocus.Custom, text: 'Custom' },
    ];

    const options = (props.questionStructure === SiufQuestionStructure.SingleQuestion)
        ? FivePointSiufFocusOptions
        : FivePointSiufFocusOptions.filter(o => o.value !== SiufFocus.NetPromoter);

    let questionText: JSX.Element;

    if (props.questionFocus === SiufFocus.NetPromoter) {
        questionText = (
            <Form.Input fluid value={ GetProductNameFromSiufNpsQuestion(props.questionText) }
                label='Product Name'
                readOnly={ props.isReadOnly }
                placeholder='E.g. Windows 10, Cortana, etc'
                required
                tabIndex='5'
                onChange={ e => props.onUpdateQuestionText(GetSiufNpsQuestion(e.currentTarget.value)) }
            />
        );
    } else {
        questionText = (
            <Form.Input fluid value={ props.questionText }
                label='Question Text'
                readOnly={ props.isReadOnly }
                placeholder='Enter your question here'
                required
                tabIndex='5'
                onChange={ e => props.onUpdateQuestionText(e.currentTarget.value) }
            />
        );
    }

    return (
        <React.Fragment>
            { questionText }
            <Form.Group widths='equal'>
                {
                    props.isReadOnly
                        ?
                        <Form.Input fluid value={ options.find(o => o.value === props.questionFocus)?.text } label='Question Focus'
                            readOnly={ true }
                            placeholder='Select question focus' required
                            tabIndex='5'
                        />
                        :
                        <Form.Select fluid value={ props.questionFocus }
                            label='Question Focus' options={ options }
                            placeholder='Select question focus'
                            disabled={ props.isReadOnly }
                            title='Question Focus'
                            tabIndex='5'
                            onChange={ (event, data) => {
                                const focus = data.value as SiufFocus;
                                if (focus !== props.questionFocus) {
                                    const focusInfo = GetFivePointSiufFocusValues(focus);
                                    props.onUpdateAnchorText(focusInfo.minAnchor, focusInfo.maxAnchor);
                                    if (focus === SiufFocus.NetPromoter) {
                                        props.onUpdateQuestionText(GetSiufNpsQuestion(props.questionText));
                                        if (props.questionStructure === SiufQuestionStructure.SingleQuestion) {
                                            for (let index = 0; index <= 4; index++) {
                                                props.onUpdateFollowQuestionText("Please explain why you gave this score.", index);
                                            }
                                        }
                                    } else {
                                        props.onUpdateQuestionText(props.questionText);
                                    }
                                    props.onUpdateSiufFocus(focus);
                                }
                            } }
                        />
                }
                <Form.Input fluid value={ props.minAnchor } label='Negative Anchor'
                    placeholder='Enter negative anchor here' required
                    readOnly={ props.isReadOnly }
                    tabIndex='5'
                    onChange={ e => {
                        props.onUpdateAnchorText(e.currentTarget.value as string, props.maxAnchor);
                        if (props.questionFocus !== SiufFocus.Custom) {
                            props.onUpdateSiufFocus(SiufFocus.Custom);
                        }
                    } }
                />
                <Form.Input fluid value={ props.maxAnchor } label='Positive Anchor'
                    placeholder='Enter positive anchor here' required
                    readOnly={ props.isReadOnly }
                    tabIndex='5'
                    onChange={ e => {
                        props.onUpdateAnchorText(props.minAnchor, e.currentTarget.value as string);
                        if (props.questionFocus !== SiufFocus.Custom) {
                            props.onUpdateSiufFocus(SiufFocus.Custom);
                        }
                    } }
                />
            </Form.Group>
            {
                (props.questionStructure === SiufQuestionStructure.SingleQuestion)
                &&
                <React.Fragment>
                    <Form.Input fluid value={ props.ratings1FollowupQuestion } label='Ratings 1 Follow up Question'
                        placeholder='Enter follow up question here'
                        readOnly={ props.isReadOnly }
                        tabIndex='5'
                        onChange={ e => props.onUpdateFollowQuestionText(e.currentTarget.value, 0) }
                    />
                    <Form.Input fluid value={ props.ratings2FollowupQuestion } label='Ratings 2 Follow up Question'
                        placeholder='Enter follow up question here'
                        readOnly={ props.isReadOnly }
                        tabIndex='5'
                        onChange={ e => props.onUpdateFollowQuestionText(e.currentTarget.value, 1) }
                    />
                    <Form.Input fluid value={ props.ratings3FollowupQuestion } label='Ratings 3 Follow up Question'
                        placeholder='Enter follow up question here'
                        readOnly={ props.isReadOnly }
                        tabIndex='5'
                        onChange={ e => props.onUpdateFollowQuestionText(e.currentTarget.value, 2) }
                    />
                    <Form.Input fluid value={ props.ratings4FollowupQuestion } label='Ratings 4 Follow up Question'
                        placeholder='Enter follow up question here'
                        readOnly={ props.isReadOnly }
                        tabIndex='5'
                        onChange={ e => props.onUpdateFollowQuestionText(e.currentTarget.value, 3) }
                    />
                    <Form.Input fluid value={ props.ratings5FollowupQuestion } label='Ratings 5 Follow up Question'
                        placeholder='Enter follow up question here'
                        readOnly={ props.isReadOnly }
                        tabIndex='5'
                        onChange={ e => props.onUpdateFollowQuestionText(e.currentTarget.value, 4) }
                    />
                </React.Fragment>
            }
        </React.Fragment>
    );
};

export default FivePointRatingQuestion;