import { combineReducers, Action, AnyAction, Dispatch } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { IResourceProviderOptions, IResourceProviderOptions_Default } from '../contracts';
import { SiufDomainAction } from '../actions';
import { ISiufDomainState } from '../contracts/states';
import { appStateRootReducer, uiStateRootReducer, } from './siufRootReducers';

// Additional props for connected React components. This prop is passed by default with `connect()`
export interface IConnectedReduxProps<A extends Action = AnyAction> {
    dispatch: Dispatch<A>;
}

// Environment configuration reducer
export const configurationReducer = (state: IResourceProviderOptions = IResourceProviderOptions_Default, action: SiufDomainAction) => {
    switch (action.type) {
        default:
            return state;
    }
};

// SIUF Domain Root reducer
export const siufDomainRootReducer = combineReducers<ISiufDomainState>({
    appState: appStateRootReducer,
    uiState: uiStateRootReducer
});

// https://redux.js.org/api/combinereducers
// This is the root reducer for the application
// Add additional reducers to this list
const createRootReducer = (history: History) => combineReducers({
    router: connectRouter(history),
    // Rest of the reducers
    siufDomain: siufDomainRootReducer,
    // Resource provider is black here because its value will be populated by the environment configs during configureStore() call
    resourceProviders: configurationReducer
});

export default createRootReducer;