import * as React from 'react';
import { Message, Icon } from 'semantic-ui-react';

type Props = {
    isVisible: boolean;
    header: JSX.Element | string;
};

const LoadingMessage: React.FC<Props> = props => {

    return (
        props.isVisible
        &&
        <Message icon>
            <Icon name='circle notched' loading color="blue" />
            <Message.Content>
                <Message.Header>
                    { props.header }
                </Message.Header>
                { props.children }
            </Message.Content>
        </Message>
    );
};

export default LoadingMessage;