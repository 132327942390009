export enum QuestionDetailsActionTypes {

    // Ajax related actions types

    // normal action types

    UPDATE_QUESTIONTYPE = '@@SIUF/QUESTIONDETAILS/UPDATE_QUESTIONTYPE',

    UPDATE_TITLE = '@@SIUF/QUESTIONDETAILS/UPDATE_TITLE',
    UPDATE_IGNORETITLEONTOAST = '@@SIUF/QUESTIONDETAILS/IGNORETITLEONTOAST',
    ADD_QUESTION = '@@SIUF/QUESTIONDETAILS/ADD_QUESTION',
    REMOVE_QUESTION = '@@SIUF/QUESTIONDETAILS/REMOVE_QUESTION',
    UPDATE_NPSQUESTION = '@@SIUF/QUESTIONDETAILS/UPDATE_NPSQUESTION',

    UPDATE_QUESTIONTEXT = '@@SIUF/QUESTIONDETAILS/UPDATE_QUESTIONTEXT',

    UPDATE_SIUFFOCUS = '@@SIUF/QUESTIONDETAILS/UPDATE_SIUFFOCUS',
    UPDATE_ANCHORTEXT = '@@SIUF/QUESTIONDETAILS/UPDATE_ANCHORTEXT',

    UPDATE_FOLLOWUPQUESTIONTEXT = '@@SIUF/QUESTIONDETAILS/UPDATE_FOLLOWUPQUESTIONTEXT',

    UPDATE_CHECKBOXTEXT = '@@SIUF/QUESTIONDETAILS/UPDATE_CHECKBOXTEXT',
    ADD_CHECKBOXTEXT = '@@SIUF/QUESTIONDETAILS/ADD_CHECKBOXTEXT',
    REMOVE_CHECKBOXTEXT = '@@SIUF/QUESTIONDETAILS/REMOVE_CHECKBOXTEXT',

    UPDATE_LIKERTRESPONSETYPE = '@@SIUF/QUESTIONDETAILS/UPDATE_LIKERTRESPONSETYPE',
    UPDATE_LIKERTRESPONSECOUNT = '@@SIUF/QUESTIONDETAILS/UPDATE_LIKERTRESPONSECOUNT',
    UPDATE_LIKERTOPTIONTEXT = '@@SIUF/QUESTIONDETAILS/UPDATE_LIKERTOPTIONTEXT',
    ADD_LIKERTOPTION = '@@SIUF/QUESTIONDETAILS/ADD_LIKERTOPTION',
    REMOVE_LIKERTOPTION = '@@SIUF/QUESTIONDETAILS/REMOVE_LIKERTOPTION',
    ADD_LIKERTSCALEITEM = '@@SIUF/QUESTIONDETAILS/ADD_LIKERTSCALEITEM',
    UPDATE_LIKERTSCALEITEMTEXT = '@@SIUF/QUESTIONDETAILS/UPDATE_LIKERTSCALEITEMTEXT',
    REMOVE_LIKERTSCALEITEM = '@@SIUF/QUESTIONDETAILS/REMOVE_LIKERTSCALEITEM',

    ADD_PAGE = '@@SIUF/QUESTIONDETAILS/ADD_PAGE',
    REMOVE_PAGE = '@@SIUF/QUESTIONDETAILS/REMOVE_PAGE',
    CHANGE_EDIT_PAGE = '@@SIUF/QUESTIONDETAILS/CHANGE_EDIT_PAGE',
    CHANGE_PREVIEW_PAGE = '@@SIUF/QUESTIONDETAILS/CHANGE_PREVIEW_PAGE',
}