import { combineReducers } from 'redux';
import { ISiufDomainAppState, ISiufDomainUiState } from '../contracts/states';
import { authoringAppStateReducer, authoringUiStateReducer } from './authoringReducers';
import { reviewingAppStateReducer, reviewingUiStateReducer } from './reviewingReducers';

// App State reducers
export const appStateRootReducer = combineReducers<ISiufDomainAppState>({
    authoring: authoringAppStateReducer,
    reviewing: reviewingAppStateReducer
});

// Ui State reducers
export const uiStateRootReducer = combineReducers<ISiufDomainUiState>({
    authoring: authoringUiStateReducer,
    reviewing: reviewingUiStateReducer
});
