import * as React from 'react';
import { Button, Form, Header, Message, Segment, SemanticCOLORS, Table, Checkbox } from 'semantic-ui-react';
import { ErrorType, ISectionUiState, RequestState } from '../../../contracts';
import { ErrorLevel, ISiufValidationResult, ISubmitState, SiufValidationSource } from '../../../contracts/models';

type Props = {
    siufId: number;
    uiState: ISectionUiState;
    validationResult: ISiufValidationResult;
    validationSubmitState: ISubmitState<ISiufValidationResult>;
    sideloadSubmitState: ISubmitState<string>;
    downloadXmlSubmitState: ISubmitState<string>;
    isSiufDirty: boolean;
    isSideLoadEnabled: boolean;
    hasMultipleQuestions: boolean;
    exportExtendedPayload: boolean;
    onValidateSiuf: (siufId: number) => void;
    onSideloadSiuf: (siufId: number, exportExtendedPayload: boolean) => void;
    onDownloadSiufXml: (siufId: number, exportExtendedPayload: boolean) => void;
    onDismissSubmitState: () => void;
    onChangeExportExtendedPayload: (exportExtendedPayload: boolean) => void;
};

const Validation: React.FC<Props> = props => {

    const hasValidationErrors = (props.validationResult.HasErrors && props.validationResult.Errors.length > 0);
    const hasValidationWarnings = (props.validationResult.HasWarnings && props.validationResult.Errors.length > 0);
    let validationErrors: JSX.Element[];
    if (hasValidationErrors || hasValidationWarnings) {
        validationErrors = props.validationResult.Errors.sort((a, b) => a.Level - b.Level).map((e, i) => {
            return (
                <Table.Row key={ i } error={ e.Level === ErrorLevel.Error } warning={ e.Level === ErrorLevel.Warning }>
                    <Table.Cell textAlign='center'>
                        { e.Level === ErrorLevel.Error ? "Error" : "Warning" }
                    </Table.Cell>
                    <Table.Cell textAlign='center'>
                        { e.Message }
                    </Table.Cell>
                    <Table.Cell textAlign='center'>
                        { SiufValidationSource[e.ValidationSource] }
                    </Table.Cell>
                </Table.Row>
            );
        });
    } else if (props.validationSubmitState.requestState === RequestState.notStarted) {
        validationErrors = [
            <Table.Row key={ 0 }>
                <Table.Cell />
                <Table.Cell textAlign='center'>Validation has not been performed.</Table.Cell>
                <Table.Cell />
            </Table.Row>
        ];
    } else if (props.validationSubmitState.requestState === RequestState.inProgress) {
        validationErrors = [
            <Table.Row key={ 0 }>
                <Table.Cell />
                <Table.Cell textAlign='center'>Performing validation...</Table.Cell>
                <Table.Cell />
            </Table.Row>
        ];
    } else {
        validationErrors = [
            <Table.Row key={ 0 }>
                <Table.Cell />
                <Table.Cell textAlign='center'>No validation errors detected.</Table.Cell>
                <Table.Cell />
            </Table.Row>
        ];
    }

    let validationTableColor: SemanticCOLORS;
    if (hasValidationErrors) {
        validationTableColor = 'red';
    } else if (hasValidationWarnings) {
        validationTableColor = 'brown';
    } else if (props.validationSubmitState.requestState === RequestState.notStarted) {
        validationTableColor = 'yellow';
    } else if (props.validationSubmitState.requestState === RequestState.inProgress) {
        validationTableColor = 'blue';
    } else {
        validationTableColor = 'green';
    }

    const validationResults = (
        <Segment basic loading={ props.validationSubmitState.requestState === RequestState.inProgress }>
            <Table celled color={ validationTableColor }>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell width={ 2 } textAlign='center'>
                            Type
                    </Table.HeaderCell>
                        <Table.HeaderCell width={ 10 } textAlign='center'>
                            Message
                    </Table.HeaderCell>
                        <Table.HeaderCell width={ 4 } textAlign='center'>
                            Source
                    </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    { validationErrors }
                </Table.Body>
            </Table>
        </Segment>
    );

    const validation = (
        <React.Fragment>
            <Header as='h4'>Before you publish your SIUF question, it is a good idea to validate that your scenario is working as you intended</Header>
            { validationResults }
            {
                props.isSiufDirty
                &&
                <Message info content='Save this SIUF first before you perform validation and sideload testing' />
            }
            {
                (props.validationSubmitState.requestState === RequestState.complete && props.validationSubmitState.errorType === ErrorType.error)
                &&
                <Message color='red' content={ `An error occurred while calling validation. Please ask for help. Error: ${props.validationSubmitState.errorMessage}` } />
            }
            <Button primary loading={ props.validationSubmitState.requestState === RequestState.inProgress }
                disabled={ (props.validationSubmitState.requestState === RequestState.inProgress) || props.isSiufDirty }
                tabIndex='10'
                onClick={ e => props.onValidateSiuf(props.siufId) }>
                Validate and check for errors
            </Button>
        </React.Fragment>
    );

    const sideload = (
        <React.Fragment>
            <Header as='h4' content='Once your SIUF passes validation, you can sideload this SIUF locally to test it' />
            {
                !props.isSideLoadEnabled
                &&
                <Message info
                    header='Sideloading is not enabled for this SIUF'
                    content='Sideloading does not apply to SIUFs with uri trigger type' />
            }
            {
                (props.sideloadSubmitState.requestState === RequestState.complete && props.sideloadSubmitState.errorType === ErrorType.error)
                &&
                <Message color='red'
                    header='An error occurred while calling sideload. Please ask for help'
                    content={ `Error: ${props.sideloadSubmitState.errorMessage}` } />
            }
            <Form>
                {
                    props.hasMultipleQuestions 
                    &&
                    <Form.Field>
                        <Checkbox label='Sideload all Questions' checked={props.exportExtendedPayload}
                            onChange={(event, data) => props.onChangeExportExtendedPayload(data.checked)}
                            disabled={ props.isSiufDirty || !props.isSideLoadEnabled || hasValidationErrors || (props.validationSubmitState.requestState !== RequestState.complete) || (props.sideloadSubmitState.requestState === RequestState.inProgress) }
                        />
                    </Form.Field>
                }            
                <Button primary loading={ props.sideloadSubmitState.requestState === RequestState.inProgress }
                    disabled={ props.isSiufDirty || !props.isSideLoadEnabled || hasValidationErrors || (props.validationSubmitState.requestState !== RequestState.complete) || (props.sideloadSubmitState.requestState === RequestState.inProgress) }
                    tabIndex='10'
                    onClick={ e => props.onSideloadSiuf(props.siufId, props.exportExtendedPayload) }>
                    Sideload this SIUF locally
                </Button>
                <Button primary loading={ props.downloadXmlSubmitState.requestState === RequestState.inProgress }
                    disabled={ props.isSiufDirty || !props.isSideLoadEnabled || hasValidationErrors || (props.validationSubmitState.requestState !== RequestState.complete) || (props.downloadXmlSubmitState.requestState === RequestState.inProgress) }
                    tabIndex='10'
                    onClick={ e => props.onDownloadSiufXml(props.siufId, props.exportExtendedPayload) }>
                    Download SIUF Xml
                </Button>
            </Form>
        </React.Fragment>
    );

    return (
        <React.Fragment>
            { validation }
            { sideload }
        </React.Fragment>
    );
};

export default Validation;