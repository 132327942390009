import axios from "axios";
import { GetAppEnvironmentConfigs } from "../appConfigs";
import { MsalService } from "./msalService";

let siufAuthApiParam = {
    scopes: []
},
forceRefresh: boolean = false;

export const SetupAxiosInterceptors = () => {
    // Setup interceptors to intercept all axios requests
    axios.interceptors.request.use(async config => {
        // Add the authorization header with the access token to axios requests
        // If no token is found, assume no authorization header is required

        // Reading the API Scope from the Environment Configs
        const environmentConfigs = GetAppEnvironmentConfigs();
        
        if (environmentConfigs) {
            siufAuthApiParam.scopes.push(environmentConfigs.endpoints.siufApiScope);
        }

        if (siufAuthApiParam.scopes.length > 0) {
            let token: string = await MsalService.GetAccessToken(siufAuthApiParam, forceRefresh);
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
                forceRefresh = false;
            } else {
                console.error(`Unable to acquire access token for ${config.url}`);
            }
            return config;
        } else {
            console.error(`Unable to acquire siufApiScope`);
            throw Error("Unable to acquire siufApiScope! Verify App Environment configuration.");
        }

    }, error => {
        return Promise.reject(error);
    });

    axios.interceptors.response.use(undefined, error => {
        // Setup interceptors to intercept all axios response errors
        // Cleanup the token that did not work from the cache on 401 response
        if (error.response && error.response.status === 401) {
            forceRefresh = true;
        }
        return Promise.reject(error);
    });
};