import { combineReducers } from 'redux';
import { AuthoringUiActionTypes, PropertiesActionTypes, QuestionDetailsActionTypes, SiufBasicsActionTypes, SiufCreationActionTypes, SiufDomainAction, SiufRetrievalActionTypes, SiufSubmissionActionTypes, SiufTriggerActionTypes, StateMachineActionTypes, TargetingActionTypes } from '../actions';
import { ErrorType, FivePointQuestionEntityDefault, IAnswerEntityDefault, ICREExceptionEntity, IDeviceFilterEntity_Default, IncludeTestSystemDeviceFilter, IPayloadEntity, IPayload_Default, IQuestionEntity, ISectionUiState, ISectionUiState_Default, ISiufAuthoringDomainAppState, ISiufAuthoringUiState, ISiufCreationAppState, ISiufCreationAppState_Default, ISiufEditingAppState, ISiufEditingAppState_Default, ISiufValidationResult_Default, IStateModelEntity_Default, ISubmitState_Default, ITargetingEntity, ITargeting_Default, RequestState, SiufAdvancedProperty, SiufAuthoringSectionId, SiufCREException, SiufFocus, SiufQuestionStructure, SiufQuestionType, SiufTriggeringType, SiufTypeSelection, SubmitOperation, DEFAULT_PREVIEW_SELECTION, SiufTargetType, SiufRingChannel, MAXIMUM_LIKERT_SCALE_ITEM_COUNT, IScaleItemEntityDefault, MAXIMUM_LIKERT_RESPONSE_OPTION_COUNT, IPageEntity, MAXIMUM_PAGE_COUNT, PageEntityDefault } from '../contracts';
import { ConvertRingsToRingSelection, ConvertRingChannelsToRingChannelSelection, GetDefaultQuestion, IsSiufStateReadOnly, IsTestSystemIncludedDeviceFilterSet, GetSiufTypeSelectionFromSiufEntity, GetUpdatedLikertAnswers, GetQuestionsWithLikertProperties, GetPagesWithQuestions } from '../utils';
import { stateMachineReducer } from './stateMachineReducers';

// App State reducers

export const pageAppStateReducer = (state: IPageEntity = PageEntityDefault, action: SiufDomainAction) => {
    switch(action.type){
        case QuestionDetailsActionTypes.UPDATE_QUESTIONTYPE:
        case QuestionDetailsActionTypes.UPDATE_LIKERTRESPONSETYPE:
        case QuestionDetailsActionTypes.UPDATE_LIKERTRESPONSECOUNT:
        case QuestionDetailsActionTypes.UPDATE_QUESTIONTEXT:
        case QuestionDetailsActionTypes.UPDATE_SIUFFOCUS:
        case QuestionDetailsActionTypes.UPDATE_ANCHORTEXT:
        case QuestionDetailsActionTypes.UPDATE_FOLLOWUPQUESTIONTEXT:
        case QuestionDetailsActionTypes.ADD_CHECKBOXTEXT:
        case QuestionDetailsActionTypes.UPDATE_CHECKBOXTEXT:
        case QuestionDetailsActionTypes.REMOVE_CHECKBOXTEXT:
        case QuestionDetailsActionTypes.ADD_LIKERTOPTION:
        case QuestionDetailsActionTypes.UPDATE_LIKERTOPTIONTEXT:
        case QuestionDetailsActionTypes.REMOVE_LIKERTOPTION:
        case QuestionDetailsActionTypes.ADD_LIKERTSCALEITEM:
        case QuestionDetailsActionTypes.UPDATE_LIKERTSCALEITEMTEXT:
        case QuestionDetailsActionTypes.REMOVE_LIKERTSCALEITEM:
        return {
            ...state,
            Questions: state.Questions.map((question, index) => {
                if(action.payload.questionIndex === question.QuestionIndex){
                    return questionAppStateReducer(question, action);
                } else {
                    return question;
                }
            })
        };
        case QuestionDetailsActionTypes.ADD_QUESTION:
            return {
                ...state,
                Questions: [
                    ...state.Questions,
                    {
                        ...FivePointQuestionEntityDefault,
                        QuestionStructure: SiufQuestionStructure.MultiQuestion,
                        QuestionIndex: state.Questions.length
                    }
                ]

            };
        case QuestionDetailsActionTypes.REMOVE_QUESTION:
            return { 
                ...state,
                Questions: state.Questions.filter(q => q.QuestionIndex !== action.payload.questionIndex).map((q, i) => {
                    return {
                        ...q,
                        QuestionIndex: i
                    };
                })}
        default:
            return state;
    }
}
export const questionAppStateReducer = (state: IQuestionEntity = FivePointQuestionEntityDefault, action: SiufDomainAction) => {
    switch (action.type) {
        case QuestionDetailsActionTypes.UPDATE_QUESTIONTYPE:
            return {
                ...state,
                ...GetDefaultQuestion(action.payload.questionType),
                QuestionIndex: state.QuestionIndex,
                QuestionStructure: state.QuestionStructure
            };
        case QuestionDetailsActionTypes.UPDATE_LIKERTRESPONSETYPE:
            return {
                ...state,
                ...GetUpdatedLikertAnswers(action.payload.likertResponseType, state.LikertResponseCount, action.payload.questionFocus, action.payload.followUpQuestion),
                LikertResponseType: action.payload.likertResponseType
            };
        case QuestionDetailsActionTypes.UPDATE_LIKERTRESPONSECOUNT:
            return {
                ...state,
                ...GetUpdatedLikertAnswers(state.LikertResponseType, action.payload.likertResponseCount, action.payload.questionFocus, action.payload.followUpQuestion),
                LikertResponseCount: action.payload.likertResponseCount
            };
        case QuestionDetailsActionTypes.UPDATE_QUESTIONTEXT:
            return {
                ...state,
                QuestionText: action.payload.questionText
            };
        case QuestionDetailsActionTypes.UPDATE_SIUFFOCUS:
            return {
                ...state,
                QuestionFocus: action.payload.siufFocus
            };
        case QuestionDetailsActionTypes.UPDATE_ANCHORTEXT:
            return {
                ...state,
                Answers: [
                    // 1st section, if any
                    ...state.Answers.slice(
                        0,
                        state.Answers.findIndex(a => a.AnswerIndex === action.payload.anchorIndex)),
                    // Item to be modified
                    {
                        ...state.Answers.find(a => a.AnswerIndex === action.payload.anchorIndex),
                        AnswerText: action.payload.anchorText
                    },
                    // 3rd section, if any
                    ...state.Answers.slice(
                        state.Answers.findIndex(a => a.AnswerIndex === action.payload.anchorIndex) + 1,
                        state.Answers.length)
                ]
            };
        case QuestionDetailsActionTypes.UPDATE_FOLLOWUPQUESTIONTEXT:
            return {
                ...state,
                Answers: [
                    // 1st section, if any
                    ...state.Answers.slice(
                        0,
                        state.Answers.findIndex(a => a.AnswerIndex === action.payload.anchorIndex)),
                    // Item to be modified
                    {
                        ...state.Answers.find(a => a.AnswerIndex === action.payload.anchorIndex),
                        FollowUpQuestion: {
                            // Follow up question currently is always a text question
                            QuestionText: action.payload.followUpQuestionText,
                            QuestionStructure: SiufQuestionStructure.FollowUpQuestion,
                            QuestionType: SiufQuestionType.Text,
                            QuestionFocus: SiufFocus.None,
                            QuestionIndex: 0,
                            ToastText: "",
                            Answers: [],
                            IsNpsQuestion: false
                        }
                    },
                    // 3rd section, if any
                    ...state.Answers.slice(
                        state.Answers.findIndex(a => a.AnswerIndex === action.payload.anchorIndex) + 1,
                        state.Answers.length)
                ]
            };
        case QuestionDetailsActionTypes.ADD_CHECKBOXTEXT:
            return {
                ...state,
                Answers: [
                    ...state.Answers,
                    {
                        ...IAnswerEntityDefault,
                        AnswerIndex: state.Answers.length
                    }
                ]
            };
        case QuestionDetailsActionTypes.UPDATE_CHECKBOXTEXT:
            return {
                ...state,
                Answers: [
                    // 1st section, if any
                    ...state.Answers.slice(
                        0,
                        state.Answers.findIndex(a => a.AnswerIndex === action.payload.checkBoxIndex)),
                    // Item to be modified
                    {
                        ...state.Answers.find(a => a.AnswerIndex === action.payload.checkBoxIndex),
                        AnswerText: action.payload.checkBoxText
                    },
                    // 3rd section, if any
                    ...state.Answers.slice(
                        state.Answers.findIndex(a => a.AnswerIndex === action.payload.checkBoxIndex) + 1,
                        state.Answers.length)
                ]
            };
        case QuestionDetailsActionTypes.REMOVE_CHECKBOXTEXT:
            return {
                ...state,
                Answers: state.Answers.filter(a => a.AnswerIndex !== action.payload.checkBoxIndex).map((a, i) => {
                    return {
                        ...a,
                        AnswerIndex: i
                    };
                }),
            };
        case QuestionDetailsActionTypes.ADD_LIKERTOPTION:
            if(state.Answers?.length >= MAXIMUM_LIKERT_RESPONSE_OPTION_COUNT)
                return state;
            return {
                ...state,
                Answers: [
                    ...state.Answers,
                    {
                        ...IAnswerEntityDefault,
                        AnswerIndex: state.Answers.length,
                        AnswerText: action.payload.likertOptionText || ""
                    }
                ]
            };
        case QuestionDetailsActionTypes.UPDATE_LIKERTOPTIONTEXT:
            return {
                ...state,
                Answers: [
                    // 1st section, if any
                    ...state.Answers.slice(
                        0,
                        state.Answers.findIndex(a => a.AnswerIndex === action.payload.likertOptionIndex)),
                    // Item to be modified
                    {
                        ...state.Answers.find(a => a.AnswerIndex === action.payload.likertOptionIndex),
                        AnswerText: action.payload.likertOptionText
                    },
                    // 3rd section, if any
                    ...state.Answers.slice(
                        state.Answers.findIndex(a => a.AnswerIndex === action.payload.likertOptionIndex) + 1,
                        state.Answers.length)
                ]
            };
        case QuestionDetailsActionTypes.REMOVE_LIKERTOPTION:
            return {
                ...state,
                Answers: state.Answers.filter(a => a.AnswerIndex !== action.payload.likertOptionIndex).map((a, i) => {
                    return {
                        ...a,
                        AnswerIndex: i
                    };
                }),
            };
        
        case QuestionDetailsActionTypes.ADD_LIKERTSCALEITEM:
            if(state.ScaleItems?.length >= MAXIMUM_LIKERT_SCALE_ITEM_COUNT)
                return state;
            return {
                ...state,
                ScaleItems: [
                    ...state.ScaleItems,
                    {
                        ...IScaleItemEntityDefault,
                        ScaleItemIndex: state.ScaleItems.length
                    }
                ]
            };
        case QuestionDetailsActionTypes.UPDATE_LIKERTSCALEITEMTEXT:
            return {
                ...state,
                ScaleItems: [
                    // 1st section, if any
                    ...state.ScaleItems.slice(
                        0,
                        state.ScaleItems.findIndex(a => a.ScaleItemIndex === action.payload.likertScaleItemIndex)),
                    // Item to be modified
                    {
                        ...state.ScaleItems.find(a => a.ScaleItemIndex === action.payload.likertScaleItemIndex),
                        ScaleItemText: action.payload.likertScaleItemText
                    },
                    // 3rd section, if any
                    ...state.ScaleItems.slice(
                        state.ScaleItems.findIndex(a => a.ScaleItemIndex === action.payload.likertScaleItemIndex) + 1,
                        state.ScaleItems.length)
                ]
            };
        case QuestionDetailsActionTypes.REMOVE_LIKERTSCALEITEM:
            return {
                ...state,
                ScaleItems: state.ScaleItems.filter(a => a.ScaleItemIndex !== action.payload.likertScaleItemIndex).map((a, i) => {
                    return {
                        ...a,
                        ScaleItemIndex: i
                    };
                }),
            };
        default:
            return state;
    }
};

export const pageCollectionAppStateReducer = (state: IPageEntity[] = [], action: SiufDomainAction) => {
    switch (action.type) {
        case SiufBasicsActionTypes.UPDATE_SIUFTYPE:
            if (action.payload.siufType === SiufTypeSelection.singleQuestion) {
                return [{
                    PageIndex: 0,
                    Questions: [FivePointQuestionEntityDefault]
                }];
            } else if (action.payload.siufType === SiufTypeSelection.multiQuestion) {
                return [{
                        PageIndex: 0,
                        Questions: [{
                            ...FivePointQuestionEntityDefault,
                            QuestionStructure: SiufQuestionStructure.MultiQuestion
                        }]
                    }];
            }
            return state;
        case QuestionDetailsActionTypes.UPDATE_QUESTIONTYPE:
        case QuestionDetailsActionTypes.UPDATE_LIKERTRESPONSETYPE:
        case QuestionDetailsActionTypes.UPDATE_LIKERTRESPONSECOUNT:
        case QuestionDetailsActionTypes.UPDATE_QUESTIONTEXT:
        case QuestionDetailsActionTypes.UPDATE_SIUFFOCUS:
        case QuestionDetailsActionTypes.UPDATE_ANCHORTEXT:
        case QuestionDetailsActionTypes.UPDATE_FOLLOWUPQUESTIONTEXT:
        case QuestionDetailsActionTypes.ADD_CHECKBOXTEXT:
        case QuestionDetailsActionTypes.UPDATE_CHECKBOXTEXT:
        case QuestionDetailsActionTypes.REMOVE_CHECKBOXTEXT:
        case QuestionDetailsActionTypes.ADD_LIKERTOPTION:
        case QuestionDetailsActionTypes.UPDATE_LIKERTOPTIONTEXT:
        case QuestionDetailsActionTypes.REMOVE_LIKERTOPTION:
        case QuestionDetailsActionTypes.ADD_LIKERTSCALEITEM:
        case QuestionDetailsActionTypes.UPDATE_LIKERTSCALEITEMTEXT:
        case QuestionDetailsActionTypes.REMOVE_LIKERTSCALEITEM:
        case QuestionDetailsActionTypes.ADD_QUESTION:
        case QuestionDetailsActionTypes.REMOVE_QUESTION:
            return [
                // 1st section, if any
                ...state.slice(
                    0,
                    state.findIndex(q => q.PageIndex === action.payload.pageIndex)),
                // Item to be modified
                pageAppStateReducer(state.find(q => q.PageIndex === action.payload.pageIndex), action),
                // 3rd section, if any
                ...state.slice(
                    state.findIndex(q => q.PageIndex === action.payload.pageIndex) + 1,
                    state.length)
            ];
        case QuestionDetailsActionTypes.ADD_PAGE:
            if(state.length >= MAXIMUM_PAGE_COUNT)
                return state;
            return [
                ...state,
                {
                    PageIndex: state.length,
                    Questions: [{
                        ...FivePointQuestionEntityDefault,
                        QuestionStructure: SiufQuestionStructure.MultiQuestion,
                        QuestionIndex: 0
                    }]
                }
            ];
        case QuestionDetailsActionTypes.REMOVE_PAGE:
            return state.filter(q => q.PageIndex !== action.payload.pageIndex).map((p, i) => {
                return {
                    ...p,
                    PageIndex: i
                };
            });
        case QuestionDetailsActionTypes.UPDATE_NPSQUESTION:
            return state.map((page, pageIndex) => {
                return {
                    ...page,
                    Questions: page.Questions.map((question, questionIndex) => {
                        return {
                            ...question,
                            IsNpsQuestion: (pageIndex == action.payload.pageIndex && questionIndex == action.payload.questionIndex) ?
                                action.payload.checked : false
                        }
                    })
                }
            });
        default:
            return state;
    }
};

export const creExceptionsAppStateReducer = (state: ICREExceptionEntity[] = [], action: SiufDomainAction) => {
    switch (action.type) {
        case PropertiesActionTypes.UPDATE_ADVANCEDPROPERTY:
            switch (action.payload.property) {
                case SiufAdvancedProperty.postLogonQuietPeriod:
                    if (action.payload.propertyValue) {
                        if (state.findIndex(e => e.CREExceptionId === SiufCREException.PostLogonQuietPeriod) === -1) {
                            // Only add the setting if it doesn't already exist
                            return [
                                ...state,
                                {
                                    CREExceptionId: SiufCREException.PostLogonQuietPeriod,
                                    CREExceptionName: ""
                                }
                            ];
                        }
                        return state;
                    } else {
                        return state.filter(e => e.CREExceptionId !== SiufCREException.PostLogonQuietPeriod);
                    }
                case SiufAdvancedProperty.reaskAfterUpdate:
                    if (action.payload.propertyValue) {
                        if (state.findIndex(e => e.CREExceptionId === SiufCREException.ReaskAfterUpdate) === -1) {
                            // Only add the setting if it doesn't already exist
                            return [
                                ...state,
                                {
                                    CREExceptionId: SiufCREException.ReaskAfterUpdate,
                                    CREExceptionName: ""
                                }
                            ];
                        }
                        return state;
                    } else {
                        return state.filter(e => e.CREExceptionId !== SiufCREException.ReaskAfterUpdate);
                    }
                case SiufAdvancedProperty.reaskRule:
                    if (action.payload.propertyValue) {
                        if (state.findIndex(e => e.CREExceptionId === SiufCREException.Reask) === -1) {
                            // Only add the setting if it doesn't already exist
                            return [
                                ...state,
                                {
                                    CREExceptionId: SiufCREException.Reask,
                                    CREExceptionName: ""
                                }
                            ];
                        }
                        return state;
                    } else {
                        return state.filter(e => e.CREExceptionId !== SiufCREException.Reask);
                    }
                case SiufAdvancedProperty.frequencyRule:
                    if (action.payload.propertyValue) {
                        if (state.findIndex(e => e.CREExceptionId === SiufCREException.Frequency) === -1) {
                            // Only add the setting if it doesn't already exist
                            return [
                                ...state,
                                {
                                    CREExceptionId: SiufCREException.Frequency,
                                    CREExceptionName: ""
                                }
                            ];
                        }
                        return state;
                    } else {
                        return state.filter(e => e.CREExceptionId !== SiufCREException.Frequency);
                    }
                case SiufAdvancedProperty.useMLModel:
                    if (action.payload.propertyValue) {
                        if (state.findIndex(e => e.CREExceptionId === SiufCREException.UseMLModel) === -1) {
                            // Only add the setting if it doesn't already exist
                            return [
                                ...state,
                                {
                                    CREExceptionId: SiufCREException.UseMLModel,
                                    CREExceptionName: ""
                                }
                            ];
                        }
                        return state;
                    } else {
                        return state.filter(e => e.CREExceptionId !== SiufCREException.UseMLModel);
                    }
                case SiufAdvancedProperty.useFeedbackHubToToast:
                    if (action.payload.propertyValue) {
                        if (state.findIndex(e => e.CREExceptionId === SiufCREException.UseFeedbackHubToToast) === -1) {
                            // Only add the setting if it doesn't already exist
                            return [
                                ...state,
                                {
                                    CREExceptionId: SiufCREException.UseFeedbackHubToToast,
                                    CREExceptionName: ""
                                }
                            ];
                        }
                        return state;
                    } else {
                        return state.filter(e => e.CREExceptionId !== SiufCREException.UseFeedbackHubToToast);
                    }
                default:
                    return state;
            }
        default:
            return state;
    }
};

export const payloadAppStateReducer = (state: IPayloadEntity = IPayload_Default, action: SiufDomainAction) => {
    switch (action.type) {
        case SiufBasicsActionTypes.UPDATE_SIUFTYPE:
        case QuestionDetailsActionTypes.UPDATE_QUESTIONTYPE:
        case QuestionDetailsActionTypes.UPDATE_LIKERTRESPONSETYPE:
        case QuestionDetailsActionTypes.UPDATE_LIKERTRESPONSECOUNT:
        case QuestionDetailsActionTypes.ADD_QUESTION:
        case QuestionDetailsActionTypes.REMOVE_QUESTION:
        case QuestionDetailsActionTypes.ADD_PAGE:
        case QuestionDetailsActionTypes.REMOVE_PAGE:
        case QuestionDetailsActionTypes.UPDATE_NPSQUESTION:
        case QuestionDetailsActionTypes.UPDATE_QUESTIONTEXT:
        case QuestionDetailsActionTypes.UPDATE_SIUFFOCUS:
        case QuestionDetailsActionTypes.UPDATE_ANCHORTEXT:
        case QuestionDetailsActionTypes.UPDATE_FOLLOWUPQUESTIONTEXT:
        case QuestionDetailsActionTypes.ADD_CHECKBOXTEXT:
        case QuestionDetailsActionTypes.UPDATE_CHECKBOXTEXT:
        case QuestionDetailsActionTypes.REMOVE_CHECKBOXTEXT:
        case QuestionDetailsActionTypes.ADD_LIKERTOPTION:
        case QuestionDetailsActionTypes.UPDATE_LIKERTOPTIONTEXT:
        case QuestionDetailsActionTypes.REMOVE_LIKERTOPTION:
        case QuestionDetailsActionTypes.ADD_LIKERTSCALEITEM:
        case QuestionDetailsActionTypes.UPDATE_LIKERTSCALEITEMTEXT:
        case QuestionDetailsActionTypes.REMOVE_LIKERTSCALEITEM:
            return {
                ...state,
                //Questions: questionCollectionAppStateReducer(state.Questions, action),
                Pages: pageCollectionAppStateReducer(state.Pages, action)
            };
        case QuestionDetailsActionTypes.UPDATE_TITLE:
            return {
                ...state,
                Title: action.payload.title
            };
        case QuestionDetailsActionTypes.UPDATE_IGNORETITLEONTOAST:
            return {
                ...state,
                IgnoreTitleOnToast: action.payload.IgnoreTitleOnToast
            };
        case SiufTriggerActionTypes.UPDATE_TRIGGER_TYPE:
            return {
                ...state,
                TriggeringType: action.payload.triggerType
            };
        case PropertiesActionTypes.UPDATE_PREVIOUS_QUESTION_ID:
            return {
                ...state,
                CREQuestionId: action.payload.questionId
            };
        case PropertiesActionTypes.UPDATE_ADVANCEDPROPERTY:
            switch (action.payload.property) {
                case SiufAdvancedProperty.doNotAskSiufAgainIfSeenPreviousVersion:
                    return {
                        ...state,
                        DoNotAskSiufAgainIfSeenPreviousVersion: action.payload.propertyValue,
                        CREQuestionId: ""
                    };
                case SiufAdvancedProperty.askEmail:
                    return {
                        ...state,
                        AskUserForEmail: action.payload.propertyValue
                    };
                case SiufAdvancedProperty.noAutoDeactivate:
                    return {
                        ...state,
                        NoAutoDeactivate: action.payload.propertyValue
                    };
                case SiufAdvancedProperty.postLogonQuietPeriod:
                case SiufAdvancedProperty.reaskAfterUpdate:
                case SiufAdvancedProperty.reaskRule:
                case SiufAdvancedProperty.frequencyRule:
                case SiufAdvancedProperty.useMLModel:
                case SiufAdvancedProperty.useFeedbackHubToToast:
                    return {
                        ...state,
                        CREExceptions: creExceptionsAppStateReducer(state.CREExceptions, action)
                    };
                default:
                    return state;
            }
        case TargetingActionTypes.UPDATE_DEVICE_TYPE:
            return {
                ...state,
                ToastText: ""
            };
        case TargetingActionTypes.UPDATE_TOAST_TEXT:
            return {
                ...state,
                ToastText: action.payload.toastText
            };
        case QuestionDetailsActionTypes.CHANGE_EDIT_PAGE:
            return {
                ...state,
                ActiveEditPage: action.payload.activeEditPage
            };
        case QuestionDetailsActionTypes.CHANGE_PREVIEW_PAGE:
            return {
                ...state,
                ActivePreviewPage: action.payload.activePreviewPage
            };
        default:
            return state;
    }
};

export const targetingAppStateReducer = (state: ITargetingEntity = ITargeting_Default, action: SiufDomainAction) => {
    switch (action.type) {
        case TargetingActionTypes.UPDATE_DEVICE_TYPE:
            return {
                ...state,
                DeviceType: action.payload.deviceType
            };
        case TargetingActionTypes.UPDATE_MINBUILD:
            return {
                ...state,
                MinBuild: action.payload.minBuild
            };
        case TargetingActionTypes.UPDATE_MAXBUILD:
            return {
                ...state,
                MaxBuild: (action.payload.maxBuild) > 0 ? action.payload.maxBuild : null
            };
        case TargetingActionTypes.UPDATE_TARGETING_TYPE:
            return (state.TargetType === action.payload.targetingType)
                ? state
                : {
                    ...state,
                    TargetType: action.payload.targetingType,
                    Rings: [],
                    RingChannels: (action.payload.targetingType === SiufTargetType.RetailRecommendedSampling) ? [SiufRingChannel.Retail] : [],
                    CustomGroupIds: [],
                    DeviceIdUri: "",
                    AudienceName: ""
                };
        case TargetingActionTypes.UPDATE_TARGETING_RING:
            return {
                ...state,
                Rings: ConvertRingsToRingSelection(action.payload.ringSelection, action.payload.siufRings),
            };
        case TargetingActionTypes.UPDATE_TARGETING_RINGCHANNEL:
            return {
                ...state,
                RingChannels: ConvertRingChannelsToRingChannelSelection(action.payload.ringChannelSelection, action.payload.siufRingChannels),
            };
        case TargetingActionTypes.UPDATE_AUDIENCE_NAME:
            return {
                ...state,
                AudienceName: action.payload.audienceName
            };
        case TargetingActionTypes.UPDATE_DEVICEID_URI:
            return {
                ...state,
                DeviceIdUri: action.payload.deviceIdUri
            };
        case TargetingActionTypes.UPDATE_CUSTOM_GROUP_IDS:
            return {
                ...state,
                CustomGroupIds: [...action.payload.customGroupIds]
            };
        case TargetingActionTypes.UPDATE_INCLUDE_TEST_SYSTEM:
            if (action.payload.includeTestSystems) {
                return {
                    ...state,
                    DeviceFilters: [
                        ...state.DeviceFilters,
                        IncludeTestSystemDeviceFilter
                    ]
                };
            } else {
                return {
                    ...state,
                    DeviceFilters: state.DeviceFilters.filter(df => !IsTestSystemIncludedDeviceFilterSet(df))
                };
            }
        case TargetingActionTypes.ADD_DEVICE_FILTER:
            return {
                ...state,
                DeviceFilters: [
                    ...state.DeviceFilters,
                    IDeviceFilterEntity_Default
                ]
            };
        case TargetingActionTypes.REMOVE_DEVICE_FILTER:
            return {
                ...state,
                DeviceFilters: state.DeviceFilters.filter((df, i) => action.payload.index !== i)
            };
        case TargetingActionTypes.UPDATE_DEVICE_FILTER:
            return {
                ...state,
                DeviceFilters: [
                    // 1st section, if any
                    ...state.DeviceFilters.slice(
                        0,
                        action.payload.index),
                    // Item to be modified
                    {
                        ...state.DeviceFilters[action.payload.index],
                        ...action.payload.deviceFilter
                    },
                    // 3rd section, if any
                    ...state.DeviceFilters.slice(
                        action.payload.index + 1,
                        state.DeviceFilters.length)
                ]
            };
        default:
            return state;
    }
};

export const newSiufAppStateReducer = (state: ISiufCreationAppState = ISiufCreationAppState_Default, action: SiufDomainAction) => {
    switch (action.type) {
        case SiufCreationActionTypes.UPDATE_NEWSIUF_PROPERTIES:
            return {
                ...state,
                newSiufModel: action.payload.newSiufModel
            };
        case SiufCreationActionTypes.CREATE_NEWSIUF_SENT:
            return {
                ...state,
                submitState: {
                    ...ISubmitState_Default,
                    requestState: RequestState.inProgress,
                    operation: SubmitOperation.createSiuf
                }
            };
        case SiufCreationActionTypes.CREATE_NEWSIUF_SUCCESS:
            return {
                ...state,
                submitState: {
                    ...state.submitState,
                    requestState: RequestState.complete,
                    errorType: ErrorType.none,
                    errorMessage: '',
                    response: action.payload.response
                }
            };
        case SiufCreationActionTypes.CREATE_NEWSIUF_FAIL:
            return {
                ...state,
                submitState: {
                    ...state.submitState,
                    requestState: RequestState.complete,
                    errorType: ErrorType.error,
                    errorMessage: action.payload.error
                }
            };
        default:
            return state;
    }
};

export const existingSiufAppStateReducer = (state: ISiufEditingAppState = ISiufEditingAppState_Default, action: SiufDomainAction) => {
    switch (action.type) {
        case SiufRetrievalActionTypes.GET_SIUF_FROM_SERVER_SENT:
            return {
                ...state,
                submitState: {
                    ...ISubmitState_Default,
                    requestState: RequestState.inProgress,
                    operation: SubmitOperation.loadSiuf
                }
            };
        case SiufRetrievalActionTypes.GET_SIUF_FROM_SERVER_SUCCESS:
            return {
                ...state,
                isDirty: false,
                siufEntity: {
                    ...state.siufEntity,
                    ...action.payload.response,
                    Payload: {
                        ...action.payload.response.Payload,
                        Pages: GetPagesWithQuestions(action.payload.response.Payload.Pages),
                        ActiveEditPage: 0,
                        ActivePreviewPage: 0
                    },
                    Targeting: action.payload.response.Targeting || ITargeting_Default,
                    StateModel: action.payload.response.StateModel || IStateModelEntity_Default
                },
                siufTypeSelection: GetSiufTypeSelectionFromSiufEntity(action.payload.response),
                currentSelectedPreviewOption: DEFAULT_PREVIEW_SELECTION,
                submitState: {
                    ...state.submitState,
                    requestState: RequestState.notStarted,  // No need to show complete message for loading
                    errorType: ErrorType.none,
                    errorMessage: '',
                    response: action.payload.response
                },
                sideloadSubmitState: {
                    ...state.sideloadSubmitState,
                    requestState: RequestState.notStarted,  // No need to show complete message for loading
                    errorType: ErrorType.none,
                    errorMessage: ''
                },
                downloadXmlSubmitState: {
                    ...state.downloadXmlSubmitState,
                    requestState: RequestState.notStarted,  // No need to show complete message for loading
                    errorType: ErrorType.none,
                    errorMessage: ''
                },
                validationSubmitState: {
                    ...state.validationSubmitState,
                    requestState: RequestState.notStarted,  // No need to show complete message for loading
                    errorType: ErrorType.none,
                    errorMessage: '',
                    Errors: []
                    
                }
            };
        case SiufRetrievalActionTypes.GET_SIUF_FROM_SERVER_FAIL:
            return {
                ...state,
                submitState: {
                    ...state.submitState,
                    requestState: RequestState.complete,
                    errorType: ErrorType.error,
                    errorMessage: action.payload.error
                }
            };
        case SiufCreationActionTypes.CREATE_NEWSIUF_SUCCESS:
            return {
                ...state,
                isDirty: false,
                siufEntity: {
                    ...state.siufEntity,
                    ...action.payload.response,
                    Payload: {
                        ...action.payload.response.Payload,
                        Pages: GetPagesWithQuestions(action.payload.response.Payload.Pages),
                        ActiveEditPage: 0,
                        ActivePreviewPage: 0
                    },
                    Targeting: (action.payload.response.Payload.TriggeringType === SiufTriggeringType.StateMachine) ? ITargeting_Default : null,
                    StateModel: (action.payload.response.Payload.TriggeringType === SiufTriggeringType.StateMachine) ? IStateModelEntity_Default : null
                }
            };
        case SiufBasicsActionTypes.UPDATE_SIUFTYPE:
            return {
                ...state,
                isDirty: true,
                siufTypeSelection: action.payload.siufType,
                siufEntity: {
                    ...state.siufEntity,
                    Payload: payloadAppStateReducer(state.siufEntity.Payload, action)
                }
            };
        case SiufTriggerActionTypes.UPDATE_LIFETIME_INDAYS:
            return {
                ...state,
                isDirty: true,
                siufEntity: {
                    ...state.siufEntity,
                    LifeSpanInDays: action.payload.durationInDays
                }
            };
        case TargetingActionTypes.UPDATE_JUSTIFICATION:
            return {
                ...state,
                isDirty: true,
                siufEntity: {
                    ...state.siufEntity,
                    Justifications: [
                        // 1st section, if any
                        ...state.siufEntity.Justifications.slice(
                            0,
                            state.siufEntity.Justifications.findIndex(j => j.QuestionType === action.payload.justification.QuestionType)),
                        // Item to be modified
                        {
                            ...state.siufEntity.Justifications.find(j => j.QuestionType === action.payload.justification.QuestionType),
                            Answer: action.payload.justification.Answer
                        },
                        // 3rd section, if any
                        ...state.siufEntity.Justifications.slice(
                            state.siufEntity.Justifications.findIndex(j => j.QuestionType === action.payload.justification.QuestionType) + 1,
                            state.siufEntity.Justifications.length)
                    ]
                }
            };
        case SiufTriggerActionTypes.UPDATE_TRIGGER_TYPE:
            return {
                ...state,
                isDirty: true,
                siufEntity: {
                    ...state.siufEntity,
                    Payload: payloadAppStateReducer(state.siufEntity.Payload, action),
                    Targeting: (action.payload.triggerType === SiufTriggeringType.StateMachine) ? state.siufEntity.Targeting : null,
                    StateModel: (action.payload.triggerType === SiufTriggeringType.StateMachine) ? state.siufEntity.StateModel : null,
                }
            };
        case QuestionDetailsActionTypes.UPDATE_QUESTIONTYPE:
        case QuestionDetailsActionTypes.UPDATE_LIKERTRESPONSETYPE:
        case QuestionDetailsActionTypes.UPDATE_LIKERTRESPONSECOUNT:
        case QuestionDetailsActionTypes.ADD_QUESTION:
        case QuestionDetailsActionTypes.REMOVE_QUESTION:
        case QuestionDetailsActionTypes.ADD_PAGE:
        case QuestionDetailsActionTypes.REMOVE_PAGE:
        case QuestionDetailsActionTypes.UPDATE_NPSQUESTION:
        case QuestionDetailsActionTypes.CHANGE_EDIT_PAGE:
        case QuestionDetailsActionTypes.CHANGE_PREVIEW_PAGE:
        case QuestionDetailsActionTypes.UPDATE_QUESTIONTEXT:
        case QuestionDetailsActionTypes.UPDATE_SIUFFOCUS:
        case QuestionDetailsActionTypes.UPDATE_ANCHORTEXT:
        case QuestionDetailsActionTypes.UPDATE_FOLLOWUPQUESTIONTEXT:
        case QuestionDetailsActionTypes.ADD_CHECKBOXTEXT:
        case QuestionDetailsActionTypes.UPDATE_CHECKBOXTEXT:
        case QuestionDetailsActionTypes.REMOVE_CHECKBOXTEXT:
        case QuestionDetailsActionTypes.ADD_LIKERTOPTION:
        case QuestionDetailsActionTypes.UPDATE_LIKERTOPTIONTEXT:
        case QuestionDetailsActionTypes.REMOVE_LIKERTOPTION:
        case QuestionDetailsActionTypes.ADD_LIKERTSCALEITEM:
        case QuestionDetailsActionTypes.UPDATE_LIKERTSCALEITEMTEXT:
        case QuestionDetailsActionTypes.REMOVE_LIKERTSCALEITEM:
        case QuestionDetailsActionTypes.UPDATE_TITLE:
        case QuestionDetailsActionTypes.UPDATE_IGNORETITLEONTOAST:
        case PropertiesActionTypes.UPDATE_ADVANCEDPROPERTY:
        case PropertiesActionTypes.UPDATE_PREVIOUS_QUESTION_ID:
        case TargetingActionTypes.UPDATE_TOAST_TEXT:
            return {
                ...state,
                isDirty: true,
                siufEntity: {
                    ...state.siufEntity,
                    Payload: payloadAppStateReducer(state.siufEntity.Payload, action)
                }
            };
        case TargetingActionTypes.UPDATE_MINBUILD:
        case TargetingActionTypes.UPDATE_MAXBUILD:
        case TargetingActionTypes.UPDATE_TARGETING_TYPE:
        case TargetingActionTypes.UPDATE_TARGETING_RING:
        case TargetingActionTypes.UPDATE_TARGETING_RINGCHANNEL:
        case TargetingActionTypes.UPDATE_AUDIENCE_NAME:
        case TargetingActionTypes.UPDATE_DEVICEID_URI:
        case TargetingActionTypes.UPDATE_CUSTOM_GROUP_IDS:
        case TargetingActionTypes.UPDATE_INCLUDE_TEST_SYSTEM:
        case TargetingActionTypes.ADD_DEVICE_FILTER:
        case TargetingActionTypes.REMOVE_DEVICE_FILTER:
        case TargetingActionTypes.UPDATE_DEVICE_FILTER:
            return {
                ...state,
                isDirty: true,
                siufEntity: {
                    ...state.siufEntity,
                    Targeting: targetingAppStateReducer(state.siufEntity.Targeting, action)
                }
            };
        case StateMachineActionTypes.SET_STATEMACHINENAME:
        case StateMachineActionTypes.ADD_TRANSITIONFILTER:
        case StateMachineActionTypes.REMOVE_TRANSITIONFILTER:
        case StateMachineActionTypes.UPDATE_TRANSITIONFILTER_REFERENCE:
        case StateMachineActionTypes.UPDATE_TRANSITIONFILTER_STRICT:
        case StateMachineActionTypes.REMOVE_TRANSITION:
        case StateMachineActionTypes.UPDATE_TRIGGERFILTER:
        case StateMachineActionTypes.UPDATE_TRIGGERFILTER_EVENT:
        case StateMachineActionTypes.UPDATE_TRANSITION:
        case StateMachineActionTypes.ADD_TRANSITION:
        case StateMachineActionTypes.UPDATE_TRIGGEREVENT:
        case StateMachineActionTypes.REMOVE_TRIGGEREVENT:
        case StateMachineActionTypes.ADD_TRIGGEREVENT:
        case StateMachineActionTypes.REMOVE_STATE:
        case StateMachineActionTypes.ADD_STATE:
            return {
                ...state,
                isDirty: true,
                siufEntity: {
                    ...state.siufEntity,
                    StateModel: {
                        ...state.siufEntity.StateModel,
                        StateMachineModel: stateMachineReducer(state.siufEntity.StateModel.StateMachineModel, action)
                    }
                }
            };
        case TargetingActionTypes.UPDATE_DEVICE_TYPE:
            return {
                ...state,
                isDirty: true,
                siufEntity: {
                    ...state.siufEntity,
                    Payload: payloadAppStateReducer(state.siufEntity.Payload, action),
                    Targeting: targetingAppStateReducer(state.siufEntity.Targeting, action)
                }
            };
        case StateMachineActionTypes.UPDATE_MAX_DURATION_IN_MS:
            return {
                ...state,
                isDirty: true,
                siufEntity: {
                    ...state.siufEntity,
                    StateModel: {
                        ...state.siufEntity.StateModel,
                        MaxDurationMs: action.payload.durationInMs
                    }
                }
            };
        case SiufBasicsActionTypes.UPDATE_SIUFNAME:
            return {
                ...state,
                isDirty: true,
                siufEntity: {
                    ...state.siufEntity,
                    SiufName: action.payload.siufName
                }
            };
        case SiufBasicsActionTypes.UPDATE_SIUFOWNER:
            return {
                ...state,
                isDirty: true,
                siufEntity: {
                    ...state.siufEntity,
                    Owner: action.payload.ownerAlias
                }
            };
        case SiufBasicsActionTypes.UPDATE_EXPORT_EXTENDED_PAYLOAD:
            return {
                ...state,
                exportExtendedPayload: action.payload.exportExtendedPayload
            };
        case SiufSubmissionActionTypes.DISMISS_SUBMIT_STATE:
            return {
                ...state,
                submitState: ISubmitState_Default
            };
        case SiufSubmissionActionTypes.SAVE_SIUF_SENT:
            return {
                ...state,
                submitState: {
                    ...ISubmitState_Default,
                    requestState: RequestState.inProgress,
                    operation: SubmitOperation.saveSiuf
                },
                validationSubmitState: {
                    ...ISubmitState_Default,
                    requestState: RequestState.notStarted,
                    operation: SubmitOperation.validateSiuf
                },
                validationResult: ISiufValidationResult_Default
            };
        case SiufSubmissionActionTypes.SAVE_SIUF_SUCCESS:
            return {
                ...state,
                isDirty: false,
                submitState: {
                    ...state.submitState,
                    requestState: RequestState.complete,
                    errorType: ErrorType.none,
                    errorMessage: '',
                    response: action.payload.response
                }
            };
        case SiufSubmissionActionTypes.SAVE_SIUF_FAIL:
            return {
                ...state,
                submitState: {
                    ...state.submitState,
                    requestState: RequestState.complete,
                    errorType: ErrorType.error,
                    errorMessage: action.payload.error
                }
            };
        case SiufSubmissionActionTypes.VALIDATE_SIUF_SENT:
            return {
                ...state,
                validationSubmitState: {
                    ...ISubmitState_Default,
                    requestState: RequestState.inProgress,
                    operation: SubmitOperation.validateSiuf
                },
                validationResult: ISiufValidationResult_Default
            };
        case SiufSubmissionActionTypes.VALIDATE_SIUF_SUCCESS:
            return {
                ...state,
                validationSubmitState: {
                    ...state.submitState,
                    requestState: RequestState.complete,
                    errorType: ErrorType.none,
                    errorMessage: '',
                    response: action.payload.response
                },
                validationResult: action.payload.response
            };
        case SiufSubmissionActionTypes.VALIDATE_SIUF_FAIL:
            return {
                ...state,
                validationSubmitState: {
                    ...state.submitState,
                    requestState: RequestState.complete,
                    errorType: ErrorType.error,
                    errorMessage: action.payload.error,
                    response: null
                }
            };
        case SiufSubmissionActionTypes.SUBMIT_SIUF_SENT:
            return {
                ...state,
                submitState: {
                    ...ISubmitState_Default,
                    requestState: RequestState.inProgress,
                    operation: SubmitOperation.submitSiufForReview
                }
            };
        case SiufSubmissionActionTypes.SUBMIT_SIUF_SUCCESS:
            return {
                ...state,
                submitState: {
                    ...state.submitState,
                    requestState: RequestState.complete,
                    errorType: ErrorType.none,
                    errorMessage: '',
                    response: action.payload.response
                }
            };
        case SiufSubmissionActionTypes.SUBMIT_SIUF_FAIL:
            return {
                ...state,
                submitState: {
                    ...state.submitState,
                    requestState: RequestState.complete,
                    errorType: ErrorType.error,
                    errorMessage: action.payload.error
                }
            };
        case SiufSubmissionActionTypes.WITHDRAW_SIUF_SENT:
            return {
                ...state,
                submitState: {
                    ...ISubmitState_Default,
                    requestState: RequestState.inProgress,
                    operation: SubmitOperation.withdrawSiufFromReview
                }
            };
        case SiufSubmissionActionTypes.WITHDRAW_SIUF_SUCCESS:
            return {
                ...state,
                submitState: {
                    ...state.submitState,
                    requestState: RequestState.complete,
                    errorType: ErrorType.none,
                    errorMessage: '',
                    response: action.payload.response
                }
            };
        case SiufSubmissionActionTypes.WITHDRAW_SIUF_FAIL:
            return {
                ...state,
                submitState: {
                    ...state.submitState,
                    requestState: RequestState.complete,
                    errorType: ErrorType.error,
                    errorMessage: action.payload.error
                }
            };
        case SiufSubmissionActionTypes.TERMINATE_SIUF_FROM_AUTHOR_SENT:
            return {
                ...state,
                submitState: {
                    ...ISubmitState_Default,
                    requestState: RequestState.inProgress,
                    operation: SubmitOperation.terminateSiuf
                }
            };
        case SiufSubmissionActionTypes.TERMINATE_SIUF_FROM_AUTHOR_SUCCESS:
            return {
                ...state,
                submitState: {
                    ...state.submitState,
                    requestState: RequestState.complete,
                    errorType: ErrorType.none,
                    errorMessage: '',
                    response: action.payload.response
                }
            };
        case SiufSubmissionActionTypes.TERMINATE_SIUF_FROM_AUTHOR_FAIL:
            return {
                ...state,
                submitState: {
                    ...state.submitState,
                    requestState: RequestState.complete,
                    errorType: ErrorType.error,
                    errorMessage: action.payload.error
                }
            };
        case SiufSubmissionActionTypes.REACTIVATE_SIUF_SENT:
            return {
                ...state,
                submitState: {
                    ...ISubmitState_Default,
                    requestState: RequestState.inProgress,
                    operation: SubmitOperation.reactivateSiuf
                }
            };
        case SiufSubmissionActionTypes.REACTIVATE_SIUF_SUCCESS:
            return {
                ...state,
                submitState: {
                    ...state.submitState,
                    requestState: RequestState.complete,
                    errorType: ErrorType.none,
                    errorMessage: '',
                    response: action.payload.response
                }
            };
        case SiufSubmissionActionTypes.REACTIVATE_SIUF_FAIL:
            return {
                ...state,
                submitState: {
                    ...state.submitState,
                    requestState: RequestState.complete,
                    errorType: ErrorType.error,
                    errorMessage: action.payload.error
                }
            };
        case SiufSubmissionActionTypes.SIDELOAD_SIUF_SENT:
            return {
                ...state,
                sideloadSubmitState: {
                    ...ISubmitState_Default,
                    requestState: RequestState.inProgress,
                    operation: SubmitOperation.sideloadSiuf
                }
            };
        case SiufSubmissionActionTypes.SIDELOAD_SIUF_SUCCESS:
            return {
                ...state,
                sideloadSubmitState: {
                    ...state.submitState,
                    requestState: RequestState.complete,
                    errorType: ErrorType.none,
                    errorMessage: '',
                    response: action.payload.response
                }
            };
        case SiufSubmissionActionTypes.SIDELOAD_SIUF_FAIL:
            return {
                ...state,
                sideloadSubmitState: {
                    ...state.submitState,
                    requestState: RequestState.complete,
                    errorType: ErrorType.error,
                    errorMessage: action.payload.error,
                    response: null
                }
            };
        case SiufSubmissionActionTypes.GET_SIUF_XML_SENT:
            return {
                ...state,
                downloadXmlSubmitState: {
                    ...ISubmitState_Default,
                    requestState: RequestState.inProgress,
                    operation: SubmitOperation.sideloadSiuf
                }
            };
        case SiufSubmissionActionTypes.GET_SIUF_XML_SUCCESS:
            return {
                ...state,
                downloadXmlSubmitState: {
                    ...state.submitState,
                    requestState: RequestState.complete,
                    errorType: ErrorType.none,
                    errorMessage: '',
                    response: action.payload.response
                }
            };
        case SiufSubmissionActionTypes.GET_SIUF_XML_FAIL:
            return {
                ...state,
                downloadXmlSubmitState: {
                    ...state.submitState,
                    requestState: RequestState.complete,
                    errorType: ErrorType.error,
                    errorMessage: action.payload.error,
                    response: null
                }
            };
        default:
            return state;
    }
};

export const authoringAppStateReducer = combineReducers<ISiufAuthoringDomainAppState>({
    newSiuf: newSiufAppStateReducer,
    currentSiuf: existingSiufAppStateReducer
});

// Ui State reducers

export const authoringOverallUiStateReducer = (state: ISectionUiState = ISectionUiState_Default, action: SiufDomainAction) => {
    switch (action.type) {
        case SiufRetrievalActionTypes.GET_SIUF_FROM_SERVER_SENT:
            return {
                ...state,
                isLoading: true
            };
        case SiufRetrievalActionTypes.GET_SIUF_FROM_SERVER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isReadOnly: IsSiufStateReadOnly(action.payload.response.State)
            };
        case SiufRetrievalActionTypes.GET_SIUF_FROM_SERVER_FAIL:
            return {
                ...state,
                isLoading: false
            };
        default:
            return state;
    }
};

export const authoringSiufTypeUiStateReducer = (state: ISectionUiState = ISectionUiState_Default, action: SiufDomainAction) => {
    switch (action.type) {
        case AuthoringUiActionTypes.UI_UPDATE_AUTHORING_SECTION_VISIBILITY:
            return {
                ...state,
                isVisible: (action.payload.section === SiufAuthoringSectionId.type) ?
                    action.payload.isVisible :
                    state.isVisible
            };
        case SiufRetrievalActionTypes.GET_SIUF_FROM_SERVER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isReadOnly: IsSiufStateReadOnly(action.payload.response.State)
            };
        default:
            return state;
    }
};

export const authoringQuestionDetailsUiStateReducer = (state: ISectionUiState = ISectionUiState_Default, action: SiufDomainAction) => {
    switch (action.type) {
        case AuthoringUiActionTypes.UI_UPDATE_AUTHORING_SECTION_VISIBILITY:
            return {
                ...state,
                isVisible: (action.payload.section === SiufAuthoringSectionId.question) ?
                    action.payload.isVisible :
                    state.isVisible
            };
        case SiufRetrievalActionTypes.GET_SIUF_FROM_SERVER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isReadOnly: IsSiufStateReadOnly(action.payload.response.State)
            };
        default:
            return state;
    }
};

export const authoringPreviewUiStateReducer = (state: ISectionUiState = ISectionUiState_Default, action: SiufDomainAction) => {
    switch (action.type) {
        case AuthoringUiActionTypes.UI_UPDATE_AUTHORING_SECTION_VISIBILITY:
            return {
                ...state,
                isVisible: (action.payload.section === SiufAuthoringSectionId.preview) ?
                    action.payload.isVisible :
                    state.isVisible
            };
        case SiufRetrievalActionTypes.GET_SIUF_FROM_SERVER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isReadOnly: IsSiufStateReadOnly(action.payload.response.State)
            };
        default:
            return state;
    }
};

export const authoringTriggerUiStateReducer = (state: ISectionUiState = ISectionUiState_Default, action: SiufDomainAction) => {
    switch (action.type) {
        case AuthoringUiActionTypes.UI_UPDATE_AUTHORING_SECTION_VISIBILITY:
            return {
                ...state,
                isVisible: (action.payload.section === SiufAuthoringSectionId.trigger) ?
                    action.payload.isVisible :
                    state.isVisible
            };
        case SiufRetrievalActionTypes.GET_SIUF_FROM_SERVER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isReadOnly: IsSiufStateReadOnly(action.payload.response.State)
            };
        default:
            return state;
    }
};

export const authoringTargetingUiStateReducer = (state: ISectionUiState = ISectionUiState_Default, action: SiufDomainAction) => {
    switch (action.type) {
        case AuthoringUiActionTypes.UI_UPDATE_AUTHORING_SECTION_VISIBILITY:
            return {
                ...state,
                isVisible: (action.payload.section === SiufAuthoringSectionId.targeting) ?
                    action.payload.isVisible :
                    state.isVisible
            };
        case SiufRetrievalActionTypes.GET_SIUF_FROM_SERVER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isReadOnly: IsSiufStateReadOnly(action.payload.response.State)
            };
        default:
            return state;
    }
};

export const authoringSettingsUiStateReducer = (state: ISectionUiState = ISectionUiState_Default, action: SiufDomainAction) => {
    switch (action.type) {
        case AuthoringUiActionTypes.UI_UPDATE_AUTHORING_SECTION_VISIBILITY:
            return {
                ...state,
                isVisible: (action.payload.section === SiufAuthoringSectionId.settings) ?
                    action.payload.isVisible :
                    state.isVisible
            };
        case SiufRetrievalActionTypes.GET_SIUF_FROM_SERVER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isReadOnly: IsSiufStateReadOnly(action.payload.response.State)
            };
        default:
            return state;
    }
};

export const authoringSubmissionUiStateReducer = (state: ISectionUiState = ISectionUiState_Default, action: SiufDomainAction) => {
    switch (action.type) {
        case AuthoringUiActionTypes.UI_UPDATE_AUTHORING_SECTION_VISIBILITY:
            return {
                ...state,
                isVisible: (action.payload.section === SiufAuthoringSectionId.submission) ?
                    action.payload.isVisible :
                    state.isVisible
            };
        default:
            return state;
    }
};

export const authoringUiStateReducer = combineReducers<ISiufAuthoringUiState>({
    overall: authoringOverallUiStateReducer,
    siufBasics: authoringSiufTypeUiStateReducer,
    questionDetails: authoringQuestionDetailsUiStateReducer,
    preview: authoringPreviewUiStateReducer,
    trigger: authoringTriggerUiStateReducer,
    targeting: authoringTargetingUiStateReducer,
    settings: authoringSettingsUiStateReducer,
    validation: authoringSubmissionUiStateReducer
});
