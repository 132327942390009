import * as React from 'react';
import { Button, Card, Divider, Form, Header, Icon, Input, Label, Message, Popup } from 'semantic-ui-react';
import { ITargetingEntity, IUpdateDeviceFilterModel, RegistryHiveType, RegistryOperators, RegistryValueType, SiufDeviceType, SiufRing, SiufTargetRingSelection, SiufTargetType, SiufTargetRingChannelSelection, SiufRingChannel, DISABLE_RETAIL_ALL_TARGETING, DISABLE_RINGS_TARGETING } from '../../../contracts/models';
import { ISectionUiState } from '../../../contracts/states';
import { ConvertRingSelectionFromRings, ConvertRingChannelSelectionFromRingChannels, GetSiufSupportedDeviceTypes, GetSupportedRegistryHiveTypes, GetSupportedRegistryOperators, GetSupportedRegistryValueTypes, GetSupportedSiufRingTypes, GetSupportedSiufRingChannelTypes, IsTestSystemIncludedDeviceFilterSet } from '../../../utils';
import '../../../siufAuthUx.css'

type Props = {
    targetingModel: ITargetingEntity;
    toastText: string;
    uiState: ISectionUiState;
    onUpdateDeviceType: (deviceType: SiufDeviceType) => void;
    onUpdateMinBuild: (minBuild: number) => void;
    onUpdateMaxBuild: (maxBuild: number) => void;
    onUpdateTargetingType: (targetingType: SiufTargetType) => void;
    onUpdateTargetingRing: (ringSelection: SiufTargetRingSelection, rings?: SiufRing[]) => void;
    onUpdateTargetingRingChannel: (ringChannelSelection: SiufTargetRingChannelSelection, ringChannels?: SiufRingChannel[]) => void;
    onUpdateAudience: (audienceName: string) => void;
    onUpdateDeviceIdUri: (deviceIdUri: string) => void;
    onUpdateCustomGroupIds: (customGroupIds: string[]) => void;
    onUpdateIncludeTestSystem: (includeTestSystem: boolean) => void;
    onAddDeviceFilter: () => void;
    onRemoveDeviceFilter: (index: number) => void;
    onUpdateDeviceFilter: (index: number, deviceFilter: IUpdateDeviceFilterModel) => void;
    onUpdateToastText: (toastText: string) => void;
};

const TargetingProperties: React.FC<Props> = props => {
    const popContentStyle = {
        backgroundColor: '#f8ffff',
        color: '#276f86'
    }
    let deviceTypeOptions = GetSiufSupportedDeviceTypes().map(dt => {
        return {
            key: dt.type,
            value: dt.type,
            text: dt.displayName
        };
    });

    const deviceType =
        props.uiState.isReadOnly
            ?
            <Form.Input fluid value={ deviceTypeOptions.find(o => o.value === props.targetingModel.DeviceType).text }
                label='Select the device type this SIUF targets'
                readOnly={ true }
                placeholder='Select SIUF life time' required
            />
            :
            <Form.Select fluid value={ props.targetingModel.DeviceType }
                label='Select the device type this SIUF targets'
                options={ deviceTypeOptions }
                placeholder='Select SIUF life time' required
                disabled={ props.uiState.isReadOnly }
                title='Select the device type this SIUF targets'
                tabIndex='8'
                onChange={ (event, data) => {
                    const dt = data.value as SiufDeviceType;
                    if (dt !== props.targetingModel.DeviceType) {
                        props.onUpdateDeviceType(dt);
                    }
                } }
            />;

    const toastText = (
        <Form.Input fluid value={ props.toastText }
            label='Enter a toast text (required for SIUFs on XBox)'
            readOnly={ props.uiState.isReadOnly }
            required
            placeholder='Enter the toast text here (max 64 characters)'
            title='Enter a toast text (required for SIUFs on XBox)'
            tabIndex='8'
            onChange={ e => { props.onUpdateToastText(e.currentTarget.value); } }
        />
    );

    const buildRange = (
        <Form.Group widths='equal'>
            <Form.Field value={ props.targetingModel.MinBuild || 0 }
                control='input' type='number' min={ 0 } max={ 999999 }
                label='Minimum Build'
                placeholder='Enter minimum build here' required
                readOnly={ props.uiState.isReadOnly }
                title='Minimum Build'
                tabIndex='8'
                onChange={ (e: any) => props.onUpdateMinBuild(Number(e.currentTarget.value)) }
            />
            <Form.Field value={ props.targetingModel.MaxBuild || undefined }
                control='input' type='number' min={ props.targetingModel.MinBuild } max={ 999999 }
                label='Maximum Build (Optional)'
                placeholder='Enter maximum build here'
                readOnly={ props.uiState.isReadOnly }
                title='Maximum Build (Optional)'
                tabIndex='8'
                onChange={ (e: any) => props.onUpdateMaxBuild(Number(e.currentTarget.value)) }
            />
        </Form.Group>
    );

    const targetingType = (
        <Form.Group inline>
            <label>Select the Targeting Type:</label>
            <Popup content='Rings will be deprecated in favour of Rings and Channels' position='bottom center' style={popContentStyle} trigger={
                <Form.Radio
                    label='Rings'
                    name='SiufTargeting_Rings'
                    readOnly={ props.uiState.isReadOnly || DISABLE_RINGS_TARGETING }
                    checked={ props.targetingModel.TargetType === SiufTargetType.Rings }
                    value={ SiufTargetType.Rings }
                    title='Rings'
                    tabIndex='8'
                    className = 'ui radio_button_new'
                    onChange={ () => props.onUpdateTargetingType(SiufTargetType.Rings) }
                />
            }/>
            <Form.Radio
                label='Rings and Channels'
                name='SiufTargeting_RingsAndChannels'
                readOnly={ props.uiState.isReadOnly }
                checked={ props.targetingModel.TargetType === SiufTargetType.RingChannels }
                value={ SiufTargetType.RingChannels }
                title='Rings and Channels'
                tabIndex='8'
                className = 'ui radio_button_new'
                onChange={ () => props.onUpdateTargetingType(SiufTargetType.RingChannels) }
            />
            <Form.Radio
                label='Audience'
                name='SiufTargeting_Audience'
                readOnly={ props.uiState.isReadOnly }
                checked={ props.targetingModel.TargetType === SiufTargetType.AudienceId }
                value={ SiufTargetType.AudienceId }
                title='Audience'
                tabIndex='8'
                className = 'ui radio_button_new'
                onChange={ () => props.onUpdateTargetingType(SiufTargetType.AudienceId) }
            />
            <Form.Radio
                label='Custom Groups'
                name='SiufTargeting_CustomGroup'
                readOnly={ props.uiState.isReadOnly }
                checked={ props.targetingModel.TargetType === SiufTargetType.CustomGroups }
                value={ SiufTargetType.CustomGroups }
                title='Custom Groups'
                tabIndex='8'
                className = 'ui radio_button_new'
                onChange={ () => props.onUpdateTargetingType(SiufTargetType.CustomGroups) }
            />
            <Form.Radio
                label='Device Id Uri'
                name='SiufTargeting_DeviceIdUri'
                readOnly={ props.uiState.isReadOnly }
                checked={ props.targetingModel.TargetType === SiufTargetType.DeviceIdUri }
                value={ SiufTargetType.DeviceIdUri }
                title='Device Id Uri'
                tabIndex='8'
                className = 'ui radio_button_new'
                onChange={ () => props.onUpdateTargetingType(SiufTargetType.DeviceIdUri) }
            />
            <Form.Radio
                label='Retail (Recommended Sampling)'
                name='SiufTargeting_RetailRecommendedSampling'
                readOnly={ props.uiState.isReadOnly }
                checked={ props.targetingModel.TargetType === SiufTargetType.RetailRecommendedSampling }
                value={ SiufTargetType.RetailRecommendedSampling }
                title='Retail (Recommended Sampling)'
                tabIndex='8'
                className = 'ui radio_button_new'
                onChange={ () => props.onUpdateTargetingType(SiufTargetType.RetailRecommendedSampling) }
            />
            <Form.Radio
                label='All Rings and Channels'
                name='SiufTargeting_AllRingsAndChannels'
                readOnly={ props.uiState.isReadOnly }
                checked={ props.targetingModel.TargetType === SiufTargetType.AllRingsAndAllDevices }
                value={ SiufTargetType.AllRingsAndAllDevices }
                title='All Rings and Channels'
                tabIndex='8'
                className = 'ui radio_button_new'
                onChange={() => props.onUpdateTargetingType(SiufTargetType.AllRingsAndAllDevices) }
            />
        </Form.Group>
    );

    const selectedRings = ((props.targetingModel.TargetType === SiufTargetType.Rings) && (props.targetingModel.Rings)) ?
        ConvertRingSelectionFromRings(props.targetingModel.Rings) : SiufTargetRingSelection.custom;

    const rings = (props.targetingModel.TargetType === SiufTargetType.Rings) && (
        <React.Fragment>
            <Message info content='With the new addition of Channels to the Windows release system, 
            the &apos;Rings&apos; tab will be deprecated in the near future in favor of the new &apos;Rings and Channels&apos; tab' />            
            <Header as='h5'>Select the Rings to target:</Header>
            <Form.Radio
                label='Default: Internal Microsoft and Insider Rings (Canary, Selfhost, Microsoft, Insider Fast, Insider Slow, Release Preview, NOT_SET)'
                name='SiufTargetingRings_DefaultInternalMicrosoftAndInsiderRings'
                readOnly={ props.uiState.isReadOnly }
                checked={ selectedRings === SiufTargetRingSelection.internalMicrosoftAndInsider }
                value={ SiufTargetRingSelection.internalMicrosoftAndInsider }
                title='Default: Internal Microsoft and Insider Rings (Canary, Selfhost, Microsoft, Insider Fast, Insider Slow, Release Preview, NOT_SET)'
                tabIndex='8'
                className = 'ui radio_button_new'
                onChange={ () => props.onUpdateTargetingRing(SiufTargetRingSelection.internalMicrosoftAndInsider) }
            />
            <Form.Radio
                label='Internal Microsoft Rings only (Canary, Selfhost)'
                name='SiufTargetingRings_InternalMicrosoftRingsOnly'
                readOnly={ props.uiState.isReadOnly }
                checked={ selectedRings === SiufTargetRingSelection.internalMicrosoft }
                value={ SiufTargetRingSelection.internalMicrosoft }
                title='Internal Microsoft Rings only (Canary, Selfhost)'
                tabIndex='8'
                className = 'ui radio_button_new'
                onChange={ () => props.onUpdateTargetingRing(SiufTargetRingSelection.internalMicrosoft) }
            />
            <Form.Radio
                label='Insider Rings Only (Microsoft, Insider Fast, Insider Slow, NOT_SET)'
                name='SiufTargetingRings_InsiderRingsOnly'
                readOnly={ props.uiState.isReadOnly }
                checked={ selectedRings === SiufTargetRingSelection.insider }
                value={ SiufTargetRingSelection.insider }
                title='Insider Rings Only (Microsoft, Insider Fast, Insider Slow, NOT_SET)'
                tabIndex='8'
                className = 'ui radio_button_new'
                onChange={ () => props.onUpdateTargetingRing(SiufTargetRingSelection.insider) }
            />
            <Form.Radio
                label='Retail (No Sampling)'
                name='SiufTargetingRings_Retail'
                readOnly={ props.uiState.isReadOnly || DISABLE_RETAIL_ALL_TARGETING }
                checked={ selectedRings === SiufTargetRingSelection.retailAll }
                value={ SiufTargetRingSelection.retailAll }
                title='Retail (No Sampling)'
                tabIndex='8'
                className = 'ui radio_button_new'
                onChange={ () => props.onUpdateTargetingRing(SiufTargetRingSelection.retailAll) }
            />
            <Form.Radio
                label='Custom Selection'
                name='SiufTargetingRings_CustomSelection'
                readOnly={ props.uiState.isReadOnly }
                checked={ selectedRings === SiufTargetRingSelection.custom }
                value={ SiufTargetRingSelection.custom }
                title='Custom Selection'
                tabIndex='8'
                className = 'ui radio_button_new'
                onChange={ () => props.onUpdateTargetingRing(SiufTargetRingSelection.custom, []) }
            />
            {
                (selectedRings === SiufTargetRingSelection.custom)
                &&
                <Form.Select value={ props.targetingModel.Rings }
                    label='Select a custom combination of SIUF Rings'
                    multiple
                    options={
                        GetSupportedSiufRingTypes().map(dt => {
                            return {
                                key: dt.type,
                                value: dt.type,
                                text: dt.displayName
                            };
                        })
                    }
                    placeholder='No SIUF Rings selected'
                    disabled={ props.uiState.isReadOnly }
                    title='Select a custom combination of SIUF Rings'
                    tabIndex='8'
                    onChange={ (event, data) => {
                        const values = data.value as SiufRing[];
                        props.onUpdateTargetingRing(SiufTargetRingSelection.custom, values);
                    } }
                />
            }
        </React.Fragment>
    );

    const selectedRingChannels = ((props.targetingModel.TargetType === SiufTargetType.RingChannels) && (props.targetingModel.RingChannels)) ?
        ConvertRingChannelSelectionFromRingChannels(props.targetingModel.RingChannels) : SiufTargetRingChannelSelection.custom;

    const ringChannels = (props.targetingModel.TargetType === SiufTargetType.RingChannels) && (
        <React.Fragment>
            <Header as='h5'>Select the Rings and Channels to target:</Header>
            <Form.Radio
                label='Default: Internal Microsoft and Insider Rings (Canary, Selfhost, Microsoft, Insider Fast, Insider Slow, Release Preview, NOT_SET)'
                name='SiufTargetingRingChannels_DefaultInternal Microsoft'
                readOnly={ props.uiState.isReadOnly }
                checked={selectedRingChannels === SiufTargetRingChannelSelection.internalMicrosoftAndInsider }
                value={ SiufTargetRingChannelSelection.internalMicrosoftAndInsider }
                title='Default: Internal Microsoft and Insider Rings (Canary, Selfhost, Microsoft, Insider Fast, Insider Slow, Release Preview, NOT_SET)'
                tabIndex='8'
                className = 'ui radio_button_new'
                onChange={ () => props.onUpdateTargetingRingChannel(SiufTargetRingChannelSelection.internalMicrosoftAndInsider) }
            />
            <Form.Radio
                label='Internal Microsoft Rings only (Canary, Selfhost)'
                name='SiufTargetingRingChannels_InternalMicrosoftRingsOnly'
                readOnly={ props.uiState.isReadOnly }
                checked={selectedRingChannels === SiufTargetRingChannelSelection.internalMicrosoft }
                value={ SiufTargetRingChannelSelection.internalMicrosoft }
                title='Internal Microsoft Rings only (Canary, Selfhost)'
                tabIndex='8'
                className = 'ui radio_button_new'
                onChange={ () => props.onUpdateTargetingRingChannel(SiufTargetRingChannelSelection.internalMicrosoft) }
            />
            <Form.Radio
                label='Insider Rings Only (Microsoft, Insider Fast, Insider Slow, NOT_SET)'
                name='SiufTargetingRingChannels_InsiderRingsOnly'
                readOnly={ props.uiState.isReadOnly }
                checked={selectedRingChannels === SiufTargetRingChannelSelection.insider }
                value={ SiufTargetRingChannelSelection.insider }
                title='Insider Rings Only (Microsoft, Insider Fast, Insider Slow, NOT_SET)'
                tabIndex='8'
                className = 'ui radio_button_new'
                onChange={ () => props.onUpdateTargetingRingChannel(SiufTargetRingChannelSelection.insider) }
            />
            <Form.Radio
                label='Default Channels: Dev and Beta Channels (Dev_Internal, Dev_External, Beta_Internal, Beta_External)'
                name='SiufTargetingRingChannels_DefaultChannelsDevAndBeta'
                readOnly={ props.uiState.isReadOnly }
                checked={selectedRingChannels === SiufTargetRingChannelSelection.internalMicrosoftAndInsiderChannel }
                value={ SiufTargetRingChannelSelection.internalMicrosoftAndInsiderChannel }
                title='Default Channels: Dev and Beta Channels (Dev_Internal, Dev_External, Beta_Internal, Beta_External)'
                tabIndex='8'
                className = 'ui radio_button_new'
                onChange={ () => props.onUpdateTargetingRingChannel(SiufTargetRingChannelSelection.internalMicrosoftAndInsiderChannel) }
            />
             <Form.Radio
                label='Internal Microsoft Channel Only (Dev_Internal)'
                name='SiufTargetingRingChannels_InternalMicrosoftChannelOnly'
                readOnly={ props.uiState.isReadOnly }
                checked={selectedRingChannels === SiufTargetRingChannelSelection.internalMicrosoftChannel }
                value={ SiufTargetRingChannelSelection.internalMicrosoftChannel }
                title='Internal Microsoft Channel Only (Dev_Internal)'
                tabIndex='8'
                className = 'ui radio_button_new'
                onChange={ () => props.onUpdateTargetingRingChannel(SiufTargetRingChannelSelection.internalMicrosoftChannel) }
            />
             <Form.Radio
                label='Insider Channels Only (Dev_External, Beta_Internal, Beta_External) '
                name='SiufTargetingRingChannels_InsiderChannelsOnly'
                readOnly={ props.uiState.isReadOnly }
                checked={selectedRingChannels === SiufTargetRingChannelSelection.insiderChannel }
                value={ SiufTargetRingChannelSelection.insiderChannel }
                title='Insider Channels Only (Dev_External, Beta_Internal, Beta_External) '
                tabIndex='8'
                className = 'ui radio_button_new'
                onChange={ () => props.onUpdateTargetingRingChannel(SiufTargetRingChannelSelection.insiderChannel) }
            />
            <Form.Radio
                label='Internal Preview Channels Only (Feature_Preview_Internal, Release_Preview_Internal) '
                name='SiufTargetingRingChannels_InternalPreviewChannelsOnly'
                readOnly={props.uiState.isReadOnly}
                checked={selectedRingChannels === SiufTargetRingChannelSelection.internalPreviewChannel}
                value={SiufTargetRingChannelSelection.internalPreviewChannel}
                title='Internal Preview Channels Only (Feature_Preview_Internal, Release_Preview_Internal) '
                tabIndex='8'
                className = 'ui radio_button_new'
                onChange={() => props.onUpdateTargetingRingChannel(SiufTargetRingChannelSelection.internalPreviewChannel)}
            />
            <Form.Radio
                label='Feature and Release Preview Channels (Feature_Preview_External, Release_Preview_External) '
                name='SiufTargetingRingChannels_FeatureAndReleasePreviewChannels'
                readOnly={props.uiState.isReadOnly}
                checked={selectedRingChannels === SiufTargetRingChannelSelection.previewChannel}
                value={SiufTargetRingChannelSelection.previewChannel}
                title='Feature and Release Preview Channels (Feature_Preview_External, Release_Preview_External) '
                tabIndex='8'
                className = 'ui radio_button_new'
                onChange={() => props.onUpdateTargetingRingChannel(SiufTargetRingChannelSelection.previewChannel)}
            />
            <Form.Radio
                label='Retail (No Sampling)'
                name='SiufTargetingRingChannels_Retail'
                readOnly={props.uiState.isReadOnly || DISABLE_RETAIL_ALL_TARGETING }
                checked={selectedRingChannels === SiufTargetRingChannelSelection.retailAll}
                value={SiufTargetRingChannelSelection.retailAll}
                title='Retail (No Sampling)'
                tabIndex='8'
                className = 'ui radio_button_new'
                onChange={() => props.onUpdateTargetingRingChannel(SiufTargetRingChannelSelection.retailAll)}
            />
            <Form.Radio
                label='Custom Selection'
                name='SiufTargetingRingChannels_CustomSelection'
                readOnly={ props.uiState.isReadOnly }
                checked={selectedRingChannels === SiufTargetRingChannelSelection.custom }
                value={ SiufTargetRingChannelSelection.custom }
                title='Custom Selection'
                tabIndex='8'
                className = 'ui radio_button_new'
                onChange={ () => props.onUpdateTargetingRingChannel(SiufTargetRingChannelSelection.custom, []) }
            />
            {
                (selectedRingChannels === SiufTargetRingChannelSelection.custom)
                &&
                <Form.Select value={ props.targetingModel.RingChannels }
                    label='Select a custom combination of SIUF Rings and Channels'
                    multiple
                    options={
                        GetSupportedSiufRingChannelTypes().map(dt => {
                            return {
                                key: dt.type,
                                value: dt.type,
                                text: dt.displayName
                            };
                        })
                    }
                    placeholder='No SIUF Rings or Channels selected'
                    disabled={ props.uiState.isReadOnly }
                    title='Select a custom combination of SIUF Rings and Channels'
                    tabIndex='8'
                    onChange={ (event, data) => {
                        const values = data.value as SiufRingChannel[];
                        props.onUpdateTargetingRingChannel(SiufTargetRingChannelSelection.custom, values);
                    } }
                />
            }
        </React.Fragment>
    );

    const audience = (props.targetingModel.TargetType === SiufTargetType.AudienceId) && (
        <Form.Input fluid label='Specify the Audience name' required value={ props.targetingModel.AudienceName }
            readOnly={ props.uiState.isReadOnly }
            placeholder='Enter the name of the audience'
            title='Specify the Audience name'
            tabIndex='8'
            onChange={ e => { props.onUpdateAudience(e.currentTarget.value); } } />
    );

    const deviceIdList = (props.targetingModel.TargetType === SiufTargetType.DeviceIdUri) && (
        <Form.Input fluid label='Specify the uri for a list of device IDs' required value={ props.targetingModel.DeviceIdUri }
            readOnly={ props.uiState.isReadOnly }
            placeholder='Enter the uri for a list of devices'
            title='Specify the uri for a list of device IDs'
            tabIndex='8'
            onChange={ e => { props.onUpdateDeviceIdUri(e.currentTarget.value); } } />
    );

    const customGroupIdLabel = (props.targetingModel.TargetType === SiufTargetType.CustomGroups) && (
        props.targetingModel.CustomGroupIds
            ?
            props.targetingModel.CustomGroupIds.map((g, i) => {
                return <Label basic color='black' key={ i }>
                    { g }
                    <Icon name="delete" color="red"
                        onClick={ () => {
                            if (props.targetingModel.CustomGroupIds) {
                                props.onUpdateCustomGroupIds(props.targetingModel.CustomGroupIds.filter(cgi => cgi !== g));
                            }
                        } } />
                </Label>;
            })
            :
            []
    );

    let addCustomGroupInput: HTMLInputElement;

    const customGroupIds = (props.targetingModel.TargetType === SiufTargetType.CustomGroups) && (
        <React.Fragment>
            <Header as='h5'>Add custom group Ids:</Header>
            <Label.Group>
                { customGroupIdLabel }
            </Label.Group>
            <Input fluid type="text" placeholder="Enter new group" action>
                <input ref={ node => addCustomGroupInput = node } title='Enter new group' tabIndex='8'/>
                <Button positive title='Enter new group' tabIndex='8' onClick={ () => {
                    const newGroup = addCustomGroupInput.value && addCustomGroupInput.value.trim();
                    if (newGroup && props.targetingModel.CustomGroupIds && !props.targetingModel.CustomGroupIds.includes(newGroup)) {
                        props.onUpdateCustomGroupIds([...props.targetingModel.CustomGroupIds, newGroup]);
                        addCustomGroupInput.value = "";
                    }
                } }>
                    Add
                </Button>
            </Input>
        </React.Fragment>
    );

    let targetingUi: JSX.Element;

    switch (props.targetingModel.TargetType) {
        case SiufTargetType.Rings:
            targetingUi = rings;
            break;
        case SiufTargetType.RingChannels:
            targetingUi = ringChannels;
            break;
        case SiufTargetType.AudienceId:
            targetingUi = audience;
            break;
        case SiufTargetType.DeviceIdUri:
            targetingUi = deviceIdList;
            break;
        case SiufTargetType.CustomGroups:
            targetingUi = customGroupIds;
            break;
        case SiufTargetType.RetailRecommendedSampling:
        case SiufTargetType.AllRingsAndAllDevices:
            break;
        default:
            targetingUi = (
                <Header as='h3'>{ `Targeting type ${props.targetingModel.TargetType}not recognized` }</Header>
            );
    }

    let deviceFilters = (
        <React.Fragment>
            <Header as='h5'>Manage Device Filters (Optional):</Header>
            <Form.Group inline>
                {
                    !props.uiState.isReadOnly
                    &&
                    <Form.Button positive title='Add new Device Filter' tabIndex='8' onClick={ () => props.onAddDeviceFilter() }>
                        Add new Device Filter
                    </Form.Button>
                }
                <Form.Checkbox label='Do not target test systems'
                    readOnly={ props.uiState.isReadOnly }
                    checked={
                        props.targetingModel.DeviceFilters
                        &&
                        props.targetingModel.DeviceFilters.findIndex(df => IsTestSystemIncludedDeviceFilterSet(df)) !== -1
                    }
                    title='Do not target test systems' tabIndex='8' onChange={ (event, data) => props.onUpdateIncludeTestSystem(data.checked) }
                />
            </Form.Group>
            <Card.Group>
                {
                    props.targetingModel.DeviceFilters.map((df, i) => {
                        return (
                            <Card key={ i }>
                                <Card.Content>
                                    <React.Fragment key={ i }>
                                        <Form.Input label='Device Filter Name'
                                            fluid required
                                            value={ df.FilterName }
                                            readOnly={ props.uiState.isReadOnly }
                                            placeholder='Enter a name for your device filter'
                                            title='Device Filter Name'
                                            tabIndex='8'
                                            onChange={ e => props.onUpdateDeviceFilter(i, {
                                                FilterName: e.currentTarget.value,
                                                Hive: df.Hive,
                                                KeyName: df.KeyName,
                                                ValueName: df.ValueName,
                                                ValueType: df.ValueType,
                                                Value: df.Value,
                                                Operation: df.Operation
                                            }) } />
                                        <Form.Select value={ df.Hive }
                                            label='Registry Hive'
                                            options={
                                                GetSupportedRegistryHiveTypes().map(h => {
                                                    return {
                                                        key: h.type,
                                                        value: h.type,
                                                        text: h.displayName
                                                    };
                                                })
                                            }
                                            placeholder='No registry hive selected'
                                            disabled={ props.uiState.isReadOnly }
                                            title='Registry Hive'
                                            tabIndex='8'
                                            onChange={ (event, data) => {
                                                const value = data.value as RegistryHiveType;
                                                props.onUpdateDeviceFilter(i, {
                                                    FilterName: df.FilterName,
                                                    Hive: value,
                                                    KeyName: df.KeyName,
                                                    ValueName: df.ValueName,
                                                    ValueType: df.ValueType,
                                                    Value: df.Value,
                                                    Operation: df.Operation
                                                });
                                            } }
                                        />
                                        <Form.Input label='Registry Key Name'
                                            fluid required
                                            value={ df.KeyName }
                                            readOnly={ props.uiState.isReadOnly }
                                            placeholder='Enter the registry key name'
                                            title='Registry Key Name'
                                            tabIndex='8'
                                            onChange={ e => props.onUpdateDeviceFilter(i, {
                                                FilterName: df.FilterName,
                                                Hive: df.Hive,
                                                KeyName: e.currentTarget.value,
                                                ValueName: df.ValueName,
                                                ValueType: df.ValueType,
                                                Value: df.Value,
                                                Operation: df.Operation
                                            }) } />
                                        <Form.Select value={ df.ValueType }
                                            label='Registry Value Type'
                                            options={
                                                GetSupportedRegistryValueTypes().map(v => {
                                                    return {
                                                        key: v.type,
                                                        value: v.type,
                                                        text: v.displayName
                                                    };
                                                })
                                            }
                                            placeholder='No registry value type selected'
                                            disabled={ props.uiState.isReadOnly }
                                            title='Registry Value Type'
                                            tabIndex='8'
                                            onChange={ (event, data) => {
                                                const value = data.value as RegistryValueType;
                                                props.onUpdateDeviceFilter(i, {
                                                    FilterName: df.FilterName,
                                                    Hive: df.Hive,
                                                    KeyName: df.KeyName,
                                                    ValueName: df.ValueName,
                                                    ValueType: value,
                                                    Value: df.Value,
                                                    Operation: df.Operation
                                                });
                                            } }
                                        />
                                        <Form.Input label='Registry Value Name'
                                            fluid required
                                            value={ df.ValueName }
                                            readOnly={ props.uiState.isReadOnly }
                                            placeholder='Enter the registry value name'
                                            title='Registry Value Name'
                                            tabIndex='8'
                                            onChange={ e => props.onUpdateDeviceFilter(i, {
                                                FilterName: df.FilterName,
                                                Hive: df.Hive,
                                                KeyName: df.KeyName,
                                                ValueName: e.currentTarget.value,
                                                ValueType: df.ValueType,
                                                Value: df.Value,
                                                Operation: df.Operation
                                            }) } />
                                        <Form.Input label='Registry Value'
                                            fluid
                                            value={ df.Value }
                                            readOnly={ props.uiState.isReadOnly }
                                            placeholder='Enter registry the value'
                                            title='Registry Value'
                                            tabIndex='8'
                                            onChange={ e => props.onUpdateDeviceFilter(i, {
                                                FilterName: df.FilterName,
                                                Hive: df.Hive,
                                                KeyName: df.KeyName,
                                                ValueName: df.ValueName,
                                                ValueType: df.ValueType,
                                                Value: e.currentTarget.value,
                                                Operation: df.Operation
                                            }) } />
                                        <Form.Select value={ df.Operation }
                                            label='Operation'
                                            options={
                                                GetSupportedRegistryOperators().map(op => {
                                                    return {
                                                        key: op.type,
                                                        value: op.type,
                                                        text: op.displayName
                                                    };
                                                })
                                            }
                                            placeholder='No operation selected'
                                            disabled={ props.uiState.isReadOnly }
                                            title='No operation selected'
                                            tabIndex='8'
                                            onChange={ (event, data) => {
                                                const value = data.value as RegistryOperators;
                                                props.onUpdateDeviceFilter(i, {
                                                    FilterName: df.FilterName,
                                                    Hive: df.Hive,
                                                    KeyName: df.KeyName,
                                                    ValueName: df.ValueName,
                                                    ValueType: df.ValueType,
                                                    Value: df.Value,
                                                    Operation: value
                                                });
                                            } }
                                        />
                                    </React.Fragment>
                                </Card.Content>
                                <Card.Content extra>
                                    {
                                        !props.uiState.isReadOnly
                                        &&
                                        <Button fluid negative tabIndex='8' onClick={ () => props.onRemoveDeviceFilter(i) }>
                                            Remove this Device Filter
                                        </Button>
                                    }
                                </Card.Content>
                            </Card>
                        );
                    })
                }
            </Card.Group>
        </React.Fragment>
    );

    return (
        <React.Fragment>
            <Form>
                { deviceType }
                {
                    (props.targetingModel.DeviceType === SiufDeviceType.Xbox)
                    &&
                    toastText
                }
                { buildRange }
                <Divider hidden />
                <Divider />
                { targetingType }
                { targetingUi }
                <Divider />
                { deviceFilters }
                <Divider hidden />
            </Form>
        </React.Fragment>
    );
};

export default TargetingProperties;