import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import CheckboxPreview from '../../../components/authoring/questionPreview/CheckboxPreview';
import { IApplicationRootReducerState } from "../../../configureStore";
import { IQuestionEntity } from '../../../contracts/models';

type Props = {
    questionModel: IQuestionEntity
};

const mapStateToProps = (state: IApplicationRootReducerState, ownProps: Props) => ({
    questionStructure: ownProps.questionModel.QuestionStructure,
    questionText: ownProps.questionModel.QuestionText,
    checkboxTexts: ownProps.questionModel.Answers.sort((a, b) => (a.AnswerIndex > b.AnswerIndex) ? 1 : -1).map(a => a.AnswerText),
    followupQuestion: ownProps.questionModel.Answers[0].FollowUpQuestion ? ownProps.questionModel.Answers[0].FollowUpQuestion.QuestionText : "",
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
 });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CheckboxPreview);
