import { connect } from 'react-redux';
import { IApplicationRootReducerState } from "../../../configureStore";
import { Dispatch } from 'redux';
import * as siufAuthoringActions from '../../../actions';
import * as selectors from '../../../selectors';
import { ISiufReviewSearchProperty } from '../../../contracts/models';
import SearchReview from '../../../components/reviewing/search/SearchReview';

type Props = {
};

const mapStateToProps = (state: IApplicationRootReducerState, ownProps: Props) => ({
    siufCollection: selectors.getReviewSiufCollectionAppState(state),
    searchProperties: selectors.getReviewSearchAppState(state),
    uiState: selectors.getReviewSearchUiState(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    onUpdateSearchProperty: (searchProperties: ISiufReviewSearchProperty) => dispatch(siufAuthoringActions.updateSearchProperty(searchProperties))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchReview);