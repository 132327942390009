import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import * as ActionCreators from '../../../../actions/authoring/stateMachine/actions';
import DetailsPanel from '../../../../components/authoring/stateMachine/transitions/DetailsPanel';
import { IApplicationRootReducerState } from "../../../../configureStore";
import { IUpdateTransitionModel } from '../../../../contracts/states/stateMachineStates';

const mapStateToProps = (state: IApplicationRootReducerState) => ({});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
    updateTransition: (transitionChanges: IUpdateTransitionModel) => dispatch(ActionCreators.updateStateMachineTransition(transitionChanges))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DetailsPanel);