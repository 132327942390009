import { ISiufDomainAppState, ISiufDomainAppState_Default } from "./appStates";
import { ISiufDomainUiState, ISiufDomainUiState_Default } from "./uiStates";

export * from './appStates';
export * from './uiStates';
export * from './stateMachineStates';

export interface ISiufDomainState {
    appState: ISiufDomainAppState;
    uiState: ISiufDomainUiState;
}

export const ISiufDomainState_Default: ISiufDomainState = {
    "appState": ISiufDomainAppState_Default,
    "uiState": ISiufDomainUiState_Default
};