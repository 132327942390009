export enum SiufBasicsActionTypes {

    // Ajax related actions types

    // normal action types

    UPDATE_SIUFTYPE = '@@SIUF/BASICS/UPDATE_SIUFTYPE',
    UPDATE_SIUFNAME = '@@SIUF/BASICS/UPDATE_SIUFNAME',
    UPDATE_SIUFOWNER = '@@SIUF/BASICS/UPDATE_SIUFOWNER',
    UPDATE_EXPORT_EXTENDED_PAYLOAD = '@@SIUF/BASICS/UPDATE_EXPORT_EXTENDED_PAYLOAD'
}