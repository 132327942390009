import { action } from "typesafe-actions";
import { SiufBasicsActionTypes } from ".";
import { SiufTypeSelection } from "../../../contracts/models";

export const updateSiufType = (siufType: SiufTypeSelection) => action(
    SiufBasicsActionTypes.UPDATE_SIUFTYPE,
    {
        siufType
    }
);

export const updateSiufName = (siufName: string) => action(
    SiufBasicsActionTypes.UPDATE_SIUFNAME,
    {
        siufName
    }
);

export const updateSiufOwner = (ownerAlias: string) => action(
    SiufBasicsActionTypes.UPDATE_SIUFOWNER,
    {
        ownerAlias
    }
);

export const updateExportExtendedPayload = (exportExtendedPayload: boolean) => action(
    SiufBasicsActionTypes.UPDATE_EXPORT_EXTENDED_PAYLOAD,
    {
        exportExtendedPayload
    }
);
