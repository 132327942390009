export enum TargetingActionTypes {

    // Ajax related actions types

    // normal action types

    UPDATE_DEVICE_TYPE = '@@SIUF/TARGETING/UPDATE_DEVICE_TYPE',
    UPDATE_MINBUILD = '@@SIUF/TARGETING/UPDATE_MINBUILD',
    UPDATE_MAXBUILD = '@@SIUF/TARGETING/UPDATE_MAXBUILD',
    UPDATE_TARGETING_TYPE = '@@SIUF/TARGETING/UPDATE_TARGETING_TYPE',
    UPDATE_TARGETING_RING = '@@SIUF/TARGETING/UPDATE_TARGETING_RING',
    UPDATE_TARGETING_RINGCHANNEL = '@@SIUF/TARGETING/UPDATE_TARGETING_RINGCHANNEL',
    UPDATE_AUDIENCE_NAME = '@@SIUF/TARGETING/UPDATE_AUDIENCE',
    UPDATE_DEVICEID_URI = '@@SIUF/TARGETING/UPDATE_DEVICEID_URI',
    UPDATE_CUSTOM_GROUP_IDS = '@@SIUF/TARGETING/UPDATE_CUSTOM_GROUP_IDS',
    UPDATE_JUSTIFICATION = '@@SIUF/TARGETING/UPDATE_JUSTIFICATION',
    UPDATE_INCLUDE_TEST_SYSTEM = '@@SIUF/TARGETING/UPDATE_INCLUDE_TEST_SYSTEM',
    ADD_DEVICE_FILTER = '@@SIUF/TARGETING/ADD_DEVICE_FILTER',
    REMOVE_DEVICE_FILTER = '@@SIUF/TARGETING/REMOVE_DEVICE_FILTER',
    UPDATE_DEVICE_FILTER = '@@SIUF/TARGETING/UPDATE_DEVICE_FILTER',
    UPDATE_TOAST_TEXT = '@@SIUF/TARGETING/UPDATE_TOAST_TEXT'
}