// https://github.com/supasate/connected-react-router

import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware, RouterState } from 'connected-react-router';
import { createBrowserHistory, BrowserHistoryBuildOptions } from 'history';
import { composeWithDevTools as compseWithDevToolsDevelopment } from 'redux-devtools-extension';
import { composeWithDevTools as compseWithDevToolsProduction } from 'redux-devtools-extension/logOnly';
import createRootReducer from './reducers';
import { IResourceProviderOptions } from './contracts';
import { ISiufDomainState, ISiufDomainState_Default } from './contracts/states';

export interface IApplicationRootReducerState {
    siufDomain: ISiufDomainState;
    router: RouterState;
    resourceProviders: IResourceProviderOptions;
}

// Create browser history to use in the Redux store
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
export const history = createBrowserHistory({ basename: baseUrl } as BrowserHistoryBuildOptions);

export default function configureStore (resourceConfigs: IResourceProviderOptions) {
    const middleware = [
        thunk,
        routerMiddleware(history)
    ];

    // In development, use the browser's Redux dev tools extension if installed
    const enhancers: any = [];
    const isDevelopment = process.env.NODE_ENV === 'development';

    const initialState = {
        // Any future states will be computed by the root reducer
        siufDomain: ISiufDomainState_Default,
        // Router will be setup by the root reducer
        router: null,
        // Get the initial configs from the environment
        resourceProviders: resourceConfigs
    } as IApplicationRootReducerState;

    // We use differnt DevTools for development vs production
    // DevTools: https://github.com/zalmoxisus/redux-devtools-extension
    const store = isDevelopment
        ? createStore(
            createRootReducer(history),
            initialState,
            compseWithDevToolsDevelopment(
                applyMiddleware(
                    ...middleware),
                ...enhancers))
        : createStore(
            createRootReducer(history),
            initialState,
            compseWithDevToolsProduction(
                applyMiddleware(
                    ...middleware),
                ...enhancers));

    return store;
}
