import { connect } from 'react-redux';
import { IApplicationRootReducerState } from "../../configureStore";
import * as selectors from '../../selectors';
import * as siufAuthoringActions from '../../actions';
import { RouteComponentProps } from 'react-router';
import { IConnectedReduxProps } from '../../reducers';
import { ThunkDispatch } from 'redux-thunk';
import Review from '../../components/reviewing/Review';
import { SiufReviewingSectionId } from '../../contracts/models';

type IPropsFromState = {
};

type IRouteParams = {
};

type AllProps = IPropsFromState & RouteComponentProps<IRouteParams> & IConnectedReduxProps;

const mapStateToProps = (state: IApplicationRootReducerState, ownProps: AllProps) => ({
    siufCollection: selectors.getFilteredReviewSiufCollectionAppState(state),
    totalSiufCount: selectors.getReviewSiufCollectionCount(state),
    uiState: selectors.getReviewUiState(state),
    hostPlatform: state.resourceProviders.hostPlatform,
    cosmicUrl: state.resourceProviders.cosmicUrl,
    cosmicUrlLabel: state.resourceProviders.cosmicUrlLabel,
    cosmicMigrationPrefix: state.resourceProviders.cosmicMigrationPrefix,
    cosmicMigrationSuffix: state.resourceProviders.cosmicMigrationSuffix
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>, ownProps: AllProps) => ({
    onRefreshSiufs: () => dispatch(siufAuthoringActions.getSiufReviewsFromServer()),
    onUpdateSectionVisibility: (sectionId: SiufReviewingSectionId, isVisible: boolean) => dispatch(siufAuthoringActions.updateUiReviewSectionVisibility(sectionId, isVisible))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Review);
