import { connect } from 'react-redux';
import { IApplicationRootReducerState } from "../../../configureStore";
import { Dispatch } from 'redux';
import QuestionCollection from '../../../components/authoring/questionDetails/QuestionCollection';
import * as selectors from '../../../selectors';
import * as siufAuthoringActions from '../../../actions';
import { MAXIMUM_PAGE_COUNT, PageChangeParams } from '../../../contracts';

type Props = {
};

const mapStateToProps = (state: IApplicationRootReducerState, ownProps: Props) => ({
    siufType: selectors.getSiufTypeSelection(state),
    pageCollectionModel: selectors.getSiufPagesSorted(state),
    title: selectors.getSiufQuestionsTitle(state),
    ignoreTitleOnToast: selectors.getSiufIgnoreTitleOnToast(state),
    uiState: selectors.getSiufQuestionDetailsUiState(state),
    activeEditPage: selectors.getSiufActiveEditPage(state),
    activePreviewPage: selectors.getSiufActivePreviewPage(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    onAddQuestion: (pageIndex: number) => dispatch(siufAuthoringActions.addQuestion(pageIndex)),
    onRemoveQuestion: (pageIndex: number, questionIndex: number, questionCount: number, activePreviewPage: number) => {
        if(questionCount === 1){
            //If there is only 1 question in a page, remove the page
            dispatch(siufAuthoringActions.removePage(pageIndex));
            //If the removed page is not the first, set active page to be removed page -1
            if(pageIndex > 0){
                dispatch(siufAuthoringActions.changeEditPage(pageIndex-1))                
            }
            if(activePreviewPage > 0 && activePreviewPage >= pageIndex){
                dispatch(siufAuthoringActions.changePreviewPage(activePreviewPage-1));
            }            
        } else {
            dispatch(siufAuthoringActions.removeQuestion(pageIndex, questionIndex))
        }
    },
    onUpdateTitle: (title: string) => dispatch(siufAuthoringActions.updateTitle(title)),
    onUpdateIgnoreTitleOnToast: (ignoreTitleOnToast: boolean) => dispatch(siufAuthoringActions.updateIgnoreTitleOnToast(ignoreTitleOnToast)),
    onAddPage: (pageCount?: number) => {
        dispatch(siufAuthoringActions.addPage())
        if(pageCount < MAXIMUM_PAGE_COUNT){
            dispatch(siufAuthoringActions.changeEditPage(pageCount))
        }
    },
    onRemovePage: (pageIndex: number, activeEditPage: number, activePreviewPage: number) => {
        dispatch(siufAuthoringActions.removePage(pageIndex))
        //If the removed page is lower than the active edit page, then reduce the active edit page by 1
        if(activeEditPage > 0 && activeEditPage >= pageIndex){
            dispatch(siufAuthoringActions.changeEditPage(activeEditPage-1))
        }
        //If the removed page is lower than the active preview page, then reduce the active preview page by 1
        if(activePreviewPage > 0 && activePreviewPage >= pageIndex){
            dispatch(siufAuthoringActions.changePreviewPage(activePreviewPage-1))
        }
    },
    onChangeEditPage: (pageIndex: string | number) => dispatch(siufAuthoringActions.changeEditPage(pageIndex)),
    onUpdateNpsQuestion: (pageIndex: number, questionIndex: number, checked: boolean) => dispatch(siufAuthoringActions.updateNpsQuestion(pageIndex, questionIndex, checked))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(QuestionCollection);