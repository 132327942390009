import * as React from 'react';
import { Accordion, Divider, Header, Icon, Label, Message, Segment } from 'semantic-ui-react';
import ApprovalView from '../../containers/reviewing/approval/ApprovalView';
import SearchReviewView from '../../containers/reviewing/search/SearchReviewView';
import { HostPlatform, ISiufReviewInfo, SiufReviewingSectionId } from '../../contracts/models';
import { ISiufReviewingUiState } from '../../contracts/states';

type Props = {
    siufCollection: ISiufReviewInfo[];
    totalSiufCount: number;
    uiState: ISiufReviewingUiState;
    hostPlatform: HostPlatform;
    cosmicUrl: string;
    cosmicUrlLabel: string;
    cosmicMigrationPrefix: string;
    cosmicMigrationSuffix: string;
    onUpdateSectionVisibility: (sectionId: SiufReviewingSectionId, isVisible: boolean) => void;
    onRefreshSiufs: () => void;
};

export default class Review extends React.Component<Props> {

    constructor (props: Props) {
        super(props);

        this.props.onUpdateSectionVisibility.bind(this);
    }

    private renderCosmicMigrationAlert() {
        return (
            <Message error icon>
                <Message.Content>
                    <Message.Content>
                        {this.props.cosmicMigrationPrefix} <a className='unsetBackground' href={this.props.cosmicUrl}>{this.props.cosmicUrlLabel}</a>{this.props.cosmicMigrationSuffix}
                    </Message.Content>
                </Message.Content>
            </Message>
        );
    }

    public render () {

        let panels: Array<{ key: string, title: JSX.Element, content: JSX.Element }> = [];

        panels.push(
            {
                key: 'SiufReviewSearch',
                title: <Accordion.Title active={ this.props.uiState.search.isVisible }>
                    <Header as='h3' color='black'>
                        <Icon name="dropdown" onClick={ () => this.props.onUpdateSectionVisibility(SiufReviewingSectionId.search, !this.props.uiState.search.isVisible) } />
                        Search for SIUFs
                         <Label basic color="blue">
                            { `Showing ${this.props.siufCollection.length} of ${this.props.totalSiufCount} total SIUFs` }
                        </Label>
                        {
                            this.props.uiState.search.isReadOnly
                            &&
                            <Label basic color='orange'>Read Only</Label>
                        }
                    </Header>
                </Accordion.Title>,
                content: <Accordion.Content active={ this.props.uiState.search.isVisible } >
                    <Segment basic loading={ this.props.uiState.search.isLoading }>
                        <SearchReviewView />
                    </Segment>
                </Accordion.Content>,
            },
            {
                key: 'SiufReviewApproval',
                title: <Accordion.Title active={ this.props.uiState.approval.isVisible }>
                    <Header as='h3' color='black'>
                        <Icon name="dropdown" onClick={ () => this.props.onUpdateSectionVisibility(SiufReviewingSectionId.approval, !this.props.uiState.approval.isVisible) } />
                        SIUF Approval
                            {
                            this.props.uiState.approval.isReadOnly
                            &&
                            <Label basic color='orange'>Read Only</Label>
                        }
                        <Label color='blue' as='button' onClick={ () => this.props.onRefreshSiufs() }><Icon name='refresh' />Refresh from Server</Label>
                    </Header>
                </Accordion.Title>,
                content: <Accordion.Content active={ this.props.uiState.approval.isVisible } >
                    <Segment basic loading={ this.props.uiState.approval.isLoading }>
                        <ApprovalView />
                    </Segment>
                </Accordion.Content>,
            }
        );

        return (
            <Segment basic>
                {this.props.hostPlatform == HostPlatform.Azure && this.renderCosmicMigrationAlert()}
                <Header as="h1">
                    <Icon name="file alternate" />
                    <Header.Content>
                        SIUF Review
                    </Header.Content>
                </Header>
                <Divider hidden />
                <Accordion fluid defaultActiveIndex={ 0 } panels={ panels } />
            </Segment>
        );
    }
}