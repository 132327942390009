import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import * as ActionCreators from '../../../../actions/authoring/stateMachine/actions';
import ConditionsPanel from '../../../../components/authoring/stateMachine/transitions/ConditionsPanel';
import { IApplicationRootReducerState } from "../../../../configureStore";
import { uuidv4_id } from '../../../../utils/dataUtils';
import * as selectors from '../../../../selectors';

const mapStateToProps = (state: IApplicationRootReducerState) => ({
    allTransitionFilters: selectors.getStateMachineRootState(state).TransitionFilters
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
    addTransitionFilter: (transitionId: string): string => {
        let newTriggerFilterId = uuidv4_id();
        let newTransitionFilterId = uuidv4_id();
        dispatch(ActionCreators.addStateMachineTransitionFilter(newTransitionFilterId, newTriggerFilterId, transitionId));
        return newTransitionFilterId;
    },
    removeTransitionFilter: (transitionFilterId: string) => dispatch(ActionCreators.removeStateMachineTransitionFilter(transitionFilterId))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ConditionsPanel);