import { connect } from 'react-redux';
import { IApplicationRootReducerState } from "../../../configureStore";
import { Dispatch } from 'redux';
import AdvancedProperties from '../../../components/authoring/advancedSettings/AdvancedProperties';
import * as selectors from '../../../selectors';
import * as siufAuthoringActions from '../../../actions';
import { SiufAdvancedProperty } from '../../../contracts/models';

type Props = {
};

const mapStateToProps = (state: IApplicationRootReducerState, ownProps: Props) => ({
    previousQuestionId: selectors.getSiufPreviousQuestionId(state),
    advancedProperties: selectors.getSiufAdvancedProperties(state),
    uiState: selectors.getSiufSettingsUiState(state),
    isInsightfulSurvey: selectors.getIsInsightfulSurvey(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    onUpdateAdancedProperty: (property: SiufAdvancedProperty, propertyValue: boolean) => dispatch(siufAuthoringActions.updateAdvancedProperties(property, propertyValue)),
    onUpdatePreviousQuestionId: (questionId: string) => dispatch(siufAuthoringActions.updatePreviousQuestionId(questionId))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AdvancedProperties);