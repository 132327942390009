import * as React from 'react';
import { Segment } from 'semantic-ui-react';
import SiufCreationView from '../../containers/authoring/creation/SiufCreationView';

type Props = {
};

export default class NewSiuf extends React.Component<Props> {

    public render () {

        return (
            <React.Fragment>
                <Segment basic>
                    <SiufCreationView />
                </Segment>
            </React.Fragment>
        );
    }
}