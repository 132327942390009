import { SiufQuestionType, SiufFocus, SiufRing, SiufTargetRingSelection, SiufTargetRingChannelSelection, SiufLifeTimeOption, SiufDeviceType, SiufQuestionStructure, SiufState, RegistryHiveType, RegistryValueType, RegistryOperators, IDeviceFilterEntity, SubmitOperation, ISiufEntity, SiufTypeSelection, SiufRingChannel, LikertQuestionEntityDefault, LikertResponseType, LikertResponseCount, IAnswerEntity, IQuestionEntity, IPageEntity } from '../contracts/models';
import { GetDisplayNameForSiufQuestionTypes, GetDisplayNameForSiufLifeTimes as GetDisplayNameForSiufLifeSpans, GetDisplayNameForSiufDeviceTypes, GetDisplayNameForSiufTypes, GetDisplayNameForSiufState, GetDisplayNameForSiufRings, GetDisplayNameForSiufRingChannels, GetDisplayNameForLikertResponseTypes, GetDisplayNameForLikertResponseCounts } from '.';
import { FivePointQuestionEntityDefault, ThreePointQuestionEntityDefault, YesNoQuestionEntityDefault, TextQuestionEntityDefault, CheckBoxQuestionEntityDefault } from '../contracts';
import uuidv4 from 'uuid/v4';

export const uuidv4_id = (): string => (uuidv4());

export const GetOwnerAliasFromEmail = (email: string) => {
    if (!email) {
        return "";
    }
    const regex = /^(\w+)/g;
    let result = '';
    try {
        result = email.match(regex)[0];
    } catch (e) {
        console.error(`Invalid regex match from GetOwnerAliasFromEmail. Text: ${email}`);
        return "";
    }
    return result;
};

export const GetSiufTypeSelectionFromSiufEntity = (siufEntity: ISiufEntity) => {
    if (siufEntity && siufEntity.Payload && siufEntity.Payload.Pages && siufEntity.Payload.Pages.length > 0) {
        const firstQuestion = siufEntity.Payload.Pages.find(p => p.PageIndex == 0)?.Questions.find(q => q.QuestionIndex === 0);
        if (firstQuestion) {
            if (firstQuestion.QuestionStructure === SiufQuestionStructure.SingleQuestion) {
                return SiufTypeSelection.singleQuestion;
            } else if (firstQuestion.QuestionStructure === SiufQuestionStructure.MultiQuestion) {
                return SiufTypeSelection.multiQuestion;
            }
        }
    }

    console.error("Cannot determine the SIUF question structure from this SiufEntity, will default selection to single question type");
    return SiufTypeSelection.singleQuestion;
};

export const IsSiufStateReadOnly = (state: SiufState) => {
    switch (state) {
        case SiufState.Draft:
            return false;
        case SiufState.Submitted:
        case SiufState.Approved:
        case SiufState.Published:
        case SiufState.Terminated:
        default:
            return true;
    }
};

export const GetSupportedSiufQuestionTypes = () => {
    const siufQuestionTypes = Object.keys(SiufQuestionType).filter(d => !isNaN(Number(d)));
    const result = siufQuestionTypes.map(d => {
        const siufQuestionTypeId = parseInt(d, 10);
        return {
            siufQuestionType: siufQuestionTypeId,
            displayName: GetDisplayNameForSiufQuestionTypes(siufQuestionTypeId)
        };
    });
    return result;
};

export const GetLikertResponseTypes = () => {
    const likertResponseTypes = Object.keys(LikertResponseType).filter(d => !isNaN(Number(d)));
    const result = likertResponseTypes.map(d => {
        const likertResponseTypeId = parseInt(d, 10);
        return {
            likertResponseType: likertResponseTypeId,
            displayName: GetDisplayNameForLikertResponseTypes(likertResponseTypeId)
        };
    });
    return result;
};

export const GetLikertResponseCounts = () => {
    const likertResponseCounts = Object.keys(LikertResponseCount).filter(d => !isNaN(Number(d)));
    const result = likertResponseCounts.map(d => {
        const likertResponseCountId = parseInt(d, 10);
        return {
            likertResponseCount: likertResponseCountId,
            displayName: GetDisplayNameForLikertResponseCounts(likertResponseCountId)
        };
    });
    return result;
};

export const GetQuestionsWithLikertProperties = (questions: IQuestionEntity[]) => {
    questions.forEach((question) => {
        if(question.QuestionType === SiufQuestionType.Likert){
            question.LikertResponseCount = GetLikertResponseCount(question.Answers),
            question.LikertResponseType = GetLikertResponseType(question.Answers)
        }
    });
    return questions;

}
export const GetPagesWithQuestions = (pages: IPageEntity[]) => {
    if(!pages || pages.length == 0){
        pages = [Object.assign({}, {PageIndex: 0}, {Questions: [FivePointQuestionEntityDefault]})]
    }
    else {
        pages.forEach((page) => {
            page.Questions.forEach((question) => {
                if(question.QuestionType === SiufQuestionType.Likert){
                    question.LikertResponseCount = GetLikertResponseCount(question.Answers),
                    question.LikertResponseType = GetLikertResponseType(question.Answers)
                }
            });
        });
    }
    return pages;

}

export const GetDefaultQuestion = (questionType: SiufQuestionType) => {
    switch (questionType) {
        case SiufQuestionType.FivePoint:
            return FivePointQuestionEntityDefault;
        case SiufQuestionType.ThreePoint:
            return ThreePointQuestionEntityDefault;
        case SiufQuestionType.YesNo:
            return YesNoQuestionEntityDefault;
        case SiufQuestionType.Text:
            return TextQuestionEntityDefault;
        case SiufQuestionType.CheckBox:
            return CheckBoxQuestionEntityDefault;
        case SiufQuestionType.Likert:
            return LikertQuestionEntityDefault;
        default:
            return null;
    }
};

export const GetUpdatedLikertAnswers = (likertResponseType: LikertResponseType, likertResponseCount: LikertResponseCount, questionFocus: SiufFocus, followUpQuestionText: string) => {
    if(likertResponseCount === LikertResponseCount.Custom){
        return null;
    }
    var answers = [];
    if(likertResponseType === LikertResponseType.Alphanumeric && questionFocus != SiufFocus.Custom && 
        (likertResponseCount === LikertResponseCount.Three || likertResponseCount === LikertResponseCount.Five)){
        answers = GetLikertSiufFocusValues(questionFocus, likertResponseCount);
    } else {
        answers = GetDefaultLikertAnswers(likertResponseType, likertResponseCount);
    }
    answers.filter(ans => ans.AnswerIndex === 0).map(ans => ans.FollowUpQuestion = 
        {
            // Follow up question currently is always a text question
            QuestionText: followUpQuestionText,
            QuestionStructure: SiufQuestionStructure.FollowUpQuestion,
            QuestionType: SiufQuestionType.Text,
            QuestionFocus: SiufFocus.None,
            QuestionIndex: 0,
            ToastText: "",
            Answers: []
        });
    return Object.assign({},{ Answers: answers});
};
export const GetDefaultLikertAnswers = (likertResponseType: LikertResponseType, likertResponseCount: LikertResponseCount) => {
    var answers = [];
    for(let i = 0; i < likertResponseCount; i++){
        answers.push({
            AnswerText: likertResponseType === LikertResponseType.Numeric ? i+1 : "",
            AnswerIndex: i
        })
    }  
    return answers;
};

export const GetFivePointSiufFocusFromValue = (minAnchor: string, maxAnchor: string, siufQuestionStructure: SiufQuestionStructure) => {
    if (!minAnchor || !maxAnchor) {
        return SiufFocus.Custom;
    }

    if (minAnchor === GetFivePointSiufFocusValues(SiufFocus.Accuracy).minAnchor && maxAnchor === GetFivePointSiufFocusValues(SiufFocus.Accuracy).maxAnchor) {
        return SiufFocus.Accuracy;
    } else if (minAnchor === GetFivePointSiufFocusValues(SiufFocus.Aesthetics).minAnchor && maxAnchor === GetFivePointSiufFocusValues(SiufFocus.Aesthetics).maxAnchor) {
        return SiufFocus.Aesthetics;
    } else if (minAnchor === GetFivePointSiufFocusValues(SiufFocus.Difficulty).minAnchor && maxAnchor === GetFivePointSiufFocusValues(SiufFocus.Difficulty).maxAnchor) {
        return SiufFocus.Difficulty;
    } else if (minAnchor === GetFivePointSiufFocusValues(SiufFocus.Duration).minAnchor && maxAnchor === GetFivePointSiufFocusValues(SiufFocus.Duration).maxAnchor) {
        return SiufFocus.Duration;
    } else if (minAnchor === GetFivePointSiufFocusValues(SiufFocus.Efficiency).minAnchor && maxAnchor === GetFivePointSiufFocusValues(SiufFocus.Efficiency).maxAnchor) {
        return SiufFocus.Efficiency;
    } else if (minAnchor === GetFivePointSiufFocusValues(SiufFocus.Frequency).minAnchor && maxAnchor === GetFivePointSiufFocusValues(SiufFocus.Frequency).maxAnchor) {
        return SiufFocus.Frequency;
    } else if (minAnchor === GetFivePointSiufFocusValues(SiufFocus.Satisfaction).minAnchor && maxAnchor === GetFivePointSiufFocusValues(SiufFocus.Satisfaction).maxAnchor) {
        return SiufFocus.Satisfaction;
    } else if (minAnchor === GetFivePointSiufFocusValues(SiufFocus.Usefulness).minAnchor && maxAnchor === GetFivePointSiufFocusValues(SiufFocus.Usefulness).maxAnchor) {
        return SiufFocus.Usefulness;
    } else if (minAnchor === GetFivePointSiufFocusValues(SiufFocus.NetPromoter).minAnchor && maxAnchor === GetFivePointSiufFocusValues(SiufFocus.NetPromoter).maxAnchor
    && siufQuestionStructure === SiufQuestionStructure.SingleQuestion){
        return SiufFocus.NetPromoter;
    }

    return SiufFocus.Custom;
};

export const GetFivePointSiufFocusValues = (siufFocus: SiufFocus) => {
    switch (siufFocus) {
        case SiufFocus.Accuracy:
            return {
                minAnchor: "Not accurate enough",
                maxAnchor: "Very accurate"
            };
        case SiufFocus.Aesthetics:
            return {
                minAnchor: "Not enjoyable enough",
                maxAnchor: "Very enjoyable"
            };
        case SiufFocus.Difficulty:
            return {
                minAnchor: "Too hard",
                maxAnchor: "Not hard"
            };
        case SiufFocus.Duration:
            return {
                minAnchor: "Too long",
                maxAnchor: "Not long"
            };
        case SiufFocus.Efficiency:
            return {
                minAnchor: "Too slow",
                maxAnchor: "Not slow"
            };
        case SiufFocus.Frequency:
            return {
                minAnchor: "Not often enough",
                maxAnchor: "Very often"
            };
        case SiufFocus.NetPromoter:
            return {
                minAnchor: "Not at all likely",
                maxAnchor: "Extremely likely"
            };
        case SiufFocus.Satisfaction:
            return {
                minAnchor: "Not satisfied enough",
                maxAnchor: "Very satisfied"
            };
        case SiufFocus.Usefulness:
            return {
                minAnchor: "Not useful enough",
                maxAnchor: "Very useful"
            };
        case SiufFocus.Custom:
        case SiufFocus.None:
        default:
            return {
                minAnchor: "",
                maxAnchor: ""
            };
    }
};

export const GetThreePointSiufFocusFromValue = (posAnchor: string, neuAnchor: string, negAnchor: string) => {
    if (!posAnchor || !neuAnchor || !negAnchor) {
        return SiufFocus.Custom;
    }

    if (posAnchor === GetThreePointSiufFocusValues(SiufFocus.Accuracy).posAnchor && neuAnchor === GetThreePointSiufFocusValues(SiufFocus.Accuracy).neuAnchor && negAnchor === GetThreePointSiufFocusValues(SiufFocus.Accuracy).negAnchor) {
        return SiufFocus.Accuracy;
    } else if (posAnchor === GetThreePointSiufFocusValues(SiufFocus.Aesthetics).posAnchor && neuAnchor === GetThreePointSiufFocusValues(SiufFocus.Aesthetics).neuAnchor && negAnchor === GetThreePointSiufFocusValues(SiufFocus.Accuracy).negAnchor) {
        return SiufFocus.Aesthetics;
    } else if (posAnchor === GetThreePointSiufFocusValues(SiufFocus.Difficulty).posAnchor && neuAnchor === GetThreePointSiufFocusValues(SiufFocus.Difficulty).neuAnchor && negAnchor === GetThreePointSiufFocusValues(SiufFocus.Accuracy).negAnchor) {
        return SiufFocus.Difficulty;
    } else if (posAnchor === GetThreePointSiufFocusValues(SiufFocus.Duration).posAnchor && neuAnchor === GetThreePointSiufFocusValues(SiufFocus.Duration).neuAnchor && negAnchor === GetThreePointSiufFocusValues(SiufFocus.Accuracy).negAnchor) {
        return SiufFocus.Duration;
    } else if (posAnchor === GetThreePointSiufFocusValues(SiufFocus.Efficiency).posAnchor && neuAnchor === GetThreePointSiufFocusValues(SiufFocus.Efficiency).neuAnchor && negAnchor === GetThreePointSiufFocusValues(SiufFocus.Accuracy).negAnchor) {
        return SiufFocus.Efficiency;
    } else if (posAnchor === GetThreePointSiufFocusValues(SiufFocus.Frequency).posAnchor && neuAnchor === GetThreePointSiufFocusValues(SiufFocus.Frequency).neuAnchor && negAnchor === GetThreePointSiufFocusValues(SiufFocus.Accuracy).negAnchor) {
        return SiufFocus.Frequency;
    } else if (posAnchor === GetThreePointSiufFocusValues(SiufFocus.Satisfaction).posAnchor && neuAnchor === GetThreePointSiufFocusValues(SiufFocus.Satisfaction).neuAnchor && negAnchor === GetThreePointSiufFocusValues(SiufFocus.Accuracy).negAnchor) {
        return SiufFocus.Satisfaction;
    } else if (posAnchor === GetThreePointSiufFocusValues(SiufFocus.Usefulness).posAnchor && neuAnchor === GetThreePointSiufFocusValues(SiufFocus.Usefulness).neuAnchor && negAnchor === GetThreePointSiufFocusValues(SiufFocus.Accuracy).negAnchor) {
        return SiufFocus.Usefulness;
    }

    return SiufFocus.Custom;
};

export const GetThreePointSiufFocusValues = (siufFocus: SiufFocus) => {
    switch (siufFocus) {
        case SiufFocus.Accuracy:
            return {
                posAnchor: "Very accurate",
                neuAnchor: "Somewhat accurate",
                negAnchor: "Not accurate enough"
            };
        case SiufFocus.Aesthetics:
            return {
                posAnchor: "Very enjoyable",
                neuAnchor: "Somewhat enjoyable",
                negAnchor: "Not enjoyable enough"
            };
        case SiufFocus.Difficulty:
            return {
                posAnchor: "Not hard",
                neuAnchor: "Somewhat hard",
                negAnchor: "Too hard"
            };
        case SiufFocus.Duration:
            return {
                posAnchor: "Not long",
                neuAnchor: "Somewhat long",
                negAnchor: "Too long"
            };
        case SiufFocus.Efficiency:
            return {
                posAnchor: "Not slow",
                neuAnchor: "Somewhat slow",
                negAnchor: "Too slow"
            };
        case SiufFocus.Frequency:
            return {
                posAnchor: "Very often",
                neuAnchor: "Somewhat often",
                negAnchor: "Not often enough"
            };
        case SiufFocus.Satisfaction:
            return {
                posAnchor: "Very satisfied",
                neuAnchor: "Somewhat satisfied",
                negAnchor: "Not satisfied enough"
            };
        case SiufFocus.Usefulness:
            return {
                posAnchor: "Very useful",
                neuAnchor: "Somewhat useful",
                negAnchor: "Not useful enough"
            };
        case SiufFocus.NetPromoter:
        case SiufFocus.Custom:
        case SiufFocus.None:
        default:
            return {
                posAnchor: "",
                neuAnchor: "",
                negAnchor: ""
            };
    }
};

export const GetLikertSiufFocusFromValue = (likertOptions: IAnswerEntity[]) => {
    switch(likertOptions?.length){
        case LikertResponseCount.Three:
            return GetThreeLikertSiufFocusFromValue(likertOptions);
        case LikertResponseCount.Five:
            return GetFiveLikertSiufFocusFromValue(likertOptions);
        default:
            return SiufFocus.Custom;
    }
}
export const GetThreeLikertSiufFocusFromValue = (likertOptions: IAnswerEntity[]) => {
    const accuracyValues = GetThreeLikertSiufFocusValues(SiufFocus.Accuracy);
    if (likertOptions[0].AnswerText === accuracyValues[0].AnswerText && likertOptions[1].AnswerText === accuracyValues[1].AnswerText && likertOptions[2].AnswerText === accuracyValues[2].AnswerText) {
        return SiufFocus.Accuracy;
    }
    const aesteticValues = GetThreeLikertSiufFocusValues(SiufFocus.Aesthetics);
    if (likertOptions[0].AnswerText === aesteticValues[0].AnswerText && likertOptions[1].AnswerText === aesteticValues[1].AnswerText && likertOptions[2].AnswerText === aesteticValues[2].AnswerText) {
        return SiufFocus.Aesthetics;
    }
    const difficultyValues = GetThreeLikertSiufFocusValues(SiufFocus.Difficulty);
    if (likertOptions[0].AnswerText === difficultyValues[0].AnswerText && likertOptions[1].AnswerText === difficultyValues[1].AnswerText && likertOptions[2].AnswerText === difficultyValues[2].AnswerText) {
        return SiufFocus.Difficulty;
    }
    const durationValues = GetThreeLikertSiufFocusValues(SiufFocus.Duration);
    if (likertOptions[0].AnswerText === durationValues[0].AnswerText && likertOptions[1].AnswerText === durationValues[1].AnswerText && likertOptions[2].AnswerText === durationValues[2].AnswerText) {
        return SiufFocus.Duration;
    }
    const efficiencyValues = GetThreeLikertSiufFocusValues(SiufFocus.Efficiency);
    if (likertOptions[0].AnswerText === efficiencyValues[0].AnswerText && likertOptions[1].AnswerText === efficiencyValues[1].AnswerText && likertOptions[2].AnswerText === efficiencyValues[2].AnswerText) {
        return SiufFocus.Efficiency;
    }
    const frequencyValues = GetThreeLikertSiufFocusValues(SiufFocus.Frequency);
    if (likertOptions[0].AnswerText === frequencyValues[0].AnswerText && likertOptions[1].AnswerText === frequencyValues[1].AnswerText && likertOptions[2].AnswerText === frequencyValues[2].AnswerText) {
        return SiufFocus.Frequency;
    }
    const satisfactionValues = GetThreeLikertSiufFocusValues(SiufFocus.Satisfaction);
    if (likertOptions[0].AnswerText === satisfactionValues[0].AnswerText && likertOptions[1].AnswerText === satisfactionValues[1].AnswerText && likertOptions[2].AnswerText === satisfactionValues[2].AnswerText) {
        return SiufFocus.Satisfaction;
    }
    const usefulnessValues = GetThreeLikertSiufFocusValues(SiufFocus.Usefulness);
    if (likertOptions[0].AnswerText === usefulnessValues[0].AnswerText && likertOptions[1].AnswerText === usefulnessValues[1].AnswerText && likertOptions[2].AnswerText === usefulnessValues[2].AnswerText) {
        return SiufFocus.Usefulness;
    } 
    const netPromoterValues = GetThreeLikertSiufFocusValues(SiufFocus.NetPromoter);
    if (likertOptions[0].AnswerText === netPromoterValues[0].AnswerText && likertOptions[1].AnswerText === netPromoterValues[1].AnswerText && likertOptions[2].AnswerText === netPromoterValues[2].AnswerText) {
        return SiufFocus.NetPromoter;
    }

    return SiufFocus.Custom;
};

export const GetFiveLikertSiufFocusFromValue = (likertOptions: IAnswerEntity[]) => {
    const accuracyValues = GetFiveLikertSiufFocusValues(SiufFocus.Accuracy);
    if (likertOptions[0].AnswerText === accuracyValues[0].AnswerText && likertOptions[1].AnswerText === accuracyValues[1].AnswerText && likertOptions[2].AnswerText === accuracyValues[2].AnswerText && likertOptions[3].AnswerText === accuracyValues[3].AnswerText && likertOptions[4].AnswerText === accuracyValues[4].AnswerText) {
        return SiufFocus.Accuracy;
    }
    const aesteticValues = GetFiveLikertSiufFocusValues(SiufFocus.Aesthetics);
    if (likertOptions[0].AnswerText === aesteticValues[0].AnswerText && likertOptions[1].AnswerText === aesteticValues[1].AnswerText && likertOptions[2].AnswerText === aesteticValues[2].AnswerText && likertOptions[3].AnswerText === aesteticValues[3].AnswerText && likertOptions[4].AnswerText === aesteticValues[4].AnswerText) {
        return SiufFocus.Aesthetics;
    }
    const difficultyValues = GetFiveLikertSiufFocusValues(SiufFocus.Difficulty);
    if (likertOptions[0].AnswerText === difficultyValues[0].AnswerText && likertOptions[1].AnswerText === difficultyValues[1].AnswerText && likertOptions[2].AnswerText === difficultyValues[2].AnswerText && likertOptions[3].AnswerText === difficultyValues[3].AnswerText && likertOptions[4].AnswerText === difficultyValues[4].AnswerText) {
        return SiufFocus.Difficulty;
    }
    const durationValues = GetFiveLikertSiufFocusValues(SiufFocus.Duration);
    if (likertOptions[0].AnswerText === durationValues[0].AnswerText && likertOptions[1].AnswerText === durationValues[1].AnswerText && likertOptions[2].AnswerText === durationValues[2].AnswerText && likertOptions[3].AnswerText === durationValues[3].AnswerText && likertOptions[4].AnswerText === durationValues[4].AnswerText) {
        return SiufFocus.Duration;
    }
    const efficiencyValues = GetFiveLikertSiufFocusValues(SiufFocus.Efficiency);
    if (likertOptions[0].AnswerText === efficiencyValues[0].AnswerText && likertOptions[1].AnswerText === efficiencyValues[1].AnswerText && likertOptions[2].AnswerText === efficiencyValues[2].AnswerText && likertOptions[3].AnswerText === efficiencyValues[3].AnswerText && likertOptions[4].AnswerText === efficiencyValues[4].AnswerText) {
        return SiufFocus.Efficiency;
    }
    const frequencyValues = GetFiveLikertSiufFocusValues(SiufFocus.Frequency);
    if (likertOptions[0].AnswerText === frequencyValues[0].AnswerText && likertOptions[1].AnswerText === frequencyValues[1].AnswerText && likertOptions[2].AnswerText === frequencyValues[2].AnswerText && likertOptions[3].AnswerText === frequencyValues[3].AnswerText && likertOptions[4].AnswerText === frequencyValues[4].AnswerText) {
        return SiufFocus.Frequency;
    }
    const satisfactionValues = GetFiveLikertSiufFocusValues(SiufFocus.Satisfaction);
    if (likertOptions[0].AnswerText === satisfactionValues[0].AnswerText && likertOptions[1].AnswerText === satisfactionValues[1].AnswerText && likertOptions[2].AnswerText === satisfactionValues[2].AnswerText && likertOptions[3].AnswerText === satisfactionValues[3].AnswerText && likertOptions[4].AnswerText === satisfactionValues[4].AnswerText) {
        return SiufFocus.Satisfaction;
    }
    const usefulnessValues = GetFiveLikertSiufFocusValues(SiufFocus.Usefulness);
    if (likertOptions[0].AnswerText === usefulnessValues[0].AnswerText && likertOptions[1].AnswerText === usefulnessValues[1].AnswerText && likertOptions[2].AnswerText === usefulnessValues[2].AnswerText && likertOptions[3].AnswerText === usefulnessValues[3].AnswerText && likertOptions[4].AnswerText === usefulnessValues[4].AnswerText) {
        return SiufFocus.Usefulness;
    } 

    return SiufFocus.Custom;
};

export const GetSiufNpsQuestion = (productName: string) => {
    return `How likely are you to recommend ${productName} to a friend or colleague?`;
};

export const GetProductNameFromSiufNpsQuestion = (questionText: string) => {
    if (!questionText) {
        return "";
    }
    const regex = /^How likely are you to recommend\s(.*?)\sto a friend or colleague/;
    let result = '';
    try {
        result = questionText.match(regex)[1];
    } catch (e) {
        console.error(`Invalid regex match from GetProductNameFromSiufNpsQuestion. Text: ${questionText}`);
        return "";
    }
    return result;
};

export const ConvertRingSelectionFromRings = (rings: SiufRing[]) => {
    if ((rings.length === 1)
        && rings.includes(SiufRing.Retail)) {
        return SiufTargetRingSelection.retailAll;
    }

    if ((rings.length === 2)
        && rings.includes(SiufRing.Canary)
        && rings.includes(SiufRing.OSG)) {
        return SiufTargetRingSelection.internalMicrosoft;
    }

    if ((rings.length === 4)
        && rings.includes(SiufRing.MSIT)
        && rings.includes(SiufRing.WIF)
        && rings.includes(SiufRing.WIS)
        && rings.includes(SiufRing.NOT_SET)) {
        return SiufTargetRingSelection.insider;
    }

    if ((rings.length === 7)
        && rings.includes(SiufRing.Canary)
        && rings.includes(SiufRing.OSG)
        && rings.includes(SiufRing.MSIT)
        && rings.includes(SiufRing.WIF)
        && rings.includes(SiufRing.WIS)
        && rings.includes(SiufRing.RP)
        && rings.includes(SiufRing.NOT_SET)) {
        return SiufTargetRingSelection.internalMicrosoftAndInsider;
    }

    return SiufTargetRingSelection.custom;
};

export const ConvertRingChannelSelectionFromRingChannels = (ringchannels: SiufRingChannel[]) => {
    if ((ringchannels.length === 1)
        && ringchannels.includes(SiufRingChannel.Retail)) {
        return SiufTargetRingChannelSelection.retailAll;
    }

    if ((ringchannels.length === 1)
        && ringchannels.includes(SiufRingChannel.Dev_Internal)
        ) {
        return SiufTargetRingChannelSelection.internalMicrosoftChannel;
    }

    if ((ringchannels.length === 2)
        && ringchannels.includes(SiufRingChannel.Canary)
        && ringchannels.includes(SiufRingChannel.OSG)) {
        return SiufTargetRingChannelSelection.internalMicrosoft;
    } 

    if ((ringchannels.length === 2)
        && ringchannels.includes(SiufRingChannel.Feature_Preview_Internal)
        && ringchannels.includes(SiufRingChannel.Release_Preview_Internal)) {
        return SiufTargetRingChannelSelection.internalPreviewChannel;
    } 

    if ((ringchannels.length === 2)
        && ringchannels.includes(SiufRingChannel.Feature_Preview_External)
        && ringchannels.includes(SiufRingChannel.Release_Preview_External)) {
        return SiufTargetRingChannelSelection.previewChannel;
    }

    if ((ringchannels.length === 4)
        && ringchannels.includes(SiufRingChannel.MSIT)
        && ringchannels.includes(SiufRingChannel.WIF)
        && ringchannels.includes(SiufRingChannel.WIS)
        && ringchannels.includes(SiufRingChannel.NOT_SET)) {
        return SiufTargetRingChannelSelection.insider;
    }

    if ((ringchannels.length === 4)
        && ringchannels.includes(SiufRingChannel.Dev_Internal)
        && ringchannels.includes(SiufRingChannel.Dev_External)
        && ringchannels.includes(SiufRingChannel.Beta_Internal)
        && ringchannels.includes(SiufRingChannel.Beta_External)) {
        return SiufTargetRingChannelSelection.internalMicrosoftAndInsiderChannel;
    }

    if ((ringchannels.length === 3)
        && ringchannels.includes(SiufRingChannel.Dev_External)
        && ringchannels.includes(SiufRingChannel.Beta_Internal)
        && ringchannels.includes(SiufRingChannel.Beta_External)) {
        return SiufTargetRingChannelSelection.insiderChannel;
    }

    if ((ringchannels.length === 7)
        && ringchannels.includes(SiufRingChannel.Canary)
        && ringchannels.includes(SiufRingChannel.OSG)
        && ringchannels.includes(SiufRingChannel.MSIT)
        && ringchannels.includes(SiufRingChannel.WIF)
        && ringchannels.includes(SiufRingChannel.WIS)
        && ringchannels.includes(SiufRingChannel.RP)
        && ringchannels.includes(SiufRingChannel.NOT_SET)) {
        return SiufTargetRingChannelSelection.internalMicrosoftAndInsider;
    }
    

    return SiufTargetRingChannelSelection.custom;
};

export const ConvertRingsToRingSelection = (ringSelection: SiufTargetRingSelection, siufRings: SiufRing[] = []) => {
    switch (ringSelection) {
        case SiufTargetRingSelection.internalMicrosoftAndInsider:
            return [SiufRing.Canary, SiufRing.OSG, SiufRing.MSIT, SiufRing.WIF, SiufRing.WIS, SiufRing.RP, SiufRing.NOT_SET];
        case SiufTargetRingSelection.internalMicrosoft:
            return [SiufRing.Canary, SiufRing.OSG];
        case SiufTargetRingSelection.insider:
            return [SiufRing.MSIT, SiufRing.WIF, SiufRing.WIS, SiufRing.NOT_SET];
        case SiufTargetRingSelection.retailAll:
            return [SiufRing.Retail];
        case SiufTargetRingSelection.custom:
            return siufRings;
        default:
            return [];
    }
};

export const ConvertRingChannelsToRingChannelSelection = (ringChannelSelection: SiufTargetRingChannelSelection, siufRingChannels: SiufRingChannel[] = []) => {
    switch (ringChannelSelection) {
        case SiufTargetRingChannelSelection.internalMicrosoftAndInsider:
            return [SiufRingChannel.Canary, SiufRingChannel.OSG, SiufRingChannel.MSIT, SiufRingChannel.WIF, SiufRingChannel.WIS, SiufRingChannel.RP, SiufRingChannel.NOT_SET];
        case SiufTargetRingChannelSelection.internalMicrosoft:
            return [SiufRingChannel.Canary, SiufRingChannel.OSG];
        case SiufTargetRingChannelSelection.insider:
            return [SiufRingChannel.MSIT, SiufRingChannel.WIF, SiufRingChannel.WIS, SiufRingChannel.NOT_SET];
        case SiufTargetRingChannelSelection.retailAll:
            return [SiufRingChannel.Retail];
        case SiufTargetRingChannelSelection.internalMicrosoftChannel:
                return [SiufRingChannel.Dev_Internal];
        case SiufTargetRingChannelSelection.insiderChannel:
            return [SiufRingChannel.Dev_External, SiufRingChannel.Beta_Internal, SiufRingChannel.Beta_External];
        case SiufTargetRingChannelSelection.internalMicrosoftAndInsiderChannel:
            return [SiufRingChannel.Dev_Internal, SiufRingChannel.Dev_External, SiufRingChannel.Beta_Internal, SiufRingChannel.Beta_External];
        case SiufTargetRingChannelSelection.internalPreviewChannel:
            return [SiufRingChannel.Feature_Preview_Internal, SiufRingChannel.Release_Preview_Internal];
        case SiufTargetRingChannelSelection.previewChannel:
            return [SiufRingChannel.Feature_Preview_External, SiufRingChannel.Release_Preview_External];
        case SiufTargetRingChannelSelection.custom:
            return siufRingChannels;
        default:
            return [];
    }
};

export const GetSupportedSiufRingTypes = () => {
    const types = Object.keys(SiufRing).filter(d => !isNaN(Number(d)));
    const result = types.map(d => {
        const typeOption = parseInt(d, 10);
        return {
            type: typeOption,
            displayName: GetDisplayNameForSiufRings(typeOption)
        };
    });
    return result;
};

export const GetSupportedSiufRingChannelTypes = () => {
    const types = Object.keys(SiufRingChannel).filter(d => !isNaN(Number(d)));
    const result = types.map(d => {
        const typeOption = parseInt(d, 10);
        return {
            type: typeOption,
            displayName: GetDisplayNameForSiufRingChannels(typeOption)
        };
    });
    return result;
};

export const GetSupportedRegistryHiveTypes = () => {
    const types = Object.keys(RegistryHiveType).filter(d => !isNaN(Number(d)));
    const result = types.map(d => {
        const typeOption = parseInt(d, 10);
        return {
            type: typeOption,
            displayName: RegistryHiveType[typeOption]
        };
    });
    return result;
};

export const GetSupportedRegistryValueTypes = () => {
    const types = Object.keys(RegistryValueType).filter(d => !isNaN(Number(d)));
    const result = types.map(d => {
        const typeOption = parseInt(d, 10);
        return {
            type: typeOption,
            displayName: RegistryValueType[typeOption]
        };
    });
    return result;
};

export const GetSupportedRegistryOperators = () => {
    const types = Object.keys(RegistryOperators).filter(d => !isNaN(Number(d)));
    const result = types.map(d => {
        const typeOption = parseInt(d, 10);
        return {
            type: typeOption,
            displayName: RegistryOperators[typeOption]
        };
    });
    return result;
};

export const GetSiufLifeSpanSelection = () => {
    const types = Object.keys(SiufLifeTimeOption).filter(d => !isNaN(Number(d)));
    const result = types.map(d => {
        const typeOption = parseInt(d, 10);
        return {
            type: typeOption,
            displayName: GetDisplayNameForSiufLifeSpans(typeOption),
            disabled: typeOption > 365
        };
    });
    return result;
};

export const GetSiufStateTypes = () => {
    const types = Object.keys(SiufState).filter(d => !isNaN(Number(d)));
    const result = types.map(d => {
        const typeOption = parseInt(d, 10);
        return {
            type: typeOption,
            displayName: GetDisplayNameForSiufState(typeOption)
        };
    });
    return result;
};

export const GetSiufSupportedDeviceTypes = () => {
    const types = Object.keys(SiufDeviceType).filter(d => !isNaN(Number(d)));
    const result = types.map(d => {
        const typeOption = parseInt(d, 10);
        return {
            type: typeOption,
            displayName: GetDisplayNameForSiufDeviceTypes(typeOption)
        };
    });
    return result;
};

export const GetSiufSupportedStructureTypes = () => {
    const types = Object.keys(SiufQuestionStructure).filter(d => !isNaN(Number(d)) && (Number(d) !== SiufQuestionStructure.FollowUpQuestion));
    const result = types.map(d => {
        const typeOption = parseInt(d, 10);
        return {
            type: typeOption,
            displayName: GetDisplayNameForSiufTypes(typeOption)
        };
    });
    return result;
};

export const GetSiufSupportedQuestionTypes = () => {
    const types = Object.keys(SiufQuestionType).filter(d => !isNaN(Number(d)));
    const result = types.map(d => {
        const typeOption = parseInt(d, 10);
        return {
            type: typeOption,
            displayName: GetDisplayNameForSiufQuestionTypes(typeOption)
        };
    });
    return result;
};

export const GetSiufLifeTimeSelectionFromLifeSpan = (lifeSpanInDays: number) => {
    if (lifeSpanInDays <= 30) {
        return SiufLifeTimeOption._30Days;
    } else if (lifeSpanInDays <= 90) {
        return SiufLifeTimeOption._90Days;
    } else if (lifeSpanInDays <= 180) {
        return SiufLifeTimeOption._180Days;
    } else if (lifeSpanInDays <= 365) {
        return SiufLifeTimeOption._365Days;
    } else {
        return SiufLifeTimeOption._730Days;
    }
};

export const IsTestSystemIncludedDeviceFilterSet = (deviceFilter: IDeviceFilterEntity) => {
    return (
        deviceFilter.Hive === RegistryHiveType.HKEY_LOCAL_MACHINE
        &&
        deviceFilter.ValueType === RegistryValueType.REG_NONE
        &&
        deviceFilter.Operation === RegistryOperators.notexists
        &&
        deviceFilter.ValueName && deviceFilter.ValueName.trim().toUpperCase() === 'ISTEST'
        &&
        deviceFilter.KeyName && deviceFilter.KeyName.trim().toUpperCase() === "SOFTWARE\\MICROSOFT\\SQMCLIENT"
    );
};

export const ShowSuccessMessage = (submitOperation: SubmitOperation) => {
    switch (submitOperation) {
        case SubmitOperation.loadSiuf:
        case SubmitOperation.reactivateSiuf:
        case SubmitOperation.saveSiuf:
        case SubmitOperation.sideloadSiuf:
        case SubmitOperation.submitSiufForReview:
        case SubmitOperation.terminateSiuf:
        case SubmitOperation.withdrawSiufFromReview:
            return true;
        case SubmitOperation.createSiuf:
        case SubmitOperation.validateSiuf:
        case SubmitOperation.none:
        default:
            return false;
    }
};

export const GetLikertSiufFocusValues = (siufFocus: SiufFocus, likertResponseCount: LikertResponseCount) => {
    switch(likertResponseCount){
        case LikertResponseCount.Three:
            return GetThreeLikertSiufFocusValues(siufFocus);
        case LikertResponseCount.Five:
            return GetFiveLikertSiufFocusValues(siufFocus);
        default:
            return [];
    }
}

export const GetThreeLikertSiufFocusValues = (siufFocus: SiufFocus) => {
    switch (siufFocus) {
        case SiufFocus.Accuracy:
            return [
                {
                    AnswerIndex: 0,
                    AnswerText: "Not accurate enough"
                },
                {
                    AnswerIndex: 1,
                    AnswerText: "Accurate"
                },
                {
                    AnswerIndex: 2,
                    AnswerText: "Very accurate"
                }
            ];
        case SiufFocus.Aesthetics:
            return [
                {
                    AnswerIndex: 0,
                    AnswerText: "Not enjoyable enough"
                },
                {
                    AnswerIndex: 1,
                    AnswerText: "Enjoyable"
                },
                {
                    AnswerIndex: 2,
                    AnswerText: "Very enjoyable"
                }
            ];
        case SiufFocus.Difficulty:
            return [
                {
                    AnswerIndex: 0,
                    AnswerText: "Very hard"
                },
                {
                    AnswerIndex: 1,
                    AnswerText: "Hard"
                },
                {
                    AnswerIndex: 2,
                    AnswerText: "Not hard"
                }
            ];
        case SiufFocus.Duration:
            return [
                {
                    AnswerIndex: 0,
                    AnswerText: "Very long"
                },
                {
                    AnswerIndex: 1,
                    AnswerText: "Long"
                },
                {
                    AnswerIndex: 2,
                    AnswerText: "Not long"
                }
            ];
        case SiufFocus.Efficiency:
            return [
                {
                    AnswerIndex: 0,
                    AnswerText: "Very slow"
                },
                {
                    AnswerIndex: 1,
                    AnswerText: "Slow"
                },
                {
                    AnswerIndex: 2,
                    AnswerText: "Not slow"
                }
            ];
        case SiufFocus.Frequency:
            return [
                {
                    AnswerIndex: 0,
                    AnswerText: "Not often enough"
                },
                {
                    AnswerIndex: 1,
                    AnswerText: "Often"
                },
                {
                    AnswerIndex: 2,
                    AnswerText: "Very often"
                }
            ];
        case SiufFocus.NetPromoter:
            return [
                {
                    AnswerIndex: 0,
                    AnswerText: "Not likely"
                },
                {
                    AnswerIndex: 1,
                    AnswerText: "Likely"
                },
                {
                    AnswerIndex: 2,
                    AnswerText: "Extremely likely"
                }
            ];
        case SiufFocus.Satisfaction:
            return [
                {
                    AnswerIndex: 0,
                    AnswerText: "Not satisfied enough"
                },
                {
                    AnswerIndex: 1,
                    AnswerText: "Satisfied"
                },
                {
                    AnswerIndex: 2,
                    AnswerText: "Very satisfied"
                }
            ];
        case SiufFocus.Usefulness:
            return [
                {
                    AnswerIndex: 0,
                    AnswerText: "Not often useful"
                },
                {
                    AnswerIndex: 1,
                    AnswerText: "Useful"
                },
                {
                    AnswerIndex: 2,
                    AnswerText: "Very useful"
                }
            ];
        case SiufFocus.Custom:
        case SiufFocus.None:
        default:
            return [];
    }
};

export const GetFiveLikertSiufFocusValues = (siufFocus: SiufFocus) => {
    switch (siufFocus) {
        case SiufFocus.Accuracy:
            return [
                {
                    AnswerIndex: 0,
                    AnswerText: "Very inaccurate"
                },
                {
                    AnswerIndex: 1,
                    AnswerText: "Inaccurate"
                },
                {
                    AnswerIndex: 2,
                    AnswerText: "Neutral"
                },
                {
                    AnswerIndex: 3,
                    AnswerText: "Accurate"
                },
                {
                    AnswerIndex: 4,
                    AnswerText: "Very accurate"
                }
            ];
        case SiufFocus.Aesthetics:
            return [
                {
                    AnswerIndex: 0,
                    AnswerText: "Not enjoyable at all"
                },
                {
                    AnswerIndex: 1,
                    AnswerText: "Somewhat not enjoyable"
                },
                {
                    AnswerIndex: 2,
                    AnswerText: "Neutral"
                },
                {
                    AnswerIndex: 3,
                    AnswerText: "Enjoyable"
                },
                {
                    AnswerIndex: 4,
                    AnswerText: "Very enjoyable"
                }
            ];
        case SiufFocus.Difficulty:
            return [
                {
                    AnswerIndex: 0,
                    AnswerText: "Very hard"
                },
                {
                    AnswerIndex: 1,
                    AnswerText: "Hard"
                },
                {
                    AnswerIndex: 2,
                    AnswerText: "Neutral"
                },
                {
                    AnswerIndex: 3,
                    AnswerText: "Easy"
                },
                {
                    AnswerIndex: 4,
                    AnswerText: "Very easy"
                }
            ];
        case SiufFocus.Duration:
            return [
                {
                    AnswerIndex: 0,
                    AnswerText: "Very long"
                },
                {
                    AnswerIndex: 1,
                    AnswerText: "Long"
                },
                {
                    AnswerIndex: 2,
                    AnswerText: "Neutral"
                },
                {
                    AnswerIndex: 3,
                    AnswerText: "Short"
                },
                {
                    AnswerIndex: 4,
                    AnswerText: "Very short"
                }
            ];
        case SiufFocus.Efficiency:
            return [
                {
                    AnswerIndex: 0,
                    AnswerText: "Very inefficient"
                },
                {
                    AnswerIndex: 1,
                    AnswerText: "Inefficient"
                },
                {
                    AnswerIndex: 2,
                    AnswerText: "Neutral"
                },
                {
                    AnswerIndex: 3,
                    AnswerText: "Efficient"
                },
                {
                    AnswerIndex: 4,
                    AnswerText: "Very efficient"
                }
            ];
        case SiufFocus.Frequency:
            return [
                {
                    AnswerIndex: 0,
                    AnswerText: "Not often at all"
                },
                {
                    AnswerIndex: 1,
                    AnswerText: "Somewhat not often"
                },
                {
                    AnswerIndex: 2,
                    AnswerText: "Neutral"
                },
                {
                    AnswerIndex: 3,
                    AnswerText: "Often"
                },
                {
                    AnswerIndex: 4,
                    AnswerText: "Very often"
                }
            ];
        case SiufFocus.NetPromoter:
            return [
                {
                    AnswerIndex: 0,
                    AnswerText: "Very unlikely"
                },
                {
                    AnswerIndex: 1,
                    AnswerText: "Unikely"
                },
                {
                    AnswerIndex: 2,
                    AnswerText: "Neutral"
                },
                {
                    AnswerIndex: 3,
                    AnswerText: "Likely"
                },
                {
                    AnswerIndex: 4,
                    AnswerText: "Very likely"
                }
            ];
        case SiufFocus.Satisfaction:
            return [
                {
                    AnswerIndex: 0,
                    AnswerText: "Very dissatisfied"
                },
                {
                    AnswerIndex: 1,
                    AnswerText: "Somewhat disatisfied"
                },
                {
                    AnswerIndex: 2,
                    AnswerText: "Neutral"
                },
                {
                    AnswerIndex: 3,
                    AnswerText: "Satisfied"
                },
                {
                    AnswerIndex: 4,
                    AnswerText: "Very satisfied"
                }
            ];
        case SiufFocus.Usefulness:
            return [
                {
                    AnswerIndex: 0,
                    AnswerText: "Not useful at all"
                },
                {
                    AnswerIndex: 1,
                    AnswerText: "Somewhat not useful"
                },
                {
                    AnswerIndex: 2,
                    AnswerText: "Neutral"
                },
                {
                    AnswerIndex: 3,
                    AnswerText: "Useful"
                },
                {
                    AnswerIndex: 4,
                    AnswerText: "Very useful"
                }
            ];
        case SiufFocus.Custom:
        case SiufFocus.None:
        default:
            return [];
    }
};

export const GetLikertResponseInputCssClass = (likertResponseCount: LikertResponseCount, likertResponseType: LikertResponseType) => {
    if(likertResponseCount === LikertResponseCount.Three){
        return 'likertThreeOptionInput';
    } else if(likertResponseCount ===  LikertResponseCount.Five){
        return 'likertFiveOptionInput';
    } else if(likertResponseCount === LikertResponseCount.Ten && likertResponseType === LikertResponseType.Numeric){
        return 'likertTenOptionInput';
    }
    else{
        return 'likertDefaultOptionInput';
    }
}

export const GetLikertResponseType = (likertResponses: IAnswerEntity[], currentResponseType?: LikertResponseType) => {
    if(currentResponseType)
        return currentResponseType;
    for(let i = 0; i < likertResponses.length; i++){
        if(isNaN(+likertResponses[i].AnswerText)){
            return LikertResponseType.Alphanumeric;
        }
    }
    return LikertResponseType.Numeric;
}


export const GetLikertResponseCount = (likertResponses: IAnswerEntity[], currentResponseCount?: LikertResponseType) => {
    if(currentResponseCount !== undefined){
        return currentResponseCount;
    }
    switch(likertResponses?.length){
        case LikertResponseCount.Three:
            return LikertResponseCount.Three;
        case LikertResponseCount.Five:
            return LikertResponseCount.Five;
        case LikertResponseCount.Ten:
            return LikertResponseCount.Ten;
        default:
            return LikertResponseCount.Custom;
    }
}