import * as React from 'react';
import { Form } from 'semantic-ui-react';
import { INPUT_DELAY, SiufQuestionStructure } from '../../../contracts/models';

type Props = {
    isReadOnly: boolean,
    questionStructure: SiufQuestionStructure
    questionText: string;
    onUpdateQuestionText: (questionText: string) => void;
};

const FreeformTextQuestion: React.FC<Props> = props => {

    // Delay the update
    let timer: NodeJS.Timeout;
    const onUpdateWithDelay = (func: any, ...params: any[]) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Function/call
            return func.call(props, ...params);
        }, INPUT_DELAY);
    };

    return (
        <React.Fragment>
            <Form.Input defaultValue={ props.questionText } label='Question Text'
                placeholder='Enter your question here' required
                readOnly={ props.isReadOnly }
                tabIndex='5'
                onChange={ e => onUpdateWithDelay(props.onUpdateQuestionText, e.currentTarget.value) }
            />
        </React.Fragment>
    );
};

export default FreeformTextQuestion;