import { connect } from 'react-redux';
import { IApplicationRootReducerState } from "../../../configureStore";
import { Dispatch } from 'redux';
import YesNoQuestion from '../../../components/authoring/questionDetails/YesNoQuestion';
import { IQuestionEntity } from '../../../contracts/models';
import * as siufAuthoringActions from '../../../actions';

type Props = {
    questionModel: IQuestionEntity;
    isReadOnly: boolean;
    pageIndex: number;
};

const mapStateToProps = (state: IApplicationRootReducerState, ownProps: Props) => ({
    isReadOnly: ownProps.isReadOnly,
    questionStructure: ownProps.questionModel.QuestionStructure,
    questionText: ownProps.questionModel.QuestionText,
    yesFollowupQuestion: ownProps.questionModel.Answers.find(a => a.AnswerIndex === 0).FollowUpQuestion ? ownProps.questionModel.Answers.find(a => a.AnswerIndex === 0).FollowUpQuestion.QuestionText : "",
    noFollowupQuestion: ownProps.questionModel.Answers.find(a => a.AnswerIndex === 1).FollowUpQuestion ? ownProps.questionModel.Answers.find(a => a.AnswerIndex === 1).FollowUpQuestion.QuestionText : ""
});

const mapDispatchToProps = (dispatch: Dispatch, ownProps: Props) => ({
    onUpdateQuestionText: (questionText: string) => dispatch(siufAuthoringActions.updateQuestionText(ownProps.pageIndex, ownProps.questionModel.QuestionIndex, questionText)),
    onUpdateFollowQuestionText: (questionText: string, index: number) => dispatch(siufAuthoringActions.updateFollowUpQuestionText(ownProps.pageIndex, ownProps.questionModel.QuestionIndex, questionText, index))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(YesNoQuestion);
