import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import LikertPreview from '../../../components/authoring/questionPreview/LikertPreview';
import { IApplicationRootReducerState } from "../../../configureStore";
import { IQuestionEntity } from '../../../contracts/models';

type Props = {
    questionModel: IQuestionEntity;
};

const mapStateToProps = (state: IApplicationRootReducerState, ownProps: Props) => ({
    questionStructure: ownProps.questionModel.QuestionStructure,
    questionText: ownProps.questionModel.QuestionText,
    isNpsQuestion: ownProps.questionModel.IsNpsQuestion,
    responseOptions: ownProps.questionModel.Answers?.sort((a, b) => (a.AnswerIndex > b.AnswerIndex) ? 1 : -1)?.map(a => a.AnswerText),
    scaleItems: ownProps.questionModel.ScaleItems?.sort((a, b) => (a.ScaleItemIndex > b.ScaleItemIndex) ? 1 : -1)?.map(a => a.ScaleItemText) || [],
    followupQuestion: ownProps.questionModel.Answers.find(a => a.AnswerIndex === 0)?.FollowUpQuestion ? ownProps.questionModel.Answers.find(a => a.AnswerIndex === 0)?.FollowUpQuestion.QuestionText : "",
});

const mapDispatchToProps = (dispatch: Dispatch, ownProps: Props) => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LikertPreview);
