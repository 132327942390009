import React from 'react';
import { Route, Switch } from 'react-router';
import RootLayout from '../components/layout/RootLayout';
import ErrorPage from '../components/ui/ErrorPage';
import NotFoundPage from '../components/ui/NotFoundPage';
import ExistingSiufViewPage from '../containers/authoring/ExistingSiufViewPage';
import NewSiufViewPage from '../containers/authoring/NewSiufViewPage';
import ReviewViewPage from '../containers/reviewing/ReviewViewPage';
import UserProfileService from '../userProfile';
import Navigation from './navigationActions';

const PrivateRoute = ({ component: Component, ...rest }: any) => (
    <Route { ...rest }
        render={ props => (
            UserProfileService.IsCurrentUserReviewer()
                ? <Component { ...props } />
                : <ErrorPage
                    header="Uh oh, it looks like you are unauthorized to view this page"
                    message="The part of the SIUF Authoring site requires reviewer permissions. If you believe this is an error, please contact sifcrew for support" />
        ) }
    />
);

const Routes = (
    <RootLayout>
        <Switch>
            <Route exact path="/" component={ NewSiufViewPage } />
            <Route exact path={ Navigation.Route_AuthoringPage + '/' } component={ NewSiufViewPage } />
            <Route exact path={ Navigation.Route_NewAuthoringPage } component={ NewSiufViewPage } />
            <Route path={ Navigation.Route_AuthoringPage + '/:id' } component={ ExistingSiufViewPage } />
            <PrivateRoute path={ Navigation.Route_ReviewsPage } component={ ReviewViewPage } />
            <Route component={ NotFoundPage } />
        </Switch>
    </RootLayout>
);

export default Routes;