import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import * as siufAuthoringActions from '../../../actions';
import Validation from '../../../components/authoring/validation/Validation';
import { IApplicationRootReducerState } from "../../../configureStore";
import * as selectors from '../../../selectors';

type Props = {
};

const mapStateToProps = (state: IApplicationRootReducerState, ownProps: Props) => ({
    siufId: selectors.getSiufId(state),
    uiState: selectors.getSiufValidationUiState(state),
    validationResult: selectors.getSiufValidationResult(state),
    validationSubmitState: selectors.getSiufValidationSubmitState(state),
    sideloadSubmitState: selectors.getSiufSideloadSubmitState(state),
    downloadXmlSubmitState: selectors.getSiufXmlDownloadSubmitState(state),
    isSiufDirty: selectors.getExistingSiufDirtyState(state),
    isSideLoadEnabled: selectors.getSideLoadEnabledState(state),
    hasMultipleQuestions: selectors.getExistingSiufMultiQuestionState(state),
    exportExtendedPayload: selectors.getExportExtendedPayloadState(state)
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>, ownProps: Props) => ({
    onValidateSiuf: (siufId: number) => dispatch(siufAuthoringActions.validateSiufFromServer(siufId)),
    onDismissSubmitState: () => dispatch(siufAuthoringActions.dismissSubmitState()),
    onSideloadSiuf: (siufId: number, exportExtendedPayload: boolean) => dispatch(siufAuthoringActions.sideloadSiufFromServer(siufId, exportExtendedPayload)),
    onDownloadSiufXml: (siufId: number, exportExtendedPayload: boolean) => dispatch(siufAuthoringActions.downloadSiufXmlFromServer(siufId, exportExtendedPayload)),
    onChangeExportExtendedPayload: (exportExtendedPayload: boolean) => dispatch(siufAuthoringActions.updateExportExtendedPayload(exportExtendedPayload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Validation);