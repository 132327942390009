import * as React from 'react';
import { Form } from 'semantic-ui-react';
import { INPUT_DELAY, SiufFocus, SiufQuestionStructure } from '../../../contracts/models';
import { GetThreePointSiufFocusValues } from '../../../utils';

type Props = {
    isReadOnly: boolean,
    questionStructure: SiufQuestionStructure
    questionText: string;
    questionFocus: SiufFocus;
    positiveAnchor: string;
    neutralAnchor: string;
    negativeAnchor: string;
    positiveFollowupQuestion?: string;
    neutralFollowupQuestion?: string;
    negativeFollowupQuestion?: string;
    onUpdateSiufFocus: (questionFocus: SiufFocus) => void;
    onUpdateQuestionText: (questionText: string) => void;
    onUpdateAnchorText: (positiveAnchor: string, neutralAnchor: string, negativeAnchor: string) => void;
    onUpdateFollowQuestionText: (questionText: string, index: number) => void;
};

const ThreePointRatingQuestion: React.FC<Props> = props => {

    // Delay the update
    let timer: NodeJS.Timeout;
    const onUpdateWithDelay = (func: any, ...params: any[]) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Function/call
            return func.call(props, ...params);
        }, INPUT_DELAY);
    };

    const options = [
        { key: 'Accuracy', value: SiufFocus.Accuracy, text: 'Accuracy' },
        { key: 'Aesthetics', value: SiufFocus.Aesthetics, text: 'Aesthetics' },
        { key: 'Difficulty', value: SiufFocus.Difficulty, text: 'Difficulty' },
        { key: 'Duration', value: SiufFocus.Duration, text: 'Duration' },
        { key: 'Efficiency', value: SiufFocus.Efficiency, text: 'Efficiency' },
        { key: 'Frequency', value: SiufFocus.Frequency, text: 'Frequency' },
        { key: 'Satisfaction', value: SiufFocus.Satisfaction, text: 'Satisfaction' },
        { key: 'Usefulness', value: SiufFocus.Usefulness, text: 'Usefulness' },
        { key: 'Custom', value: SiufFocus.Custom, text: 'Custom' },
    ];

    return (
        <React.Fragment>
            <Form.Input defaultValue={ props.questionText } label='Question Text'
                readOnly={ props.isReadOnly }
                tabIndex='5'
                placeholder='Enter your question here' required
                onChange={ e => onUpdateWithDelay(props.onUpdateQuestionText, e.currentTarget.value) }
            />
            <Form.Group widths='equal'>
                {
                    props.isReadOnly
                        ?
                        <Form.Input fluid value={ options.find(o => o.value === props.questionFocus).text } label='Question Focus'
                            readOnly={ true }
                            tabIndex='5'
                            placeholder='Select question focus' required
                        />
                        :
                        <Form.Select fluid value={ props.questionFocus } label='Question Focus'
                            options={ options }
                            placeholder='Select question focus' required
                            title='Question Focus'
                            tabIndex='5'
                            onChange={ (event, data) => {
                                const focus = data.value as SiufFocus;
                                if (focus !== props.questionFocus) {
                                    const focusInfo = GetThreePointSiufFocusValues(focus);
                                    props.onUpdateAnchorText(focusInfo.posAnchor, focusInfo.neuAnchor, focusInfo.negAnchor);
                                    props.onUpdateSiufFocus(focus);
                                }
                            } }
                        />
                }

                <Form.Input fluid value={ props.positiveAnchor } label='Positive Anchor'
                    placeholder='Enter positive anchor here' required
                    tabIndex='5'
                    readOnly={ props.isReadOnly }
                    onChange={ e => {
                        props.onUpdateAnchorText(e.currentTarget.value as string, props.neutralAnchor, props.negativeAnchor);
                        props.onUpdateSiufFocus(SiufFocus.Custom);
                    } }
                />
                <Form.Input fluid value={ props.neutralAnchor } label='Neutral Anchor'
                    placeholder='Enter neutral anchor here' required
                    tabIndex='5'
                    readOnly={ props.isReadOnly }
                    onChange={ e => {
                        props.onUpdateAnchorText(props.positiveAnchor, e.currentTarget.value as string, props.negativeAnchor);
                        props.onUpdateSiufFocus(SiufFocus.Custom);
                    } }
                />
                <Form.Input fluid value={ props.negativeAnchor } label='Negative Anchor'
                    placeholder='Enter negative anchor here' required
                    tabIndex='5'
                    readOnly={ props.isReadOnly }
                    onChange={ e => {
                        props.onUpdateAnchorText(props.positiveAnchor, props.neutralAnchor, e.currentTarget.value as string);
                        props.onUpdateSiufFocus(SiufFocus.Custom);
                    } }
                />
            </Form.Group>
            {
                (props.questionStructure === SiufQuestionStructure.SingleQuestion)
                &&
                <React.Fragment>
                    <Form.Input fluid value={ props.positiveFollowupQuestion } label='Positive Follow up Question'
                        placeholder='Enter follow up question here'
                        readOnly={ props.isReadOnly }
                        tabIndex='5'
                        onChange={ e => props.onUpdateFollowQuestionText(e.currentTarget.value, 2) }
                    />
                    <Form.Input fluid value={ props.neutralFollowupQuestion } label='Neutral Follow up Question'
                        placeholder='Enter follow up question here'
                        readOnly={ props.isReadOnly }
                        tabIndex='5'
                        onChange={ e => props.onUpdateFollowQuestionText(e.currentTarget.value, 1) }
                    />
                    <Form.Input fluid value={ props.negativeFollowupQuestion } label='Negative Follow up Question'
                        placeholder='Enter follow up question here'
                        readOnly={ props.isReadOnly }
                        tabIndex='5'
                        onChange={ e => props.onUpdateFollowQuestionText(e.currentTarget.value, 0) }
                    />
                </React.Fragment>
            }
        </React.Fragment>
    );
};

export default ThreePointRatingQuestion;