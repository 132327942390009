import { MsalProvider } from '@azure/msal-react';
import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import 'semantic-ui-css/semantic.min.css';
import './siufAuthUx.css'
import { InitializeAppEnvironmentConfigs } from './appConfigs';
import { MsalService } from './auth';
import UnauthenticatedPage from './components/ui/UnauthenticatedPage';
import configureStore, { history } from './configureStore';
import { IResourceProviderOptions } from './contracts';
import { MsalAuthentication } from "./InitMsalAuthentication";
import registerServiceWorker from './registerServiceWorker';

class Entry {

    public async run (isDevelopment: boolean) {

        // By-pass the need to launch the backend
        // Note: only enable this for local testing
        const isDevMode = false;
        let isAppEnvironmentInitialize = false;

        let configs: IResourceProviderOptions;

        // Initialize App Environment Configs
        try {
            configs = await InitializeAppEnvironmentConfigs(isDevMode);
            isAppEnvironmentInitialize = configs.azureAD ? true : false;
        }
        catch (error) {
            console.error("Failed to load initial resource configurations from the server. Details: " + error);
            isAppEnvironmentInitialize = false;
        }

        let render: () => void;

        if (isAppEnvironmentInitialize === true) {
            const reduxStore = configureStore(configs);

            // Initiating and configuring the MSAL PublicClientApplication object
            const msalInstance = MsalService.PublicClientApplication(isDevMode, configs.azureAD);

            render = () => {

                ReactDOM.render(
                    <Provider store={reduxStore}>
                        <ConnectedRouter history={history}>
                            <MsalProvider instance={msalInstance}>
                                <MsalAuthentication />
                            </MsalProvider>
                        </ConnectedRouter>
                    </Provider>,
                    document.getElementById('root'));
            };

        }
        else {
            render = () => {
                ReactDOM.render(<UnauthenticatedPage />,
                    document.getElementById('root'));
            };
        }

        render();
        registerServiceWorker();
    }
}

let entry = new Entry();
entry.run((process.env.NODE_ENV === 'development'));