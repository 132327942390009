import { RegistryHiveType, RegistryOperators, RegistryValueType, SiufCREException, SiufDeviceType, SiufJustificationQuestionType, SiufQuestionStructure, SiufQuestionType, SiufRing, SiufTargetType, SiufRingChannel, IScaleItemEntity, HostPlatform } from ".";
import { StateMachineModel } from "../states/stateMachineStates";
//
// Target: SIUF Authoring API
// Please do not modify unless the server API contract changes
//

export enum RequestReviewAction {
    Submit = 1,
    Cancel = 2
}

export enum ReviewAction {
    Approve = 1,
    Reject = 2
}

export enum CreationType {
    CreateNewSiuf = 0,
    CloneSiuf,
    CreateFromTemplate
}

export enum SiufTriggeringType {
    StateMachine = 0,
    URI = 1
}

export enum SiufStateAction {
    Submit = 0,
    Cancel = 1,
    Terminate = 2,
    Reactivate = 3,
    Import,
    TestSideLoad
}

export enum SiufReviewAction {
    Approve = 0,
    Reject = 1
}

export interface IReviewActionModel {
    ReviewAction: RequestReviewAction;
}

export interface INewSiufModel {
    CreationType: CreationType;
    OldSiufId: number;
    NewSiufName: string;
    Owner: string;
    TriggeringType: SiufTriggeringType;
    HostPlatform: HostPlatform;
}

export const INewSiufModel_Default: INewSiufModel = {
    CreationType: CreationType.CreateNewSiuf,
    OldSiufId: 0,
    NewSiufName: "",
    Owner: "",
    TriggeringType: SiufTriggeringType.StateMachine,
    HostPlatform: 0
};

export interface IUpdateJustificationModel {
    QuestionType: SiufJustificationQuestionType;
    Answer: string;
}

export interface IUpdateDeviceFilterModel {
    FilterName: string;
    Hive: RegistryHiveType;
    KeyName: string;
    ValueName: string;
    ValueType: RegistryValueType;
    Value: string;
    Operation: RegistryOperators;
}

export interface IUpdateTargetingModel {
    MinBuild: number;
    MaxBuild?: number;
    DeviceType: SiufDeviceType;
    TargetType: SiufTargetType;
    Rings: SiufRing[];
    CustomGroupIds: string[];
    DeviceIdUri: string;
    AudienceName: string;
    DeviceFilters: IUpdateDeviceFilterModel[];
}

export interface IUpdateTargetingModel {
    MinBuild: number;
    MaxBuild?: number;
    DeviceType: SiufDeviceType;
    TargetType: SiufTargetType;
    Rings: SiufRing[];
    RingChannels: SiufRingChannel[];
    CustomGroupIds: string[];
    DeviceIdUri: string;
    AudienceName: string;
    DeviceFilters: IUpdateDeviceFilterModel[];
}

export interface IUpdateTargetingModel {
    MinBuild: number;
    MaxBuild?: number;
    DeviceType: SiufDeviceType;
    TargetType: SiufTargetType;
    RingChannels: SiufRingChannel[];
    CustomGroupIds: string[];
    DeviceIdUri: string;
    AudienceName: string;
    DeviceFilters: IUpdateDeviceFilterModel[];
}

export interface IUpdateStateModelModel {
    StateMachineModel: StateMachineModel;
    MaxDurationMs: number;
}

export interface IUpdateAnswerModel {
    AnswerText: string;
    AnswerIndex: number;
    FollowUpQuestionText: string;
}

export interface IUpdateQuestionModel {
    QuestionText: string;
    QuestionStructure: SiufQuestionStructure;
    QuestionType: SiufQuestionType;
    QuestionIndex: number;
    Answers: IUpdateAnswerModel[];
    ScaleItems: IScaleItemEntity[];
    IsNpsQuestion: boolean;
}

export interface IUpdatePageModel {
    pageIndex: number;
    Questions: IUpdateQuestionModel[];
}

export interface IUpdatePayloadModel {
    TriggeringType: SiufTriggeringType;
    Title: string;
    UseCaseDescription: string;
    CREQuestionId?: string;
    AskUserForEmail: boolean;
    NoAutoDeactivate: boolean;
    DoNotAskSiufAgainIfSeenPreviousVersion: boolean;
    SurveyRequesterName: string;
    HeroImageUrl: string;
    ProfileImageUrl: string;
    ToastText: string;
    IgnoreTitleOnToast: boolean;
    CREExceptions: SiufCREException[];
    Pages: IUpdatePageModel[];
}

export interface IUpdateSiufModel {
    Owner: string;
    SiufName: string;
    LifeSpanInDays: number;
    Justifications: IUpdateJustificationModel[];
    Comments: string;
    UpdatePayloadModel: IUpdatePayloadModel;
    UpdateTargetingModel: IUpdateTargetingModel;
    UpdateStateModelModel: IUpdateStateModelModel;
    HostPlatform: HostPlatform;
}

export interface ISiufActionModel {
    Action: SiufStateAction;
    Comment: string;
}

export interface PageChangeParams {
    ActiveEditPage?: string | number;
    ActivePreviewPage?: string | number;
    QuestionCount?: number;
}