import * as React from 'react';
import { Label } from 'semantic-ui-react';
import { SiufDeviceType } from '../../contracts/models';
import { GetDisplayNameForSiufDeviceTypes } from '../../utils';

type Props = {
    deviceType: SiufDeviceType;
};

const DeviceLabel: React.FC<Props> = props => {
    switch (props.deviceType) {
        case SiufDeviceType.Andromeda:
            return <Label basic color="orange">{ GetDisplayNameForSiufDeviceTypes(SiufDeviceType.Andromeda) }</Label>;
        case SiufDeviceType.Core:
            return <Label basic color="brown">{ GetDisplayNameForSiufDeviceTypes(SiufDeviceType.Core) }</Label>;
        case SiufDeviceType.Desktop:
            return <Label basic color="black">{ GetDisplayNameForSiufDeviceTypes(SiufDeviceType.Desktop) }</Label>;
        case SiufDeviceType.Holographic:
            return <Label basic color="blue">{ GetDisplayNameForSiufDeviceTypes(SiufDeviceType.Holographic) }</Label>;
        case SiufDeviceType.Mobile:
            return <Label basic color="blue">{ GetDisplayNameForSiufDeviceTypes(SiufDeviceType.Mobile) }</Label>;
        case SiufDeviceType.TeamOS:
            return <Label basic color="purple">{ GetDisplayNameForSiufDeviceTypes(SiufDeviceType.TeamOS) }</Label>;
        case SiufDeviceType.Xbox:
            return <Label basic color="green">{ GetDisplayNameForSiufDeviceTypes(SiufDeviceType.Xbox) }</Label>;
        default:
            return <Label color="red">{ "Unknown Device" }</Label>;
    }
};

export default DeviceLabel;