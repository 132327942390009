import * as React from 'react';
import { Form } from 'semantic-ui-react';
import { SiufQuestionStructure } from '../../../contracts/models';

type Props = {
    questionStructure: SiufQuestionStructure
    questionText: string;
};

const FreeformTextPreview: React.FC<Props> = props => {

    return (
        <React.Fragment>
            <Form widths='equal'>
                <h3>{ props.questionText || '<Question Text>' }</h3>
                <Form.TextArea placeholder='Enter your feedback here...' tabIndex='6' />
            </Form>
        </React.Fragment>
    );
};

export default FreeformTextPreview;