/* tslint:disable:no-duplicate-imports class-name semicolon interface-name switch-default switch-final-break radix*/

import * as React from "react";
import { ISectionUiState, IUpdateTransitionFilterReferenceModel, IUpdateTransitionFilterStrictModel, IUpdateTriggerFilterEventModel, IUpdateTriggerFilterModel, TransitionFilter, SingleTriggerFilter, DualTriggerFilter, TriggerEvent, TriggerFilter, TriggerFilterEvent, ScenarioMetadataProperty, SystemProperty, Operation, TriggerFilterOperation, TriggerFilterOperationDescription } from "../../../../contracts";
import { Form, Button, Divider } from "semantic-ui-react";

interface ConditionSingleOrDualTriggerFilterPanelProps {
    transitionFilter: TransitionFilter;
    triggerFilter: SingleTriggerFilter | DualTriggerFilter;
    triggerEvents: TriggerEvent[];
    triggerFilters: TriggerFilter[];
    uiState: ISectionUiState;
    updateTriggerFilter (triggerFilterChanges: IUpdateTriggerFilterModel): void;
    updateTriggerFilterEvent (triggerFilterEventChanges: IUpdateTriggerFilterEventModel): void;
    updateTransitionFilterReference (updateTransitionFilterChanges: IUpdateTransitionFilterReferenceModel): void;
    updateTransitionFilterStrict (updateTransitionFilterChanges: IUpdateTransitionFilterStrictModel): void;
};
interface ConditionSingleOrDualTriggerFilterPanelState {
    isSelectingReferencedFilter: boolean;
    currentReferencedTriggerFilterId: string
};
export default class ConditionSingleOrDualTriggerFilterPanel extends React.Component<ConditionSingleOrDualTriggerFilterPanelProps, ConditionSingleOrDualTriggerFilterPanelState> {
    constructor (props: ConditionSingleOrDualTriggerFilterPanelProps) {
        super(props);
        this.state = {
            isSelectingReferencedFilter: false,
            currentReferencedTriggerFilterId: props.triggerFilter.TriggerFilterId
        };
    };

    public render () {
        let { triggerFilter } = this.props;
        return (
            <React.Fragment>
                <Form.Group>
                    <Form.Field disabled={ this.props.uiState.isReadOnly } label='Type' control='select' value={ triggerFilter.Type } onChange={ this.handleTypeChange } width={ 4 } title='Type' tabIndex='7'>
                        <option value='single'>Single filter</option>
                        <option value='dual'>Dual filter</option>
                        <option value='reference'>Reference an existing filter</option>
                    </Form.Field>
                    { !this.state.isSelectingReferencedFilter &&
                        <Form.Field
                            disabled={ this.props.uiState.isReadOnly }
                            label='Filter Name'
                            control='input'
                            value={ triggerFilter.FilterName }
                            width={ 12 }
                            title='Filter Name'
                            tabIndex='7'
                            onChange={ this.handleFilterNameChange } />
                    }
                    { this.state.isSelectingReferencedFilter &&
                        <React.Fragment>
                            <Form.Field
                                disabled={ this.props.uiState.isReadOnly }
                                label='Filter'
                                control='select'
                                value={ this.state.currentReferencedTriggerFilterId }
                                width={ 8 }
                                title='Filter'
                                tabIndex='7'
                                onChange={ this.handleReferencedTriggerFilterChange }>
                                { this.getTriggerFilterOptions() }
                            </Form.Field>
                            <Form.Field width={ 4 }>
                                <label>&nbsp;</label>
                                <Button disabled={ this.props.uiState.isReadOnly } onClick={ this.handleCreateReferenceClick } title='Create Reference' tabIndex='7'>Create Reference</Button>
                            </Form.Field>
                        </React.Fragment>
                    }
                </Form.Group>
                { !this.state.isSelectingReferencedFilter &&
                    <React.Fragment>
                        <Form.Group>
                            <Form.Field
                                disabled={ this.props.uiState.isReadOnly }
                                label='Apply to Escalations'
                                control='input'
                                type='checkbox'
                                checked={ triggerFilter.ApplyToEscalations }
                                title='Apply to Escalations'
                                tabIndex='7'
                                onChange={ this.handleApplyToEscalationsChange } />
                            <Form.Field
                                disabled={ this.props.uiState.isReadOnly }
                                label='Strict'
                                control='input'
                                type='checkbox'
                                checked={ this.props.transitionFilter.IsStrict }
                                title='Strict'
                                tabIndex='7'
                                onChange={ this.handleStrictFilterChange } />
                        </Form.Group>
                        { this.renderTriggerFilterEvents() }
                    </React.Fragment>
                }
            </React.Fragment>
        );
    }
    private handleFilterNameChange = (e: any) => this.props.updateTriggerFilter({ TriggerFilterId: this.props.triggerFilter.TriggerFilterId, FilterName: e.target.value });
    private handleTypeChange = (e: any) => {
        let triggerFilterType = e.target.value;
        if (triggerFilterType === 'reference') {
            this.setState({ isSelectingReferencedFilter: true });
        } else {
            this.setState({ isSelectingReferencedFilter: false });
            this.props.updateTriggerFilter({ TriggerFilterId: this.props.triggerFilter.TriggerFilterId, Type: e.target.value });
        }
    }
    private handleApplyToEscalationsChange = (e: any) => this.props.updateTriggerFilter({ TriggerFilterId: this.props.triggerFilter.TriggerFilterId, ApplyToEscalations: e.target.checked });

    private handleReferencedTriggerFilterChange = (e: any) => this.setState({ currentReferencedTriggerFilterId: e.target.value });
    private getTriggerFilterOptions = () => this.props.triggerFilters.map(triggerFilter =>
        <option key={ `triggerFilter+${triggerFilter.TriggerFilterId}` } value={ triggerFilter.TriggerFilterId }>{ triggerFilter.FilterName }</option>);
    private handleCreateReferenceClick = (e: any) => {
        this.setState({
            isSelectingReferencedFilter: false
        });
        this.props.updateTransitionFilterReference({
            TransitionFilterId: this.props.transitionFilter.TransitionFilterId,
            TriggerFilterId: this.state.currentReferencedTriggerFilterId
        })
    };
    private handleStrictFilterChange = (e: any) => this.props.updateTransitionFilterStrict({ TransitionFilterId: this.props.transitionFilter.TransitionFilterId, Strict: e.target.checked });

    private renderTriggerFilterEvents = () => {
        switch (this.props.triggerFilter.Type) {
            case 'single':
                return <React.Fragment>
                    { this.renderTriggerFilterEventFields(this.props.triggerFilter.Event, false) }
                    { this.renderOperationOperand() }
                </React.Fragment>;
            case 'dual':
                return <React.Fragment>
                    { this.renderTriggerFilterEventFields(this.props.triggerFilter.Event1, false) }
                    { this.renderOperationOperand() }
                    { this.renderTriggerFilterEventFields(this.props.triggerFilter.Event2, true) }
                </React.Fragment>
        }
    }

    private renderTriggerFilterEventFields = (event: TriggerFilterEvent, isSecondEvent: boolean) => {
        let type =
            <Form.Group inline>
                <Form.Field label='Filter on' disabled={ this.props.uiState.isReadOnly } width={ 6 } control='select' value={ event.Type } onChange={ this.handleTriggerFilterEventTypeChange(isSecondEvent) } title='Filter on' tabIndex='7'>
                    <option value='triggerEvent'>Trigger event</option>
                    <option value='scenarioMetadata'>Scenario metadata</option>
                </Form.Field>
            </Form.Group>;
        switch (event.Type) {
            case 'scenarioMetadata':
                return <React.Fragment>
                    <Divider horizontal>Scenario Metadata</Divider>
                    { type }
                    <Form.Group>
                        <Form.Field label='Property' control='select' width={ 16 } value={ event.Property } disabled={ this.props.uiState.isReadOnly } onChange={ this.handleEventPropertyChange(isSecondEvent) } title='Property' tabIndex='7'>
                            <option value={ ScenarioMetadataProperty.IsACOn }>Is AC On</option>
                            <option value={ ScenarioMetadataProperty.IsContinuumSessionActive }>Is Continuum Session Active</option>
                            <option value={ ScenarioMetadataProperty.IsEscalationInProgress }>Is Escalation in Progress</option>
                            <option value={ ScenarioMetadataProperty.IsKernelDebuggerPresent }>Is Kernel Debugger Present</option>
                            <option value={ ScenarioMetadataProperty.LastApplicationInstallTime }>Last Application Install Time</option>
                            <option value={ ScenarioMetadataProperty.LastBootTime }>Last Boot Time</option>
                            <option value={ ScenarioMetadataProperty.LastDeviceArrivalOrRemovalTime }>Last Device Arrival or Removal Time</option>
                            <option value={ ScenarioMetadataProperty.LastLogonTime }>Last Logon Time</option>
                            <option value={ ScenarioMetadataProperty.LastPowerSourceChangeTime }>Last Power Source Change Time</option>
                            <option value={ ScenarioMetadataProperty.LastResumeFromHibernationTime }>Last Resume From Hibernation Time</option>
                            <option value={ ScenarioMetadataProperty.LastResumeFromStandbyTime }>Last Resume from Standby Time</option>
                            <option value={ ScenarioMetadataProperty.LastSuccessfulRefreshTime }>Last Successful Refresh Time</option>
                            <option value={ ScenarioMetadataProperty.LastUnlockTime }>Last Unlock Time</option>
                            <option value={ ScenarioMetadataProperty.TimeSinceInstall }>Time Since Install</option>
                        </Form.Field>
                    </Form.Group>
                </React.Fragment>;
            case 'triggerEvent':
                let property;
                switch (event.Property.Type) {
                    case 'eventProperty':
                        property = <Form.Field label='Property' control='input' value={ event.Property.Property || '' } disabled={ this.props.uiState.isReadOnly } onChange={ this.handleEventPropertyChange(isSecondEvent) } width={ 4 } title='Event Property' tabIndex='7' />;
                        break;
                    case 'systemProperty':
                        property = (<Form.Field label='Property' control='select' value={ event.Property.Property } disabled={ this.props.uiState.isReadOnly } onChange={ this.handleEventPropertyChange(isSecondEvent) } width={ 4 } title='System Property' tabIndex='7'>
                            <option value={ SystemProperty.AID }>AID</option>
                            <option value={ SystemProperty.AppId }>AppId</option>
                            <option value={ SystemProperty.AppVer }>AppVer</option>
                            <option value={ SystemProperty.OpCode }>OpCode</option>
                            <option value={ SystemProperty.PID }>PID</option>
                            <option value={ SystemProperty.TID }>TID</option>
                            <option value={ SystemProperty.TimeStamp }>TimeStamp</option>
                            <option value={ SystemProperty.BootId }>BootId</option>
                            <option value={ SystemProperty.AppSessionId }>AppSessionId</option>
                        </Form.Field>);
                        break;
                }
                return (<React.Fragment>
                    <Divider horizontal>Event</Divider>
                    { type }
                    <Form.Group>
                        <Form.Field
                            label='Event'
                            disabled={ this.props.uiState.isReadOnly }
                            control='select'
                            value={ event.TriggerEventId || '' }
                            width={ 8 }
                            title='Event'
                            tabIndex='7'
                            onChange={ this.handleEventChange(isSecondEvent) }>
                            <option key='' value=''>Please select an event</option>
                            { this.props.triggerEvents.map(this.renderTriggerEventOption) }
                        </Form.Field>
                        <Form.Field
                            label='Source'
                            disabled={ this.props.uiState.isReadOnly }
                            control='select'
                            value={ event.Property.Type }
                            width={ 4 }
                            title='Source'
                            tabIndex='7'
                            onChange={ this.handleEventPropertyTypeChange(isSecondEvent) }>
                            <option value='eventProperty'>Event Property</option>
                            <option value='systemProperty'>System Property</option>
                        </Form.Field>
                        { property }
                    </Form.Group>
                    <Form.Group>
                        <Form.Field
                            label='Bitmask'
                            disabled={ this.props.uiState.isReadOnly }
                            control='input'
                            type='number'
                            value={ event.Bitmask || '' }
                            title='Bitmask'
                            tabIndex='7'
                            onChange={ this.handleBitmaskChange(isSecondEvent) } />
                        <Form.Field
                            label='Constant Operation'
                            disabled={ this.props.uiState.isReadOnly }
                            control='select'
                            value={ event.ConstantOperation ? event.ConstantOperation.Operation : 'none' }
                            title='Constant Operation'
                            tabIndex='7'
                            onChange={ this.handleConstantOperationChange(isSecondEvent) }>
                            <option value='none'>None</option>
                            <option value={ Operation.Add }>Add</option>
                            <option value={ Operation.Divide }>Divide</option>
                            <option value={ Operation.Multiply }>Multiply</option>
                            <option value={ Operation.Subtract }>Subtract</option>
                        </Form.Field>
                        { event.ConstantOperation ?
                            <Form.Field
                                label='Value'
                                disabled={ this.props.uiState.isReadOnly }
                                control='input'
                                type='number'
                                value={ event.ConstantOperation.Operand }
                                title='Value'
                                tabIndex='7'
                                onChange={ this.handleConstantOperandChange(isSecondEvent) } /> : null
                        }
                    </Form.Group>
                    <Form.Group>
                        <Form.Field
                            label='Is Strict Transition'
                            disabled={ this.props.uiState.isReadOnly }
                            control='input'
                            type='checkbox'
                            value={ event.StrictTransition }
                            checked={ event.StrictTransition }
                            title='Is Strict Transition'
                            tabIndex='7'
                            onChange={ this.handleStrictTransitionChange(isSecondEvent) } />
                    </Form.Group>
                </React.Fragment>);
        }
    };
    private handleEventPropertyChange = (isSecondEvent: boolean) => (e: any) => this.props.updateTriggerFilterEvent({ TriggerFilterId: this.props.triggerFilter.TriggerFilterId, IsSecondEvent: isSecondEvent, Property: e.target.value });
    private handleTriggerFilterEventTypeChange = (isSecondEvent: boolean) => (e: any) => this.props.updateTriggerFilterEvent({ TriggerFilterId: this.props.triggerFilter.TriggerFilterId, IsSecondEvent: isSecondEvent, Type: e.target.value });
    private handleEventChange = (isSecondEvent: boolean) => (e: any) => this.props.updateTriggerFilterEvent({ TriggerFilterId: this.props.triggerFilter.TriggerFilterId, IsSecondEvent: isSecondEvent, TriggerEventId: e.target.value });
    private handleEventPropertyTypeChange = (isSecondEvent: boolean) => (e: any) => this.props.updateTriggerFilterEvent({ TriggerFilterId: this.props.triggerFilter.TriggerFilterId, IsSecondEvent: isSecondEvent, PropertyType: e.target.value });
    private handleBitmaskChange = (isSecondEvent: boolean) => (e: any) => this.props.updateTriggerFilterEvent({ TriggerFilterId: this.props.triggerFilter.TriggerFilterId, IsSecondEvent: isSecondEvent, Bitmask: e.target.value });
    private handleConstantOperationChange = (isSecondEvent: boolean) => (e: any) => {
        let operation = e.target.value;
        if (operation === 'none') {
            this.props.updateTriggerFilterEvent({ TriggerFilterId: this.props.triggerFilter.TriggerFilterId, IsSecondEvent: isSecondEvent, ConstantOperand: null, ConstantOperation: null });
        } else {
            this.props.updateTriggerFilterEvent({ TriggerFilterId: this.props.triggerFilter.TriggerFilterId, IsSecondEvent: isSecondEvent, ConstantOperation: operation });
        }
    }
    private handleConstantOperandChange = (isSecondEvent: boolean) => (e: any) => this.props.updateTriggerFilterEvent({ TriggerFilterId: this.props.triggerFilter.TriggerFilterId, IsSecondEvent: isSecondEvent, ConstantOperand: e.target.value });
    private handleStrictTransitionChange = (isSecondEvent: boolean) => (e: any) => this.props.updateTriggerFilterEvent({ TriggerFilterId: this.props.triggerFilter.TriggerFilterId, IsSecondEvent: isSecondEvent, StrictTransition: e.target.checked });

    private renderTriggerEventOption = (triggerEvent: TriggerEvent) => {
        switch (triggerEvent.Type) {
            case 'manifestEtw':
                return (<option key={ `triggerEventId+${triggerEvent.TriggerEventId}` } value={ triggerEvent.TriggerEventId }>{ triggerEvent.EventName }</option>);
            case 'time':
                return (<option key={ `triggerEventId+${triggerEvent.TriggerEventId}` } value={ triggerEvent.TriggerEventId }>Wait { triggerEvent.DurationMs }ms</option>);
            case 'traceLogging':
                return (<option key={ `triggerEventId+${triggerEvent.TriggerEventId}` } value={ triggerEvent.TriggerEventId }>{ triggerEvent.EventName }</option>);
        }
    };

    private renderOperationOperand = () => {
        let operand = (this.props.triggerFilter.Type === 'single' ?
            <Form.Field
                label='Operand'
                disabled={ this.props.uiState.isReadOnly }
                control='input'
                width={ 12 }
                value={ this.props.triggerFilter.Operand }
                title='Operand'
                tabIndex='7'
                onChange={ this.handleOperandChange } /> : null);
        return <React.Fragment>
            <Divider horizontal>Comparison</Divider>
            <Form.Group>
                <Form.Field
                    label='Operation'
                    disabled={ this.props.uiState.isReadOnly }
                    control='select'
                    width={ 4 }
                    value={ this.props.triggerFilter.Operation }
                    title='Operation'
                    tabIndex='7'
                    onChange={ this.handleOperationChange }>
                    <option value={ TriggerFilterOperation.Equals }>{ TriggerFilterOperationDescription.Equals }</option>
                    <option value={ TriggerFilterOperation.GreaterThan }>{ TriggerFilterOperationDescription.GreaterThan }</option>
                    <option value={ TriggerFilterOperation.GreaterThanOrEqualTo }>{ TriggerFilterOperationDescription.GreaterThanOrEqualTo }</option>
                    <option value={ TriggerFilterOperation.LessThan }>{ TriggerFilterOperationDescription.LessThan }</option>
                    <option value={ TriggerFilterOperation.LessThanOrEqualTo }>{ TriggerFilterOperationDescription.LessThanOrEqualTo }</option>
                    <option value={ TriggerFilterOperation.Like }>{ TriggerFilterOperationDescription.Like }</option>
                    <option value={ TriggerFilterOperation.NotEquals }>{ TriggerFilterOperationDescription.NotEquals }</option>
                    <option value={ TriggerFilterOperation.NotLike }>{ TriggerFilterOperationDescription.NotLike }</option>
                    <option value={ TriggerFilterOperation.NotRegex }>{ TriggerFilterOperationDescription.NotRegex }</option>
                    <option value={ TriggerFilterOperation.Regex }>{ TriggerFilterOperationDescription.Regex }</option>
                </Form.Field>
                { operand }
            </Form.Group>
        </React.Fragment>;
    }
    private handleOperandChange = (e: any) => this.props.updateTriggerFilter({ TriggerFilterId: this.props.triggerFilter.TriggerFilterId, Operand: e.target.value });
    private handleOperationChange = (e: any) => this.props.updateTriggerFilter({ TriggerFilterId: this.props.triggerFilter.TriggerFilterId, Operation: e.target.value });
};