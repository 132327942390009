import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import * as ActionCreators from '../../../../actions/authoring/stateMachine/actions';
import TransitionsContainer from '../../../../components/authoring/stateMachine/transitions/TransitionsContainer';
import { IApplicationRootReducerState } from "../../../../configureStore";
import { uuidv4_id } from '../../../../utils/dataUtils';

const mapStateToProps = (state: IApplicationRootReducerState) => ({});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => ({
    addTransition: (fromStateId: string): string => {
        let newTransitionId = uuidv4_id();
        dispatch(ActionCreators.addStateMachineTransition({ TransitionId: newTransitionId, FromStateId: fromStateId }));
        return newTransitionId;
    },
    removeTransition: (transitionId: string) => dispatch(ActionCreators.removeStateMachineTransition(transitionId)),
    removeState: (stateId: string) => dispatch(ActionCreators.removeStateMachineState(stateId)),
    addState: () => dispatch(ActionCreators.addStateMachineState())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(TransitionsContainer);