import { combineReducers } from "redux";
import { ReviewApprovalActionTypes, ReviewingUiActionTypes, ReviewSearchActionTypes, SiufDomainAction, SiufSubmissionActionTypes } from "../actions";
import { ISectionUiState, ISectionUiState_Default, ISiufReviewInfo, ISiufReviewingDomainAppState, ISiufReviewingUiState, ISiufReviewSearchProperty, ISiufReviewSearchProperty_Default, ISiufReviewSortProperty, ISiufReviewSortProperty_Default, SiufEntityToReviewSiufEntityMapper, SiufReviewingSectionId, SiufReviewState } from "../contracts";

export const siufCollectionAppStateReducer = (state: ISiufReviewInfo[] = [], action: SiufDomainAction) => {
    switch (action.type) {
        case ReviewApprovalActionTypes.FETCH_REVIEW_SIUFS_SENT:
            return state;
        case ReviewApprovalActionTypes.FETCH_REVIEW_SIUFS_SUCCESS:
            return action.payload.response.map(s => {
                return {
                    siufId: s.SiufId,
                    siufReviewEntity: SiufEntityToReviewSiufEntityMapper(s),
                    reviewState: SiufReviewState.notReviewed,
                    errorMessage: ""
                };
            });
        case ReviewApprovalActionTypes.FETCH_REVIEW_SIUFS_FAIL:
            return state;
        case ReviewApprovalActionTypes.REVIEW_APPROVE_SIUFS_SENT:
        case ReviewApprovalActionTypes.REVIEW_REJECT_SIUFS_SENT:
        case SiufSubmissionActionTypes.TERMINATE_SIUF_SENT:
            return [
                // 1st section, if any
                ...state.slice(
                    0,
                    state.findIndex(s => s.siufId === action.payload.siufId)),
                // Item to be modified
                {
                    siufId: action.payload.siufId,
                    siufReviewEntity: state.find(s => s.siufId === action.payload.siufId).siufReviewEntity,
                    reviewState: SiufReviewState.inProgress,
                    errorMessage: ""
                },
                // 3rd section, if any
                ...state.slice(
                    state.findIndex(s => s.siufId === action.payload.siufId) + 1,
                    state.length)
            ];
        case ReviewApprovalActionTypes.REVIEW_APPROVE_SIUFS_SUCCESS:
            return [
                // 1st section, if any
                ...state.slice(
                    0,
                    state.findIndex(s => s.siufId === action.payload.response.SiufId)),
                // Item to be modified
                {
                    siufId: action.payload.siufId,
                    siufReviewEntity: state.find(s => s.siufId === action.payload.siufId).siufReviewEntity,
                    reviewState: SiufReviewState.approved,
                    errorMessage: ""
                },
                // 3rd section, if any
                ...state.slice(
                    state.findIndex(s => s.siufId === action.payload.response.SiufId) + 1,
                    state.length)
            ];
        case ReviewApprovalActionTypes.REVIEW_REJECT_SIUFS_SUCCESS:
            return [
                // 1st section, if any
                ...state.slice(
                    0,
                    state.findIndex(s => s.siufId === action.payload.response.SiufId)),
                // Item to be modified
                {
                    siufId: action.payload.siufId,
                    siufReviewEntity: state.find(s => s.siufId === action.payload.siufId).siufReviewEntity,
                    reviewState: SiufReviewState.rejected,
                    errorMessage: ""
                },
                // 3rd section, if any
                ...state.slice(
                    state.findIndex(s => s.siufId === action.payload.response.SiufId) + 1,
                    state.length)
            ];
        case SiufSubmissionActionTypes.TERMINATE_SIUF_SUCCESS:
            return [
                // 1st section, if any
                ...state.slice(
                    0,
                    state.findIndex(s => s.siufId === action.payload.response.SiufId)),
                // Item to be modified
                {
                    siufId: action.payload.siufId,
                    siufReviewEntity: state.find(s => s.siufId === action.payload.siufId).siufReviewEntity,
                    reviewState: SiufReviewState.terminated,
                    errorMessage: ""
                },
                // 3rd section, if any
                ...state.slice(
                    state.findIndex(s => s.siufId === action.payload.response.SiufId) + 1,
                    state.length)
            ];
        case ReviewApprovalActionTypes.REVIEW_APPROVE_SIUFS_FAIL:
        case ReviewApprovalActionTypes.REVIEW_REJECT_SIUFS_FAIL:
        case SiufSubmissionActionTypes.TERMINATE_SIUF_FAIL:
            return [
                // 1st section, if any
                ...state.slice(
                    0,
                    state.findIndex(s => s.siufId === action.payload.siufId)),
                // Item to be modified
                {
                    siufId: action.payload.siufId,
                    siufReviewEntity: state.find(s => s.siufId === action.payload.siufId).siufReviewEntity,
                    reviewState: SiufReviewState.error,
                    errorMessage: action.payload.error
                },
                // 3rd section, if any
                ...state.slice(
                    state.findIndex(s => s.siufId === action.payload.siufId) + 1,
                    state.length)
            ];
        default:
            return state;
    }
};

export const reviewingSearchAppStateReducer = (state: ISiufReviewSearchProperty = ISiufReviewSearchProperty_Default, action: SiufDomainAction) => {
    switch (action.type) {
        case ReviewSearchActionTypes.UPDATE_SEARCH_PROPERTIES:
            return {
                ...state,
                ...action.payload.searchProperties
            };
        default:
            return state;
    }
};

export const reviewSortPropertyAppStateReducer = (state: ISiufReviewSortProperty = ISiufReviewSortProperty_Default, action: SiufDomainAction) => {
    switch (action.type) {
        case ReviewSearchActionTypes.UPDATE_SORT_PROPERTY:
            return {
                ...state,
                property: action.payload.property,
                isAscending: !state.isAscending
            };
        default:
            return state;
    }
};

export const reviewingAppStateReducer = combineReducers<ISiufReviewingDomainAppState>({
    siufCollection: siufCollectionAppStateReducer,
    searchProperty: reviewingSearchAppStateReducer,
    sortProperty: reviewSortPropertyAppStateReducer
});

export const reviewingSearchUiStateReducer = (state: ISectionUiState = ISectionUiState_Default, action: SiufDomainAction) => {
    switch (action.type) {
        case ReviewingUiActionTypes.UI_UPDATE_AUTHORING_SECTION_VISIBILITY:
            return {
                ...state,
                isVisible: (action.payload.section === SiufReviewingSectionId.search) ?
                    action.payload.isVisible :
                    state.isVisible
            };
        default:
            return state;
    }
};

export const reviewingApprovalUiStateReducer = (state: ISectionUiState = ISectionUiState_Default, action: SiufDomainAction) => {
    switch (action.type) {
        case ReviewingUiActionTypes.UI_UPDATE_AUTHORING_SECTION_VISIBILITY:
            return {
                ...state,
                isVisible: (action.payload.section === SiufReviewingSectionId.approval) ?
                    action.payload.isVisible :
                    state.isVisible
            };
        case ReviewApprovalActionTypes.FETCH_REVIEW_SIUFS_SENT:
            return {
                ...state,
                isLoading: true
            };
        case ReviewApprovalActionTypes.FETCH_REVIEW_SIUFS_SUCCESS:
            return {
                ...state,
                isLoading: false
            };
        case ReviewApprovalActionTypes.FETCH_REVIEW_SIUFS_FAIL:
            return {
                ...state,
                isLoading: false
            };
        default:
            return state;
    }
};

export const reviewingUiStateReducer = combineReducers<ISiufReviewingUiState>({
    search: reviewingSearchUiStateReducer,
    approval: reviewingApprovalUiStateReducer
});