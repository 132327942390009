export enum SiufSubmissionActionTypes {

    // Ajax related actions types

    SAVE_SIUF_SENT = '@@SIUF/SUBMISSION/SAVE_SIUF_SENT',
    SAVE_SIUF_SUCCESS = '@@SIUF/SUBMISSION/SAVE_SIUF_SUCCESS',
    SAVE_SIUF_FAIL = '@@SIUF/SUBMISSION/SAVE_SIUF_FAIL',

    VALIDATE_SIUF_SENT = '@@SIUF/SUBMISSION/VALIDATE_SIUF_SENT',
    VALIDATE_SIUF_SUCCESS = '@@SIUF/SUBMISSION/VALIDATE_SIUF_SUCCESS',
    VALIDATE_SIUF_FAIL = '@@SIUF/SUBMISSION/VALIDATE_SIUF_FAIL',

    SUBMIT_SIUF_SENT = '@@SIUF/SUBMISSION/SUBMIT_SIUF_SENT',
    SUBMIT_SIUF_SUCCESS = '@@SIUF/SUBMISSION/SUBMIT_SIUF_SUCCESS',
    SUBMIT_SIUF_FAIL = '@@SIUF/SUBMISSION/SUBMIT_SIUF_FAIL',

    WITHDRAW_SIUF_SENT = '@@SIUF/SUBMISSION/WITHDRAW_SIUF_SENT',
    WITHDRAW_SIUF_SUCCESS = '@@SIUF/SUBMISSION/WITHDRAW_SIUF_SUCCESS',
    WITHDRAW_SIUF_FAIL = '@@SIUF/SUBMISSION/WITHDRAW_SIUF_FAIL',

    TERMINATE_SIUF_FROM_AUTHOR_SENT = '@@SIUF/SUBMISSION/TERMINATE_SIUF_FROM_AUTHOR_SENT',
    TERMINATE_SIUF_FROM_AUTHOR_SUCCESS = '@@SIUF/SUBMISSION/TERMINATE_SIUF_FROM_AUTHOR_SUCCESS',
    TERMINATE_SIUF_FROM_AUTHOR_FAIL = '@@SIUF/SUBMISSION/TERMINATE_SIUF_FROM_AUTHOR_FAIL',

    TERMINATE_SIUF_SENT = '@@SIUF/SUBMISSION/TERMINATE_SIUF_SENT',
    TERMINATE_SIUF_SUCCESS = '@@SIUF/SUBMISSION/TERMINATE_SIUF_SUCCESS',
    TERMINATE_SIUF_FAIL = '@@SIUF/SUBMISSION/TERMINATE_SIUF_FAIL',

    REACTIVATE_SIUF_SENT = '@@SIUF/SUBMISSION/REACTIVATE_SIUF_SENT',
    REACTIVATE_SIUF_SUCCESS = '@@SIUF/SUBMISSION/REACTIVATE_SIUF_SUCCESS',
    REACTIVATE_SIUF_FAIL = '@@SIUF/SUBMISSION/REACTIVATE_SIUF_FAIL',

    SIDELOAD_SIUF_SENT = '@@SIUF/SUBMISSION/SIDELOAD_SIUF_SENT',
    SIDELOAD_SIUF_SUCCESS = '@@SIUF/SUBMISSION/SIDELOAD_SIUF_SUCCESS',
    SIDELOAD_SIUF_FAIL = '@@SIUF/SUBMISSION/SIDELOAD_SIUF_FAIL',

    GET_SIUF_XML_SENT = '@@SIUF/SUBMISSION/GET_SIUF_XML_SENT',
    GET_SIUF_XML_SUCCESS = '@@SIUF/SUBMISSION/GET_SIUF_XML_SUCCESS',
    GET_SIUF_XML_FAIL = '@@SIUF/SUBMISSION/GET_SIUF_XML_FAIL',

    // normal action types

    DISMISS_SUBMIT_STATE = '@@SIUF/SUBMISSION/DISMISS_SUBMIT_STATE',
    UPDATE_EXPORT_EXTENDED_PAYLOAD = '@@SIUF/SUBMISSION/UPDATE_EXPORT_EXTENDED_PAYLOAD'
}