import * as React from 'react';
import { Confirm, Form, Message } from 'semantic-ui-react';
import { IJustificationEntity, INPUT_DELAY, IUpdateJustificationModel, SiufTypeSelection } from '../../../contracts/models';
import { ISectionUiState } from '../../../contracts/states';
import { GetDisplayNameForSiufTypeSelections } from '../../../utils';

type Props = {
    siufType: SiufTypeSelection;
    siufName: string;
    ownerAlias: string;
    justifications: IJustificationEntity[];
    uiState: ISectionUiState;
    onUpdateSiufTypeSelection: (siufType: SiufTypeSelection) => void;
    onUpdateSiufName: (siufName: string) => void;
    onUpdateOwnerAlias: (ownerAlias: string) => void;
    onUpdateJustifications: (justification: IUpdateJustificationModel) => void;
};

type State = {
    isConfirmationOpen: boolean;
    selection: SiufTypeSelection;
};

export default class SiufBasics extends React.Component<Props, State> {

    constructor (props: Props) {
        super(props);

        this.props.onUpdateSiufTypeSelection.bind(this);
        this.props.onUpdateSiufName.bind(this);
        this.props.onUpdateOwnerAlias.bind(this);
        this.props.onUpdateJustifications.bind(this);

        this.open.bind(this);
        this.close.bind(this);

        this.state = {
            isConfirmationOpen: false,
            selection: SiufTypeSelection.singleQuestion
        };
    }

    private open = (selection: SiufTypeSelection) => this.setState({ isConfirmationOpen: true, selection: selection });
    private close = () => this.setState({ ...this.state, isConfirmationOpen: false });

    // Delay the update
    private timer: NodeJS.Timeout;
    private onUpdateWithDelay = (func: any, ...params: any[]) => {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Function/call
            return func.call(this.props, ...params);
        }, INPUT_DELAY);
    }

    public render () {

        let justificationView = (
            <React.Fragment>
                <Message info>
                    If your SIUF targets any devices that aren't Insiders or Internal, you MUST answer the following prompts below.
                    These answers are required for us to approve your SIUF, even if it has been approved before.
            </Message>
                {
                    this.props.justifications.map(j => {
                        return (
                            <Form.Input key={ j.QuestionType } label={ j.Question }
                                fluid
                                defaultValue={ j.Answer }
                                readOnly={ this.props.uiState.isReadOnly }
                                placeholder='Enter response here'
                                maxLength='500'
                                tabIndex='4'
                                onChange={ e => this.props.onUpdateJustifications({
                                    QuestionType: j.QuestionType,
                                    Answer: e.currentTarget.value
                                }) } />
                        );
                    })
                }
            </React.Fragment>
        );

        return (
            <React.Fragment>
                <Form>
                    <Form.Input fluid label='SIUF Name' required defaultValue={ this.props.siufName }
                        readOnly={ this.props.uiState.isReadOnly }
                        tabIndex='4'
                        placeholder='Enter a name for this SIUF'
                        onChange={ e => { this.onUpdateWithDelay(this.props.onUpdateSiufName, e.currentTarget.value); } } />
                    <Form.Input fluid label='SIUF Owner' required defaultValue={ this.props.ownerAlias }
                        readOnly={ this.props.uiState.isReadOnly }
                        tabIndex='4'
                        placeholder='Enter the owner alias for this SIUF'
                        onChange={ e => { this.onUpdateWithDelay(this.props.onUpdateOwnerAlias, e.currentTarget.value); } } />
                    <Form.Group inline>
                        <label>Select the type of SIUF:</label>
                        <Form.Radio
                            label='Single Question SIUF'
                            name='SiufStructure_SingleQuestion'
                            readOnly={ this.props.uiState.isReadOnly }
                            tabIndex='4'
                            className = 'ui radio_button_new'
                            title='Single Question SIUF'
                            checked={ this.props.siufType === SiufTypeSelection.singleQuestion }
                            value={ SiufTypeSelection.singleQuestion }
                            onChange={ () => this.open(SiufTypeSelection.singleQuestion) }
                        />
                        <Form.Radio
                            label='Multi Question SIUF'
                            name='SiufStructure_MultiQuestion'
                            readOnly={ this.props.uiState.isReadOnly }
                            tabIndex='4'
                            className = 'ui radio_button_new'
                            title='Multi Question SIUF'
                            checked={ this.props.siufType === SiufTypeSelection.multiQuestion }
                            value={ SiufTypeSelection.multiQuestion }
                            onChange={ () => this.open(SiufTypeSelection.multiQuestion) }
                        />
                        <Confirm
                            content={ `Changing question structure to ${GetDisplayNameForSiufTypeSelections(this.state.selection)} will remove any existing question content, are you sure?` }
                            cancelButton='No, keep existing type'
                            confirmButton="Yes, change to new type"
                            open={ this.state.isConfirmationOpen }
                            onCancel={ () => this.close() }
                            onConfirm={ () => {
                                this.props.onUpdateSiufTypeSelection(this.state.selection);
                                this.close();
                            } }
                        />
                    </Form.Group>
                    { justificationView }
                </Form>
            </React.Fragment>
        );
    }
}
